import React, { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  DELETE_BUDGET,
  GET_BUDGET_TEMPLATES,
  GET_BUDGETS,
  UPDATE_BUDGET,
} from '@atom/graph/budget';
import { Icon, IconButton, Menu, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  Budget,
  BudgetModal,
  BudgetsConnection,
  BudgetsConnectionInput,
  BudgetTemplatesConnection,
  BudgetUpdateInput,
} from '@atom/types/budget';
import history from '@atom/utilities/history';
import { formatDateMoment } from '@atom/utilities/timeUtilities';

import BudgetDetailContext from './BudgetDetailContext';
const { MenuItem } = Menu;

import BudgetsFormModal from '../budgets/budgetsModal/BudgetsFormModal';
import DeleteModal from '../common/DeleteModal';

import '../../styles/detail-header.css';

const styles = {
  menuIcon: {
    margin: '0.75rem',
    color: colors.neutral.white,
  },
};

const formatDate = (millis: number) => formatDateMoment(millis, 'MM/DD/YYYY');

const BudgetDetailHeader = () => {
  const { budget } = useContext(BudgetDetailContext);
  const [activeModal, setActiveModal] = useState<BudgetModal>(null);

  const { data: budgetOptionsData, loading: loadingBudgetsDropdown } = useQuery<
    { budgets: BudgetsConnection },
    { input: BudgetsConnectionInput }
  >(GET_BUDGETS, {
    variables: {
      input: {
        templateId: budget.templateId,
      },
    },
  });

  const budgetOptions: Budget[] = useMemo(
    () => R.pathOr([], ['budgets', 'budgets'], budgetOptionsData),
    [budgetOptionsData],
  );

  const { data: templatesData, loading: loadingTemplates } = useQuery<{
    budgetTemplates: BudgetTemplatesConnection;
  }>(GET_BUDGET_TEMPLATES);

  const templates = R.pathOr(
    [],
    ['budgetTemplates', 'budgetTemplates'],
    templatesData,
  );

  const budgetNamesUsed = useMemo(
    () => new Set(budgetOptions.map(({ name }) => name)),
    [budgetOptions],
  );

  const [deleteBudget] = useMutation<{}, { id: string }>(DELETE_BUDGET);

  const handleDeleteBudget = async () => {
    try {
      await deleteBudget({ variables: { id: budget.id } });
      Snackbar.info({
        message: `Deleted budget "${budget.name}"`,
      });
      history.push('/budgets');
    } catch {
      Snackbar.error({
        message: `An error occurred while deleting ${budget.name}. Please Try Again.`,
      });
    } finally {
      setActiveModal(null);
    }
  };

  const [updateBudget] = useMutation<
    { budgetUpdate: Budget },
    { input: BudgetUpdateInput }
  >(UPDATE_BUDGET);

  const handleUpdateBudget = async (input: BudgetUpdateInput) => {
    try {
      await updateBudget({
        variables: {
          input,
        },
      });
      Snackbar.info({
        message: `Updated budget "${input.name}"`,
      });
    } catch (error) {
      Snackbar.error({
        message:
          'An error occurred while updating your budget. Please Try Again.',
      });
    } finally {
      setActiveModal(null);
    }
  };

  const datePortion: string = ` (${formatDate(
    budget?.startDate?.valueOf(),
  )} - ${formatDate(budget?.endDate?.valueOf())})`;

  const title: string = !R.isEmpty(budget)
    ? `${budget.name}${datePortion}`
    : '';

  return (
    <div styleName="header-container budgets-header">
      <div styleName="name-container">
        <IconButton onClick={() => history.push('/budgets')} tooltip="Go back">
          <Icon color={colors.neutral.white}>arrow_back</Icon>
        </IconButton>
        <span>{title}</span>
        {loadingBudgetsDropdown && !R.isEmpty(budgetOptions) ? (
          <Progress />
        ) : (
          <Menu
            id="budget_navigation"
            noIconButton
            icon={<Icon style={styles.menuIcon}>arrow_drop_down</Icon>}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          >
            <div styleName="menu-heading">{budget.templateName}</div>
            {budgetOptions?.map(option => (
              <MenuItem
                onClick={() => history.push(`/budget/${option.id}`)}
                key={option.id}
                disabled={option.id === budget.id}
              >
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
      <div>
        <IconButton
          onClick={() => setActiveModal(BudgetModal.EDIT)}
          tooltip="Edit Budget"
        >
          <Icon color={colors.neutral.white}>edit</Icon>
        </IconButton>
        <IconButton
          onClick={() => setActiveModal(BudgetModal.DELETE)}
          tooltip="Delete Budget"
        >
          <Icon color={colors.neutral.white}>delete</Icon>
        </IconButton>
        {activeModal === BudgetModal.EDIT && !loadingTemplates && (
          <BudgetsFormModal
            onClose={() => setActiveModal(null)}
            budget={budget}
            modalType={activeModal}
            templates={templates}
            budgetNamesUsed={budgetNamesUsed}
            handleUpdateBudget={handleUpdateBudget}
          />
        )}
        <DeleteModal
          open={activeModal === BudgetModal.DELETE}
          title="Delete Budget?"
          content={`Are you sure you want to delete budget "${budget?.name}"?`}
          onCancel={() => setActiveModal(null)}
          onConfirm={handleDeleteBudget}
        />
      </div>
    </div>
  );
};

export default BudgetDetailHeader;
