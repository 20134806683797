import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_BUDGET } from '@atom/graph/budget';
import { Progress } from '@atom/mui';
import {
  Budget,
  BudgetCategory,
  BudgetItem,
  BudgetItemTemplate,
  BudgetUnit,
} from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailBreadcrumbs from './BudgetDetailBreadcrumbs';
import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailFilters from './BudgetDetailFilters';
import BudgetDetailHeader from './BudgetDetailHeader';
import BudgetDetailUnit from './BudgetDetailUnit';
import { EditField } from './budgetDetailUtils';

import './budgetDetail.css';

interface Props {
  match: any;
}

const BudgetDetail = ({ match }: Props) => {
  const [parentBudgetUnit, setParentBudgetUnit] = useState<BudgetUnit>();
  const [childBudgetUnits, setChildBudgetUnits] = useState<BudgetUnit[]>([]);
  const [budgetCategories, setBudgetCategories] = useState<BudgetCategory[]>(
    null,
  );
  const [unitsVisited, setUnitsVisited] = useState<{ id?: BudgetUnit }>({});

  // filters
  const [excludeZeroBudgetItems, setExcludeZeroBudgetItems] = useState<boolean>(
    false,
  );
  const [categoryFilters, setCategoryFilters] = useState<BudgetCategory[]>([]);
  const [budgetItemTemplateFilters, setBudgetItemTemplateFilters] = useState<
    BudgetItemTemplate[]
  >([]);
  const categoryIds = useMemo(
    () => categoryFilters.map(category => category.id),
    [categoryFilters],
  );
  const budgetItemTemplateNames = useMemo(
    () => budgetItemTemplateFilters.map(template => template.name),
    [budgetItemTemplateFilters],
  );

  // Item Editing
  const [editingItem, setEditingItem] = useState<BudgetItem>();
  const [editingField, setEditingField] = useState<EditField>();
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(
    new Set([]),
  );

  const { data: budgetData, loading } = useQuery(GET_BUDGET, {
    variables: {
      id: match.params.id,
    },
    fetchPolicy: 'network-only',
  });
  const budget: Budget = R.pathOr(null, ['budget'], budgetData);

  useEffect(() => {
    if (!isNilOrEmpty(parentBudgetUnit) && !unitsVisited[parentBudgetUnit.id]) {
      setUnitsVisited({
        ...unitsVisited,
        [parentBudgetUnit.id]: parentBudgetUnit,
      });
    }
  }, [parentBudgetUnit]);

  return (
    <BudgetDetailContext.Provider
      value={{
        budget,
        parentBudgetUnit,
        setParentBudgetUnit,
        childBudgetUnits,
        setChildBudgetUnits,
        budgetCategories,
        setBudgetCategories,
        unitsVisited,
        setUnitsVisited,
        categoryFilters,
        categoryIds,
        setCategoryFilters,
        budgetItemTemplateFilters,
        setBudgetItemTemplateFilters,
        budgetItemTemplateNames,
        editingItem,
        setEditingItem,
        editingField,
        setEditingField,
        expandedCategories,
        setExpandedCategories,
        excludeZeroBudgetItems,
        setExcludeZeroBudgetItems,
      }}
    >
      <>
        {loading || isNilOrEmpty(budget) ? (
          <Progress />
        ) : (
          <>
            <BudgetDetailHeader />
            <div styleName="container">
              <BudgetDetailBreadcrumbs />
              <BudgetDetailFilters />
              <BudgetDetailUnit />
            </div>
          </>
        )}
      </>
    </BudgetDetailContext.Provider>
  );
};

export default BudgetDetail;
