import React, { useState } from 'react';

import AdditionalOptions from '@atom/components/common/AdditionalOptions';
import RenameModal from '@atom/components/common/RenameModal';
import colors from '@atom/styles/colors';
import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import './formInstance.css';

interface Props {
  media: MediaItem;
  assetId: string;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
}

const MediaTile = ({ media, assetId, removeFile, renameMedia }: Props) => {
  const [name, setName] = useState<string>(media.name);

  const { id, type, fileId, fileExtension } = media;

  const handleRename = (mediaId: string, updatedName: string) => {
    setName(updatedName);
    renameMedia(media.id, assetId, updatedName);
  };

  const handleRemove = () => {
    removeFile(media.id, assetId);
  };

  return (
    <div styleName="media-tile-container">
      <div styleName="media-image-container">
        <img
          styleName="image"
          src={`${FILES_ENDPOINT}/${fileId}_180`}
          alt={name}
        />
      </div>
      <div styleName="media-tile-info">
        <div styleName="file-name">{`${name}.${fileExtension}`}</div>
        <AdditionalOptions
          iconStyle={{ color: colors.neutral.gray }}
          menuItems={[
            {
              type: 'trigger',
              primaryText: 'edit',
              id,
              element: (
                <RenameModal
                  id={id}
                  name={name}
                  type={type}
                  renameAction={handleRename}
                />
              ),
            },
            {
              type: 'dialog',
              primaryText: 'delete',
              modalProps: {
                labelCancel: 'Cancel',
                labelConfirm: 'Delete',
                confirmAction: handleRemove,
                title: `Delete ${name}`,
                text: 'Are you sure you want to delete this photo?',
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default MediaTile;
