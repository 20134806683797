import React, { useMemo, useRef } from 'react';

import { Icon, List, Popover, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetCategory } from '@atom/types/budget';

const { ListItemButton } = List;

export type ComboSelectProps = {
  /**
   * Displayed before selected items and as placeholder text
   */
  itemName: string;
  /**
   * Is the options visible
   */
  open?: boolean;
  /**
   * Set popover open state
   */
  setOpen: (open: boolean) => void;
  /**
   * Objects to display as options
   */
  options: { id: string; name: string }[];
  /**
   * Are options loading
   */
  loading: boolean;
  /**
   * Trigger option call
   */
  fetchOptions: () => void;
  /**
   * Search string from input
   */
  query: string;
  /**
   * Triggers search query
   */
  onQueryChange: (value: string) => void;
  /**
   * Selected option ids
   */
  selectedOptions: BudgetCategory[];
  /**
   * Selected option ids
   */
  onSelectOption: (option: { id: string; name: string }) => void;
  /**
   * Use to clear selected
   */
  clearSelections: () => void;
  /**
   * How many characters to display on the selected string
   * - defaults to 35
   */
  truncation?: number;
  /**
   * Used as id for QA tests
   */
  dataCyLabel?: string;
};

const styles = {
  popover: {
    marginTop: '0.5rem',
  },
  progressContainer: {
    padding: '0.5rem',
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: '0.5rem 0.5rem 0 0.5rem',
    borderBottom: `1px solid ${colors.neutral.gray}`,
    color: colors.neutral.gray,
  },
};

const getButtonStyle = selectedOptions => ({
  border: `1px solid ${colors.neutral.ash}`,
  borderRadius: '0.25rem',
  color:
    selectedOptions?.length > 0 ? colors.neutral.black : colors.neutral.gray,
  padding: '0.25rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
});

export const ComboSelect = (props: ComboSelectProps) => {
  const {
    itemName,
    open = false,
    setOpen,
    loading,
    options,
    fetchOptions,
    query,
    onQueryChange,
    dataCyLabel,
    selectedOptions,
    onSelectOption,
    clearSelections,
    truncation = 35,
  } = props;
  const anchor = useRef();

  // Pin selectedOptions to top of options
  const selectedOptionIds = useMemo(
    () => selectedOptions.map(selectedOption => selectedOption.id),
    [selectedOptions],
  );
  const topOptions = useMemo(
    () => options.filter(option => selectedOptionIds.includes(option.id)),
    [options, selectedOptions],
  );

  const handleClick = () => {
    fetchOptions();
    setOpen(true);
  };

  const selectedLabel: string = useMemo(() => {
    const valueString = selectedOptions
      .map(value => value.name)
      .join(', ')
      .slice(0, truncation);
    return `: ${valueString}${valueString.length >= truncation ? '...' : ''} (${
      selectedOptions.length
    })`;
  }, [selectedOptions]);

  const handleClearSelection = event => {
    event.stopPropagation();
    clearSelections();
  };

  return (
    <>
      <div
        onClick={handleClick}
        ref={anchor}
        style={getButtonStyle(selectedOptions)}
        data-cy={dataCyLabel}
      >
        {itemName}
        {selectedOptions?.length > 0 && selectedLabel}
        {selectedOptions?.length > 0 ? (
          <Icon onClick={handleClearSelection}>close</Icon>
        ) : (
          <Icon>arrow_drop_down</Icon>
        )}
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        style={styles.popover}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <div style={styles.searchInput}>
          <Icon>search</Icon>
          <TextField
            placeholder="Search"
            value={query}
            onChange={event => onQueryChange(event.target.value)}
            fullWidth={false}
            disableUnderline
          />
        </div>
        {loading ? (
          <div style={styles.progressContainer}>
            <Progress size={20} />
          </div>
        ) : (
          <List>
            {topOptions.map(option => (
              <ListItemButton
                selected
                onClick={() => onSelectOption(option)}
                key={option.id}
                disableRipple
              >
                {option.name}
              </ListItemButton>
            ))}
            {options.map(
              option =>
                !topOptions.includes(option) && (
                  <ListItemButton
                    onClick={() => onSelectOption(option)}
                    key={option.id}
                    disableRipple
                  >
                    {option.name}
                  </ListItemButton>
                ),
            )}
          </List>
        )}
      </Popover>
    </>
  );
};

export default ComboSelect;
