import React from 'react';

import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';

import { Tab } from '../../../BulkContext';
import Field from '../Field';

import TeamSection from './teamSection/TeamSection';
import TaskDateField from './TaskDateField';
import TaskDescription from './TaskDescription';
import TaskFields from './TaskFields';

import '../body.css';

export interface Props {
  tab: Tab;
}

const TaskBody = ({ tab }: Props) => (
  <div styleName="body">
    <div styleName="body-header">
      <div styleName="body-header-title">
        {tab.subText}: {tab.label}
      </div>
    </div>
    <div styleName="body-content">
      <div styleName="body-content-two-column">
        <Field
          icon="event"
          input={
            <TaskDateField
              label="Due Date"
              name="dueDate"
              disabled={doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR)}
              taskIndex={tab.taskIndex}
            />
          }
        />
        <Field
          input={
            <TaskDateField
              label="Start Date"
              name="startTime"
              disabled={doesNotHaveRolePermissions(ROLE_SETS.MANAGER)}
              taskIndex={tab.taskIndex}
            />
          }
        />
      </div>
      <Field
        icon="subject"
        input={<TaskDescription taskIndex={tab.taskIndex} />}
      />
      <TaskFields taskIndex={tab.taskIndex} />
      <div styleName="body-content-section">
        <TeamSection taskIndex={tab.taskIndex} />
      </div>
    </div>
  </div>
);

export default TaskBody;
