import React from 'react';

import { Button, Paper } from '@atom/mui';
import colors from '@atom/styles/colors';
import history from '@atom/utilities/history';

import BudgetingHeader from './BudgetingHeader';

import './budgeting.css';

const styles = {
  paper: {
    width: '21.25rem',
    padding: '1.25rem',
  },
  button: {
    color: colors.brand.blue,
    marginTop: '1.25rem',
  },
};

const Budgeting = () => {
  return (
    <>
      <BudgetingHeader />
      <div styleName="container">
        <div styleName="left-pane" />
        <div styleName="right-pane">
          <Paper style={styles.paper}>
            <div styleName="card-title">Level of Service</div>
            <div styleName="bar">
              <div styleName="bar-divider" />
            </div>
            <div styleName="card-container">
              <div styleName="card-container-labels">
                <div>Created By</div>
                <div>Created On</div>
                <div>Inventory Type</div>
                <div>Published On</div>
              </div>
              <div styleName="card-container-values">
                <div>Patrick Skoglund</div>
                <div>10/10/2022</div>
                <div>Sign</div>
                <div>10/10/2022</div>
              </div>
            </div>
            <Button
              onClick={() =>
                // mock mongo ID for more realistic vaporware url
                history.push('/budgeting/611c0b9866dafb18ab50983c')
              }
              style={styles.button}
            >
              View Details
            </Button>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default Budgeting;
