import React, { createContext } from 'react';

import {
  GrantType,
  Policy,
  PolicyAction,
  PolicyResource,
  Resource,
} from '@atom/types/policy';

export type PolicyModalView = PolicyResource | GrantType | 'MAIN';

// base modal style for any view not included in policyModalStyles below
export const basePolicyModalStyle = {
  minHeight: '14rem',
  maxHeight: '50vh',
  padding: '2rem 2rem 0 2rem',
};

// views with custom modal styles can be added here
export const policyModalStyles: {
  [key in PolicyModalView]?: React.CSSProperties;
} = {
  [PolicyResource.INVENTORY_ITEM]: {
    ...basePolicyModalStyle,
    height: '50vh',
  },
  [PolicyResource.INVENTORY_FOLDER]: {
    ...basePolicyModalStyle,
    height: '50vh',
  },
  [PolicyResource.WORK_TYPE_FOLDER]: {
    ...basePolicyModalStyle,
    height: '50vh',
    padding: 0,
  },
  [PolicyResource.ANALYTICS_FOLDER]: {
    ...basePolicyModalStyle,
    height: '50vh',
  },
};

export interface PolicyModalState {
  view: PolicyModalView;
  resource: PolicyResource;
  grantType: GrantType;
  grants: Resource[];
  actions: PolicyAction[];
  existingPolicies: Policy[];
  loadingExistingPolicies: boolean;
}

export interface Context {
  state: PolicyModalState;
  updateState: (update: Partial<PolicyModalState>) => void;
  // policy being edited in edit modal
  policy?: Policy;
}

export const initialState: PolicyModalState = {
  view: 'MAIN',
  resource: null,
  grantType: null,
  grants: [],
  actions: [],
  existingPolicies: [],
  loadingExistingPolicies: false,
};

export default createContext<Context>({
  state: initialState,
  updateState: () => {},
});
