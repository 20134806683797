import { GeoJSONGeometry } from './geojson';

export enum LocationFieldDataType {
  SHORT_TEXT = 'shorttext',
  LONG_TEXT = 'longtext',
  DATE = 'date',
  DATE_TIME = 'datetime',
  ENUM_SINGLE = 'enumsingle',
  ENUM_MULTIPLE = 'enummultiple',
  NUMBER = 'number',
}

export enum ALDOTLocationDataTitle {
  MANAGEMENT_UNIT = 'Management Unit',
  COUNTY = 'County',
  ROAD_CLASS = 'Road Class',
  ROUTE = 'Route',
  DIRECTION = 'Direction',
  START_MILEPOST = 'Start Milepost',
  END_MILEPOST = 'End Milepost',
}

export interface ALDOTLocationDataState {
  [ALDOTLocationDataTitle.MANAGEMENT_UNIT]?: string;
  [ALDOTLocationDataTitle.COUNTY]?: string;
  [ALDOTLocationDataTitle.ROAD_CLASS]?: string;
  [ALDOTLocationDataTitle.ROUTE]?: string;
  [ALDOTLocationDataTitle.DIRECTION]?: string;
  [ALDOTLocationDataTitle.START_MILEPOST]?: any;
  [ALDOTLocationDataTitle.END_MILEPOST]?: any;
}

// Key is dropdown title, value is current enumeration value
// START_MILEPOST and END_MILEPOST are exceptions, representing min and max values
export interface ALDOTLocationOptionsState {
  [ALDOTLocationDataTitle.MANAGEMENT_UNIT]?: string[];
  [ALDOTLocationDataTitle.COUNTY]?: string[];
  [ALDOTLocationDataTitle.ROAD_CLASS]?: string[];
  [ALDOTLocationDataTitle.ROUTE]?: string[];
  [ALDOTLocationDataTitle.DIRECTION]?: string[];
  [ALDOTLocationDataTitle.START_MILEPOST]?: any;
  [ALDOTLocationDataTitle.END_MILEPOST]?: any;
}

export interface ALDOTLocationDataItem {
  title: string;
  dataType: LocationFieldDataType;
  enumeration?: string[];
  value?: any;
}

export interface TaskLocation {
  id: string;
  workOrderId: string;
  taskId: string;
  name: string;
  geometry: any;
  // Data is tenant specific, currently the only use case is ALDOT.
  // In the future this property will be dynamic
  data?: ALDOTLocationDataItem[];
  createdDate?: number;
  createdBy?: string;
  updatedDate?: number;
  updatedBy?: string;
}

export interface TaskLocationsConnection {
  totalCount: number;
  taskLocations: TaskLocation[];
}

export interface TaskLocationAutocomplete {
  components?: ALDOTLocationDataItem[];
  minMilePost?: number;
  maxMilePost?: number;
}

export interface TaskLocationFind {
  name: string;
  geometry: GeoJSONGeometry;
}

export interface TaskLocationsConnectionInput {
  ids: string[];
}

export interface TaskLocationCreateInput {
  workOrderId: string;
  taskId: string;
  name: string;
  geometry: GeoJSONGeometry;
  data?: ALDOTLocationDataItem[];
}

export interface TaskLocationUpdateInput {
  id: string;
  name?: string;
  geometry?: GeoJSONGeometry;
  data?: ALDOTLocationDataItem[];
}

export interface TaskLocationSimpleInputItem {
  name: string;
  value: string;
}

export interface TaskLocationSimpleInput {
  data?: TaskLocationSimpleInputItem[];
}
