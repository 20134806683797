import React from 'react';
import { Skeleton } from '@mui/material';

import './skeletonRow.css';

const SkeletonTableRow = () => (
  <div styleName="skeleton-table-row">
    <div styleName="skeleton-table-cell-1">
      <Skeleton width="100%" />
    </div>
    <div styleName="skeleton-table-cell-2">
      <Skeleton width="100%" />
    </div>
    <div styleName="skeleton-table-cell-3">
      <Skeleton width="100%" />
    </div>
    <div styleName="skeleton-table-cell-4">
      <Skeleton width="100%" />
    </div>
  </div>
);

export default SkeletonTableRow;
