import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { SCHEMA_UPDATE } from '@atom/graph/schema';
import { Button, Icon, Menu, Modal, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { SchemaTree, SchemaUpdateInput } from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import { getModalValue, ModalVariant } from './SchemaStatusModalValues';

import './schemaDetailHeader.css';

const { MenuItem } = Menu;

const HTTP_STATUS_CONFLICT = 422;

const SchemaStatus = () => {
  const { schemaTree, refetchSchemaTree } = useContext(SchemaDetailContext);

  const [modalVariant, setModalVariant] = useState<ModalVariant>();

  const [updateSchema] = useMutation<
    { schemaUpdate: SchemaTree },
    { input: SchemaUpdateInput }
  >(SCHEMA_UPDATE);

  const updateWorkTemplate = async () => {
    const isPublished = modalVariant === ModalVariant.PUBLISH_CONFIRM;

    try {
      await updateSchema({
        variables: {
          input: {
            schemaId: schemaTree?.id,
            isPublished,
          },
        },
      });

      refetchSchemaTree();

      setModalVariant(null);
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setModalVariant(ModalVariant.DRAFT_ERROR);
      } else {
        Snackbar.error({ message: 'An unknown error occurred' });
      }
    }
  };

  const modalValue = getModalValue(modalVariant);

  const getFooter = () => {
    return modalVariant === ModalVariant.DRAFT_ERROR ? (
      <Button
        color="primary"
        variant="contained"
        onClick={() => setModalVariant(null)}
      >
        {modalValue?.button}
      </Button>
    ) : (
      <div>
        <Button
          onClick={() => setModalVariant(null)}
          style={{ marginRight: '0.5rem' }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={updateWorkTemplate}
        >
          {modalValue?.button}
        </Button>
      </div>
    );
  };

  const icon = schemaTree.isPublished ? 'lock' : 'edit';
  const text = schemaTree.isPublished ? 'published' : 'draft';
  const publishedOnClick = schemaTree.isPublished
    ? () => {}
    : () => setModalVariant(ModalVariant.PUBLISH_CONFIRM);
  const draftOnClick = !schemaTree.isPublished
    ? () => {}
    : () => setModalVariant(ModalVariant.DRAFT_CONFIRM);

  return (
    <>
      <Menu
        icon={
          <div styleName="status-container">
            <Icon color={colors.neutral.white}>{icon}</Icon>
            <div styleName="status-text">{text}</div>
            <Icon color={colors.neutral.white}>arrow_drop_down</Icon>
          </div>
        }
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        noIconButton
      >
        <MenuItem
          key="published"
          onClick={publishedOnClick}
          selected={schemaTree.isPublished}
          startAdornment={<Icon>lock</Icon>}
        >
          <div styleName="status-option">
            <div>PUBLISHED</div>
            {schemaTree.isPublished && (
              <Icon color={colors.brand.blue}>check</Icon>
            )}
          </div>
        </MenuItem>
        <MenuItem
          key="draft"
          onClick={draftOnClick}
          selected={!schemaTree.isPublished}
          startAdornment={<Icon>edit</Icon>}
        >
          <div styleName="status-option">
            <div>DRAFT</div>
            {!schemaTree.isPublished && (
              <Icon color={colors.brand.blue}>check</Icon>
            )}
          </div>
        </MenuItem>
      </Menu>
      <Modal
        title={modalValue?.title}
        open={!isNilOrEmpty(modalVariant)}
        onCancel={() => setModalVariant(null)}
        footer={getFooter()}
      >
        {modalValue?.content}
      </Modal>
    </>
  );
};

export default SchemaStatus;
