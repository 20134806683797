import * as React from 'react';
import * as R from 'ramda';

import ElementNavigationIcon from '@atom/components/common/elements/ElementNavigationIcon';

import PreviewAttributeDetail from './PreviewAttributeDetail';

import './detail.css';

interface PassedProps {
  selectedItem: any;
}

type Props = PassedProps;

interface State {
  activeView: string;
}

const initialState = {
  activeView: 'info',
};

class PreviewDetailViewPane extends React.Component<Props, State> {
  state = initialState;

  setActiveView = (activeView: string) => {
    this.setState({ activeView });
  };

  setElementBodyPane = (activeView: string) => {
    const { selectedItem } = this.props;

    const content = {
      info: !R.isEmpty(selectedItem) ? (
        <PreviewAttributeDetail schema={selectedItem} />
      ) : (
        <div />
      ),
      media: <div />,
    };

    return content[activeView];
  };

  render() {
    const { activeView } = this.state;
    const { selectedItem } = this.props;

    const views = [
      {
        value: 'info',
        icon: 'info',
      },
      {
        value: 'media',
        icon: 'folder',
      },
    ];

    return !R.isEmpty(selectedItem) ? (
      <>
        <div styleName="icon-row">
          <div styleName="element-name">{selectedItem.name}</div>
          {views.map((view: Object) => {
            return (
              <ElementNavigationIcon
                // @ts-ignore
                key={view.value}
                // @ts-ignore
                value={view.value}
                // @ts-ignore
                icon={view.icon}
                activeView={activeView}
                setActiveView={this.setActiveView}
              />
            );
          })}
        </div>
        {this.setElementBodyPane(activeView)}
      </>
    ) : (
      <div />
    );
  }
}

export default PreviewDetailViewPane;
