import React, { useState } from 'react';
import LocationOnIcon from 'material-ui/svg-icons/communication/location-on';

import DeleteModal from '@atom/components/common/DeleteModal';
import DialogTrigger from '@atom/components/common/DialogTrigger';
import FontIconButton from '@atom/components/common/FontIconButton';
// @ts-ignore
import editSchemaIcon from '@atom/components/common/svgIcons/editSchemaIcon.svg';
// @ts-ignore
import lineTypeIconWhite from '@atom/components/common/svgIcons/lineTypeIconWhite.svg';
// @ts-ignore
import tabIcon from '@atom/components/common/svgIcons/tabIcon.svg';
import { Button, Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaDetail } from '@atom/types/inventory';

import EditSchemaModal from './EditSchemaModal';

import './dataManagementDetail.css';

interface Props {
  schema: InventorySchemaDetail;
  isEditView: boolean;
  isDisabled: boolean;
  editRootSchema: (
    name: string,
    colorId: number,
    markerId: number,
    isMaterial: boolean,
    locationType: string,
  ) => void;
  publishRootSchema: () => void;
  deleteRootSchema: () => void;
  navigateBack: () => void;
  toggleView: () => void;
  pendingChanges: boolean;
}

const styles = {
  icon: {
    width: '24px',
    height: '24px',
    color: colors.neutral.white,
    fontWeight: '500',
  },
  button: {
    padding: '0 1rem',
  },
};

const getLocationTypeIcon = (type: string) => {
  switch (type) {
    case 'Point': {
      return <LocationOnIcon color={colors.neutral.white} />;
    }
    case 'LineString': {
      return <img src={lineTypeIconWhite} />;
    }
    default: {
      return <Icon color={colors.neutral.white}>category</Icon>;
    }
  }
};

const DataManagementDetailHeader = ({
  schema,
  isEditView,
  isDisabled,
  navigateBack,
  toggleView,
  editRootSchema,
  publishRootSchema,
  deleteRootSchema,
  pendingChanges,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const icon = isEditView ? tabIcon : editSchemaIcon;
  const tooltip = isEditView ? 'View Details' : 'Edit Attributes and Sub Items';

  const locationTypeIcon = getLocationTypeIcon(schema.locationType);

  const handleBack = () => {
    if (pendingChanges) {
      setOpen(true);
      return;
    }

    navigateBack();
  };

  return (
    <div styleName="navigation-bar-container">
      <div styleName="back-navigation-container" onClick={handleBack}>
        <div styleName="back-button-icon">
          <Icon style={styles.icon}>arrow_back</Icon>
        </div>
        <div styleName="location-icon-container">{locationTypeIcon}</div>
        <div styleName="back-button-text">{schema.name}</div>
      </div>
      <div styleName="right-icon-container">
        {!isDisabled && (
          <EditSchemaModal schema={schema} editSchema={editRootSchema} />
        )}
        <IconButton onClick={toggleView} tooltip={tooltip}>
          <img src={icon} />
        </IconButton>
        <DialogTrigger
          labelCancel="Cancel"
          labelConfirm="Delete"
          confirmAction={deleteRootSchema}
          title={`Delete ${schema.name} Inventory Type?`}
          content="Deleting this inventory type will also delete all items under it and all associated assets."
          trigger={
            <FontIconButton
              icon="delete"
              color={colors.neutral.white}
              tooltip="Delete"
              tooltipPosition="bottom-left"
              // @ts-ignore
              style={{ marginRight: '0.5rem' }}
            />
          }
        />
        {isDisabled && !pendingChanges ? (
          <div styleName="published-block">
            <Icon color={colors.neutral.white}>lock</Icon>
            <div styleName="published-text">published</div>
          </div>
        ) : (
          <DialogTrigger
            labelCancel="Cancel"
            labelConfirm="Publish"
            confirmAction={publishRootSchema}
            title={
              pendingChanges ? 'Publish Changes' : 'Publish Inventory Type'
            }
            content={
              pendingChanges
                ? 'Changes will apply to all the items with this inventory type. Common attribute changes will apply to all the inventory items in the system. Are you sure you want to publish these changes?'
                : "Once inventory types are published and in use, you can't make changes or delete them. Are you sure you want to publish the inventory type?"
            }
            trigger={
              <Button
                style={{
                  color: colors.brand.purple,
                  backgroundColor: colors.neutral.white,
                }}
              >
                Publish
              </Button>
            }
          />
        )}
      </div>
      <DeleteModal
        open={open}
        title="Discard Changes?"
        content="Changes will not be saved. Are you sure you want to discard all changes?"
        onCancel={() => setOpen(false)}
        onConfirm={navigateBack}
        confirmText="Discard"
      />
    </div>
  );
};

export default DataManagementDetailHeader;
