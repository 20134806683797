import React, { useContext, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as R from 'ramda';

import {
  GET_BUDGET_CATEGORIES,
  GET_BUDGET_UNIT,
  GET_BUDGET_UNITS,
} from '@atom/graph/budget';
import {
  BudgetCategoriesConnection,
  BudgetCategoriesConnectionInput,
  BudgetUnit,
  BudgetUnitConnectionInput,
  BudgetUnitsConnection,
  BudgetUnitsConnectionInput,
} from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailCategories from './BudgetDetailCategories';
import BudgetDetailChildUnits from './BudgetDetailChildUnits';
import BudgetDetailContext from './BudgetDetailContext';

import './budgetDetail.css';

const BudgetDetailUnit = () => {
  const {
    budget,
    parentBudgetUnit,
    setParentBudgetUnit,
    childBudgetUnits,
    setChildBudgetUnits,
    budgetCategories,
    setBudgetCategories,
    categoryIds,
    budgetItemTemplateNames,
    excludeZeroBudgetItems,
  } = useContext(BudgetDetailContext);

  const { data: parentBudgetUnitData, refetch: refetchParentUnit } = useQuery<
    { budgetUnit: BudgetUnit },
    { input: BudgetUnitConnectionInput }
  >(GET_BUDGET_UNIT, {
    variables: {
      input: {
        budgetId: budget.id,
        budgetUnitId: parentBudgetUnit?.id || budget.rootBudgetUnitId,
        categoryIds,
        budgetItemTemplateNames,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(
    () =>
      setParentBudgetUnit(R.pathOr(null, ['budgetUnit'], parentBudgetUnitData)),
    [parentBudgetUnitData],
  );

  const [getBudgetUnits] = useLazyQuery<
    { budgetUnits: BudgetUnitsConnection },
    { input: BudgetUnitsConnectionInput }
  >(GET_BUDGET_UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: response =>
      setChildBudgetUnits(response?.budgetUnits.budgetUnits),
  });

  const [getBudgetCategories] = useLazyQuery<
    { budgetCategories: BudgetCategoriesConnection },
    { input: BudgetCategoriesConnectionInput }
  >(GET_BUDGET_CATEGORIES, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      const categoriesData = response?.budgetCategories?.budgetCategories;
      setBudgetCategories(categoriesData);
    },
  });

  // Get display categories or units
  // - update with parentBudgetUnit and filter changes
  const callUpdateChildren = () => {
    if (!isNilOrEmpty(parentBudgetUnit)) {
      if (parentBudgetUnit.hasBudgetItems === false) {
        setBudgetCategories(null);
        getBudgetUnits({
          variables: {
            input: {
              budgetId: budget.id,
              parentBudgetUnitId: parentBudgetUnit.id,
              categoryIds,
              budgetItemTemplateNames,
            },
          },
        });
      } else {
        setChildBudgetUnits(null);
        getBudgetCategories({
          variables: {
            input: {
              budgetId: budget.id,
              budgetUnitId: parentBudgetUnit.id,
              categoryIds,
              budgetItemTemplateNames,
              excludeZeroBudgetItems,
            },
          },
        });
      }
    }
  };
  useEffect(callUpdateChildren, [
    parentBudgetUnit,
    categoryIds,
    budgetItemTemplateNames,
    excludeZeroBudgetItems,
  ]);

  return (
    <>
      <div styleName="overview-heading">Overview</div>
      <div styleName="total-budget-label">Total Budget</div>
      <div styleName="total-budget-amount">
        {numberToLocaleString(parentBudgetUnit?.totalBudget)}
      </div>
      <>
        {!R.isNil(childBudgetUnits) && <BudgetDetailChildUnits />}
        {!R.isNil(budgetCategories) && (
          <BudgetDetailCategories
            refetchParentUnit={refetchParentUnit}
            getBudgetCategories={getBudgetCategories}
          />
        )}
      </>
    </>
  );
};

export default BudgetDetailUnit;
