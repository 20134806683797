import React from 'react';
import { useSelector } from 'react-redux';
import { FontIcon } from 'material-ui';
import * as R from 'ramda';

import { Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import api from '@atom/utilities/api';
import { USERS_ENDPOINT } from '@atom/utilities/endpoints';
import {
  getCurrentUserLocation,
  getGeoJSONLocation,
} from '@atom/utilities/mapUtilities';

interface Props {
  onLocationChange: (location: { lat: number; lng: number }) => void;
  style?: React.CSSProperties;
}

const styles = {
  icon: {
    color: colors.neutral.gray,
    cursor: 'pointer',
  },
};

const CurrentLocation = ({ onLocationChange, style }: Props) => {
  const userId = useSelector(R.pathOr('', ['userProfile', 'userId']));

  const handleClick = async () => {
    const location = await getCurrentUserLocation();

    if (location) {
      onLocationChange(location);

      api.patch(`${USERS_ENDPOINT}/${userId}`, {
        location: getGeoJSONLocation(location),
      });
    } else {
      Snackbar.error({
        message:
          'Atom cannot retrieve your location. Please check your browser permission settings.',
      });
    }
  };

  return (
    <FontIcon
      onClick={handleClick}
      className="material-icons"
      children="gps_fixed"
      style={{
        ...styles.icon,
        ...style,
      }}
    />
  );
};

export default CurrentLocation;
