import * as React from 'react';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import FontIconButton from '@atom/components/common/FontIconButton';
import { Icon } from '@atom/mui';
import { getAttributeIconName } from '@atom/selectors/schemaSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import {
  AttributesType,
  InventoryDetailRenamePayload,
} from '@atom/types/inventory';

import EditField from './EditField';

import './dataManagementDetail.css';

interface Props {
  onClick: (attributeGroupId: string, attributeId: string) => void;
  isActive: boolean;
  attributeId: string;
  schemaId: string;
  isDisabled: boolean;
  attributeGroupId: string;
  attribute: AttributesType;
  attributeInEditMode: string;
  updateAttributeInEditMode: (id: string) => void;
  onAttributeRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onAttributeRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  onAttributeDelete: (
    schemaId: string,
    attributeGroupId: string,
    attributeId: string,
  ) => boolean;
}

const styles = {
  toolIcon: {
    padding: 0,
    height: '35px',
    width: '35px',
  },
  dragIcon: {
    fontSize: 20,
    opacity: 0.5,
    color: colors.neutral.gray,
    transform: 'rotate(-90deg)',
  },
  dialog: {
    delete: {
      contentStyle: {
        width: layout.modalWidth,
        paddingRight: '2rem',
        paddingBottom: '1rem',
      },
      titleStyle: {
        fontSize: fonts.xl,
        padding: '1.125em 1.125em 1.125em 1.25em',
        fontWeight: '500',
      },
      actionsContainerStyle: {
        paddingRight: '1rem',
        paddingBottom: '1rem',
      },
    },
  },
};

class DataManagementElementAttributeEntityRow extends React.Component<Props> {
  render() {
    const {
      attribute,
      isActive,
      onClick,
      attributeId,
      schemaId,
      attributeGroupId,
      attributeInEditMode,
      updateAttributeInEditMode,
      onAttributeRenameSave,
      onAttributeRenameCancel,
      onAttributeDelete,
      isDisabled,
    } = this.props;
    const attributeIcon = getAttributeIconName(attribute.dataType);
    const isInEditMode =
      attributeId === attributeInEditMode || attribute.name === 'Untitled';

    const rightIconButtons = isDisabled ? (
      <div />
    ) : (
      <div styleName={`entity-button-container ${isActive ? 'active' : ''}`}>
        <FontIconButton
          color={colors.neutral.gray}
          style={styles.toolIcon}
          icon="edit"
          onClick={(): void => updateAttributeInEditMode(attributeId)}
          tooltip="Rename"
          tooltipPosition="bottom-left"
        />
        <DialogTrigger
          labelCancel="Cancel"
          labelConfirm="Delete"
          confirmAction={(): boolean =>
            onAttributeDelete(schemaId, attributeGroupId, attributeId)
          }
          title="Delete Attribute?"
          content={`Are you  sure you want to delete the ${attribute.name} attribute?`}
          trigger={
            <FontIconButton
              color={colors.neutral.gray}
              style={styles.toolIcon}
              icon="delete"
              tooltip="Delete"
              tooltipPosition="bottom-left"
            />
          }
        />
      </div>
    );

    const leftIconStyles = isActive
      ? {
          lineHeight: '3.125rem',
          color: colors.brand.blue,
        }
      : { lineHeight: '3.125rem', color: colors.neutral.gray };

    const leftIcons = (
      <div styleName="left-attribute-icons">
        <Icon style={styles.dragIcon}>drag_handle</Icon>
        <Icon style={leftIconStyles}>{attributeIcon}</Icon>
      </div>
    );

    const attributeRow = (
      <React.Fragment>
        <div styleName="attribute-list-row-info">
          {leftIcons}
          <div
            styleName={`attribute-list-row-name ${isActive ? 'active' : ''}`}
          >
            {attribute.name}
          </div>
        </div>
        {rightIconButtons}
      </React.Fragment>
    );

    return (
      <div
        styleName={`attribute-list-row ${isActive ? 'active' : ''}`}
        onClick={(): void => onClick(attributeGroupId, attributeId)}
      >
        {isInEditMode ? (
          <EditField
            id={schemaId}
            attributeGroupId={attributeGroupId}
            attributeId={attribute.id}
            name={attribute.name}
            leftIcon={leftIcons}
            onRenameSave={onAttributeRenameSave}
            onRenameCancel={onAttributeRenameCancel}
          />
        ) : (
          attributeRow
        )}
      </div>
    );
  }
}

export default DataManagementElementAttributeEntityRow;
