import * as R from 'ramda';

import {
  ALDOTLocationDataItem,
  ALDOTLocationDataState,
  ALDOTLocationDataTitle,
  ALDOTLocationOptionsState,
  LocationFieldDataType,
  TaskLocation,
  TaskLocationAutocomplete,
  TaskLocationSimpleInput,
} from '@atom/types/taskLocation';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export enum AddAssetError {
  ALDOT_CROSS = 'ALDOT_CROSS',
  ALDOT_CART = 'ALDOT_CART',
  NONE = 'NONE',
}

export const INITIAL_DATA_STATE: ALDOTLocationDataState = {
  [ALDOTLocationDataTitle.MANAGEMENT_UNIT]: null,
  [ALDOTLocationDataTitle.COUNTY]: null,
  [ALDOTLocationDataTitle.ROAD_CLASS]: null,
  [ALDOTLocationDataTitle.ROUTE]: null,
  [ALDOTLocationDataTitle.DIRECTION]: null,
  [ALDOTLocationDataTitle.START_MILEPOST]: '',
  [ALDOTLocationDataTitle.END_MILEPOST]: '',
};

export const INITIAL_OPTIONS_STATE = {
  [ALDOTLocationDataTitle.MANAGEMENT_UNIT]: [],
  [ALDOTLocationDataTitle.COUNTY]: [],
  [ALDOTLocationDataTitle.ROAD_CLASS]: [],
  [ALDOTLocationDataTitle.ROUTE]: [],
  [ALDOTLocationDataTitle.DIRECTION]: [],
  [ALDOTLocationDataTitle.START_MILEPOST]: null,
  [ALDOTLocationDataTitle.END_MILEPOST]: null,
};

export const getInitialStateFromLocation = (
  initialState: ALDOTLocationDataState,
  location: TaskLocation,
): ALDOTLocationDataState => {
  return location?.data?.reduce((acc, dataItem) => {
    const spreadData =
      dataItem.title === ALDOTLocationDataTitle.MANAGEMENT_UNIT ||
      dataItem.title === ALDOTLocationDataTitle.COUNTY ||
      dataItem.title === ALDOTLocationDataTitle.ROAD_CLASS;

    return spreadData ? { ...acc, [dataItem.title]: dataItem.value } : acc;
  }, initialState);
};

export const getInitialStateFromAsset = (
  initialState: ALDOTLocationDataState,
  asset: any,
): ALDOTLocationDataState => {
  return R.values(asset?.attributes).reduce((acc, attribute) => {
    const spreadData =
      attribute.name === ALDOTLocationDataTitle.MANAGEMENT_UNIT ||
      attribute.name === ALDOTLocationDataTitle.COUNTY ||
      attribute.name === ALDOTLocationDataTitle.ROAD_CLASS;
    return spreadData ? { ...acc, [attribute.name]: attribute.value } : acc;
  }, initialState);
};

export const mapLocationDataToState = (
  location: TaskLocation,
  locations: TaskLocation[],
  assets: any,
): ALDOTLocationDataState => {
  let initialState = INITIAL_DATA_STATE;

  const isOnlyLocationEdit =
    locations.length === 1 && locations[0]?.id === location?.id;
  // If locations already exist on the task that are different than the current location, or assets,
  // then Management Unit, County, and Road Class fields must match.
  const fillInitialInputs =
    (!isNilOrEmpty(locations) && !isOnlyLocationEdit) || !isNilOrEmpty(assets);

  if (fillInitialInputs) {
    const otherLocation = locations[0];
    const otherAsset = assets[0];

    initialState = !isNilOrEmpty(otherLocation)
      ? getInitialStateFromLocation(initialState, otherLocation)
      : getInitialStateFromAsset(initialState, otherAsset);
  }

  if (!location) {
    return initialState;
  }

  return location?.data?.reduce((acc, dataItem) => {
    return { ...acc, [dataItem.title]: dataItem.value };
  }, initialState);
};

export const mapAutocompleteToOptionsState = (
  autocomplete: TaskLocationAutocomplete,
): ALDOTLocationOptionsState => {
  if (!autocomplete) {
    return INITIAL_OPTIONS_STATE;
  }

  const mappedFields = autocomplete.components.reduce((acc, component) => {
    return { ...acc, [component.title]: component.enumeration };
  }, INITIAL_OPTIONS_STATE);

  return {
    ...mappedFields,
    [ALDOTLocationDataTitle.START_MILEPOST]: autocomplete?.minMilePost,
    [ALDOTLocationDataTitle.END_MILEPOST]: autocomplete?.maxMilePost,
  };
};

export const mapDataToSimpleInput = (
  data: ALDOTLocationDataState,
  omitMileposts: boolean,
): TaskLocationSimpleInput => {
  return {
    data: R.keys(data).reduce((acc, title) => {
      if (
        omitMileposts &&
        (title === ALDOTLocationDataTitle.START_MILEPOST ||
          title === ALDOTLocationDataTitle.END_MILEPOST)
      ) {
        return acc;
      }

      const value =
        title === ALDOTLocationDataTitle.START_MILEPOST ||
        title === ALDOTLocationDataTitle.END_MILEPOST
          ? Number(data[title])
          : data[title];

      return isNilOrEmpty(value) ? acc : [...acc, { title, value }];
    }, []),
  };
};

export const isMilepostsValid = (
  options: ALDOTLocationOptionsState,
  values: ALDOTLocationDataState,
): boolean => {
  const startMilepostLimit = options[ALDOTLocationDataTitle.START_MILEPOST];
  const endMilepostLimit = options[ALDOTLocationDataTitle.END_MILEPOST];
  const startMilepostValue = values[ALDOTLocationDataTitle.START_MILEPOST];
  const endMilepostValue = values[ALDOTLocationDataTitle.END_MILEPOST];

  if (!isNilOrEmpty(startMilepostValue) && !isNilOrEmpty(endMilepostValue)) {
    if (
      startMilepostValue < startMilepostLimit ||
      startMilepostValue > endMilepostLimit
    ) {
      return false;
    }

    if (
      endMilepostValue < startMilepostLimit ||
      endMilepostValue > endMilepostLimit
    ) {
      return false;
    }
  }

  return true;
};

export const mapStateToData = (
  options: ALDOTLocationOptionsState,
  values: ALDOTLocationDataState,
): ALDOTLocationDataItem[] => {
  return R.keys(values).map(title => {
    if (
      title === ALDOTLocationDataTitle.START_MILEPOST ||
      title === ALDOTLocationDataTitle.END_MILEPOST
    ) {
      return {
        title,
        dataType: LocationFieldDataType.NUMBER,
        value: Number(values[title]),
      };
    }

    return {
      title,
      dataType: LocationFieldDataType.ENUM_SINGLE,
      enumeration: options[title],
      value: values[title],
    };
  });
};

const INITIAL_CROSS_ATTRIBUTES = {
  [ALDOTLocationDataTitle.MANAGEMENT_UNIT]: null,
  [ALDOTLocationDataTitle.COUNTY]: null,
  [ALDOTLocationDataTitle.ROAD_CLASS]: null,
};

// gets ALDOT cross validation attributes that are set on the task via existing locations or assets.
export const getCrossValidationAttributes = (
  assets: any[],
  locations: TaskLocation[],
) => {
  const firstLocation = locations[0];
  const firstAsset = assets[0];

  return !isNilOrEmpty(firstLocation)
    ? getInitialStateFromLocation(INITIAL_CROSS_ATTRIBUTES, firstLocation)
    : getInitialStateFromAsset(INITIAL_CROSS_ATTRIBUTES, firstAsset);
};

// Tests whether all ALDOT cross attributes match across assets
export const getAllAttributesEqual = (assets: any[]): boolean => {
  const mappedAttributes = assets.map(asset => {
    return getInitialStateFromAsset(INITIAL_CROSS_ATTRIBUTES, asset);
  });

  const firstMappedAttributes = mappedAttributes[0];

  return R.all(R.equals(firstMappedAttributes), mappedAttributes);
};

// Returns first set of cross validation attributes from the given assets
export const getCartCrossAttributes = (assets: any[]) => {
  return assets.map(asset => {
    return getInitialStateFromAsset(INITIAL_CROSS_ATTRIBUTES, asset);
  })[0];
};
