import * as React from 'react';

import colors from '@atom/styles/colors';

import FontIconButton from '../common/FontIconButton';

import './dataManagementDetail.css';

interface Props {
  name: string;
  id: string;
  isDisabled: boolean;
  addAttributeGroup: (schemaId: string) => void;
}

const DataManagementElementAttributeRow = ({
  id,
  name,
  isDisabled,
  addAttributeGroup,
}: Props) => {
  return (
    <div styleName="element-attribute-row">
      <div styleName="element-attribute-text">{name}</div>
      {isDisabled ? (
        <div />
      ) : (
        <div styleName="add-attribute-group-button-container">
          <FontIconButton
            color={colors.neutral.gray}
            icon="control_point_duplicate"
            onClick={(): void => addAttributeGroup(id)}
            tooltip="Add Group"
            tooltipPosition="bottom-left"
          />
        </div>
      )}
    </div>
  );
};

export default DataManagementElementAttributeRow;
