import React from 'react';
import * as R from 'ramda';

import { Icon, List, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetUnitSearch } from '@atom/types/budget';

const { ListItem, ListItemText } = List;

const styles = {
  iconStyle: {
    fontSize: '1.75rem',
    margin: 0,
  },
  containerPadding: {
    margin: '1rem',
  },
  listItemText: { paddingLeft: '0.5rem' },
  listItem: { cursor: 'pointer' },
};
export interface Props {
  searchResults: BudgetUnitSearch[];
  loadingSearch: boolean;
  handleNavigateToUnit: (unitId: string) => void;
}

const BudgetDetailUnitSearchResults = ({
  searchResults,
  loadingSearch,
  handleNavigateToUnit,
}: Props) => {
  return loadingSearch || R.isNil(searchResults) ? (
    <Progress style={styles.containerPadding} size={20} />
  ) : R.isEmpty(searchResults) ? (
    <div style={styles.containerPadding}>no results</div>
  ) : (
    <List>
      {searchResults.map(unit => (
        <ListItem
          key={unit.id}
          style={styles.listItem}
          onClick={() => handleNavigateToUnit(unit.id)}
        >
          <Icon style={styles.iconStyle} color={colors.neutral.gray}>
            folder
          </Icon>
          <ListItemText
            style={styles.listItemText}
            primary={unit.name}
            secondary={unit.ancestors.map(({ name }) => name).join(' / ')}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default BudgetDetailUnitSearchResults;
