import * as React from 'react';
import * as R from 'ramda';

import {
  DetailElementGroupsItem,
  InventoryDetailRenamePayload,
  InventorySchemaDetail,
} from '@atom/types/inventory';

import DataManagementElementGroupRow from './DataManagementElementGroupRow';
import DataManagementElementRow from './DataManagementElementRow';
import DataManagementRootAssetRow from './DataManagementRootAssetRow';

import './dataManagementDetail.css';

interface Props {
  schema: InventorySchemaDetail;
  activeElement: string;
  onClick: (data: string) => void;
  groupInEditMode: string;
  elementInEditMode: string;
  isDisabled: boolean;
  addSchemaOrGroup: (
    parentSchemaId: string,
    group?: string,
    groupId?: string,
  ) => void;
  onSchemaDelete: (schemaId: string) => boolean;
  onGroupDelete: (groupId: string) => boolean;
  onGroupRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onGroupRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  onElementRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onElementRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  updateElementInEditMode: (id: string) => void;
  updateGroupInEditMode: (id: string) => void;
}

class DataManagementElementPane extends React.Component<Props> {
  getElements = (elements: InventorySchemaDetail[], isInGroup: boolean) => {
    const {
      activeElement,
      isDisabled,
      onClick,
      onSchemaDelete,
      elementInEditMode,
      addSchemaOrGroup,
      onElementRenameSave,
      onElementRenameCancel,
      updateElementInEditMode,
    } = this.props;

    return elements.map((element: InventorySchemaDetail) => {
      return (
        <DataManagementElementRow
          isInGroup={isInGroup}
          onSchemaDelete={onSchemaDelete}
          elementInEditMode={elementInEditMode}
          onElementRenameSave={onElementRenameSave}
          onElementRenameCancel={onElementRenameCancel}
          updateElementInEditMode={updateElementInEditMode}
          key={element.id}
          schema={element}
          onClick={onClick}
          activeElement={activeElement}
          addSchemaOrGroup={addSchemaOrGroup}
          isDisabled={isDisabled}
        />
      );
    });
  };

  getElementGroups = () => {
    const {
      schema,
      isDisabled,
      groupInEditMode,
      addSchemaOrGroup,
      onGroupDelete,
      onGroupRenameSave,
      onGroupRenameCancel,
      updateGroupInEditMode,
    } = this.props;

    return schema.elementGroups.map((elementGroup: DetailElementGroupsItem) => {
      return (
        <React.Fragment>
          <DataManagementElementGroupRow
            key={elementGroup.id}
            parentSchemaId={schema.id}
            group={elementGroup}
            onGroupDelete={onGroupDelete}
            addSchemaOrGroup={addSchemaOrGroup}
            groupInEditMode={groupInEditMode}
            onGroupRenameSave={onGroupRenameSave}
            onGroupRenameCancel={onGroupRenameCancel}
            updateGroupInEditMode={updateGroupInEditMode}
            isDisabled={isDisabled}
          />
          {!R.isEmpty(elementGroup.elements) &&
            this.getElements(elementGroup.elements, true)}
        </React.Fragment>
      );
    });
  };

  render() {
    const {
      schema,
      activeElement,
      onClick,
      addSchemaOrGroup,
      isDisabled,
    } = this.props;

    const elements = this.getElements(schema.elements, false);
    const elementGroups = this.getElementGroups();

    return (
      <div styleName="section-pane">
        <DataManagementRootAssetRow
          isSelected={activeElement === schema.id}
          schema={schema}
          onClick={onClick}
          addSchemaOrGroup={addSchemaOrGroup}
          isDisabled={isDisabled}
        />
        <div styleName="item-tree-container">
          {elements}
          {elementGroups}
        </div>
      </div>
    );
  }
}

export default DataManagementElementPane;
