import { WorkOrderFieldDataType, WorkOrdersConnectionInput } from './work';

export enum WorkFilterSource {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export enum WorkDatePresetKey {
  WORK = 'workDatePresets',
  TASK = 'taskDatePresets',
}

export const presetDateFilterKeys = [
  'dueDatePreset',
  'completionDatePreset',
  'startDatePreset',
  'createdDatePreset',
];

export const presetTaskDateFilterKeys = presetDateFilterKeys.filter(
  key => key !== 'createdDatePreset',
);

export interface WorkDatePresets {
  dueDatePreset?: string;
  completionDatePreset?: string;
  startDatePreset?: string;
  createdDatePreset?: string;
}

export interface TaskDatePresets {
  dueDatePreset?: string;
  completionDatePreset?: string;
  startDatePreset?: string;
}

export interface DateFilterPresetsCart {
  taskDatePresets: TaskDatePresets;
  workDatePresets: WorkDatePresets;
}

export interface WorkFilter {
  id: string;
  name: string;
  isDefault: boolean;
  params: WorkOrdersConnectionInput;
  taskDatePresets?: TaskDatePresets;
  workDatePresets?: WorkDatePresets;
}

export interface WorkFiltersConnection {
  totalCount: number;
  workFilters: WorkFilter[];
}

export interface WorkFiltersConnectionInput {
  userId: string;
  source: WorkFilterSource;
  sortBy?: string;
  isAscending?: boolean;
}

export interface WorkFilterCreateInput {
  userId: string;
  source: WorkFilterSource;
  name: string;
  isDefault: boolean;
  params: WorkOrdersConnectionInput;
  taskDatePresets?: TaskDatePresets;
  workDatePresets?: WorkDatePresets;
}

export interface WorkFilterUpdateInput {
  workFilterId: string;
  name?: string;
  isDefault?: boolean;
  params?: WorkOrdersConnectionInput;
  taskDatePresets?: TaskDatePresets;
  workDatePresets?: WorkDatePresets;
}

export interface CustomFieldFilter {
  id: string;
  dataType: WorkOrderFieldDataType;
  title: string;
  enumeration?: string[];
  units?: string;
}

export interface CustomFieldFiltersConnection {
  totalCount: number;
  customFieldFilters: CustomFieldFilter[];
}

export interface CustomFieldFiltersConnectionInput {
  ids?: string[];
  page: number;
  limit: number;
}
