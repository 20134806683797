import React from 'react';
import { Link } from 'react-router-dom';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import { Icon, IconButton, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaItem } from '@atom/types/inventory';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import timeUtilities from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import './dataManagement.css';

interface Props {
  schema: InventorySchemaItem;
  onCopy: (schemaId: string) => void;
  onDelete: (schemaId: string, rootSchemaId?: string) => void;
  loading: boolean;
}

const getLocationTypeText = (locationType: string, isMaterial: boolean) => {
  if (isMaterial) {
    return 'Non-location';
  }

  return locationType === 'Point' ? 'Point' : 'LineString';
};

const SchemaTile = ({ schema, onDelete, onCopy, loading }: Props) => {
  const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(schema);

  const locationType = getLocationTypeText(
    schema.locationType,
    schema.isMaterial,
  );

  const createdByName = schema.createdBy
    ? getUserFullName(schema.createdBy)
    : '-';

  const updatedByName = schema.updatedBy
    ? getUserFullName(schema.updatedBy)
    : '-';

  const publishedStatus = schema.isPublished
    ? { icon: 'lock', text: 'Published' }
    : { icon: 'edit', text: 'Draft' };

  // TODO: [AM-14839]: Remove feature toggle
  const detailsLink = isCurrentEnvironment([Environment.DEV])
    ? `/admin/inventoryTypes-new/${schema.id}`
    : `/admin/inventoryTypes/${schema.id}`;

  return (
    <div styleName="schema-tile-container">
      {loading ? (
        <Progress style={{ height: '100%' }} size={30} />
      ) : (
        <>
          <div styleName="schema-tile-title-container">
            {icon}
            <div styleName="schema-tile-title">{schema.name}</div>
          </div>
          <div styleName="schema-tile-details-container">
            <div styleName="schema-tile-details-row">
              <div styleName="schema-tile-details-label">Location Type</div>
              <div styleName="schema-tile-details-value">{locationType}</div>
            </div>
            <div styleName="schema-tile-details-row">
              <div styleName="schema-tile-details-label">Created By</div>
              <div styleName="schema-tile-details-value">{createdByName}</div>
            </div>
            <div styleName="schema-tile-details-row">
              <div styleName="schema-tile-details-label">Created on</div>
              <div styleName="schema-tile-details-value">
                {timeUtilities.setDisplayDate(schema.createdDate)}
              </div>
            </div>
            <div styleName="schema-tile-details-row">
              <div styleName="schema-tile-details-label">Modified By</div>
              <div styleName="schema-tile-details-value">{updatedByName}</div>
            </div>
            <div styleName="schema-tile-details-row">
              <div styleName="schema-tile-details-label">Modified on</div>
              <div styleName="schema-tile-details-value">
                {timeUtilities.setDisplayDate(schema.updatedDate)}
              </div>
            </div>
            <div styleName="schema-tile-details-row">
              <div styleName="schema-tile-details-label">Status</div>
              <div styleName="schema-tile-details-value">
                <Icon color={colors.neutral.gray}>{publishedStatus.icon}</Icon>
                <div styleName="schema-tile-status-text">
                  {publishedStatus.text}
                </div>
              </div>
            </div>
          </div>
          <div styleName="schema-tile-button-container">
            <Link to={detailsLink}>
              <div styleName="schema-tile-button">view details</div>
            </Link>
            <div styleName="icon-buttons-container">
              {schema.isPublished && (
                <IconButton
                  onClick={() => onCopy(schema.id)}
                  tooltip="Duplicate"
                >
                  <Icon color={colors.neutral.gray}>content_copy</Icon>
                </IconButton>
              )}
              {hasRolePermissions(ROLE_SETS.ADMIN) && (
                <DialogTrigger
                  labelCancel="Cancel"
                  labelConfirm="Delete"
                  confirmAction={(): void => onDelete(schema.id)}
                  title={`Delete ${schema.name} Inventory Type?`}
                  content="Deleting this inventory type will also delete all items under it and all associated assets."
                  trigger={
                    <IconButton tooltip="Delete">
                      <Icon color={colors.neutral.gray}>delete</Icon>
                    </IconButton>
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SchemaTile;
