import React, { useContext } from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetModal } from '@atom/types/budget';

import BudgetsContext from './BudgetsContext';

import '../../styles/detail-header.css';

const BudgetsHeader = () => {
  const { setActiveModal } = useContext(BudgetsContext);

  return (
    <div styleName="header-container budgets-header">
      <div styleName="name-container">
        <span>Budgets</span>
      </div>
      <IconButton
        tooltip="Add Budget"
        onClick={() => setActiveModal(BudgetModal.CREATE)}
      >
        <Icon color={colors.neutral.white}>add</Icon>
      </IconButton>
    </div>
  );
};

export default BudgetsHeader;
