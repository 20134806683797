import React, { useEffect, useState } from 'react';

import { InventorySchemaDetail } from '@atom/types/inventory';
import { StartEndReadingOption, StockBasedOption } from '@atom/types/schema';

import CostDetails from './CostDetails';
import ReadingsDetails from './ReadingsDetails';
import StockDetails from './StockDetails';

import '../../../styles/body-pane.css';

interface Props {
  schema: InventorySchemaDetail;
}

const BudgetTab = ({ schema }: Props) => {
  const [stockBasedOption, setStockBasedOption] = useState<StockBasedOption>(
    schema?.budget?.stockBasedOption,
  );

  const [startEndReadingOption, setStartEndReadingOption] = useState<
    StartEndReadingOption
  >(schema?.budget?.startEndReadingOption);

  useEffect(() => {
    setStockBasedOption(schema?.budget?.stockBasedOption);
  }, [schema?.budget?.stockBasedOption]);

  useEffect(() => {
    setStartEndReadingOption(schema?.budget?.startEndReadingOption);
  }, [schema?.budget?.startEndReadingOption]);

  return (
    <div styleName="center-pane-container">
      <div styleName="center-pane" style={{ overflow: 'auto' }}>
        <CostDetails schema={schema} />
        <StockDetails
          schema={schema}
          value={stockBasedOption}
          setValue={setStockBasedOption}
          setReadingsValue={setStartEndReadingOption}
        />
        <ReadingsDetails
          schema={schema}
          value={startEndReadingOption}
          stockValue={stockBasedOption}
          setValue={setStartEndReadingOption}
        />
      </div>
    </div>
  );
};

export default BudgetTab;
