import React from 'react';
import * as R from 'ramda';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetUnitTree } from '@atom/types/budget';
import { toggleFromSet } from '@atom/utilities/setUtilities';

import './budgetDetail.css';

const PADDING_OFFSET = 1;

const styles = {
  iconStyle: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0',
  },
  blankStyle: {
    fontSize: '1.75rem',
    margin: 0,
    padding: '0.25rem 0.75rem',
  },
};

export interface Props {
  tree: BudgetUnitTree;
  indentation?: number;
  handleNavigateToUnit: (unitId: string) => void;
  collapsed: Set<string>;
  setCollapsed: (collapsed: Set<string>) => void;
}

const BudgetDetailUnitTree = ({
  tree,
  indentation = 0,
  handleNavigateToUnit,
  collapsed,
  setCollapsed,
}: Props) => {
  const leftIcon = (
    <>
      {!R.isEmpty(tree.children) ? (
        <IconButton
          style={styles.iconStyle}
          onClick={() => setCollapsed(toggleFromSet(collapsed, tree.id))}
        >
          <Icon color={colors.neutral.dim}>
            {collapsed.has(tree.id) ? 'arrow_right' : 'arrow_drop_down'}
          </Icon>
        </IconButton>
      ) : (
        <div style={styles.blankStyle} />
      )}
      <Icon style={styles.iconStyle} color={colors.neutral.gray}>
        folder
      </Icon>
    </>
  );

  const containerStyle = {
    paddingLeft: `calc(${PADDING_OFFSET * indentation}rem)`,
  };

  return (
    <>
      <div style={containerStyle}>
        <div styleName="unit-icon-container">
          {leftIcon}
          <div
            onClick={() => handleNavigateToUnit(tree.id)}
            styleName="unit-name-container"
          >
            <div>{tree.name}</div>
          </div>
        </div>
      </div>
      {!collapsed.has(tree.id) &&
        tree.children.map((child: BudgetUnitTree) => {
          return (
            <BudgetDetailUnitTree
              key={child.id}
              tree={child}
              indentation={indentation + 1}
              handleNavigateToUnit={handleNavigateToUnit}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          );
        })}
    </>
  );
};

export default BudgetDetailUnitTree;
