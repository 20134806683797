import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import { UPDATE_BUDGET_ITEM } from '@atom/graph/budget';
import { ListTable, Snackbar, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BudgetCategory,
  BudgetItem,
  BudgetItemUpdateInput,
} from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import {
  BUDGET_COL_WIDTH,
  COMMENT_COL_WIDTH,
  COST_TYPE_COL_WIDTH,
  EditField,
  FIELD_KEYS,
  FIELD_NAMES,
} from './budgetDetailUtils';

import './budgetDetail.css';

const { TableRow, TableCell, TableBody } = ListTable;

interface Props {
  budgetCategory: BudgetCategory;
  refetchParentUnit: () => void;
  getBudgetCategories: (input: any) => void;
}

const BudgetDetailItemTable = ({
  budgetCategory,
  refetchParentUnit,
  getBudgetCategories,
}: Props) => {
  const {
    budget,
    editingItem,
    setEditingItem,
    editingField,
    setEditingField,
    parentBudgetUnit,
    categoryIds,
    budgetItemTemplateNames,
    excludeZeroBudgetItems,
  } = useContext(BudgetDetailContext);
  const [editValue, setEditValue] = useState<string>();

  const [updateBudgetItem, { loading: loadingItemUpdate }] = useMutation<
    { budgetItemUpdate: BudgetItem },
    { input: BudgetItemUpdateInput }
  >(UPDATE_BUDGET_ITEM);

  const handleInputCellClick = (field: EditField, budgetItem: BudgetItem) => {
    setEditValue(budgetItem[FIELD_KEYS[field]]);
    setEditingItem(budgetItem);
    setEditingField(field);
  };

  const handleSaveField = async () => {
    if (editingField === 'VALUE' && parseFloat(editValue) < 0) {
      Snackbar.error({
        message: 'Please enter positive numbers for budget amounts.',
      });
      setEditingField(null);
      return;
    }

    // Do not save unchanged values
    if (editingItem[FIELD_KEYS[editingField]] === editValue) {
      setEditingField(null);
      return;
    }

    // Ensure all empty values passed to value field are 0
    const sanitizedValue =
      editingField === 'VALUE' && isNilOrEmpty(editValue) ? 0 : editValue;

    try {
      const updatedItemData = await updateBudgetItem({
        variables: {
          input: {
            budgetId: budget.id,
            budgetItemId: editingItem.id,
            budgetValue: editingItem.budgetValue,
            comment: editingItem.comment,
            [FIELD_KEYS[editingField]]: sanitizedValue,
          },
        },
      });

      // Value updates trigger Unit total fetch
      //  - which triggers category/item fetch
      if (editingField === 'VALUE') {
        refetchParentUnit();
      }

      // Comment updates trigger category/item fetch only
      if (editingField === 'COMMENT') {
        getBudgetCategories({
          variables: {
            input: {
              budgetId: budget.id,
              budgetUnitId: parentBudgetUnit.id,
              categoryIds,
              budgetItemTemplateNames,
              excludeZeroBudgetItems,
            },
          },
        });
      }

      const getNewValue = () =>
        numberToLocaleString(
          updatedItemData.data.budgetItemUpdate[FIELD_KEYS[editingField]],
        );

      const messagePre = `Successfully updated ${FIELD_NAMES[editingField]} on ${editingItem.name}`;
      const messagePost =
        editingField === 'VALUE'
          ? `: from ${numberToLocaleString(
              editingItem.budgetValue,
            )} to ${getNewValue()}`
          : '';
      const message = messagePre + messagePost;

      Snackbar.info({
        message,
      });
    } catch (error) {
      const message =
        error.networkError.statusCode === 422 && editingField === 'VALUE'
          ? 'Please enter only numeric characters and periods in budget values.'
          : 'An error occurred while updating your budget. Please Try Again.';
      Snackbar.error({ message });
    }
    setEditingItem(null);
  };

  const handleKeyUp = event => {
    // detect and handle enter key to trigger save
    if (event.keyCode === 13) {
      handleSaveField();
    }
  };

  return (
    <ListTable fullHeight={false}>
      <TableBody>
        {budgetCategory?.budgetItems.map(budgetItem => (
          <TableRow
            key={budgetItem.id}
            hovercolor={colors.utility.lightBlueHighlight}
          >
            <TableCell onClick={() => setEditingItem(null)}>
              <span styleName="budget-table-cell item-name-cell">
                {budgetItem?.name}
              </span>
            </TableCell>
            <TableCell
              onClick={() => setEditingItem(null)}
              width={COST_TYPE_COL_WIDTH}
            >
              <span styleName="budget-table-cell">{budgetItem.costType}</span>
            </TableCell>
            <TableCell
              onClick={() => handleInputCellClick(EditField.VALUE, budgetItem)}
              styleName="clickable"
              align="right"
              width={BUDGET_COL_WIDTH}
            >
              <span styleName="budget-table-cell">
                {!loadingItemUpdate &&
                editingField === EditField.VALUE &&
                editingItem?.id === budgetItem.id ? (
                  <>
                    $
                    <input
                      style={{ textAlign: 'right' }}
                      onChange={event => setEditValue(event.target.value)}
                      onKeyUp={event => handleKeyUp(event)}
                      onBlur={handleSaveField}
                      value={editValue}
                    />
                  </>
                ) : (
                  numberToLocaleString(budgetItem?.budgetValue)
                )}
              </span>
            </TableCell>
            <TableCell
              onClick={() =>
                handleInputCellClick(EditField.COMMENT, budgetItem)
              }
              styleName="clickable"
              width={COMMENT_COL_WIDTH}
            >
              <span styleName="budget-table-cell budget-comment-cell">
                {!loadingItemUpdate &&
                editingField === EditField.COMMENT &&
                editingItem?.id === budgetItem.id ? (
                  <input
                    value={editValue}
                    onChange={event => setEditValue(event.target.value)}
                    onKeyUp={event => handleKeyUp(event)}
                    onBlur={handleSaveField}
                  />
                ) : (
                  <Tooltip title={budgetItem?.comment}>
                    <span styleName="budget-comment-display">
                      {budgetItem?.comment}
                    </span>
                  </Tooltip>
                )}
              </span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </ListTable>
  );
};

export default BudgetDetailItemTable;
