import React, { useContext } from 'react';

import { Icon, Progress } from '@atom/mui';
import { BudgetUnit } from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailUnitTreeNav from './BudgetDetailUnitTreeNav';

import './budgetDetail.css';

const BudgetDetailBreadcrumbs = () => {
  const { parentBudgetUnit, unitsVisited, setParentBudgetUnit } = useContext(
    BudgetDetailContext,
  );

  const crumbs: BudgetUnit[] = parentBudgetUnit?.ancestors.reduce(
    (acc, id) => [...acc, unitsVisited[id]],
    [],
  );

  return (
    <>
      <div styleName="breadcrumbs">
        {crumbs?.map((crumb, idx) => (
          <React.Fragment key={crumb?.id || parentBudgetUnit?.ancestors[idx]}>
            <span styleName="crumb" onClick={() => setParentBudgetUnit(crumb)}>
              {isNilOrEmpty(crumb) ? <Progress size={20} /> : crumb?.name}
            </span>
            <span styleName="crumb-divider">
              <Icon>chevron_right</Icon>
            </span>
          </React.Fragment>
        ))}
        <span styleName="crumb" key={parentBudgetUnit?.id}>
          {parentBudgetUnit?.name}
        </span>
        <BudgetDetailUnitTreeNav />
      </div>
    </>
  );
};

export default BudgetDetailBreadcrumbs;
