export const COST_TYPE_COL_WIDTH = '8rem';
export const BUDGET_COL_WIDTH = '6rem';
export const COMMENT_COL_WIDTH = '24rem';
export const POPOVER_HEIGHT = '400px';
export const CATEGORY_EXPAND_LIMIT = 10;
export const DEBOUNCE_TIME = 1000;

export enum EditField {
  VALUE = 'VALUE',
  COMMENT = 'COMMENT',
}

export const FIELD_KEYS = {
  VALUE: 'budgetValue',
  COMMENT: 'comment',
};

export const FIELD_NAMES = {
  VALUE: 'budget',
  COMMENT: 'comment',
};
