import React from 'react';
import * as R from 'ramda';

import { EventType } from '@atom/types/event';
import { AttributesType } from '@atom/types/inventory';
import { isNumericZero } from '@atom/utilities/validationUtilities';

import BooleanAttribute from './attributeInputs/BooleanAttribute';
import DateAttribute from './attributeInputs/DateAttribute';
import EnumMultipleAttribute from './attributeInputs/EnumMultipleAttribute';
import EnumSingleAttribute from './attributeInputs/EnumSingleAttribute';
import NumericAttribute from './attributeInputs/NumericAttribute';
import TextAttribute from './attributeInputs/TextAttribute';

import '../formInstance.css';

interface PassedProps {
  attribute: AttributesType;
  isEditable: boolean;
  assetId: string;
  fieldId: string;
  attributeGroupName: string;
  progressiveUpdateFormInstanceAttribute: (
    fieldId: string,
    assetId: string,
    attributeGroupName: string,
    attributeId: string,
    body: Object,
  ) => void;
  savingFormInstance: boolean;
}

type Props = PassedProps;

interface State {
  value: any;
}

class InstanceAttributeField extends React.Component<Props, State> {
  state = {
    value:
      isNumericZero(
        this.props.attribute.dataType,
        this.props.attribute.value,
      ) || this.props.attribute.value
        ? this.props.attribute.value
        : '',
  };

  onChange = (event: EventType) => {
    const dataType = event.target.name;
    let value = event.target.value;

    if (dataType === 'number' || dataType === 'currency') {
      value = Number(event.target.value);
    }

    this.progressiveSaveAttribute(value);
    this.setState({ value });
  };

  onBooleanChange = (event: any) => {
    const value = event.target.value === 'true';

    this.progressiveSaveAttribute(value);
    this.setState({ value });
  };

  onEnumChange = (event: any) => {
    const { value } = event.target;
    this.progressiveSaveAttribute(value);
    this.setState({ value });
  };

  onDateChange = (property: string, dateMillis: number) => {
    const value = R.isNil(dateMillis) ? '' : dateMillis;
    this.progressiveSaveAttribute(value);
    this.setState({ value });
  };

  progressiveSaveAttribute = (value: any) => {
    const {
      assetId,
      fieldId,
      attribute,
      attributeGroupName,
      progressiveUpdateFormInstanceAttribute,
    } = this.props;

    const body = {
      value,
    };

    progressiveUpdateFormInstanceAttribute(
      fieldId,
      assetId,
      attributeGroupName,
      attribute.id,
      body,
    );
  };

  renderAttributeInput = () => {
    const { attribute, isEditable, savingFormInstance } = this.props;
    const { value } = this.state;

    const isEditableWhileSaving = isEditable && !savingFormInstance;

    switch (attribute.dataType) {
      case 'longtext':
      case 'shorttext': {
        return (
          <TextAttribute
            type={attribute.dataType}
            value={value}
            onChange={this.onChange}
            isEditable={isEditable && attribute.isEditable}
          />
        );
      }
      case 'currency':
      case 'number': {
        return (
          <NumericAttribute
            value={value}
            type={attribute.dataType}
            onChange={this.onChange}
            unit={attribute.unit}
            isEditable={isEditable && attribute.isEditable}
          />
        );
      }
      case 'boolean': {
        return (
          <BooleanAttribute
            value={value}
            type={attribute.dataType}
            onChange={this.onBooleanChange}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      case 'date': {
        return (
          <DateAttribute
            type={attribute.dataType}
            onChange={this.onDateChange}
            value={value}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      case 'enumsingle': {
        return (
          <EnumSingleAttribute
            type={attribute.dataType}
            value={value}
            onChange={this.onEnumChange}
            options={attribute.enumeration}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      case 'enummultiple': {
        return (
          <EnumMultipleAttribute
            type={attribute.dataType}
            value={value}
            onChange={this.onEnumChange}
            options={attribute.enumeration}
            isEditable={isEditableWhileSaving && attribute.isEditable}
          />
        );
      }
      default: {
        return <div />;
      }
    }
  };

  render() {
    const { attribute } = this.props;

    const attributeInputs = this.renderAttributeInput();

    return (
      <div styleName="attribute-field-block">
        <div styleName="attribute-name">{attribute.name}</div>
        {attributeInputs}
      </div>
    );
  }
}

export default InstanceAttributeField;
