import React from 'react';
import {
  StepLabel as MUIStepLabel,
  StepLabelProps as MUIStepLabelProps,
} from '@mui/material';

export type StepLabelProps = MUIStepLabelProps;

export const Step = (props: StepLabelProps) => {
  return <MUIStepLabel {...props} />;
};

export default Step;
