import * as React from 'react';

import AdditionalOptions from '@atom/components/common/AdditionalOptions';
import MoveInventoryModal from '@atom/components/common/MoveInventoryModal';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
import colors from '@atom/styles/colors';
import { ApplicationSearchInventoryState } from '@atom/types/store';
import history from '@atom/utilities/history';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';

import './searchPortal.css';

interface Props {
  query: string;
  assets: ApplicationSearchInventoryState[];
}

class InventorySearchResultsRows extends React.Component<Props> {
  navigate = (id: string) => {
    history.push(`/inventory/${id}`);
  };

  render() {
    const { query, assets } = this.props;

    return (
      <div styleName="search-entity-results-container">
        {assets.map((asset: ApplicationSearchInventoryState) => {
          const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(asset);

          const assetName = textDisplayUtilities.highlightedText(
            query,
            asset.name,
          );

          return (
            <div
              styleName="search-entity-row additional-options"
              key={asset.id}
              onClick={(): void => this.navigate(asset.id)}
            >
              <div styleName="search-entity-row-icon-text-container">
                {icon}
                <div styleName="search-entity-row-text-container">
                  <div styleName="search-entity-row-text">{assetName}</div>
                </div>
              </div>
              <AdditionalOptions
                iconStyle={{
                  color: colors.neutral.gray,
                }}
                menuItems={[
                  {
                    type: 'trigger',
                    primaryText: 'Move to Folder',
                    icon: <img src={folderMoveIcon} />,
                    id: asset.id,
                    element: <MoveInventoryModal id={asset.id} />,
                  },
                ]}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default InventorySearchResultsRows;
