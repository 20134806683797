import React from 'react';
import FontIcon from 'material-ui/FontIcon';

import colors from '@atom/styles/colors';

import './mapSideNavigation.css';

interface Props {
  activeNavigationState: string;
  onNavigation: (navigateTo: string) => any;
}

const styles = {
  icon: {
    color: colors.neutral.gray,
    fontSize: 24,
  },
};

const MapSideNavigation = ({
  activeNavigationState: navigationState,
  onNavigation,
}: Props) => {
  const activeNavigationState = navigationState || 'list';

  return (
    <div styleName="container">
      <div styleName="nav-link-container">
        <div
          styleName={`nav-link ${
            activeNavigationState === 'layers' ? 'active' : ''
          }`}
          onClick={() => onNavigation('layers')}
        >
          <FontIcon style={styles.icon} className="material-icons">
            layers
          </FontIcon>
        </div>
        <div
          styleName={`nav-link ${
            activeNavigationState === 'list' ? 'active' : ''
          }`}
          onClick={() => onNavigation('list')}
        >
          <FontIcon style={styles.icon} className="material-icons">
            view_list
          </FontIcon>
        </div>
      </div>
    </div>
  );
};

export default MapSideNavigation;
