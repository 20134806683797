import * as React from 'react';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import AdditionalOptions from '@atom/components/common/AdditionalOptions';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Collapse, List } from '@atom/mui';
import config from '@atom/selectors/config';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import iconUtilities from '@atom/utilities/iconUtilities';

import CreateMediaFolderModal from './CreateMediaFolderModal';
import EditMediaFolderModal from './EditMediaFolderModal';
import MoveMediaFolderModal from './MoveMediaFolderModal';

import './folders.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  rootListItemStyle: {
    lineHeight: fontStyles.md,
    fontSize: fontStyles.md,
    paddingTop: '0.50rem',
    paddingRight: '1.0rem',
    paddingBottom: '0.50rem',
    paddingLeft: '4.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    height: '1rem',
  },
  listItemStyle: {
    lineHeight: fontStyles.md,
    fontSize: fontStyles.md,
    paddingTop: '0.50rem',
    paddingRight: '1.0rem',
    paddingBottom: '0.50rem',
    paddingLeft: '4.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: '1rem',
  },
  nestedListStyle: {
    padding: '0px',
    display: 'inline-block',
    minWidth: '100%',
  },
  selectedNode: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    height: '2.5rem',
    paddingTop: '0.25rem',
    paddingRight: '0.5rem',
  },
  folderNode: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    height: '2.5rem',
    paddingTop: '0.25rem',
    paddingRight: '0.5rem',
  },
};

interface Props {
  mediaFolderTree?: any;
  onToggle: (
    id: string,
    folderPath: any[],
    expanded: boolean,
    name: string,
  ) => void;
  selectedFolder: string;
  onEdit: (folderId: string, path: any[], folderName: string) => void;
  onCreate: (folderId: string, path: any[], folderName: string) => void;
  onDelete: (id: string, childPath: any[]) => void;
  onMoveComplete: (id: string, destId: string, destPath: any[]) => void;
  canCreateMedia: boolean;
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
}

class FolderTree extends React.Component<Props> {
  getListItemRootStyle = (listItemId, selectedFolder) => {
    return selectedFolder === listItemId
      ? styles.selectedNode
      : styles.folderNode;
  };

  renderSideNode(
    data,
    onToggle,
    folderPath,
    selectedFolder,
    level: number = 0,
  ) {
    const {
      onEdit,
      onCreate,
      onDelete,
      mediaFolderTree,
      onMoveComplete,
      canUpdateMedia,
      canDeleteMedia,
      canCreateMedia,
    } = this.props;

    if (!data) {
      return [];
    }

    return data.map((child, pathIndex) => {
      const { name, id, children, expanded } = child;
      const childPath = [...folderPath, 'children', pathIndex];
      const rootStyles = this.getListItemRootStyle(id, selectedFolder);
      const isExpanded = expanded ? !expanded : true;

      const leftIcon = R.isNil(children)
        ? iconUtilities.assetFolderIcon(config.DEFAULT_FOLDER_COLOR_ID, {
            marginLeft: '24px',
            marginRight: '10px',
            bottom: '0px',
          })
        : child.expanded
        ? iconUtilities.leftFolderIconNew('down-arrow')
        : iconUtilities.leftFolderIconNew('right-arrow');

      const showFolderCreate =
        canCreateMedia && childPath.length < config.MAX_FOLDER_DEPTH * 2;
      const showAdditionalOptions =
        canUpdateMedia || canDeleteMedia || showFolderCreate;

      const rightIconButton = showAdditionalOptions ? (
        <AdditionalOptions
          iconStyle={{
            color: colors.neutral.gray,
          }}
          menuItems={[
            ...(canUpdateMedia
              ? [
                  {
                    type: 'trigger',
                    primaryText: 'rename',
                    icon: <img src={renameIcon} />,
                    id: uuid(),
                    element: (
                      <EditMediaFolderModal
                        folderId={id}
                        folderPath={childPath}
                        name={name}
                        type="folder"
                        editAction={(folderId, path, folderName) =>
                          onEdit(folderId, path, folderName)
                        }
                      />
                    ),
                  },
                ]
              : []),
            ...(showFolderCreate
              ? [
                  {
                    type: 'trigger',
                    primaryText: 'create folder',
                    id: uuid(),
                    element: (
                      <CreateMediaFolderModal
                        folderId={id}
                        folderPath={childPath}
                        type="folder"
                        createAction={(folderId, path, folderName) =>
                          onCreate(folderId, path, folderName)
                        }
                      />
                    ),
                  },
                ]
              : []),
            ...(canUpdateMedia
              ? [
                  {
                    type: 'trigger',
                    primaryText: 'move to folder',
                    id: child.id,
                    icon: <img src={folderMoveIcon} />,
                    element: (
                      <MoveMediaFolderModal
                        mediaFolderTree={mediaFolderTree}
                        type="folder"
                        ids={[child.id]}
                        loading={false}
                        onEdit={onEdit}
                        onCreate={onCreate}
                        onComplete={onMoveComplete}
                        disabled={[
                          child.id,
                          ...(child.ancestors && child.ancestors.length
                            ? [R.last(child.ancestors)]
                            : ['root']),
                        ]}
                      />
                    ),
                  },
                ]
              : []),
            ...(canDeleteMedia
              ? [
                  {
                    type: 'dialog',
                    primaryText: 'delete',
                    modalProps: {
                      labelCancel: 'Cancel',
                      labelConfirm: 'Delete',
                      confirmAction: () => onDelete(id, childPath),
                      title: `Delete ${name} folder`,
                      text:
                        'Are you sure you want to delete this folder? If the folder has any subfolders or files saved below this folder, everything will be deleted.',
                    },
                  },
                ]
              : []),
          ]}
        />
      ) : (
        <div style={{ position: 'relative' }} />
      );

      return (
        <>
          <ListItemButton
            disableGutters
            key={id}
            style={{ ...rootStyles, paddingLeft: `${level * 1}rem` }}
            onClick={() => onToggle(id, childPath, isExpanded, name)}
          >
            {leftIcon}
            <ListItemText primaryTextStyle={{ fontWeight: 0 }} primary={name} />
            {rightIconButton}
          </ListItemButton>
          <Collapse in={child.expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {!R.isNil(children)
                ? this.renderSideNode(
                    children,
                    onToggle,
                    childPath,
                    selectedFolder,
                    level + 1,
                  )
                : []}
            </List>
          </Collapse>
        </>
      );
    });
  }

  render() {
    const {
      mediaFolderTree,
      onToggle,
      selectedFolder,
      onCreate,
      canCreateMedia,
    } = this.props;
    const { id, name, children } = mediaFolderTree;

    const rootStyles = this.getListItemRootStyle(id, selectedFolder);
    const folderPath = [];

    const expanded = true;

    const icon = mediaFolderTree.expanded
      ? iconUtilities.leftFolderIconNew('down-arrow')
      : iconUtilities.leftFolderIconNew('right-arrow');

    const rightIconButton = canCreateMedia ? (
      <AdditionalOptions
        iconStyle={{
          color: colors.neutral.gray,
        }}
        menuItems={[
          {
            type: 'trigger',
            primaryText: 'create folder',
            id: uuid(),
            element: (
              <CreateMediaFolderModal
                folderId={id}
                folderPath={folderPath}
                type="folder"
                createAction={(folderId, path, folderName) =>
                  onCreate(folderId, path, folderName)
                }
              />
            ),
          },
        ]}
      />
    ) : (
      <div style={{ position: 'relative' }} />
    );

    return (
      <div styleName="folder-tree-container">
        <List disablePadding>
          <>
            <ListItemButton
              disableGutters
              key={id}
              style={rootStyles}
              onClick={() => onToggle(id, folderPath, expanded, name)}
            >
              {icon}
              <ListItemText
                primaryTextStyle={{ fontWeight: 0 }}
                primary={name}
              />
              {rightIconButton}
            </ListItemButton>
            <Collapse
              in={mediaFolderTree.expanded}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding>
                {this.renderSideNode(
                  children,
                  onToggle,
                  folderPath,
                  selectedFolder,
                  1,
                )}
              </List>
            </Collapse>
          </>
        </List>
      </div>
    );
  }
}

export default FolderTree;
