import React from 'react';
import { connect } from 'react-redux';
import { match as Match } from 'react-router-dom';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';
import { StringParam, withQueryParams } from 'use-query-params';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import { Progress, Snackbar } from '@atom/mui';
import { InventoryAssetActions } from '@atom/types/actions';
import {
  AssetDetailView,
  InventoryAssetDetailType,
} from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { ReduxStore } from '@atom/types/store';
import { hasAccess } from '@atom/utilities/accessUtilities';
import history from '@atom/utilities/history';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import AnalyticsTab from './bodyPanes/analytics/AnalyticsTab';
import CostTab from './bodyPanes/cost/CostTab';
import DetailTab from './bodyPanes/detail/DetailTab';
import Folders from './bodyPanes/folders/Folders';
import InventoryAssetInfo from './bodyPanes/info/InventoryAssetInfo';
import InventoryPhotos from './bodyPanes/inventoryPhotos/InventoryPhotos';
import AssetWorkOrders from './bodyPanes/workOrders/AssetWorkOrders';
import InventoryDetailHeader from './header/InventoryDetailHeader';

import './detail.css';

interface PassedProps {
  match: Match<{ id: string }>;
  query: { activeView: AssetDetailView };
  setQuery: any;
}

interface ReduxStateProps {
  loading: boolean;
  loadingApproveAll: boolean;
  loadingCreateWorkOrder: boolean;
  inventoryAssetDetail?: InventoryAssetDetailType;
}

interface ReduxDispatchProps {
  inventoryAssetActions: InventoryAssetActions;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

interface State {
  inventoryMoveDisabled: boolean;
}

class InventoryDetail extends React.Component<Props, State> {
  state = {
    inventoryMoveDisabled: false,
  };

  componentDidMount() {
    const { inventoryAssetActions, match, query } = this.props;

    if (!query || !query?.activeView) {
      this.setActiveView(AssetDetailView.ASSET_INFO);
    }

    inventoryAssetActions.retrieveInventoryAssetDetail({ id: match.params.id });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { inventoryAssetActions } = this.props;
      inventoryAssetActions.retrieveInventoryAssetDetail({
        id: nextProps.match.params.id,
      });
    }
  }

  componentWillUnmount() {
    Snackbar.dismiss();
  }

  setActiveView = async view => {
    const { setQuery } = this.props;

    await setQuery({ activeView: view || AssetDetailView.ASSET_INFO });

    history.handleHistoryChange(location, 'POP');
  };

  navigateBack = () => {
    history.goBackWithState('/inventory');
  };

  onAssetEdit = (
    id: string,
    name: string,
    rate: number,
    unit?: string,
    isStockBased?: boolean,
    isStartEndReading?: boolean,
    isStartEndCalculated?: boolean,
    quantityRemaining?: number,
  ) => {
    const { inventoryAssetActions, inventoryAssetDetail } = this.props;

    inventoryAssetActions.requestInventoryAssetDetailUpdate({
      name,
      id,
      ...(inventoryAssetDetail.isMaterial && {
        budget: {
          rate,
          ...(unit && { unit }),
          ...(!isNilOrEmpty(isStockBased) && { isStockBased }),
          ...(!isNilOrEmpty(isStartEndReading) && { isStartEndReading }),
          ...(!isNilOrEmpty(isStartEndCalculated) && { isStartEndCalculated }),
          ...(!isNilOrEmpty(quantityRemaining) && { quantityRemaining }),
        },
      }),
    });
  };

  onSelectedChange = (selected: string[]) => {
    this.setState({
      inventoryMoveDisabled: Boolean(selected.length),
    });
  };

  setDetailBodyPane = activeView => {
    const { inventoryAssetDetail, match } = this.props;

    const canCreateMedia = hasAccess(
      PolicyAction.CREATE_MEDIA,
      inventoryAssetDetail.actions,
    );

    const canUpdateMedia = hasAccess(
      PolicyAction.UPDATE_MEDIA,
      inventoryAssetDetail.actions,
    );

    const canDeleteMedia = hasAccess(
      PolicyAction.DELETE_MEDIA,
      inventoryAssetDetail.actions,
    );

    const canMarkAsCoverPhoto = hasAccess(
      PolicyAction.UPDATE,
      inventoryAssetDetail.actions,
    );

    const content = {
      work: <AssetWorkOrders assetId={inventoryAssetDetail.id} />,
      files: (
        <Folders
          match={match}
          subjectId={inventoryAssetDetail.id}
          subjectType="inventoryAsset"
          onSelectedChange={this.onSelectedChange}
          canCreateMedia={canCreateMedia}
          canUpdateMedia={canUpdateMedia}
          canDeleteMedia={canDeleteMedia}
        />
      ),
      photos: (
        <InventoryPhotos
          inventoryAssetDetail={inventoryAssetDetail}
          canCreateMedia={canCreateMedia}
          canMarkAsCoverPhoto={canMarkAsCoverPhoto}
          canUpdateMedia={canUpdateMedia}
          canDeleteMedia={canDeleteMedia}
        />
      ),
      assetInfo: <InventoryAssetInfo />,
      subItems: <DetailTab />,
      cost: <CostTab asset={inventoryAssetDetail} />,
      // TODO: AM-7339 Remove analytics tab component
      analytics: <AnalyticsTab />,
    };

    return content[activeView];
  };

  onApproveAllPendingChanges = () => {
    const { inventoryAssetActions, inventoryAssetDetail } = this.props;

    inventoryAssetActions.requestAssetPendingApproval({
      action: 'accept',
      element: inventoryAssetDetail,
    });
  };

  render() {
    const {
      inventoryAssetDetail,
      loading,
      loadingApproveAll,
      query,
    } = this.props;
    const { inventoryMoveDisabled } = this.state;

    const activeView = query?.activeView || AssetDetailView.ASSET_INFO;

    if (loading || R.isEmpty(inventoryAssetDetail)) {
      return (
        <div styleName="spinner-container">
          <Progress />
        </div>
      );
    }

    return (
      <div styleName="inventory-detail-container">
        <InventoryDetailHeader
          inventoryAssetDetail={inventoryAssetDetail}
          loadingApproveAll={loadingApproveAll}
          activeView={activeView}
          // @ts-ignore
          setActiveView={this.setActiveView}
          navigateBack={this.navigateBack}
          onAssetEdit={this.onAssetEdit}
          onApproveAllPendingChanges={this.onApproveAllPendingChanges}
          inventoryMoveDisabled={inventoryMoveDisabled}
        />
        {this.setDetailBodyPane(activeView)}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  inventoryAssetDetail: state.inventoryAssetDetail,
  loading: state.loading.loadingInventoryAssetDetail,
  loadingApproveAll: state.loading.loadingApproveAll,
  loadingCreateWorkOrder: state.loading.loadingCreateWorkOrder,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withQueryParams(
    { activeView: StringParam },
    // @ts-ignore
    InventoryDetail,
  ),
);
