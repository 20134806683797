import * as React from 'react';
import FontIcon from 'material-ui/FontIcon';

import colors from '@atom/styles/colors';

interface Props {
  name: string;
  color?: string;
}

const MapDrawerLayerIcon = ({ name, color }: Props) => {
  return (
    <FontIcon
      className="material-icons"
      style={{ fontSize: 24, color: color ? color : colors.neutral.gray }}
    >
      {name}
    </FontIcon>
  );
};

export default MapDrawerLayerIcon;
