import * as React from 'react';

import DatePicker from '@atom/components/common/DatePicker';
import CarouselWrapper from '@atom/components/common/photoCarousel/CarouselWrapper';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { FormFieldType } from '@atom/types/form';
import { MediaItem } from '@atom/types/media';

import FileUploadButton from '../FileUploadButton';
import FileUploadTile from '../FileUploadTile';

import '../formInstance.css';

const styles = {
  floatingLabelStyle: {
    color: colors.neutral.dim,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
  datePickerStyles: {
    width: '100%',
  },
};

interface PassedProps {
  field: FormFieldType;
  media: MediaItem[];
  fieldId: string;
  onChange: (property: string, dateMillis: number) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  fieldFileUpload: (event: EventType) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  value: any;
  isEditable: boolean;
  isUploadDisabled: boolean;
}

type Props = PassedProps;

const DatePickerField = ({
  field,
  fieldId,
  onChange,
  value,
  removeFile,
  isEditable,
  media,
  fieldFileUpload,
  isUploadDisabled,
  renameMedia,
}: Props) => {
  const dateValue = value !== '' ? value : {};
  const required = field.required ? 'required' : '';
  const medium = media.filter((med: MediaItem) => med.fieldId === fieldId);

  return (
    <div styleName="field-block">
      <div styleName={`short-textfield-container ${required}`}>
        <DatePicker
          name={field.type}
          fullWidth
          valueMillis={dateValue}
          hintText="mm/dd/yyyy"
          floatingLabelFixed
          floatingLabelText={field.title}
          textFieldStyle={styles.textFieldStyle}
          floatingLabelStyle={styles.floatingLabelStyle}
          style={styles.datePickerStyles}
          onDataChange={onChange}
          disabled={!isEditable}
        />
        {field.fileUpload && (
          <FileUploadButton
            onChange={fieldFileUpload}
            isUploadDisabled={!isEditable || isUploadDisabled}
          />
        )}
      </div>
      <div styleName="field-description">{field.description}</div>
      <div styleName="file-tile-container">
        {medium.map((mediaItem: MediaItem) => (
          <CarouselWrapper
            media={media}
            selectedMediaId={mediaItem.id}
            removeFile={removeFile}
            subjectId={fieldId}
            renameMedia={renameMedia}
            canUpdateMedia={isEditable}
            canDeleteMedia={isEditable}
          >
            <FileUploadTile key={mediaItem.id} media={mediaItem} />
          </CarouselWrapper>
        ))}
      </div>
    </div>
  );
};

export default DatePickerField;
