import React from 'react';
import { Dialog } from 'material-ui';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import * as R from 'ramda';

import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';

import './dialogTrigger.css';

const DELETE_LABEL = 'delete';

function setStatePristine() {
  return { open: false };
}

const styles = {
  confirmStyles: {
    marginLeft: '1rem',
  },
  titleStyle: {
    fontSize: fonts.xl,
    color: colors.neutral.dark,
    fontWeight: 500,
  },
};

interface Props {
  trigger: any;
  title: string;
  content: any;
  confirmAction: () => any;
  labelCancel: string;
  labelConfirm?: string;
  disableWrapperClass?: boolean;
  rootStyle?: object;
}

class DialogTrigger extends React.Component<Props> {
  state = {
    open: false,
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openModal = () => {
    this.setState({ open: true });
  };

  confirm = () => {
    const { confirmAction } = this.props;
    confirmAction();

    this.setState(setStatePristine);
  };

  render() {
    const { open } = this.state;
    const {
      trigger,
      title,
      content,
      labelCancel,
      labelConfirm,
      disableWrapperClass,
      rootStyle,
    } = this.props;

    const isDeleteModal =
      !R.isNil(labelConfirm) && R.toLower(labelConfirm) === DELETE_LABEL;

    const actionButtons = [
      <FlatButton
        label={labelCancel}
        labelStyle={{ color: colors.neutral.dark }}
        onClick={this.closeModal}
      />,
      ...(labelConfirm
        ? [
            <RaisedButton
              label={labelConfirm}
              style={styles.confirmStyles}
              onClick={this.confirm}
              labelColor={colors.neutral.white}
              backgroundColor={
                isDeleteModal ? colors.brand.red : colors.brand.blue
              }
            />,
          ]
        : []),
    ];

    if (disableWrapperClass) {
      return (
        <div style={rootStyle} onClick={this.openModal}>
          {trigger}
          <Dialog
            modal
            title={title}
            titleStyle={styles.titleStyle}
            contentStyle={{ width: layout.mediumModalWidth }}
            actions={actionButtons}
            open={open}
          >
            <div styleName="trigger-content">{content}</div>
          </Dialog>
        </div>
      );
    }

    return (
      <div styleName="trigger-wrapper" onClick={this.openModal}>
        {trigger}
        <Dialog
          modal
          title={title}
          titleStyle={styles.titleStyle}
          contentStyle={{ width: layout.mediumModalWidth }}
          actions={actionButtons}
          open={open}
        >
          <div styleName="trigger-content">{content}</div>
        </Dialog>
      </div>
    );
  }
}

export default DialogTrigger;
