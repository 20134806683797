import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog, FontIcon } from 'material-ui';
import { bindActionCreators, Dispatch } from 'redux';

import * as actionCreators from '@atom/actions/inventoryCategoryActions';
import { Button } from '@atom/mui';
import { filteredCategoriesTextSelector } from '@atom/selectors/categorySelectors';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import { InventoryCategoryActions } from '@atom/types/actions';
import { ReduxStore } from '@atom/types/store';

import CategoryListRow from './CategoryListRow';

import './categoryFilter.css';

const styles = {
  dialog: {
    bodyStyle: { margin: 0, padding: 0 },
    contentStyle: {
      width: layout.modalWidth,
      height: '100vh',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
    reset: { float: 'left' },
  },
  categorySelectionButton: {
    paddingTop: '2px',
  },
};

const setInitialState = () => ({
  open: false,
  selectedItems: new Set([]),
});

interface PassedProps {
  categories: any[];
  updateFiltersState: (object) => any;
  selectedItems?: any[];
}

interface ReduxStateProps {
  loading: boolean;
}

interface ReduxDispatchProps {
  inventoryCategoryActions: InventoryCategoryActions;
}

type Props = PassedProps & ReduxStateProps & ReduxDispatchProps;

class CategoryFilterModal extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      ...setInitialState(),
      ...(props.selectedItems
        ? {
            selectedItems: new Set(props.selectedItems),
          }
        : {}),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItems !== this.props.selectedItems) {
      this.setState({ selectedItems: new Set(nextProps.selectedItems) });
    }
  }

  closeModal = () => {
    this.setState(setInitialState);
  };

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  confirm = () => {
    // @ts-ignore
    const { selectedItems } = this.state;
    const { updateFiltersState } = this.props;

    // @ts-ignore
    updateFiltersState({ categoryIdsFilters: [...selectedItems] });
    this.setState({ open: false });
  };

  toggleChecked = (id: string) => {
    // @ts-ignore
    const { selectedItems } = this.state;

    const selectedItemsSet = new Set([...selectedItems]);

    if (selectedItemsSet.has(id)) {
      selectedItemsSet.delete(id);
      return this.setState({ selectedItems: selectedItemsSet });
    }
    selectedItemsSet.add(id);
    return this.setState({ selectedItems: selectedItemsSet });
  };

  renderCategories = () => {
    const { categories, loading, inventoryCategoryActions } = this.props;
    // @ts-ignore
    const { selectedItems } = this.state;

    return categories.map((category, categoryIndex) => {
      return (
        <div styleName="category-list-row-container" key={category.id}>
          <CategoryListRow
            inventoryCategoryActions={inventoryCategoryActions}
            isChecked={selectedItems.has(category.id)}
            category={category}
            loading={loading}
            onClick={this.toggleChecked}
            selectedItems={selectedItems}
            categoryPath={[categoryIndex]}
          />
        </div>
      );
    });
  };

  resetSelectedItems = () => {
    const { updateFiltersState } = this.props;

    this.setState({ selectedItems: new Set([]), open: false });
    // @ts-ignore
    updateFiltersState({ categoryIdsFilters: null });
  };

  render() {
    // @ts-ignore
    const { open, selectedItems } = this.state;
    const isNoItemSelected = !selectedItems.size;

    const actionButtons = [
      <Button
        // @ts-ignore
        style={styles.actionButtons.reset}
        color="primary"
        onClick={this.resetSelectedItems}
      >
        Clear selection
      </Button>,
      <Button style={styles.actionButtons.cancel} onClick={this.closeModal}>
        Cancel
      </Button>,
      <Button
        disabled={isNoItemSelected}
        onClick={this.confirm}
        color="primary"
        variant="contained"
      >
        Add
      </Button>,
    ];

    return (
      <React.Fragment>
        <div onClick={this.openModal} styleName="category-filter-trigger">
          <FontIcon
            className="material-icons"
            color={isNoItemSelected ? colors.neutral.gray : colors.brand.blue}
            children="folder"
          />
          <span
            styleName="text"
            style={{
              fontWeight: isNoItemSelected ? 'initial' : 500,
              color: isNoItemSelected ? 'initial' : colors.neutral.dark,
            }}
          >
            {filteredCategoriesTextSelector(selectedItems)}
          </span>
          <span styleName="category-filter-selection-button">choose</span>
        </div>
        <Dialog
          title="Add Category Filters"
          actions={actionButtons}
          modal
          autoScrollBodyContent
          bodyStyle={styles.dialog.bodyStyle}
          contentStyle={styles.dialog.contentStyle}
          open={open}
        >
          <div styleName="category-modal-selection-backdrop">
            {this.renderCategories()}
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loading: state.loading.loadingInventoryFilterCategoryTreeNode,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryCategoryActions: bindActionCreators(actionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryFilterModal);
