import React from 'react';

import { Modal } from '@atom/mui';
import colors from '@atom/styles/colors';

export interface Props {
  open: boolean;
  loading?: boolean;
  title?: string;
  content?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const styles = {
  content: {
    minHeight: '3rem',
  },
};

const DeleteModal = ({
  open,
  loading,
  title = 'Delete?',
  content,
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Delete',
}: Props) => {
  return (
    <Modal
      open={open}
      title={title}
      loading={loading}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText={confirmText}
      cancelButtonText={cancelText}
      contentStyle={styles.content}
      ConfirmButtonProps={{
        style: {
          ...(!loading && {
            background: colors.brand.red,
            color: colors.neutral.white,
          }),
        },
      }}
    >
      {content}
    </Modal>
  );
};

export default DeleteModal;
