import React, { useState } from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AttributesType } from '@atom/types/inventory';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import RejectChangesModal from './RejectChangesModal';

import './mapDrawerContent.css';

const styles = {
  icon: {
    padding: '0px',
    marginLeft: '0.5rem',
    height: '35px',
    width: '35px',
    bottom: '10px',
  },
  iconColor: colors.brand.blue,
};

interface Props {
  attribute: AttributesType;
  onAttributesPendingChange: (
    id: string,
    changeId: string,
    action: string,
  ) => void;
  canManageChanges: boolean;
}

const AttributeRow = ({
  attribute,
  onAttributesPendingChange,
  canManageChanges,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const showControls = !!attribute.changeId && canManageChanges;

  const handlePendingChanges = (action: string) => {
    onAttributesPendingChange(attribute.id, attribute.changeId, action);
    setOpen(false);
  };

  return (
    <div styleName="attribute-row">
      <div styleName="attribute-label">
        {attribute.name} {attribute.unit && `(${attribute.unit})`}
      </div>
      <div>
        {attributeDisplayUtility.display(
          attribute.value,
          attribute.dataType,
          attribute.unit,
          !!attribute.changeId,
          attribute.newValue,
        )}
        {showControls && (
          <div>
            <span styleName="pending-attribute-label">Edited</span>
            <IconButton tooltip="Reject" onClick={() => setOpen(true)}>
              <Icon color={styles.iconColor}>clear</Icon>
            </IconButton>
            <IconButton
              tooltip="Approve"
              onClick={() => handlePendingChanges('approve')}
            >
              <Icon color={styles.iconColor}>check</Icon>
            </IconButton>
          </div>
        )}
        <RejectChangesModal
          open={open}
          onCancel={() => setOpen(false)}
          onReject={() => handlePendingChanges('reject')}
        />
      </div>
    </div>
  );
};

export default AttributeRow;
