import React from 'react';
import styled from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogActionsProps as DialogActionsPropsType,
  DialogContent,
  DialogContentProps as DialogContentPropsType,
  DialogTitle,
  DialogTitleProps as DialogTitlePropsType,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Button, ButtonProps } from '@atom/mui';
import colors from '@atom/styles/colors';

export interface ModalProps {
  open: boolean;
  title: React.ReactNode;
  width?: 'sx' | 'sm' | 'lg' | 'xl' | 'xxl';
  loading?: boolean;
  children?: React.ReactNode;
  onCancel?: (event?, reason?) => void;
  cancelButtonText?: string;
  CancelButtonProps?: ButtonProps;
  onConfirm?: () => void;
  confirmButtonText?: string;
  ConfirmButtonProps?: ButtonProps;
  footer?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  onExited?: () => void;
  disableEscapeKeyDown?: boolean;
  disabled?: boolean;
  DialogTitleProps?: DialogTitlePropsType;
  DialogContentProps?: DialogContentPropsType;
  DialogFooterProps?: DialogActionsPropsType;
  disableHeader?: boolean;
  disableFooter?: boolean;
  onClear?: () => void;
  clearButtonText?: string;
}

const getWidthValue = width => {
  switch (width) {
    case 'sm':
      return '31.25rem';
    case 'lg':
      return '43.75rem';
    case 'xl':
      return '62.5rem';
    case 'xxl':
      return '77rem';
    default:
      return '23rem';
  }
};

const useStyles = makeStyles({
  paper: ({ width = 'sm' }: ModalProps) => ({
    // 340, 500, 700, 1000 pixels respectively
    width: getWidthValue(width),
  }),
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.neutral.ash}`,
  },
  // @ts-ignore
  content: ({ contentStyle }: ModalProps) => ({
    minHeight: '3rem',
    ...contentStyle,
  }),
  footer: {
    borderTop: `1px solid ${colors.neutral.ash}`,
  },
});

/**
 * Temporary solution to override default styling that
 * has a higher CSS specificity than makeStyles.
 **/
const MuiDialogContent = styled(DialogContent)(props => {
  return { padding: `${props?.style?.padding ?? '2rem'} !important` };
});

export const Modal = (props: ModalProps) => {
  const {
    open,
    onCancel,
    title,
    children,
    loading,
    disabled,
    footer,
    cancelButtonText = 'Cancel',
    CancelButtonProps = {},
    onConfirm,
    confirmButtonText = 'Ok',
    onExited,
    ConfirmButtonProps = {},
    DialogTitleProps = {},
    DialogContentProps = {},
    DialogFooterProps = {},
    disableEscapeKeyDown = true,
    disableHeader = false,
    disableFooter = false,
    onClear,
    clearButtonText = 'Clear',
  } = props;

  const classes = useStyles(props);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      TransitionProps={{
        onExited,
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      classes={{ paper: classes.paper }}
      maxWidth={false}
      fullWidth
    >
      {!disableHeader && (
        <DialogTitle
          {...DialogTitleProps}
          classes={{ root: classes.title, ...DialogTitleProps.classes }}
        >
          {title}
        </DialogTitle>
      )}
      <MuiDialogContent
        {...DialogContentProps}
        style={props.contentStyle}
        classes={{
          root: classes.content,
          ...DialogContentProps.classes,
        }}
      >
        {children}
      </MuiDialogContent>
      {!disableFooter && (
        <DialogActions
          classes={{
            root: classes.footer,
            ...DialogFooterProps.classes,
          }}
        >
          {footer || (
            <>
              {onClear && (
                <>
                  <Button onClick={onClear}>{clearButtonText}</Button>
                  <div style={{ flexGrow: 1 }} />
                </>
              )}
              <Button onClick={onCancel} {...CancelButtonProps}>
                {cancelButtonText}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onConfirm}
                disabled={disabled || loading}
                {...ConfirmButtonProps}
              >
                {confirmButtonText}
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
