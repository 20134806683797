import React from 'react';

import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import { Checkbox, ListTable } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Media } from '@atom/types/media';
import {
  InheritedComponent,
  InheritedComponentType,
} from '@atom/types/workTemplate';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import MediaTableOptions from './MediaTableOptions';

const {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} = ListTable;

const styles = {
  name: {
    fontWeight: '500',
    color: colors.neutral.dark,
  },
  nameDisabled: {
    fontWeight: '500',
    color: colors.neutral.gray,
  },
  content: {
    color: colors.neutral.dark,
  },
  contentDisabled: {
    color: colors.neutral.gray,
  },
};

interface Props {
  media: Media[];
  totalCount: number;
  page: number;
  limit: number;
  selectedItems: Set<string>;
  isEditDisabled: boolean;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
  toggleMedia: (id: string) => void;
  handleRename: (id: string, name: string) => void;
  handleDelete: (id: string) => void;
  isInherited?: boolean;
  inheritedComponents?: InheritedComponent[];
}

const MediaTable = ({
  media = [],
  totalCount = 0,
  page,
  limit,
  selectedItems,
  isEditDisabled,
  setPage,
  setLimit,
  toggleMedia,
  handleRename,
  handleDelete,
  isInherited = false,
  inheritedComponents = [],
}: Props) => {
  return (
    <ListTable offsetTop="320px">
      <TableHead>
        <TableRow header>
          <TableCell variant="head" width="30px" />
          {isInherited && <TableCell variant="head" width="30px" />}
          <TableCell variant="head">Name</TableCell>
          <TableCell variant="head">Uploaded By</TableCell>
          <TableCell variant="head">Uploaded By</TableCell>
          <TableCell variant="head" width="30px" />
        </TableRow>
      </TableHead>
      <TableBody>
        {media.map(item => {
          const isItemInherited = isComponentInherited(
            InheritedComponentType.MEDIA,
            inheritedComponents,
            item?.fileId,
          );

          const lockColumn = isItemInherited ? (
            <TableCell>
              <InheritanceLockIcon
                tooltip="Inherited document is not editable."
                placement="bottom-start"
              />
            </TableCell>
          ) : (
            <TableCell width="30px" />
          );

          return (
            <TableRow key={item.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedItems.has(item.id)}
                  onClick={() => toggleMedia(item.id)}
                />
              </TableCell>
              {isInherited && lockColumn}
              <TableCell>
                <a
                  href={`${FILES_ENDPOINT}/${item.fileId}`}
                  target="_blank"
                  style={isItemInherited ? styles.nameDisabled : styles.name}
                >{`${item.name}.${item.fileExtension}`}</a>
              </TableCell>
              <TableCell>
                <span
                  style={
                    isItemInherited ? styles.contentDisabled : styles.content
                  }
                >
                  {item?.createdBy?.firstName
                    ? `${item.createdBy.firstName} ${item.createdBy.lastName}`
                    : '-'}
                </span>
              </TableCell>
              <TableCell>
                <span
                  style={
                    isItemInherited ? styles.contentDisabled : styles.content
                  }
                >
                  {setDisplayDate(item.createdDate)}
                </span>
              </TableCell>
              <TableCell padding="checkbox">
                <MediaTableOptions
                  medium={item}
                  isEditDisabled={isEditDisabled || isItemInherited}
                  handleRename={handleRename}
                  handleDelete={handleDelete}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter width="70%">
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={newPage => setPage(newPage)}
            onRowsPerPageChange={event => {
              setPage(1);
              setLimit(+event.target.value);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default MediaTable;
