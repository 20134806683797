import React, { useContext } from 'react';

import SchemaDetailContext, {
  PreviewTab,
} from '@atom/components/schemaDetail/SchemaDetailContext';

import CostDetail from './costDetail/CostDetail';
import PreviewInfo from './previewInfo/PreviewInfo';
import PreviewSubItems from './previewSubItems/PreviewSubItems';

import './schemaPreview.css';

const SchemePreview = () => {
  const { previewTab } = useContext(SchemaDetailContext);

  const getActiveTab = () => {
    const tabs = {
      [PreviewTab.INFO]: <PreviewInfo />,
      [PreviewTab.SUB_ITEMS]: <PreviewSubItems />,
      [PreviewTab.COST]: <CostDetail />,
    };

    return tabs[previewTab];
  };

  return getActiveTab();
};

export default SchemePreview;
