import React, { useReducer, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import SchemaDetailContext, {
  PreviewTab,
  reducer,
  SchemaDetailView,
  SchemaTreeActionTypes,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { GET_SCHEMA_TREE } from '@atom/graph/schema';
import { Progress } from '@atom/mui';
import { SchemaTree } from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import SchemaBuilder from './schemaBuilder/SchemaBuilder';
import SchemaDetailHeader from './schemaDetailHeader/SchemaDetailHeader';
import SchemaPreview from './schemaPreview/SchemaPreview';

import './schemaDetail.css';

interface Props {
  match: Match<{ id: string }>;
}

const SchemaDetail = ({ match }: Props) => {
  const [schemaTree, dispatch] = useReducer(reducer, {} as SchemaTree);

  const [view, setView] = useState<SchemaDetailView>(SchemaDetailView.PREVIEW);
  const [previewTab, setPreviewTab] = useState<PreviewTab>(PreviewTab.INFO);

  const { refetch: refetchSchemaTree } = useQuery(GET_SCHEMA_TREE, {
    variables: {
      id: match.params.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      dispatch({
        type: SchemaTreeActionTypes.SET_SCHEMA_TREE,
        data: data?.schemaTree,
      });
    },
  });

  const schemaDetailLoading = isNilOrEmpty(schemaTree);

  const getActiveView = () => {
    const views = {
      [SchemaDetailView.PREVIEW]: <SchemaPreview />,
      [SchemaDetailView.BUILDER]: <SchemaBuilder />,
    };

    return views[view];
  };

  const viewStyle = view === SchemaDetailView.PREVIEW ? 'view preview' : 'view';

  return (
    <SchemaDetailContext.Provider
      value={{
        schemaTree,
        dispatch,
        refetchSchemaTree,
        view,
        setView,
        previewTab,
        setPreviewTab,
      }}
    >
      {schemaDetailLoading ? (
        <Progress />
      ) : (
        <>
          <SchemaDetailHeader />
          <div styleName={viewStyle}>{getActiveView()}</div>
        </>
      )}
    </SchemaDetailContext.Provider>
  );
};

export default SchemaDetail;
