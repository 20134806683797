import React from 'react';
import {
  Popover as MUIPopover,
  PopoverProps as PopoverPropsType,
} from '@mui/material';

export type PopoverProps = PopoverPropsType;

export const Popover = (props: PopoverProps) => {
  return <MUIPopover {...props} />;
};

export default Popover;
