import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import DeleteModal from '@atom/components/common/DeleteModal';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import TaskDateField from '@atom/components/common/workOrderDetail/taskFields/TaskDateField';
import TaskEnumMultipleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumMultipleField';
import TaskEnumSingleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumSingleField';
import TaskFieldCreateModal from '@atom/components/common/workOrderDetail/taskFields/TaskFieldCreateModal';
import TaskFieldEditModal from '@atom/components/common/workOrderDetail/taskFields/TaskFieldEditModal';
import TaskFieldMenu from '@atom/components/common/workOrderDetail/taskFields/TaskFieldMenu';
import TaskHyperlinkField from '@atom/components/common/workOrderDetail/taskFields/TaskHyperlinkField';
import TaskNumericField from '@atom/components/common/workOrderDetail/taskFields/TaskNumericField';
import TaskTextField from '@atom/components/common/workOrderDetail/taskFields/TaskTextField';
import InheritanceLockIcon from '@atom/components/workTemplate/InheritanceLockIcon';
import WorkTemplateContext, {
  WorkTemplateActionTypes,
} from '@atom/components/workTemplate/WorkTemplateContext';
import {
  WORK_TEMPLATE_TASK_FIELD_CREATE,
  WORK_TEMPLATE_TASK_FIELD_DELETE,
  WORK_TEMPLATE_TASK_FIELD_UPDATE,
  WORK_TEMPLATE_TASK_UPDATE,
} from '@atom/graph/workTemplate';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  TASK_FIELD_DATA_TYPE_OPTIONS,
  TaskField,
  TaskFieldDataType,
} from '@atom/types/task';
import {
  InheritedComponentType,
  WorkOrderTemplateTaskFieldCreateInput,
  WorkOrderTemplateTaskFieldCreatePayload,
  WorkOrderTemplateTaskFieldDeleteInput,
  WorkOrderTemplateTaskFieldUpdateInput,
  WorkOrderTemplateTaskFieldUpdatePayload,
  WorkOrderTemplateTaskUpdateInput,
  WorkTemplateTaskItem,
} from '@atom/types/workTemplate';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { addToSet, removeFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { isComponentInherited } from '@atom/utilities/workTemplateUtilities';

import './taskFields.css';

const DEBOUNCE_TIME = 1000;
const DROPPABLE_ID = 'fields';
const HTTP_STATUS_CONFLICT = 409;

const styles = {
  fieldIconStyles: {
    marginRight: '1.25rem',
  },
  addButtonStyles: {
    marginLeft: '4rem',
  },
};

const TaskFields = () => {
  const { workTemplate, task, dispatch } = useContext(WorkTemplateContext);

  const [selectedField, setSelectedField] = useState<TaskField>(null);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [fieldsLoading, setFieldsLoading] = useState<Set<string>>(new Set());
  const [hoveredField, setHoveredField] = useState<string>();
  const [fieldOrder, setFieldOrder] = useState<string[]>(task.fieldOrder || []);
  const [errorText, setErrorText] = useState<string>();

  useEffect(() => {
    setFieldOrder(task.fieldOrder || []);
  }, [task.id]);

  const [createField, { loading: loadingCreate }] = useMutation<
    { workOrderTemplateTaskFieldCreate: TaskField },
    { input: WorkOrderTemplateTaskFieldCreateInput }
  >(WORK_TEMPLATE_TASK_FIELD_CREATE);

  const [updateField, { loading: loadingUpdate }] = useMutation<
    { workOrderTemplateTaskFieldUpdate: TaskField },
    { input: WorkOrderTemplateTaskFieldUpdateInput }
  >(WORK_TEMPLATE_TASK_FIELD_UPDATE);

  const [deleteField, { loading: loadingDelete }] = useMutation<
    { workOrderTemplateTaskFieldDelete: boolean },
    { input: WorkOrderTemplateTaskFieldDeleteInput }
  >(WORK_TEMPLATE_TASK_FIELD_DELETE);

  const [taskUpdate] = useMutation<
    { workOrderTemplateTaskUpdate: WorkTemplateTaskItem },
    { input: WorkOrderTemplateTaskUpdateInput }
  >(WORK_TEMPLATE_TASK_UPDATE);

  useEffect(() => {
    if (createModalOpen || editModalOpen) {
      setErrorText(null);
    }
  }, [createModalOpen, editModalOpen]);

  const handleEditClick = (field: TaskField) => {
    setSelectedField(field);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (field: TaskField) => {
    setSelectedField(field);
    setDeleteModalOpen(true);
  };

  const handleAddField = (field: TaskField) => {
    dispatch({
      type: WorkTemplateActionTypes.ADD_TASK_FIELD,
      data: {
        taskId: task.id,
        field,
      },
    });

    setFieldOrder([...fieldOrder, field.id]);
  };

  const handleEditField = (field: TaskField) => {
    dispatch({
      type: WorkTemplateActionTypes.UPDATE_TASK_FIELD,
      data: {
        taskId: task.id,
        field,
      },
    });
  };

  const handleEditFieldValue = (field: TaskField) => {
    dispatch({
      type: WorkTemplateActionTypes.UPDATE_TASK_FIELD_VALUE,
      data: {
        taskId: task.id,
        field,
      },
    });
  };

  const handleChange = useCallback(
    async (fieldId: string, value: any) => {
      setFieldsLoading(loading => addToSet(loading, fieldId));

      const res = await updateField({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            taskId: task.id,
            fieldId,
            value,
          },
        },
      });

      const field = res?.data?.workOrderTemplateTaskFieldUpdate;

      if (field.subFieldsTruncated) {
        handleEditField(field);
      } else {
        handleEditFieldValue(field);
      }

      setFieldsLoading(loading => removeFromSet(loading, fieldId));
    },
    [workTemplate.id, task.id, task.fields],
  );

  const handleChangeDebounced = useCallback(
    debounce(handleChange, DEBOUNCE_TIME),
    [handleChange],
  );

  const handleDeleteConfirm = async () => {
    const fieldOrderIndex = R.indexOf(selectedField.id, fieldOrder);
    const newFieldOrder = R.remove(fieldOrderIndex, 1, fieldOrder);
    setFieldOrder(newFieldOrder);

    const res = await deleteField({
      variables: {
        input: {
          workTemplateId: workTemplate.id,
          taskId: task.id,
          fieldId: selectedField.id,
        },
      },
    });

    if (res?.data?.workOrderTemplateTaskFieldDelete) {
      setSelectedField(null);
      setDeleteModalOpen(false);

      dispatch({
        type: WorkTemplateActionTypes.DELETE_TASK_FIELD,
        data: {
          taskId: task.id,
          fieldId: selectedField.id,
        },
      });
    }
  };

  const handleCreate = async (
    payload: WorkOrderTemplateTaskFieldCreatePayload,
  ) => {
    try {
      const res = await createField({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            taskId: task.id,
            ...payload,
          },
        },
      });

      handleAddField(res?.data?.workOrderTemplateTaskFieldCreate);
      setCreateModalOpen(false);
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setErrorText('This name is already in use.');
      }
    }
  };

  const handleUpdate = async (
    payload: WorkOrderTemplateTaskFieldUpdatePayload,
  ) => {
    try {
      const res = await updateField({
        variables: {
          input: {
            workTemplateId: workTemplate.id,
            taskId: task.id,
            ...payload,
          },
        },
      });

      handleEditField(res?.data?.workOrderTemplateTaskFieldUpdate);
      setEditModalOpen(false);
    } catch (err) {
      if (err?.networkError?.statusCode === HTTP_STATUS_CONFLICT) {
        setErrorText('This name is already in use.');
      }
    }
  };

  const onDragEnd = (result: DragDrop) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const newFieldOrder = R.move(source.index, destination.index, fieldOrder);
    setFieldOrder(newFieldOrder);

    taskUpdate({
      variables: {
        input: {
          taskId: task.id,
          workTemplateId: workTemplate.id,
          fieldOrder: newFieldOrder,
        },
      },
    });

    dispatch({
      type: WorkTemplateActionTypes.UPDATE_TASK_PROPERTY,
      data: {
        taskId: task.id,
        property: 'fieldOrder',
        value: newFieldOrder,
      },
    });
  };

  const handleHover = (id?: string) => {
    if (!workTemplate?.published) {
      setHoveredField(id);
    }
  };

  const isDisabled =
    workTemplate?.published || doesNotHaveRolePermissions(ROLE_SETS.ORG_ADMIN);

  const getTaskFieldIcon = (dataType: TaskFieldDataType) => {
    const option = TASK_FIELD_DATA_TYPE_OPTIONS.find(
      item => item.dataType === dataType,
    );

    return option?.icon || '';
  };

  const getContent = (field: TaskField) => {
    const isInherited = isComponentInherited(
      InheritedComponentType.TASK_CUSTOM_FIELD,
      task?.inheritedComponents || [],
      field.id,
    );

    const props = {
      field,
      taskId: task?.id,
      fieldsLoading,
      onChange: handleChange,
      isDisabled: isDisabled || isInherited,
      onBlur: handleChange,
    };

    const hasSubFields = !isNilOrEmpty(field.subFields);

    const components = {
      [TaskFieldDataType.SHORT_TEXT]: (
        <TaskTextField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.LONG_TEXT]: (
        <TaskTextField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.DATE]: (
        <TaskDateField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.DATE_TIME]: (
        <TaskDateField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.ENUM_SINGLE]: hasSubFields ? (
        <div styleName="enum-single-nested-container">
          <TaskEnumSingleField {...props} onChange={handleChangeDebounced} />
        </div>
      ) : (
        <TaskEnumSingleField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.ENUM_MULTIPLE]: <TaskEnumMultipleField {...props} />,
      [TaskFieldDataType.NUMBER]: (
        <TaskNumericField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.HYPERLINK]: <TaskHyperlinkField {...props} />,
    };

    const iconColor =
      hoveredField === field.id ? colors.neutral.ash : colors.neutral.white;

    return (
      (
        <div
          styleName="field-drag-container"
          onMouseEnter={() => handleHover(field.id)}
          onMouseDown={() => handleHover(field.id)}
          onMouseUp={() => handleHover(null)}
        >
          <Icon color={iconColor}>drag_indicator</Icon>
          <Icon color={colors.neutral.gray} style={styles.fieldIconStyles}>
            {getTaskFieldIcon(field?.dataType)}
          </Icon>
          {components[field.dataType]}
          {isInherited && (
            <div styleName="lock-container">
              <InheritanceLockIcon
                tooltip="Inherited Custom Fields Section cannot be removed or edited."
                placement="bottom-end"
              />
            </div>
          )}
          {!isDisabled && !isInherited && (
            <TaskFieldMenu
              field={field}
              onEdit={handleEditClick}
              onDelete={handleDeleteClick}
            />
          )}
        </div>
      ) || <div />
    );
  };

  return (
    <>
      <div onMouseLeave={() => handleHover(null)}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={DROPPABLE_ID}>
            {fieldOrder.map((fieldId: string, index: number) => {
              const field = task.fields.find(item => item.id === fieldId);

              if (!field) {
                return null;
              }

              const isInherited = isComponentInherited(
                InheritedComponentType.TASK_CUSTOM_FIELD,
                task?.inheritedComponents || [],
              );

              return (
                <Draggable
                  key={`${field.title}-${index}`}
                  draggableId={field.id}
                  isDragDisabled={isDisabled || isInherited}
                  index={index}
                >
                  {getContent(field)}
                </Draggable>
              );
            })}
          </Droppable>
        </DragDropContext>
        {!isDisabled && (
          <Button
            color="primary"
            style={styles.addButtonStyles}
            startIcon={<Icon color={colors.brand.blue}>add</Icon>}
            onClick={() => setCreateModalOpen(true)}
            disabled={isDisabled}
          >
            Add Field
          </Button>
        )}
      </div>
      <TaskFieldCreateModal
        fields={task?.fields || []}
        handleCreate={handleCreate}
        loadingCreate={loadingCreate}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        isWorkTemplate
        errorText={errorText}
      />
      <TaskFieldEditModal
        fields={task?.fields || []}
        handleUpdate={handleUpdate}
        loadingUpdate={loadingUpdate}
        onClose={() => setEditModalOpen(false)}
        field={selectedField}
        open={editModalOpen}
        isWorkTemplate
        errorText={errorText}
      />
      <DeleteModal
        open={deleteModalOpen}
        loading={loadingDelete}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Delete Field"
        content="Are you sure you want to delete this field?"
      />
    </>
  );
};

export default TaskFields;
