import React, { useEffect, useState } from 'react';

import { Icon, IconButton, Modal } from '@atom/mui';
import { TaskLocation } from '@atom/types/taskLocation';

import AssetSummaryTray from '../AssetSummaryTray';

import TaskMap from './TaskMap';
import TaskMapSidebar from './TaskMapSidebar';

import './taskMapModal.css';

const styles = {
  modalContent: { padding: '0' },
};

export interface Props {
  open: boolean;
  onClose: () => void;
  handleAddLocationClickFromMap: () => void;
  handleEditLocationClickFromMap: (location: TaskLocation) => void;
  handleAddAssetClickFromMap: () => void;
  viewAssetId: string;
  setViewAssetId: (assetId: string) => void;
}

const TaskMapModal = ({
  open,
  onClose,
  handleAddLocationClickFromMap,
  handleEditLocationClickFromMap,
  handleAddAssetClickFromMap,
  viewAssetId,
  setViewAssetId,
}: Props) => {
  const [hoverId, setHoverId] = useState<string>('');
  const [selectedAssetId, setSelectedAssetId] = useState<string>(viewAssetId);

  useEffect(() => {
    setSelectedAssetId(viewAssetId);
  }, [viewAssetId]);

  const onTrayClose = () => {
    setHoverId('');
    setSelectedAssetId('');
    setViewAssetId('');
  };

  return (
    <Modal
      open={open}
      title={
        <div styleName="modal-header">
          <div>Map</div>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      }
      contentStyle={styles.modalContent}
      width="xxl"
      onCancel={onClose}
      disableFooter
    >
      <div styleName="modal-content">
        {selectedAssetId ? (
          <AssetSummaryTray assetId={selectedAssetId} onClose={onTrayClose} />
        ) : (
          <TaskMapSidebar
            setHoverId={setHoverId}
            selectedAssetId={selectedAssetId}
            setSelectedAssetId={setSelectedAssetId}
            handleAddLocationClickFromMap={handleAddLocationClickFromMap}
            handleEditLocationClickFromMap={handleEditLocationClickFromMap}
            handleAddAssetClickFromMap={handleAddAssetClickFromMap}
          />
        )}
        <div styleName="map-container">
          <TaskMap hoverId={hoverId} />
        </div>
      </div>
    </Modal>
  );
};

export default TaskMapModal;
