// This form is used for Creating, Editing and duplicating budgets

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { DatePicker, Modal, Select, TextField } from '@atom/mui';
import {
  Budget,
  BudgetCreateInput,
  BudgetDuplicateInput,
  BudgetModal,
  BudgetTemplate,
  BudgetUpdateInput,
} from '@atom/types/budget';
import {
  getEndOfCurrentMonth,
  getStartOfCurrentMonth,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './budgetsFormModal.css';

const { MenuItem } = Select;

const DUPLICATE_MSG =
  'Duplicating budget will copy over the budget amount for each budget item.';

const btnConfirmTextMap = {
  [BudgetModal.CREATE]: 'CREATE',
  [BudgetModal.EDIT]: 'SAVE',
  [BudgetModal.DUPLICATE]: 'DUPLICATE',
};

// Passing all in as props to decouple from context
// - usable in other contexts without refactor
export interface Props {
  onClose: () => void;
  budget?: Budget; // Passed in to initialize edit and duplication forms
  modalType: BudgetModal;
  templates: BudgetTemplate[];
  budgetNamesUsed: Set<string>;
  handleCreateBudget?: (formData: BudgetCreateInput) => void;
  handleUpdateBudget: (formData: BudgetUpdateInput) => void;
  handleDuplicateBudget?: (formData: BudgetDuplicateInput) => void;
}

const BudgetsFormModal = ({
  onClose,
  budget,
  modalType,
  templates = [],
  budgetNamesUsed,
  handleCreateBudget,
  handleUpdateBudget,
  handleDuplicateBudget,
}: Props) => {
  // If budget is passed, use that ID
  // - else, set to first template.id if only one template available
  // - else, set to null
  const initTemplateId = (): string => {
    return !isNilOrEmpty(budget?.templateId)
      ? budget.templateId
      : templates.length === 1
      ? templates[0].id
      : null;
  };

  // Form Inputs
  const [templateId, setTemplateId] = useState<string>(initTemplateId());
  const [name, setName] = useState<string>(budget?.name);
  const [startDate, setStartDate] = useState<Date>(
    budget?.startDate || new Date(getStartOfCurrentMonth()),
  );
  const [endDate, setEndDate] = useState<Date>(
    budget?.endDate || new Date(getEndOfCurrentMonth()),
  );

  // Modal copy based on modalType
  const [title, setTitle] = useState<string>('');
  const [duplicateMsg, setDuplicateMsg] = useState<string>('');
  const [confirmButtonText, setConfirmButtonText] = useState<string>('');

  // Only set conditional modal state when modalType is not null
  // - prevents FOEC* when modal state changes to nil during modal close
  useEffect(() => {
    if (!isNilOrEmpty(modalType)) {
      setTitle(
        `${modalType.charAt(0)}${modalType.slice(1).toLowerCase()} Budget`,
      );
      setDuplicateMsg(modalType === BudgetModal.DUPLICATE ? DUPLICATE_MSG : '');
      setConfirmButtonText(btnConfirmTextMap[modalType]);
    }
  }, [modalType]);

  const disableSelect = ['EDIT', 'DUPLICATE'].includes(modalType);

  const nameIsUsed = useMemo(() => {
    if (modalType === 'EDIT') {
      budgetNamesUsed.delete(budget.name);
    }
    return budgetNamesUsed.has(name);
  }, [name]);

  const dateRangeInvalid: boolean =
    isNilOrEmpty(startDate) ||
    isNilOrEmpty(endDate) ||
    startDate?.valueOf() > endDate?.valueOf();

  const disabled =
    isNilOrEmpty(name) ||
    nameIsUsed ||
    isNilOrEmpty(templateId) ||
    dateRangeInvalid;

  const handleSubmit = (): void => {
    switch (modalType) {
      case 'CREATE':
        handleCreateBudget({
          budgetTemplateId: templateId,
          name,
          startDate: startDate?.valueOf(),
          endDate: endDate?.valueOf(),
        });
        break;
      case 'EDIT':
        handleUpdateBudget({
          id: budget.id,
          name,
          startDate: startDate?.valueOf(),
          endDate: endDate?.valueOf(),
        });
        break;
      case 'DUPLICATE':
        handleDuplicateBudget({
          budgetId: budget.id,
          budgetTemplateId: budget.templateId,
          name,
          startDate: startDate?.valueOf(),
          endDate: endDate?.valueOf(),
        });
        break;
      default:
        break;
    }
  };

  const shouldDisableStartDate = (date: Date): boolean =>
    date?.valueOf() > endDate?.valueOf() ||
    moment(date).format('DD') !== moment(date).startOf('month').format('DD');

  const shouldDisableEndDate = (date: Date): boolean =>
    date?.valueOf() < startDate?.valueOf() ||
    moment(date).format('DD') !== moment(date).endOf('month').format('DD');

  const startDateInvalidDay: boolean = useMemo(
    () =>
      moment(startDate).format('DD') !==
      moment(startDate).startOf('month').format('DD'),
    [startDate],
  );

  const endDateInvalidDay: boolean = useMemo(
    () =>
      moment(endDate).format('DD') !==
      moment(endDate).endOf('month').format('DD'),
    [endDate],
  );

  const helperTextCssClass: string =
    startDateInvalidDay || endDateInvalidDay
      ? 'helper-text error'
      : 'helper-text';

  return (
    <Modal
      title={title}
      open
      onCancel={onClose}
      confirmButtonText={confirmButtonText}
      onConfirm={handleSubmit}
      onExited={onClose}
      data-cy="editBudgetModal"
      width="sm"
      disabled={disabled}
    >
      <div styleName="form-row">{duplicateMsg}</div>
      <div styleName="form-row">
        <Select
          id="budget_template"
          label="Budget Template"
          value={templateId || ''}
          onChange={event => setTemplateId(event.target.value)}
          disabled={disableSelect}
        >
          {templates.map(template => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div styleName="form-row">
        <TextField
          id="card_name_input"
          label="Name"
          value={name}
          onChange={event => setName(event.target.value)}
          error={nameIsUsed}
          helperText={nameIsUsed ? 'Budget name Must be unique' : ''}
        />
      </div>
      <div styleName="form-row">
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(date: Date) => setStartDate(date || null)}
          shouldDisableDate={shouldDisableStartDate}
          errorHelperText={
            dateRangeInvalid && 'End date must be after the start date'
          }
        />
        <div styleName="separator">to</div>
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(date: Date) => setEndDate(date || null)}
          shouldDisableDate={shouldDisableEndDate}
        />
      </div>
      <div styleName={helperTextCssClass}>
        The budget start date must be the 1st of the month, and the end date
        must be the last day of the month.
      </div>
    </Modal>
  );
};

export default BudgetsFormModal;

// * "Flash Of Empty Content"
