import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import TimeEntryStatusIcon from '@atom/components/common/timeEntryStatusIcon/TimeEntryStatusIcon';
import WorkOrderStatusPill from '@atom/components/common/workOrderDetail/workOrderStatusPill/WorkOrderStatusPill';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import {
  GET_TIME_ENTRIES_STATUS,
  TIME_ENTRY_UPDATE,
} from '@atom/graph/timeEntry';
import { GET_USER } from '@atom/graph/user';
import { usePreferences } from '@atom/hooks/usePreferences';
import {
  Icon,
  IconButton,
  ListTable,
  Progress,
  Select,
  TextField,
} from '@atom/mui';
import { userBudgetsSelector } from '@atom/selectors/userSelectors';
import { TimeEntryType } from '@atom/types/preferences';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryDetail,
  TimeEntryStatus,
  TimeEntryUpdate,
  WorkOrderTimeEntry,
} from '@atom/types/timeEntry';
import { UserDetail, UserInput } from '@atom/types/user';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { timeSheetTableWidths } from '@atom/utilities/timeSheetUtilities';
import {
  convertHoursToMillis,
  convertMillisToExactHours,
  roundTimeEntryDuration,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';
import { CLOSED_STATUS_ID } from '@atom/utilities/workOrderStatusUtilities';

import './timeSheet.css';

const { TableRow, TableCell } = ListTable;
const { MenuItem } = Select;

const styles = {
  workInfo: {
    minWidth: timeSheetTableWidths.work,
  },
  smallCell: {
    padding: '8px 4px',
  },
  progress: {
    marginLeft: '12px',
  },
  start: {
    padding: '8px',
  },
  status: {
    margin: '12px 0',
  },
};

interface Props {
  timeEntry: TimeEntryDetail;
  refetchTimeSheet: () => void;
  setEditTimeEntryId: (id: string) => void;
  selectedPayPeriodWeek: number;
}

const TimeSheetTableEditRow = ({
  timeEntry,
  refetchTimeSheet,
  setEditTimeEntryId,
  selectedPayPeriodWeek,
}: Props) => {
  const preferences = usePreferences();
  const { payPeriodWeeks, selectedPayPeriod } = useContext(TeamContext);

  const [date, setDate] = useState<any>(timeEntry.date || '');
  const [duration, setDuration] = useState<any>(
    convertMillisToExactHours(timeEntry.duration) || '',
  );
  const [selectedBudgetId, setSelectedBudgetId] = useState<string>(
    timeEntry?.budget?.id || '',
  );
  const [selectedType, setSelectedType] = useState<TimeEntryType>(
    timeEntry ? timeEntry?.type : null,
  );
  const [userGroupId, setUserGroupId] = useState<string>(
    timeEntry?.userGroupId || '',
  );

  const { loading: userLoading, data: userData } = useQuery<
    { user: UserDetail },
    { id: string; input: UserInput }
  >(GET_USER, {
    variables: {
      id: timeEntry.userId,
      input: {
        includeRestoredBudgets:
          timeEntry.workOrder.reopened &&
          timeEntry.workOrder.statusId !== CLOSED_STATUS_ID,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  const [timeEntryUpdate] = useMutation<
    { timeEntryUpdate: WorkOrderTimeEntry },
    { input: TimeEntryUpdate }
  >(TIME_ENTRY_UPDATE, {
    onCompleted: () => {
      refetchTimeSheet();
    },
  });

  useEffect(() => {
    getTimeEntriesStatus({
      variables: {
        input: {
          userId: timeEntry.userId,
          userGroupId,
          dateStart: selectedPayPeriod?.startDate,
          dateEnd: selectedPayPeriod?.endDate,
        },
      },
    });
  }, [userGroupId]);

  const resetState = () => {
    setEditTimeEntryId(null);
    setDate('');
    setDuration('');
    setUserGroupId('');
  };

  const handleDuration = (newDuration: number, newDate: number) => {
    if (isNilOrEmpty(newDuration)) {
      setDuration('');
      setDate('');
    } else if (newDuration >= 0) {
      setDuration(newDuration);
      setDate(newDate);
    }
  };

  const onDurationBlur = () => {
    if (!isNilOrEmpty(duration)) {
      const roundedValue = roundTimeEntryDuration(duration);

      setDuration(roundedValue);
    }
  };

  const handleTimeEntryUpdate = async () => {
    await timeEntryUpdate({
      variables: {
        input: {
          id: timeEntry.id,
          date,
          userGroupId,
          duration: convertHoursToMillis(duration),
          ...(!preferences?.timeTracking?.computedBudgets &&
            !R.isEmpty(selectedBudgetId) && {
              budgetId: selectedBudgetId,
            }),
          ...(!isNilOrEmpty(preferences?.timeTracking?.typeEnumeration) && {
            type: selectedType,
          }),
        },
      },
    });

    resetState();
  };

  const currentWeekDates = payPeriodWeeks[selectedPayPeriodWeek];

  const typeOptions = preferences?.timeTracking?.typeEnumeration;
  const hasTypeOptions = !isNilOrEmpty(typeOptions);

  const hideBudgetSelection = preferences?.timeTracking?.computedBudgets;
  const budgets = userBudgetsSelector(userData?.user);
  const budgetOptions = useMemo(() => {
    const options = budgets.filter(
      budget =>
        !budget.restored ||
        (budget.restored &&
          budget.reopenedWorkOrderIds.includes(timeEntry.workOrder.id)),
    );

    return [{ id: '', name: '', rate: 0 }, ...options];
  }, [budgets, timeEntry]);

  const userGroupIsApproved =
    timeEntriesStatusData?.timeEntries?.status === TimeEntryStatus.approved;

  const isSaveDisabled =
    isNilOrEmpty(date) ||
    isNilOrEmpty(duration) ||
    isNilOrEmpty(userGroupId) ||
    timeEntriesStatusLoading ||
    userGroupIsApproved ||
    (hasTypeOptions && R.isNil(selectedType));

  return (
    <>
      <TableRow key={timeEntry.id}>
        <TableCell
          width={timeSheetTableWidths.status}
          style={styles.start}
          center
        >
          <TimeEntryStatusIcon status={timeEntry.status} />
        </TableCell>
        <TableCell width={timeSheetTableWidths.workType}>
          {timeEntry?.workOrder?.workTemplate?.name}
        </TableCell>
        <TableCell style={styles.workInfo}>
          <div>
            <Link to={`/workOrders/${timeEntry?.workOrder?.id}`}>
              {timeEntry?.workOrder?.name}
            </Link>
            <WorkOrderStatusPill
              className="table"
              statusId={timeEntry?.workOrder?.statusId}
              style={styles.status}
            />
            {timeEntry?.workOrder?.inventoryAssetName && (
              <div styleName="subtext">{`Inventory: ${timeEntry?.workOrder?.inventoryAssetName}`}</div>
            )}
            {timeEntry?.workOrder?.dueDate && (
              <div styleName="subtext">{`Due Date: ${setDisplayDate(
                timeEntry?.workOrder?.dueDate,
              )}`}</div>
            )}
          </div>
        </TableCell>
        <TableCell width={timeSheetTableWidths.task}>
          {timeEntry?.task?.name}
        </TableCell>
        <TableCell width={timeSheetTableWidths.userGroup}>
          {!userLoading && (
            <Select
              value={userGroupId}
              error={userGroupIsApproved}
              helperText={
                userGroupIsApproved &&
                'Cannot add time to an approved timesheet.'
              }
              onChange={event => setUserGroupId(event.target.value)}
              data-cy="editTimeEntryGroupDropdown"
            >
              {userData?.user?.userGroups.map(userGroup => {
                const primaryText = userGroup.groupPath.join(' / ');

                return (
                  <MenuItem key={userGroup.id} value={userGroup.id}>
                    {primaryText}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </TableCell>
        <TableCell width={timeSheetTableWidths.budget}>
          {hideBudgetSelection
            ? hasTypeOptions && (
                <Select
                  fullWidth
                  value={selectedType}
                  onChange={event => setSelectedType(event.target.value)}
                  data-cy="editTimeEntryBudgetDropdown"
                >
                  {typeOptions.map(type => {
                    const primaryText = type.name;

                    return (
                      <MenuItem key={type.value} value={type.value}>
                        <span styleName="type-option">{primaryText}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              )
            : !userLoading && (
                <Select
                  fullWidth
                  value={selectedBudgetId}
                  onChange={event => setSelectedBudgetId(event.target.value)}
                >
                  {budgetOptions.map(option => {
                    const primaryText = option.id ? (
                      <>
                        <span>
                          {`${option.name}: ${numberToLocaleString(
                            option.rate,
                          )}/Hours `}
                        </span>
                        {option.restored && (
                          <span styleName="snapshot-suffix">(snapshot)</span>
                        )}
                      </>
                    ) : (
                      'No Budget'
                    );

                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {primaryText}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
        </TableCell>
        <TableCell
          width={timeSheetTableWidths.small}
          center
          style={styles.smallCell}
        />
        {currentWeekDates.map(day => {
          const showEdit =
            (isNilOrEmpty(date) && isNilOrEmpty(duration)) || date === day;

          return (
            <TableCell
              width={timeSheetTableWidths.small}
              center
              style={styles.smallCell}
              key={day}
            >
              {showEdit && (
                <TextField
                  type="text"
                  inputMode="numeric"
                  value={duration}
                  onChange={event => handleDuration(event.target.value, day)}
                  onBlur={onDurationBlur}
                />
              )}
            </TableCell>
          );
        })}
        <TableCell
          width={timeSheetTableWidths.small}
          center
          style={styles.smallCell}
        />
        <TableCell
          width={timeSheetTableWidths.action}
          style={styles.smallCell}
          center
        >
          <IconButton
            onClick={resetState}
            size="small"
            data-cy="timeEntryEditCloseButton"
          >
            <Icon>close</Icon>
          </IconButton>
        </TableCell>
        <TableCell
          width={timeSheetTableWidths.action}
          style={styles.smallCell}
          center
        >
          {timeEntriesStatusLoading ? (
            <Progress size={20} style={styles.progress} />
          ) : (
            <IconButton
              onClick={handleTimeEntryUpdate}
              disabled={isSaveDisabled}
              size="small"
              data-cy="timeEntryEditDoneButton"
            >
              <Icon>done</Icon>
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default TimeSheetTableEditRow;
