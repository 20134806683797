import React, { useContext } from 'react';

import { Icon, IconButton } from '@atom/mui';
import { PolicyResource } from '@atom/types/policy';

import PolicyModalContext from './PolicyModalContext';

import './policyModals.css';

interface Props {
  title: string;
}

const PolicyModalTitle = ({ title }: Props) => {
  const { state, updateState } = useContext(PolicyModalContext);

  const toTitle = (name: string) => {
    return (
      <>
        <IconButton onClick={() => updateState({ view: 'MAIN' })}>
          <Icon>arrow_back</Icon>
        </IconButton>
        {name}
      </>
    );
  };

  const getTitle = () => {
    switch (state.view) {
      case PolicyResource.INVENTORY_FOLDER: {
        return toTitle('Select Inventory Folder(s)');
      }
      case PolicyResource.INVENTORY_ITEM: {
        return toTitle('Select Inventory Item(s)');
      }
      case PolicyResource.WORK_TYPE_FOLDER: {
        return toTitle('Select Work Template Folder(s)');
      }
      case PolicyResource.ANALYTICS_FOLDER: {
        return toTitle('Select Analytics Folder(s)');
      }
      default:
        return <span>{title}</span>;
    }
  };

  return getTitle();
};

export default PolicyModalTitle;
