import React from 'react';
import {
  Stepper as MUIStepper,
  StepperProps as MUIStepperProps,
} from '@mui/material';

import Step from './Step';
import StepContent from './StepContent';
import StepLabel from './StepLabel';

export type StepperProps = MUIStepperProps;

export const Stepper = (props: StepperProps) => {
  return <MUIStepper {...props} />;
};

Stepper.Step = Step;
Stepper.StepLabel = StepLabel;
Stepper.StepContent = StepContent;

export default Stepper;
