export enum ModalVariant {
  PUBLISH_CONFIRM = 'PUBLISH_CONFIRM',
  DRAFT_CONFIRM = 'DRAFT_CONFIRM',
  DRAFT_ERROR = 'DRAFT_ERROR',
}

export const modalVariants = {
  [ModalVariant.PUBLISH_CONFIRM]: {
    title: 'Publish Inventory Type',
    content:
      'Published inventory types can be set back to Draft and modified as long as they are not in use. Once inventory types are in use, they cannot be deleted and changes are limited. Are you sure you want to publish this inventory type?',
    button: 'PUBLISH',
  },
  [ModalVariant.DRAFT_CONFIRM]: {
    title: 'Change Inventory Type to Draft Status',
    content:
      'Draft inventory types cannot be used to create inventory items. Draft inventory types can be modified and published again. Are you sure you want to change this inventory type to Draft?',
    button: 'CHANGE TO DRAFT',
  },
  [ModalVariant.DRAFT_ERROR]: {
    title: 'Cannot Change Inventory Type Status to Draft',
    content:
      'Inventory type status cannot be changed to Draft because it was used to create inventory items. However, you can still make limited changes to attributes on the Data Management page.',
    button: 'OK',
  },
};

export const getModalValue = (modalVariant: ModalVariant) => {
  return modalVariants[modalVariant];
};
