import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import {
  GET_BUDGET_UNIT,
  GET_BUDGET_UNIT_TREE,
  SEARCH_BUDGET_UNITS,
} from '@atom/graph/budget';
import { Icon, Popover, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  BudgetUnit,
  BudgetUnitConnectionInput,
  BudgetUnitSearch,
  BudgetUnitSearchConnection,
  BudgetUnitSearchInput,
  BudgetUnitTree,
} from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailUnitSearchResults from './BudgetDetailUnitSearchResults';
import BudgetDetailUnitTree from './BudgetDetailUnitTree';
import { DEBOUNCE_TIME, POPOVER_HEIGHT } from './budgetDetailUtils';

import './budgetDetail.css';

const styles = {
  popover: {
    height: POPOVER_HEIGHT,
  },
  searchInput: {
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: '0.5rem 0.5rem 0 0.5rem',
    borderBottom: `1px solid ${colors.neutral.gray}`,
    color: colors.neutral.gray,
  },
};

const BudgetDetailUnitTreeNav = () => {
  const anchor = useRef();
  const {
    budget,
    setParentBudgetUnit,
    unitsVisited,
    setUnitsVisited,
  } = useContext(BudgetDetailContext);

  const [collapsed, setCollapsed] = useState<Set<string>>(new Set([]));
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<BudgetUnitSearch[]>(null);

  const [
    searchUnits,
    { data: searchData, loading: loadingSearch },
  ] = useLazyQuery<
    { budgetUnitSearch: BudgetUnitSearchConnection },
    { input: BudgetUnitSearchInput }
  >(SEARCH_BUDGET_UNITS);

  const [getBudgetUnitAncestor] = useLazyQuery<
    { budgetUnit: BudgetUnit },
    { input: BudgetUnitConnectionInput }
  >(GET_BUDGET_UNIT, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      setUnitsVisited({
        ...unitsVisited,
        [res.budgetUnit.id]: res.budgetUnit,
      });
    },
  });

  const onCompleted = res => {
    const newParentUnit = res.budgetUnit;

    // Ensure all ancestors in budgetUnit are loaded in unitsVisited
    newParentUnit.ancestors.forEach(unitId => {
      if (isNilOrEmpty(unitsVisited[unitId])) {
        getBudgetUnitAncestor({
          variables: {
            input: {
              budgetId: budget.id,
              budgetUnitId: unitId,
            },
          },
        });
      }
    });

    setParentBudgetUnit(res.budgetUnit);
    setOpen(false);
    setQuery('');
  };

  const [getBudgetUnit] = useLazyQuery<
    { budgetUnit: BudgetUnit },
    { input: BudgetUnitConnectionInput }
  >(GET_BUDGET_UNIT, {
    fetchPolicy: 'network-only',
    onCompleted,
  });

  const handleNavigateToUnit = (unitId: string) =>
    getBudgetUnit({
      variables: {
        input: {
          budgetId: budget.id,
          budgetUnitId: unitId,
        },
      },
    });

  const searchUnitsDebounced = useCallback(
    debounce((queryString: string) => {
      searchUnits({
        variables: {
          input: {
            budgetId: budget.id,
            query: queryString,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(
    () =>
      setSearchResults(
        R.pathOr([], ['budgetUnitSearch', 'budgetUnits'], searchData),
      ),
    [searchData],
  );

  useEffect(() => {
    if (query.length > 1) {
      searchUnitsDebounced(query);
    } else {
      setSearchResults(null);
    }
  }, [query]);

  const { data: treeData, loading: loadingTree } = useQuery<
    { budgetUnitTree: BudgetUnitTree },
    { id: string }
  >(GET_BUDGET_UNIT_TREE, {
    variables: { id: budget?.id },
  });
  const tree: BudgetUnitTree = useMemo(
    () => R.pathOr(null, ['budgetUnitTree'], treeData),
    [treeData],
  );

  return R.isNil(tree) || loadingTree ? (
    <Progress size={30} />
  ) : (
    <div ref={anchor}>
      <Icon styleName="clickable" onClick={() => setOpen(prev => !prev)}>
        arrow_drop_down
      </Icon>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        style={styles.popover}
        anchorEl={anchor.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <div style={styles.searchInput}>
          <Icon>search</Icon>
          <TextField
            placeholder="Search"
            value={query}
            onChange={event => setQuery(event.target.value)}
            disableUnderline
          />
        </div>
        {R.isNil(searchResults) || query.length < 2 ? (
          <BudgetDetailUnitTree
            tree={tree}
            handleNavigateToUnit={handleNavigateToUnit}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        ) : (
          <BudgetDetailUnitSearchResults
            loadingSearch={loadingSearch}
            searchResults={searchResults}
            handleNavigateToUnit={handleNavigateToUnit}
          />
        )}
      </Popover>
    </div>
  );
};

export default BudgetDetailUnitTreeNav;
