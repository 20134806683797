import React, { useContext } from 'react';

import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { AttributesType } from '@atom/types/inventory';

import BooleanAttribute from '../../attributeControls/BooleanAttribute';
import CurrencyAttribute from '../../attributeControls/CurrencyAttribute';
import DateAttribute from '../../attributeControls/DateAttribute';
import EnumMultipleAttribute from '../../attributeControls/EnumMultipleAttribute';
import EnumSingleAttribute from '../../attributeControls/EnumSingleAttribute';
import LongTextAttribute from '../../attributeControls/LongTextAttribute';
import NumberAttribute from '../../attributeControls/NumberAttribute';
import ShortTextAttribute from '../../attributeControls/ShortTextAttribute';

import './attributeDetails.css';

interface Props {
  attributes: AttributesType[];
  width?: string;
}

export const buildAttribute = (attribute, setUnsavedChanges) => {
  const {
    id,
    value,
    newValue,
    dataType,
    enumeration,
    unit,
    changeId,
    isEditable,
  } = attribute;

  const displayValue = !!changeId ? newValue : value;

  switch (dataType) {
    case 'boolean': {
      return (
        <BooleanAttribute
          name={id}
          value={displayValue}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
    case 'longtext': {
      return (
        <LongTextAttribute
          name={id}
          value={displayValue}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
    case 'date': {
      return (
        // @ts-ignore
        <DateAttribute
          name={id}
          value={displayValue}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
    case 'number': {
      return (
        <NumberAttribute
          name={id}
          value={displayValue}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
    case 'currency': {
      return (
        <CurrencyAttribute
          name={id}
          value={displayValue}
          // @ts-ignore
          unit={unit}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
    case 'enumsingle': {
      return (
        <EnumSingleAttribute
          name={id}
          value={displayValue}
          enumeration={enumeration}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
    case 'enummultiple': {
      return (
        <EnumMultipleAttribute
          name={id}
          value={displayValue}
          enumeration={enumeration}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
    default: {
      return (
        <ShortTextAttribute
          name={id}
          value={displayValue}
          isEditable={isEditable}
          onUpdate={() => setUnsavedChanges(true)}
        />
      );
    }
  }
};

const EditAttributeList = ({ attributes, width }: Props) => {
  const { setUnsavedChanges } = useContext(WorkOrderAssetViewContext);

  return (
    <div styleName="attribute-list-container">
      {attributes.map((attribute, key) => (
        <div styleName="attribute-list-row" key={key}>
          <div style={{ minWidth: width }} styleName="attribute-label edit">
            {attribute.name}
            {attribute.unit && ` (${attribute.unit})`}:
          </div>
          <div styleName="attribute-value">
            {buildAttribute(attribute, setUnsavedChanges)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditAttributeList;
