import * as R from 'ramda';

const subDomain: string = window.location.hostname.split('.')[0];
const environment: string = window.location.hostname.split('.')[1];

export enum Environment {
  LOCAL = 'local-atom',
  DEV = 'dev-atom',
  QA = 'qa-atom',
  UAT = 'uat-atom',
  PRODUCTION = 'atomapp',
}

export enum Tenant {
  LOCAL = 'local-acme',
  DEV_ALDOT = 'dev-aldot',
  DEV_SADA = 'dev-sada',
  DEV_UDOT = 'dev-udot',
  DEV_HENNEPIN = 'dev-hennepin',
  DEV_GWRR = 'dev-gwrr',
  DEV_CBIT = 'dev-cbit',
  QA_ALDOT = 'qa-aldot',
  QA_SADA = 'qa-sada',
  QA_UDOT = 'qa-udot',
  QA_HENNEPIN = 'qa-hennepin',
  QA_GWRR = 'qa-gwrr',
  QA_CBIT = 'qa-cbit',
  UAT_ALDOT = 'uat-aldot',
  UAT_ALDOT_DEMO = 'uat-aldot-demo',
  UAT_ATOM_DEMO = 'uat-atom-demo',
  UAT_DEMO = 'uat-demo',
  UAT_GDOT = 'uat-gdot',
  UAT_GWRR = 'uat-gwrr',
  UAT_HENNEPIN = 'uat-hennepin',
  UAT_ITS_UDOT = 'uat-its-udot',
  UAT_LADOTD = 'uat-ladotd',
  UAT_SDDOT = 'uat-sddot',
  UAT_UDOT = 'uat-udot',
  UAT_VDOT_DEMO = 'uat-vdot-demo',
  UAT_WABTEC = 'uat-wabtec',
  UAT_SADA = 'uat-sada',
  UAT_CBIT = 'uat-cbit',
  ALDOT = 'aldot',
  CBIT = 'cbit',
  COLLINS_EQUIPMENT = 'collins-equipment',
  GWRR = 'gwrr',
  HENNEPIN = 'hennepin',
  LARIMER_COUNTY = 'larimercounty',
  MOBILE_COUNTY = 'mobilecounty',
  UDOT = 'udot',
  SADA = 'sada',
}

export enum Client {
  ALDOT = 'ALDOT',
  GWRR = 'GWRR',
  HENNEPIN = 'HENNEPIN',
  SADA = 'SADA',
  UDOT = 'UDOT',
  CBIT = 'CBIT',
}

export const CLIENTS = {
  [Client.ALDOT]: [
    Tenant.DEV_ALDOT,
    Tenant.QA_ALDOT,
    Tenant.UAT_ALDOT,
    Tenant.ALDOT,
  ],
  [Client.GWRR]: [
    Tenant.DEV_GWRR,
    Tenant.QA_GWRR,
    Tenant.UAT_GWRR,
    Tenant.GWRR,
  ],
  [Client.HENNEPIN]: [
    Tenant.DEV_HENNEPIN,
    Tenant.QA_HENNEPIN,
    Tenant.UAT_HENNEPIN,
    Tenant.HENNEPIN,
  ],
  [Client.SADA]: [
    Tenant.DEV_SADA,
    Tenant.QA_SADA,
    Tenant.UAT_SADA,
    Tenant.SADA,
  ],
  [Client.UDOT]: [
    Tenant.DEV_UDOT,
    Tenant.QA_UDOT,
    Tenant.UAT_UDOT,
    Tenant.UDOT,
  ],
  [Client.CBIT]: [
    Tenant.DEV_CBIT,
    Tenant.QA_CBIT,
    Tenant.UAT_CBIT,
    Tenant.CBIT,
  ],
};

// Returns true if the current environment is in the passed in list of environments
export const isCurrentEnvironment = (environments: Environment[]) => {
  return R.includes(environment, [...environments, Environment.LOCAL]);
};

// Returns true if the current tenant is in the passed in list of tenants
export const isCurrentTenant = (tenants: Tenant[]) => {
  return R.includes(subDomain, [...tenants, Tenant.LOCAL]);
};

// Returns true if the current tenant is in the passed in client grouping
// ex Client.SADA will return true for dev-sada, qa-sada, uat-sada, and sada tenants
export const isCurrentClient = (clients: Client[]) => {
  const tenantsList = clients.reduce((acc, client) => {
    return [...acc, ...CLIENTS[client]];
  }, []);

  return R.includes(subDomain, [...tenantsList, Tenant.LOCAL]);
};
