import * as React from 'react';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AttributesType } from '@atom/types/inventory';
import attributeDisplayUtility from '@atom/utilities/attributeDisplayUtility';

import './attributeDetails.css';

const styles = {
  icon: {
    padding: '0px',
    marginLeft: '0.5rem',
    height: '35px',
    width: '35px',
    bottom: '10px',
  },
  iconColor: colors.brand.blue,
};

interface Props {
  attributes: AttributesType[];
  attributeGroupName?: string;
  onPendingApproval?: (
    action: string,
    attributeGroupName: string,
    attribute: AttributesType,
  ) => void;
  canManageChanges?: boolean;
}

const AttributesList = ({
  attributes,
  attributeGroupName,
  onPendingApproval,
  canManageChanges = false,
}: Props) => {
  return (
    <div styleName="attribute-list-container">
      {attributes.map((attribute, key) => {
        const showControls = !!attribute.changeId && canManageChanges;
        const unitSuffix = ` (${attribute.unit})`;

        return (
          <div styleName="attribute-list-row" key={key}>
            <div styleName="attribute-label">
              {attribute.name}
              {attribute.unit &&
                !attribute.name.endsWith(unitSuffix) &&
                unitSuffix}
            </div>
            <div styleName="attribute-value">
              {attributeDisplayUtility.display(
                attribute.value,
                attribute.dataType,
                attribute.unit,
                !!attribute.changeId,
                attribute.newValue,
              )}
            </div>
            {showControls && (
              <div styleName="attribute-approval-container">
                <div styleName="pending-attribute-label">Edited</div>
                <div styleName="pending-attribute-control">
                  <DialogTrigger
                    labelCancel="Cancel"
                    labelConfirm="Reject"
                    confirmAction={() =>
                      onPendingApproval('reject', attributeGroupName, attribute)
                    }
                    title="Reject Changes"
                    content={
                      <span>
                        Are you sure you want to reject the changes on this
                        attribute?
                      </span>
                    }
                    trigger={
                      <IconButton style={styles.icon}>
                        <Icon color={styles.iconColor}>clear</Icon>
                      </IconButton>
                    }
                  />
                </div>
                <div styleName="pending-attribute-control">
                  <IconButton
                    tooltip="Approve"
                    style={styles.icon}
                    onClick={() =>
                      onPendingApproval('accept', attributeGroupName, attribute)
                    }
                  >
                    <Icon color={styles.iconColor}>check</Icon>
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AttributesList;
