import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '@atom/components/common/navigation/Logo';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Button, Icon } from '@atom/mui';
import colors from '@atom/styles/colors';
import { portals } from '@atom/utilities/portalUtilities';

import './errorPages.css';

const styles = {
  icon: {
    fontSize: '75px',
    backgroundColor: colors.neutral.silver,
    borderRadius: '10px',
  },
  button: {
    marginTop: '2rem',
  },
};

const NotFound = () => {
  const preferences = usePreferences();
  const landingPortal = portals[preferences.landingPortal];

  return (
    <div styleName="page">
      <div styleName="header">
        <Logo pathname={location.pathname} />
      </div>
      <div styleName="content-container">
        <div styleName="content">
          <Icon style={styles.icon} color={colors.neutral.white}>
            broken_image
          </Icon>
          <div styleName="title">Page Not Found</div>
          <div styleName="subtext">
            We're sorry. The page you requested can not be found.
          </div>
          <div styleName="subtext">
            Please go to the homepage or contact support.
          </div>
          <Link to={landingPortal.urlPath} style={styles.button}>
            <Button variant="contained" color="primary">
              Go To Homepage
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
