import React from 'react';

import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import {
  reorderAttributeGroups,
  reorderAttributes,
} from '@atom/selectors/schemaSelectors';
import {
  DetailAttributeGroupsItem,
  InventoryDetailRenamePayload,
  InventorySchemaDetail,
} from '@atom/types/inventory';

import DataManagementElementAttributeGroup from './DataManagementElementAttributeGroup';
import DataManagementElementAttributeRow from './DataManagementElementAttributeRow';

import './dataManagementDetail.css';

interface State {
  schema: InventorySchemaDetail;
}

interface Props {
  schema: InventorySchemaDetail;
  activeAttribute: string;
  onClick: (attributeGroupId: string, attributeId: string) => void;
  attributeGroupInEditMode: string;
  attributeInEditMode: string;
  isDisabled: boolean;
  updateAttributeInEditMode: (id: string) => void;
  addAttribute: (schemaId: string, attributeGroupId: string) => void;
  addAttributeGroup: (schemaId: string) => void;
  onAttributeGroupRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onAttributeGroupRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  onAttributeGroupDelete: (
    schemaId: string,
    attributeGroupId: string,
  ) => boolean;
  updateAttributeGroupInEditMode: (id: string) => void;
  onAttributeRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onAttributeRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  onAttributeDelete: (
    schemaId: string,
    attributeGroupId: string,
    attributeId: string,
  ) => boolean;
  updateSchema: (payload: Object) => void;
  loading: boolean;
}

class DataManagementAttributePane extends React.Component<Props, State> {
  state = {
    schema: this.props.schema,
  };

  static getDerivedStateFromProps(nextProps: Props): State {
    // @ts-ignore
    return !nextProps.loading ? { schema: nextProps.schema } : {};
  }

  onDragEnd = (result: Object) => {
    // @ts-ignore
    const { source, destination } = result;
    const { schema } = this.state;
    const { updateSchema } = this.props;

    if (!destination) {
      return;
    }

    // @ts-ignore
    if (result.type === 'attributeGroup') {
      const { updatedSchema, payload } = reorderAttributeGroups(
        schema,
        source,
        destination,
      );

      updateSchema(payload);
      this.setState({ schema: updatedSchema });
    } else {
      const { updatedSchema, payload } = reorderAttributes(schema, result);

      updateSchema(payload);
      this.setState({ schema: updatedSchema });
    }
  };

  render() {
    const {
      activeAttribute,
      onClick,
      isDisabled,
      attributeGroupInEditMode,
      addAttributeGroup,
      addAttribute,
      onAttributeGroupRenameSave,
      onAttributeGroupRenameCancel,
      onAttributeGroupDelete,
      updateAttributeGroupInEditMode,
      updateAttributeInEditMode,
      attributeInEditMode,
      onAttributeRenameSave,
      onAttributeRenameCancel,
      onAttributeDelete,
    } = this.props;
    const { schema } = this.state;

    return (
      <div styleName="section-pane center">
        <DataManagementElementAttributeRow
          name={schema.name}
          id={schema.id}
          isDisabled={isDisabled}
          addAttributeGroup={addAttributeGroup}
        />
        <div styleName="center-tree-container">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              droppableId="attributePane"
              type="attributeGroup"
              isDropDisabled={isDisabled}
            >
              {schema.attributeGroups.map(
                (attributeGroup: DetailAttributeGroupsItem, index: number) => {
                  const isOnlyGroup = schema.attributeGroups.length <= 1;

                  return (
                    <Draggable
                      key={attributeGroup.id}
                      draggableId={attributeGroup.id}
                      index={index}
                      type="attributeGroup"
                      isDragDisabled={isDisabled}
                    >
                      <DataManagementElementAttributeGroup
                        key={`${attributeGroup.name}-${index}`}
                        schemaId={schema.id}
                        isDisabled={isDisabled}
                        addAttribute={addAttribute}
                        activeAttribute={activeAttribute}
                        attributeGroup={attributeGroup}
                        onClick={onClick}
                        isOnlyGroup={isOnlyGroup}
                        onAttributeDelete={onAttributeDelete}
                        onAttributeRenameSave={onAttributeRenameSave}
                        onAttributeRenameCancel={onAttributeRenameCancel}
                        updateAttributeInEditMode={updateAttributeInEditMode}
                        attributeInEditMode={attributeInEditMode}
                        attributeGroupInEditMode={attributeGroupInEditMode}
                        onAttributeGroupRenameSave={onAttributeGroupRenameSave}
                        onAttributeGroupRenameCancel={
                          onAttributeGroupRenameCancel
                        }
                        onAttributeGroupDelete={onAttributeGroupDelete}
                        updateAttributeGroupInEditMode={
                          updateAttributeGroupInEditMode
                        }
                      />
                    </Draggable>
                  );
                },
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default DataManagementAttributePane;
