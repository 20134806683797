import * as React from 'react';
import LocationOnIcon from 'material-ui/svg-icons/communication/location-on';
import * as R from 'ramda';

// @ts-ignore
import lineTypeIcon from '@atom/components/common/svgIcons/lineTypeIcon.svg';
// @ts-ignore
import lineTypeIconBlue from '@atom/components/common/svgIcons/lineTypeIconBlue.svg';
import { Button, Icon, IconButton, Modal, TextField, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { EventType } from '@atom/types/event';
import { InventorySchemaDetail } from '@atom/types/inventory';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import './dataManagementDetail.css';

const styles = {
  dialog: {
    actionsContainerStyle: {
      padding: '1.25rem',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
};

interface PassedProps {
  editSchema: (
    name: string,
    colorId: number,
    markerId: number,
    isMaterial: boolean,
    locationType: string,
  ) => void;
  schema: InventorySchemaDetail;
}

type Props = PassedProps;

interface State {
  open: boolean;
  name: string;
  isMaterial: boolean;
  colorId: number;
  markerId: number;
  locationType: string;
}

class EditSchemaModal extends React.Component<Props, State> {
  state = {
    open: false,
    name: this.props.schema.name || '',
    isMaterial: this.props.schema.isMaterial,
    colorId: this.props.schema.colorId || 0,
    markerId: this.props.schema.markerId || 0,
    locationType: this.props.schema.locationType,
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openModal = () => {
    const { name, colorId, isMaterial, markerId } = this.props.schema;
    this.setState({ open: true, name, colorId, isMaterial, markerId });
  };

  onChange = (event: EventType) => {
    this.setState({ name: event.target.value });
  };

  onMarkerSelect = (event: EventType) => {
    this.setState({ markerId: Number(event.target.id) });
  };

  onColorSelect = (event: EventType) => {
    this.setState({ colorId: Number(event.target.id) });
  };

  updateIsMaterial = (isMaterial: boolean) => {
    this.setState({ isMaterial });
  };

  updateLocationType = (locationType: string) => {
    this.setState({ locationType });
  };

  confirm = () => {
    const { editSchema } = this.props;
    const { name, colorId, markerId, isMaterial, locationType } = this.state;

    editSchema(name, colorId, markerId, isMaterial, locationType);
    this.closeModal();
  };

  renderColorSwatches = () => {
    const { colorId } = this.state;
    return schemaUtilities.colorSwatch.map((swatch: string, index: number) => {
      return (
        <div
          key={swatch}
          styleName={index === colorId ? 'schema-icon selected' : 'schema-icon'}
          // @ts-ignore
          onClick={this.onColorSelect}
        >
          <Icon
            id={index.toString()}
            color={swatch}
            style={{ fontSize: '30px' }}
          >
            lens
          </Icon>
        </div>
      );
    });
  };

  renderSchemaIcons = () => {
    const { markerId } = this.state;
    return schemaUtilities.schemaIconsList.map(
      (icon: string, index: number) => {
        return (
          <div
            key={index}
            styleName={
              index === markerId ? 'schema-icon selected' : 'schema-icon'
            }
            id={index.toString()}
            // @ts-ignore
            onClick={this.onMarkerSelect}
          >
            <img id={index.toString()} src={icon} />
          </div>
        );
      },
    );
  };

  render() {
    const { open, name, isMaterial, locationType } = this.state;

    const isSaveDisabled = R.isEmpty(name);

    const getFooter = () => {
      return (
        <div style={styles.dialog.actionsContainerStyle}>
          <Button
            style={styles.actionButtons.cancel}
            color="inherit"
            onClick={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isSaveDisabled}
            onClick={this.confirm}
          >
            Save
          </Button>
        </div>
      );
    };

    const swatches = isMaterial
      ? this.renderColorSwatches()
      : this.renderSchemaIcons();

    const sectionTitle = isMaterial ? 'Color:' : 'Color and Map Marker:';

    return (
      <span>
        <Tooltip title="Edit" placement="bottom-start">
          <IconButton onClick={this.openModal}>
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
        </Tooltip>
        <Modal title="Edit Inventory Type" footer={getFooter()} open={open}>
          <TextField
            label="Inventory Type Name"
            value={name}
            id="name"
            onChange={this.onChange}
            variant="standard"
          />
          <div styleName="section-title">Type:</div>
          <div styleName="type-container">
            <div
              styleName={`type-tile ${isMaterial ? '' : 'selected'}`}
              // @ts-ignore
              onClick={() => this.updateIsMaterial(false)}
            >
              <Icon
                color={isMaterial ? colors.neutral.gray : colors.brand.blue}
              >
                map
              </Icon>
              <div styleName="type-tile-text">Location-based</div>
            </div>
            <div
              styleName={`type-tile ${isMaterial ? 'selected' : ''}`}
              // @ts-ignore
              onClick={() => this.updateIsMaterial(true)}
            >
              <Icon
                color={isMaterial ? colors.brand.blue : colors.neutral.gray}
              >
                monetization_on
              </Icon>
              <div styleName="type-tile-text">Equipment and Material</div>
            </div>
          </div>
          {!isMaterial && (
            <>
              <div styleName="section-title">Location Type:</div>
              <div styleName="type-container">
                <div
                  styleName={`type-tile ${
                    locationType === 'Point' ? 'selected' : ''
                  }`}
                  // @ts-ignore
                  onClick={() => this.updateLocationType('Point')}
                >
                  <LocationOnIcon
                    color={
                      locationType === 'Point'
                        ? colors.brand.blue
                        : colors.neutral.gray
                    }
                  />
                  <div styleName="type-tile-text">Point</div>
                </div>
                <div
                  styleName={`type-tile ${
                    locationType === 'LineString' ? 'selected' : ''
                  }`}
                  // @ts-ignore
                  onClick={() => this.updateLocationType('LineString')}
                >
                  <img
                    src={
                      locationType === 'LineString'
                        ? lineTypeIconBlue
                        : lineTypeIcon
                    }
                  />
                  <div styleName="type-tile-text">Line</div>
                </div>
              </div>
            </>
          )}
          <div styleName="section-title">{sectionTitle}</div>
          <div styleName="color-container">{swatches}</div>
        </Modal>
      </span>
    );
  }
}

export default EditSchemaModal;
