import React, { useContext, useState } from 'react';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Icon, IconButton } from '@atom/mui';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { getWorkOrderAssetsEndpoint } from '@atom/utilities/endpoints';

import './workOrderAssetView.css';

const WorkOrderAssetHeader = () => {
  const {
    setActiveElementId,
    workOrderDetail,
    workOrderAsset,
    setActiveWorkOrderAsset,
    refetchWorkOrderAssetTreeLoading,
    refetchWorkOrderAssetTree,
    previewView,
    hasUnsavedChanges,
  } = useContext(WorkOrderAssetViewContext);
  const [loading, setLoading] = useState<boolean>(false);

  const hasPendingChanges =
    hasRolePermissions(ROLE_SETS.MANAGER) && !!workOrderAsset.changeType;

  const handleApprove = async () => {
    setLoading(true);

    await api.post(
      `${getWorkOrderAssetsEndpoint(workOrderDetail.id)}/${
        workOrderAsset.id
      }/approve`,
      {
        includeChangesBefore: workOrderAsset.includeChangesBefore,
        approveChangesRecursively: true,
      },
    );

    setActiveElementId(workOrderAsset.id);
    refetchWorkOrderAssetTree();

    setLoading(false);
  };

  return (
    <div styleName="header-container">
      <div styleName="back-button-container">
        <IconButton onClick={() => setActiveWorkOrderAsset('')}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <div>Back to Tasks</div>
      </div>
      {!previewView && (
        <div styleName="changes-container">
          {refetchWorkOrderAssetTreeLoading || loading ? (
            'Saving...'
          ) : hasUnsavedChanges ? (
            <span style={{ color: '#ff8f00' }}>Unsaved Changes.</span>
          ) : (
            'All changes saved.'
          )}
          {hasPendingChanges && (
            <DialogTrigger
              labelCancel="Cancel"
              labelConfirm="Approve All"
              confirmAction={handleApprove}
              title={`Approve All Changes for ${workOrderAsset.name}`}
              content={
                <span>
                  Are you sure you want to approve all changes to
                  <strong> {workOrderAsset.name} </strong>in all tasks?
                </span>
              }
              trigger={
                <IconButton tooltip="Approve All Changes">
                  <Icon>done_all</Icon>
                </IconButton>
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default WorkOrderAssetHeader;
