import React, { useEffect, useMemo, useState } from 'react';

// @ts-ignore
import lineTypeIcon from '@atom/components/common/svgIcons/lineTypeIcon.svg';
// @ts-ignore
import lineTypeIconBlue from '@atom/components/common/svgIcons/lineTypeIconBlue.svg';
import { Icon, Modal, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  LocationType,
  SchemaSavePayload,
  SchemaTree,
} from '@atom/types/schema';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './addEditSchemaModal.css';

const styles = {
  modalContent: { padding: '2rem' },
};

interface Props {
  open: boolean;
  onClose: () => void;
  handleSave: (payload: SchemaSavePayload) => void;
  schema?: SchemaTree;
}

const AddEditSchemaModal = ({ open, onClose, handleSave, schema }: Props) => {
  const [name, setName] = useState<string>(schema?.name || '');
  const [colorId, setColorId] = useState<number>(schema?.colorId || 0);
  const [markerId, setMarkerId] = useState<number>(schema?.markerId || 0);
  const [isMaterial, setIsMaterial] = useState<boolean>(
    schema?.isMaterial || false,
  );
  const [locationType, setLocationType] = useState<LocationType>(
    schema?.locationType || LocationType.POINT,
  );

  const editMode = !isNilOrEmpty(schema);

  const resetState = () => {
    setName(schema?.name || '');
    setColorId(schema?.colorId || 0);
    setMarkerId(schema?.markerId || 0);
    setIsMaterial(schema?.isMaterial || false);
    setLocationType(schema?.locationType || LocationType.POINT);
  };

  useEffect(() => {
    resetState();
  }, [open, schema]);

  const onConfirm = () => {
    handleSave({
      name,
      colorId,
      markerId,
      locationType: isMaterial ? null : locationType,
      isMaterial,
    });
  };

  const handleMarkerClick = (id: number) => {
    setMarkerId(Number(id));
    setColorId(Number(id));
  };

  const getColorIcons = () => {
    return schemaUtilities.colorSwatch.map((swatch: string, index: number) => {
      const style = index === colorId ? 'schema-icon selected' : 'schema-icon';

      return (
        <div
          key={`${swatch}-${index}`}
          styleName={style}
          onClick={() => handleMarkerClick(index)}
        >
          <Icon
            id={index.toString()}
            color={swatch}
            style={{ fontSize: '30px' }}
          >
            lens
          </Icon>
        </div>
      );
    });
  };

  const getSchemaIcons = () => {
    return schemaUtilities.schemaIconsList.map(
      (icon: string, index: number) => {
        const style =
          index === markerId ? 'schema-icon selected' : 'schema-icon';

        return (
          <div
            key={index}
            styleName={style}
            id={index.toString()}
            onClick={() => handleMarkerClick(index)}
          >
            <img id={index.toString()} src={icon} />
          </div>
        );
      },
    );
  };

  const swatches = useMemo(() => {
    return isMaterial ? getColorIcons() : getSchemaIcons();
  }, [isMaterial, colorId, markerId]);

  const title = editMode ? 'Edit Inventory Type' : 'Add Inventory Type';
  const confirmText = editMode ? 'Save' : 'Create';

  const locationStyle = `type-tile ${isMaterial ? '' : 'selected'}`;
  const locationIconColor = isMaterial
    ? colors.neutral.gray
    : colors.brand.blue;

  const materialStyle = `type-tile ${isMaterial ? 'selected' : ''}`;
  const materialIconColor = isMaterial
    ? colors.brand.blue
    : colors.neutral.gray;

  const pointStyle = `type-tile ${
    locationType === LocationType.POINT ? 'selected' : ''
  }`;
  const pointIconColor =
    locationType === LocationType.POINT
      ? colors.brand.blue
      : colors.neutral.gray;

  const lineStringStyle = `type-tile ${
    locationType === LocationType.LINE_STRING ? 'selected' : ''
  }`;
  const lineStringIcon =
    locationType === LocationType.LINE_STRING ? lineTypeIconBlue : lineTypeIcon;

  const colorSectionTitle = isMaterial ? 'Color:' : 'Color and Map Marker:';

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onClose}
      onConfirm={onConfirm}
      onExited={resetState}
      confirmButtonText={confirmText}
      contentStyle={styles.modalContent}
    >
      <TextField
        label="Inventory Type Name"
        value={name}
        id="name"
        onChange={event => setName(event?.target?.value)}
        variant="standard"
      />
      <div styleName="section-title">Type:</div>
      <div styleName="type-container">
        <div
          styleName={locationStyle}
          onClick={() => setIsMaterial(!isMaterial)}
        >
          <Icon color={locationIconColor}>map</Icon>
          <div styleName="type-tile-text">Location-based</div>
        </div>
        <div
          styleName={materialStyle}
          onClick={() => setIsMaterial(!isMaterial)}
        >
          <Icon color={materialIconColor}>monetization_on</Icon>
          <div styleName="type-tile-text">Equipment and Material</div>
        </div>
      </div>
      {!isMaterial && (
        <>
          <div styleName="section-title">Location Type:</div>
          <div styleName="type-container">
            <div
              styleName={pointStyle}
              onClick={() => setLocationType(LocationType.POINT)}
            >
              <Icon color={pointIconColor}>location_on</Icon>
              <div styleName="type-tile-text">Point</div>
            </div>
            <div
              styleName={lineStringStyle}
              onClick={() => setLocationType(LocationType.LINE_STRING)}
            >
              <img src={lineStringIcon} />
              <div styleName="type-tile-text">Line</div>
            </div>
          </div>
        </>
      )}
      <div styleName="section-title">{colorSectionTitle}</div>
      <div styleName="color-container">{swatches}</div>
    </Modal>
  );
};

export default AddEditSchemaModal;
