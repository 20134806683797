import * as React from 'react';

import DatePicker from '@atom/components/common/DatePicker';
import colors from '@atom/styles/colors';

import '../../formInstance.css';

const styles = {
  textFieldStyle: {
    color: colors.neutral.dark,
  },
  datePickerStyles: {
    width: '100%',
  },
};

interface PassedProps {
  value: any;
  type: string;
  isEditable: boolean;
  onChange: (property: string, dateMillis: number) => void;
}

type Props = PassedProps;

const DateAttribute = ({ value, type, onChange, isEditable }: Props) => {
  return (
    <div styleName="attribute-input">
      <DatePicker
        name={type}
        fullWidth
        valueMillis={value || {}}
        hintText="mm/dd/yyyy"
        textFieldStyle={styles.textFieldStyle}
        style={styles.datePickerStyles}
        onDataChange={onChange}
        disabled={!isEditable}
      />
    </div>
  );
};

export default DateAttribute;
