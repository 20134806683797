import * as React from 'react';

import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';

import './header.css';

interface Props {
  value: string;
  label: string;
  icon: any;
  activeView: string;
  setActiveView: (value: string) => void;
}

function setButtonStyleActive(activeView, value) {
  return activeView === value ? ' active' : '';
}
const HeaderNavigationIcon = ({
  value,
  label,
  icon,
  activeView,
  setActiveView,
}: Props) => {
  const iconActiveStyle = setButtonStyleActive(activeView, value);
  const activeClassName = `icon-container${iconActiveStyle}`;
  return (
    <div onClick={() => setActiveView(value)} styleName={activeClassName}>
      <IconButton>
        <Icon color={colors.neutral.white}>{icon}</Icon>
      </IconButton>
      <div styleName="label">{label}</div>
    </div>
  );
};

export default HeaderNavigationIcon;
