import * as React from 'react';

import FontIconButton from '@atom/components/common/FontIconButton';
import { Button, Icon, TextField, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { AttributesType } from '@atom/types/inventory';

import './dataManagementDetail.css';

const styles = {
  fontSize: fonts.md,
  color: colors.neutral.dark,
};

interface Props {
  enumValue: any;
  dataType: string;
  isDisabled: boolean;
  updateOption: (event: any) => void;
  removeOption: (index: number) => void;
  addOption: () => void;
  attribute: AttributesType;
}

const DataManagementEnumBuilder = ({
  enumValue,
  updateOption,
  removeOption,
  addOption,
  isDisabled,
  dataType,
  attribute,
}: Props) => {
  const { isPendingUpdate, publishedAttribute } = attribute;
  return (
    <React.Fragment>
      {enumValue.map((option: string, index: number) => {
        if (dataType === 'enumsingle' && index === 0) {
          return <div />;
        }

        const displayNumber = dataType === 'enumsingle' ? index : index + 1;

        const optionDisabled = isPendingUpdate
          ? index < publishedAttribute.enumeration.length
          : isDisabled;

        return (
          <div key={index}>
            <div styleName="options-input-container">
              <div styleName="options-number">{`Option ${displayNumber}:`}</div>
              {optionDisabled ? (
                <Tooltip title={option} placement="right">
                  <div>
                    <TextField
                      value={option}
                      key={index}
                      onChange={updateOption}
                      id={index.toString()}
                      style={styles}
                      disabled={optionDisabled}
                    />
                  </div>
                </Tooltip>
              ) : (
                <TextField
                  value={option}
                  key={index}
                  onChange={updateOption}
                  id={index.toString()}
                  style={styles}
                  disabled={optionDisabled}
                />
              )}
              <FontIconButton
                onClick={(): void => removeOption(index)}
                color={colors.neutral.gray}
                icon="close"
                disabled={optionDisabled}
              />
            </div>
          </div>
        );
      })}
      <div styleName="options-input-container">
        <Button
          color="primary"
          onClick={addOption}
          startIcon={<Icon color={colors.brand.blue}>add</Icon>}
        >
          add option
        </Button>
      </div>
    </React.Fragment>
  );
};

export default DataManagementEnumBuilder;
