import * as React from 'react';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import AdditionalOptions from '@atom/components/common/AdditionalOptions';
import Checkbox from '@atom/components/common/Checkbox';
import RenameModal from '@atom/components/common/RenameModal';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMove.svg';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { Progress } from '@atom/mui';
import config from '@atom/selectors/config';
import { sortMedia } from '@atom/selectors/mediaSelectors';
import colors from '@atom/styles/colors';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';
import { getMediaIcon } from '@atom/utilities/iconUtilities';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import { getUserFullName } from '@atom/utilities/userUtilities';

import CreateMediaFolderModal from './CreateMediaFolderModal';
import EditMediaFolderModal from './EditMediaFolderModal';
import MoveMediaFolderModal from './MoveMediaFolderModal';

import './folders.css';

interface Props {
  media?: any[];
  mediaFolders?: any[];
  selectedType?: string;
  onToggle: (id: string, path: any[], expanded: boolean, name?: string) => void;
  folderPath?: any[];
  mediaFolderTree?: any;
  onEdit: (folderId: string, path: any[], folderName: string) => void;
  onCreate: (folderId: string, path: any[], folderName: string) => void;
  onDelete: (id: string, path: any[]) => void;
  onMoveComplete: (id: string, destId: string, destPath: any[]) => void;
  renameMedia: (id: any, name: any) => void;
  deleteMedia: (id: string) => void;
  loading?: boolean;
  toggleCheck: (id: string, data: boolean) => void;
  selectedItems: any[];
  canUpdateMedia: boolean;
  canDeleteMedia: boolean;
}

const tableDisplayHeaders = [
  '',
  'name',
  'file type',
  'created by',
  'created on',
  '',
];

const tableFields = [
  'checkbox',
  'name',
  'fileExtension',
  'createdBy',
  'createdDate',
  'additionalOptions',
];

class MediaTable extends React.Component<Props> {
  state = {
    sortColumn: '',
    isAscending: false,
  };

  toggleSort = headerIndex => {
    const { sortColumn, isAscending } = this.state;
    const sortProperty = tableFields[headerIndex];

    const sortDirection = sortColumn === sortProperty ? !isAscending : false;

    this.setState({
      sortColumn: sortProperty,
      isAscending: sortDirection,
    });
  };

  buildTableHeaders = displayHeaders => {
    return displayHeaders.map((header, headerIndex) => {
      const { sortColumn, isAscending } = this.state;
      const isSortable = header !== '';
      const sortDirection =
        isAscending && sortColumn === tableFields[headerIndex]
          ? 'sort-ascending'
          : 'sort-descending';

      const isSelected =
        sortColumn === tableFields[headerIndex] ? 'selected' : '';

      const sortClass = isSortable
        ? `header-cell ${sortDirection} ${isSelected}`
        : 'header-cell';

      const onClick = isSortable
        ? () => this.toggleSort(headerIndex)
        : () => {};

      return (
        <th styleName={sortClass} key={headerIndex} onClick={onClick}>
          {header}
        </th>
      );
    });
  };

  buildRowFields = (row, fields) => {
    return fields.map((field, fieldIndex) => {
      return this.buildCell(row, field, fieldIndex);
    });
  };

  buildRows = (dataRows, fields) => {
    return dataRows.map((row, rowIndex) => {
      return <tr key={rowIndex}>{this.buildRowFields(row, fields)}</tr>;
    });
  };

  getPath = (mediaFolderTree, ancestors, folderPath) => {
    const child = R.pathOr(null, ['children'], mediaFolderTree);
    if (R.isNil(child) || R.isEmpty(ancestors)) {
      return folderPath;
    }
    const index = child.findIndex(item => item.id === ancestors[0]);
    const path = [...folderPath, 'children', index];
    const nextAncestors = R.remove(0, 1, ancestors);

    return this.getPath(child[index], nextAncestors, path);
  };

  onFolderClick = row => {
    const { onToggle, mediaFolderTree } = this.props;
    const expanded = true;
    const ancestors = [...R.pathOr([], ['ancestors'], row), row.id];
    const path = this.getPath(mediaFolderTree, ancestors, []);

    onToggle(row.id, path, expanded);
  };

  buildCell = (row, field, index) => {
    const {
      mediaFolderTree,
      onEdit,
      onCreate,
      onDelete,
      onMoveComplete,
      renameMedia,
      deleteMedia,
      toggleCheck,
      selectedItems,
      loading,
      canUpdateMedia,
      canDeleteMedia,
    } = this.props;

    const ancestors = [...R.pathOr([], ['ancestors'], row), row.id];
    const folderPath = this.getPath(mediaFolderTree, ancestors, []);
    const isCreateDisabled = folderPath.length >= config.MAX_FOLDER_DEPTH * 2;
    const extension = row.fileId
      ? R.pathOr(null, ['fileExtension'], row)
      : 'folder';

    const nameLink =
      extension !== 'folder' ? (
        <a
          styleName="clickable-link"
          href={`${FILES_ENDPOINT}/${row.fileId}`}
          target="_blank"
        >{`${row.name}.${extension}`}</a>
      ) : (
        <div styleName="clickable-link" onClick={() => this.onFolderClick(row)}>
          {row.name}
        </div>
      );

    const folderOptions = [
      ...(canUpdateMedia
        ? [
            {
              type: 'trigger',
              primaryText: 'rename',
              icon: <img src={renameIcon} />,
              id: uuid(),
              element: (
                <EditMediaFolderModal
                  folderId={row.id}
                  folderPath={folderPath}
                  name={row.name}
                  type="folder"
                  editAction={(folderId, path, folderName) =>
                    onEdit(folderId, path, folderName)
                  }
                />
              ),
            },
          ]
        : []),
      ...(!isCreateDisabled
        ? [
            {
              type: 'trigger',
              primaryText: 'create folder',
              id: uuid(),
              element: (
                <CreateMediaFolderModal
                  folderId={row.id}
                  folderPath={folderPath}
                  type="folder"
                  createAction={(folderId, path, folderName) =>
                    onCreate(folderId, path, folderName)
                  }
                />
              ),
            },
          ]
        : []),
      ...(canUpdateMedia
        ? [
            {
              type: 'trigger',
              primaryText: 'move to folder',
              id: row.id,
              icon: <img src={folderMoveIcon} />,
              element: (
                <MoveMediaFolderModal
                  mediaFolderTree={mediaFolderTree}
                  type="folder"
                  ids={[row.id]}
                  loading={loading}
                  onEdit={onEdit}
                  onCreate={onCreate}
                  onComplete={onMoveComplete}
                  disabled={[
                    row.id,
                    ...(row.parentFolderId ? [row.parentFolderId] : []),
                    ...(row.ancestors && row.ancestors.length
                      ? [R.last(row.ancestors)]
                      : ['root']),
                  ]}
                />
              ),
            },
          ]
        : []),
      ...(canDeleteMedia
        ? [
            {
              type: 'dialog',
              primaryText: 'delete',
              modalProps: {
                labelCancel: 'Cancel',
                labelConfirm: 'Delete',
                confirmAction: () => onDelete(row.id, folderPath),
                title: `Delete ${name} folder`,
                text:
                  'Are you sure you want to delete this folder? If the folder has any subfolders or files saved below this folder, everything will be deleted.',
              },
            },
          ]
        : []),
    ];

    const fileOptions = [
      ...(canUpdateMedia
        ? [
            {
              type: 'trigger',
              primaryText: 'rename',
              icon: <img src={renameIcon} />,
              id: row.id,
              element: (
                <RenameModal
                  id={row.id}
                  name={row.name}
                  type={row.type}
                  renameAction={renameMedia}
                />
              ),
            },
          ]
        : []),
      {
        type: 'element',
        primaryText: 'download',
        element: (
          <a
            styleName="download-link"
            href={`${FILES_ENDPOINT}/${row.fileId}`}
            download={`${row.name}.${row.fileExtension}`}
          />
        ),
      },
      ...(canDeleteMedia
        ? [
            {
              type: 'dialog',
              primaryText: 'delete',
              modalProps: {
                labelCancel: 'Cancel',
                labelConfirm: 'Delete',
                confirmAction: () => deleteMedia(row.id),
                title: `Delete ${textDisplayUtilities.capitalize(row.type)}s`,
                text: `Are you sure you want to delete this ${row.type}?`,
              },
            },
          ]
        : []),
    ];

    const menuItems = extension === 'folder' ? folderOptions : fileOptions;
    const showFolderOptions =
      canUpdateMedia || canDeleteMedia || !isCreateDisabled;
    const showOptions = extension === 'folder' ? showFolderOptions : true;

    const fieldTemplates = {
      checkbox: (
        <td key={index} styleName="checkbox">
          {extension !== 'folder' && (
            <Checkbox
              isChecked={selectedItems.includes(row.id)}
              toggleCheck={() =>
                toggleCheck(row.id, selectedItems.includes(row.id))
              }
            />
          )}
        </td>
      ),
      name: (
        <td key={index} styleName="clickable">
          <div styleName="name-row-container">
            <div styleName="name-row-icon">
              {getMediaIcon(extension, row.fileId, row.name)}
            </div>
            {nameLink}
          </div>
        </td>
      ),
      fileExtension: <td key={index}>{row.fileExtension}</td>,
      createdBy: (
        <td key={index}>
          {row.createdBy ? getUserFullName(row.createdBy) : '-'}
        </td>
      ),
      createdDate: <td key={index}>{setDisplayDate(row.createdDate)}</td>,
      additionalOptions: (
        <td key={index} styleName="additional-options">
          {showOptions ? (
            <AdditionalOptions
              iconStyle={{
                color: colors.neutral.gray,
              }}
              menuItems={menuItems}
            />
          ) : (
            <div style={{ position: 'relative' }} />
          )}
        </td>
      ),
    };
    return fieldTemplates[field] || <td key={index}>{row[field]}</td>;
  };

  render() {
    const { sortColumn, isAscending } = this.state;
    const { media, mediaFolders, selectedType, loading } = this.props;

    const sortedMedia = sortMedia(sortColumn, isAscending, media);
    const sortedMediaFolders = sortMedia(sortColumn, isAscending, mediaFolders);

    const mediaList = selectedType
      ? sortedMedia
      : [...sortedMediaFolders, ...sortedMedia];

    return (
      <div styleName="main-pane detail">
        <table styleName="sticky">
          <thead>
            <tr>{this.buildTableHeaders(tableDisplayHeaders)}</tr>
          </thead>
          <tbody styleName="hidden">
            {this.buildRows(mediaList, tableFields)}
          </tbody>
        </table>
        {!loading ? (
          <table>
            <thead styleName="hidden">
              <tr>{this.buildTableHeaders(tableDisplayHeaders)}</tr>
            </thead>
            <tbody>{this.buildRows(mediaList, tableFields)}</tbody>
          </table>
        ) : (
          <Progress style={{ height: '100%' }} />
        )}
      </div>
    );
  }
}

export default MediaTable;
