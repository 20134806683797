import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import * as R from 'ramda';

import * as inventorySchemaActions from '@atom/actions/inventorySchemaActions';
import { RadioGroup, Select, TextField } from '@atom/mui';
import { budgetUnitsSelector } from '@atom/selectors/preferencesSelectors';
import { InventorySchemaDetail } from '@atom/types/inventory';
import { SchemaBudgetType } from '@atom/types/schema';

import './cost.css';

const { MenuItem } = Select;
const { RadioButton } = RadioGroup;

const styles = {
  unitOption: {
    textTransform: 'uppercase',
  },
  inputStyles: {
    width: '200px',
  },
};

interface Props {
  schema: InventorySchemaDetail;
}

const CostDetails = ({ schema }: Props) => {
  const dispatch = useDispatch();
  const budgetUnits = useSelector(budgetUnitsSelector);

  const [budgetType, setBudgetType] = useState<SchemaBudgetType>(
    schema?.budget?.type,
  );
  const [rate, setRate] = useState<number>(schema?.budget?.rate);
  const [unit, setUnit] = useState(schema?.budget?.unit);

  useEffect(() => {
    setUnit(schema?.budget?.unit);
  }, [schema?.budget?.unit]);

  const updateSchema = budget => {
    dispatch(
      inventorySchemaActions.requestUpdateSchema({
        schemaId: schema.id,
        rootSchemaId: schema.id,
        budget: {
          ...schema?.budget,
          ...budget,
        },
      }),
    );
  };

  const updateRate = event => {
    const newTimeRate = event.target.value
      ? Math.abs(Number(event.target.value))
      : null;

    setRate(newTimeRate);

    if (!R.isNil(newTimeRate)) {
      updateSchema({
        type: budgetType,
        unit,
        rate: newTimeRate || 0,
      });
    }
  };

  const updateUnit = (event: SelectChangeEvent) => {
    const { value } = event.target;

    setUnit(value);

    updateSchema({
      type: budgetType,
      unit: value,
      rate: rate || 0,
    });
  };

  const updateBudgetType = event => {
    const newBudgetType = event.target.value;

    setRate(0);
    setUnit('sq ft');
    setBudgetType(newBudgetType);
    updateSchema({ type: newBudgetType });
  };

  const isDisabled = schema.isPublished;

  return (
    <>
      <div styleName="cost-title">Cost Details</div>
      <div styleName="cost-container">
        <RadioGroup value={budgetType} onChange={updateBudgetType}>
          <RadioButton
            value={SchemaBudgetType.VARIABLE}
            label="Variable"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set the cost and unit at the individual inventory item level.
          </div>
          <RadioButton
            value={SchemaBudgetType.VARIABLE_COST}
            label="Variable Cost"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set a fixed unit for all inventory of this type.
          </div>
          {budgetType === SchemaBudgetType.VARIABLE_COST && (
            <div styleName="cost-input-container">
              <Select
                label="Unit"
                fullWidth
                InputProps={{
                  style: styles.unitOption,
                }}
                style={styles.inputStyles}
                onChange={updateUnit}
                value={unit}
                disabled={isDisabled}
              >
                {budgetUnits.map(option => {
                  return (
                    <MenuItem
                      key={option}
                      value={option || null}
                      style={styles.unitOption}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )}
          <RadioButton
            value={SchemaBudgetType.FIXED}
            label="Fixed"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set a fixed cost and unit for all inventory of this type.
          </div>
          {budgetType === SchemaBudgetType.FIXED && (
            <div styleName="cost-input-container">
              <TextField
                label="Cost"
                value={rate}
                type="number"
                style={styles.inputStyles}
                onChange={updateRate}
                InputProps={{
                  startAdornment: '$',
                }}
                disabled={isDisabled}
              />
              <div styleName="separator">per</div>
              <Select
                label="Unit"
                fullWidth
                InputProps={{
                  style: styles.unitOption,
                }}
                style={styles.inputStyles}
                onChange={updateUnit}
                value={unit}
                disabled={isDisabled}
              >
                {budgetUnits.map(option => {
                  return (
                    <MenuItem
                      key={option}
                      value={option || null}
                      style={styles.unitOption}
                    >
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )}
        </RadioGroup>
      </div>
    </>
  );
};

export default CostDetails;
