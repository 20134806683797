import * as React from 'react';

// @ts-ignore
import workTypeLocationIcon from '@atom/components/common/svgIcons/workTypeLocationIcon.svg';

import PreviewAttributeDetail from '../detail/PreviewAttributeDetail';

import '../../../styles/body-pane.css';

interface Props {
  schema: any;
}

const PreviewAssetInfo = ({ schema }: Props) => {
  return schema.isMaterial ? (
    <div styleName="center-pane-container">
      <div styleName="center-pane" style={{ overflow: 'auto' }}>
        <PreviewAttributeDetail schema={schema} />
      </div>
    </div>
  ) : (
    <>
      <div styleName="left-body-pane-half">
        <PreviewAttributeDetail schema={schema} />
      </div>
      <div styleName="right-body-pane-half">
        <div styleName="location-section">
          <div styleName="location-content">
            <img styleName="location-image" src={workTypeLocationIcon} />
            <div styleName="location-title">
              Location is determined during asset creation.
            </div>
            <div styleName="location-text">
              When an asset is created, this section will display the location
              of that asset on a map.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewAssetInfo;
