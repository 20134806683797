import * as React from 'react';

// @ts-ignore
import addGroupIcon from '@atom/components/common/svgIcons/add_group.svg';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import { IconButton } from '@atom/mui';
import { InventorySchemaDetailState } from '@atom/types/store';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import './dataManagementDetail.css';

interface Props {
  schema: InventorySchemaDetailState;
  isSelected: boolean;
  onClick: (data: string) => void;
  isDisabled: boolean;
  addSchemaOrGroup: (
    parentSchemaId: string,
    group?: string,
    groupId?: string,
  ) => void;
}

const styles = {
  iconContainer: {
    padding: 0,
    height: '40px',
    width: '40px',
  },
};

const DataManagementRootAssetRow = ({
  schema,
  isSelected,
  onClick,
  isDisabled,
  addSchemaOrGroup,
}: Props) => {
  const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(schema);

  const rightButtons = isDisabled ? (
    <div />
  ) : (
    <div styleName="add-schema-buttons-container">
      <IconButton
        tooltip="Add Group"
        style={styles.iconContainer}
        onClick={(): void => addSchemaOrGroup(schema.id, 'Untitled')}
      >
        <img src={addGroupIcon} />
      </IconButton>
      <IconButton
        tooltip="Add Sub Item"
        style={styles.iconContainer}
        onClick={(): void => addSchemaOrGroup(schema.id)}
      >
        <img src={addItemIcon} />
      </IconButton>
    </div>
  );

  return (
    <div styleName={`asset-row ${isSelected ? 'active' : ''}`}>
      <div
        styleName="asset-row-info-container"
        onClick={(): void => onClick(schema.id)}
      >
        <div styleName="asset-row-icon">{icon}</div>
        <div styleName={`asset-row-name ${isSelected ? 'active' : ''}`}>
          {schema.name}
        </div>
      </div>
      {rightButtons}
    </div>
  );
};

export default DataManagementRootAssetRow;
