import * as React from 'react';

import AttributeGroupHeader from '@atom/components/common/attributeDetail/AttributeGroupHeader';
import AttributesList from '@atom/components/common/attributeDetail/AttributesList';
import EditAttributeList from '@atom/components/common/attributeDetail/EditAttributeList';

const styles = {
  listContainer: {
    padding: '1em 1.25rem 1em 3.75em',
  },
};

export const mapAttributeValue = (value: any, dataType: string): any => {
  switch (dataType) {
    case 'currency':
    case 'number': {
      return Number(value);
    }
    case 'date': {
      return value.valueOf();
    }
    case 'boolean': {
      return value === 'true';
    }
    default:
      return value;
  }
};

interface PassedProps {
  schema: any;
}

type Props = PassedProps;

interface State {
  attributeGroups: any[];
}

class PreviewAttributeDetail extends React.Component<Props, State> {
  state = {
    attributeGroups: this.props.schema.attributeGroups,
  };

  onCollapse = (attributeGroupName: string) => {
    this.updateAttributeGroupCollapsedState(attributeGroupName);
  };

  updateAttributeGroupEditState = (
    attributeGroupName: string,
    isEdit: boolean,
  ): any => {
    const { attributeGroups } = this.state;

    const updatedAttributes = attributeGroups.map(
      (attributeGroup: any): any => {
        if (attributeGroup.name !== attributeGroupName) {
          return attributeGroup;
        }

        return {
          ...attributeGroup,
          isEdit,
        };
      },
    );

    this.setState({ attributeGroups: updatedAttributes });
  };

  onEditClick = (attributeGroupName: string) => {
    const isEdit = true;
    this.updateAttributeGroupEditState(attributeGroupName, isEdit);
  };

  onCancel = (attributeGroupName: string) => {
    const isEdit = false;
    this.updateAttributeGroupEditState(attributeGroupName, isEdit);
  };

  updateAttributeGroupCollapsedState = (attributeGroupName: string): any => {
    const { attributeGroups } = this.state;

    const updatedAttributes = attributeGroups.map(
      (attributeGroup: any): any => {
        if (attributeGroup.name !== attributeGroupName) {
          return attributeGroup;
        }

        return {
          ...attributeGroup,
          collapsed: !attributeGroup.collapsed,
        };
      },
    );

    this.setState({ attributeGroups: updatedAttributes });
  };

  render() {
    const { attributeGroups } = this.state;
    const { schema } = this.props;

    return (
      <>
        {schema.attributeGroups.map((attributeGroup: any, key: any) => {
          const collapsed = attributeGroups[key]
            ? attributeGroups[key].collapsed
            : false;

          const isEdit = attributeGroups[key]
            ? attributeGroups[key].isEdit
            : false;

          return (
            <div key={key}>
              <AttributeGroupHeader
                name={attributeGroup.name}
                onClick={this.onEditClick}
                onCollapse={this.onCollapse}
                collapsed={collapsed}
                isEdit={isEdit}
                onCancel={this.onCancel}
                onSave={this.onCancel}
                loading={false}
                canUpdate
                typePreview
              />
              {isEdit ? (
                <div style={styles.listContainer}>
                  <EditAttributeList attributes={attributeGroup.attributes} />
                </div>
              ) : collapsed ? (
                <div />
              ) : (
                <div style={styles.listContainer}>
                  <AttributesList attributes={attributeGroup.attributes} />
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
}

export default PreviewAttributeDetail;
