import * as React from 'react';
import * as R from 'ramda';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import FontIconButton from '@atom/components/common/FontIconButton';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import {
  AttributesType,
  DetailAttributeGroupsItem,
  InventoryDetailRenamePayload,
  InventorySchemaDetail,
} from '@atom/types/inventory';
import iconUtilities from '@atom/utilities/iconUtilities';

import EditField from './EditField';

import './dataManagementDetail.css';

const styles = {
  icon: {
    fontWeight: 300,
  },
  toolIcon: {
    padding: 0,
    height: '35px',
    width: '35px',
  },
  iconBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  iconContainer: {
    width: 24,
    height: 24,
    padding: 0,
  },
  arrowIcon: {
    padding: 0,
    fontSize: 14,
    transform: 'rotate(90deg)',
  },
  arrowIconContainer: {
    padding: 0,
    height: '30px',
    width: '30px',
  },
  dialog: {
    delete: {
      contentStyle: {
        width: layout.modalWidth,
        paddingRight: '2rem',
        paddingBottom: '1rem',
      },
      titleStyle: {
        fontSize: fonts.xl,
        padding: '1.125em 1.125em 1.125em 1.25em',
        fontWeight: '500',
      },
      actionsContainerStyle: {
        paddingRight: '1rem',
        paddingBottom: '1rem',
      },
    },
  },
};

interface Props {
  schema: InventorySchemaDetail;
  activeElement: string;
  onClick: (data: string) => void;
  isInGroup: boolean;
  elementInEditMode: string;
  isDisabled: boolean;
  onSchemaDelete: (schemaId: string) => boolean;
  addSchemaOrGroup: (
    parentSchemaId: string,
    group?: string,
    groupId?: string,
  ) => void;
  onElementRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onElementRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  updateElementInEditMode: (id: string) => void;
}

class DataManagementElementRow extends React.Component<Props> {
  getOffset = (
    level: number = 0,
    active: boolean,
    isInGroup: boolean,
  ): Object => {
    const baseOffset = isInGroup ? '4rem' : '2rem';
    const offset = 2.25;

    return !active
      ? {
          paddingLeft: `calc(${baseOffset} + ${offset * level}rem)`,
        }
      : {
          paddingLeft: `calc(${offset * level}rem + ${baseOffset})`,
        };
  };

  getAttributeSubText = (element: InventorySchemaDetail): string => {
    if (R.isEmpty(element.attributeGroups)) {
      return '';
    }

    const attributes = element.attributeGroups.reduce(
      (
        acc: AttributesType[],
        group: DetailAttributeGroupsItem,
      ): AttributesType[] => {
        return [...acc, ...group.attributes];
      },
      [],
    );

    return attributes.reduce(
      (acc: string, attribute: AttributesType): string => {
        if (!attribute.isVisibleAsSubtext) {
          return acc;
        }

        const label = attribute.name;
        return acc ? `${acc}, ${label}` : label;
      },
      '',
    );
  };

  renderElement = (element: InventorySchemaDetail, indentation: number = 0) => {
    const {
      activeElement,
      onClick,
      isInGroup,
      isDisabled,
      onSchemaDelete,
      addSchemaOrGroup,
      elementInEditMode,
      onElementRenameSave,
      onElementRenameCancel,
      updateElementInEditMode,
    } = this.props;
    const isSelected = activeElement === element.id;

    const subText = this.getAttributeSubText(element);

    const isInEditMode =
      element.id === elementInEditMode || element.name === 'Untitled';

    const icon = iconUtilities.getDataManagementElementIcon(
      element.markerId,
      isSelected,
    );

    const leftIcon = !R.isEmpty(element.elements) ? (
      <React.Fragment>
        <IconButton style={styles.arrowIconContainer}>
          <Icon color={colors.neutral.gray} style={styles.arrowIcon}>
            play_arrow
          </Icon>
        </IconButton>
        {icon}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div style={styles.arrowIconContainer} />
        {icon}
      </React.Fragment>
    );

    const rightIconButtons = isDisabled ? (
      <div />
    ) : (
      <div styleName="outer-buttons-container">
        <div
          styleName={`element-buttons-container ${isSelected ? 'active' : ''}`}
        >
          <IconButton
            tooltip="Add Sub Item"
            style={styles.toolIcon}
            onClick={(): void => addSchemaOrGroup(element.id)}
          >
            <div style={styles.iconBox}>
              <img src={addItemIcon} />
            </div>
          </IconButton>
          <FontIconButton
            color={colors.neutral.gray}
            style={styles.toolIcon}
            icon="edit"
            onClick={(): void => updateElementInEditMode(element.id)}
            tooltip="Rename"
            tooltipPosition="bottom-left"
          />
          <DialogTrigger
            labelCancel="Cancel"
            labelConfirm="Delete"
            confirmAction={(): boolean => onSchemaDelete(element.id)}
            title="Delete Sub Item?"
            content={`Deleting ${element.name} will also delete all items under it.`}
            trigger={
              <FontIconButton
                color={colors.neutral.gray}
                style={styles.toolIcon}
                icon="delete"
                tooltip="Delete"
                tooltipPosition="bottom-left"
              />
            }
          />
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div
          key={element.id}
          styleName={`element-row ${isSelected ? 'active' : ''}`}
          style={this.getOffset(indentation, isSelected, isInGroup)}
          onClick={(): void => onClick(element.id)}
        >
          {isInEditMode ? (
            <EditField
              id={element.id}
              name={element.name}
              leftIcon={leftIcon}
              onRenameSave={onElementRenameSave}
              onRenameCancel={onElementRenameCancel}
            />
          ) : (
            <div styleName="element-row-info-container">
              <div styleName="asset-row-icon">{leftIcon}</div>
              <div styleName="element-row-name-container">
                <div
                  styleName={`element-row-name ${isSelected ? 'active' : ''}`}
                >
                  {element.name}
                </div>
                {!R.isEmpty(subText) && (
                  <div styleName="element-row-name-subtext">{subText}</div>
                )}
              </div>
              {rightIconButtons}
            </div>
          )}
        </div>
        {!R.isEmpty(element.elements) &&
          element.elements.map((ele: InventorySchemaDetail) =>
            this.renderElement(ele, indentation + 1),
          )}
      </React.Fragment>
    );
  };

  render() {
    const { schema } = this.props;
    return this.renderElement(schema);
  }
}

export default DataManagementElementRow;
