import React from 'react';
import { FontIcon, IconButton, IconMenu, MenuItem } from 'material-ui';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import * as R from 'ramda';

// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import { Modal } from '@atom/mui';
import colors from '@atom/styles/colors';

import NewWorkOrderIcon from './svgIcons/NewWorkOrderIcon';

const DELETE_LABEL = 'delete';

const initialState = {
  open: false,
  openId: null,
  labelCancel: 'Cancel',
  labelConfirm: 'Confirm',
  confirmAction: null,
  content: null,
  title: '',
  text: '',
  dialogStyles: {},
};

function setPristineState() {
  return initialState;
}

const styles = {
  iconButtonStyle: {
    padding: 0,
    margin: 0,
    border: 0,
    width: '1.25rem',
    height: '1.5rem',
  },
  menuItemStyle: {
    textTransform: 'capitalize',
    height: '2.25rem',
    padding: 0,
    overflow: 'hidden',
  },
  iconBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  primaryTextBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '15rem',
    margin: 0,
    padding: 0,
  },
  iconStyle: {
    color: colors.neutral.gray,
  },
  primaryText: {
    margin: '0 1rem',
    color: colors.neutral.dark,
  },
};

interface Props {
  iconStyle?: object;
  dialogStyles?: object;
  menuItems: any[];
}

class AdditionalOptions extends React.Component<Props> {
  state = initialState;

  closeModal = async () => {
    await this.setState(setPristineState);
  };

  openModal = modalProps => {
    this.setState({
      open: true,
      ...modalProps,
    });
  };

  openModalById = id => {
    this.setState({
      openId: id,
    });
  };

  closeModalById = () => this.setState({ openId: null });

  confirm = () => {
    const { confirmAction } = this.state;
    /* 
       If the confirmAction returns back a false value, this indicates
       to this component that the call was unsuccessful and that the
       modal should not disappear.
    */
    const confirmResult = confirmAction();
    if (confirmResult) {
      this.setState(setPristineState);
    }
  };

  getIconType = primaryText => {
    switch (primaryText) {
      case 'edit': {
        return 'edit';
      }
      case 'delete': {
        return 'delete';
      }
      case 'download': {
        return 'file_download';
      }
      case 'create folder': {
        return 'create_new_folder';
      }
      case 'deactivate': {
        return 'block';
      }
      case 'remove': {
        return 'cancel';
      }
      case 'add element':
      case 'add sub items': {
        return 'add';
      }
      case 'duplicate': {
        return 'content_copy';
      }
      case 'add defect': {
        return 'report_problem';
      }
      case 'add protective coating': {
        return 'verified_user';
      }
      default:
        return 'star';
    }
  };

  buildPrimaryText = item => {
    const iconType = this.getIconType(item.primaryText);
    const markAsCoverPhotoColor = item.primaryText.includes('unmark')
      ? { color: colors.brand.blue }
      : {};

    let icon;

    if (item.icon) {
      icon = <div style={styles.iconBox}>{item.icon}</div>;
    } else if (item.primaryText.includes('create work for')) {
      icon = (
        <div style={styles.iconBox}>
          <NewWorkOrderIcon color={styles.iconStyle.color} />
        </div>
      );
    } else if (item.primaryText.includes('add sub item')) {
      icon = (
        <div style={styles.iconBox}>
          <img src={addItemIcon} />
        </div>
      );
    } else {
      icon = (
        <FontIcon
          className="material-icons"
          style={{
            ...styles.iconStyle,
            ...markAsCoverPhotoColor,
          }}
        >
          {iconType}
        </FontIcon>
      );
    }

    return (
      <div style={styles.primaryTextBox}>
        {icon}
        <p style={styles.primaryText}>{item.primaryText}</p>
      </div>
    );
  };

  buildMenuItem = (item, key) => {
    const primaryText = this.buildPrimaryText(item);

    const menuItem = {
      dialog: (
        <MenuItem
          key={key}
          // @ts-ignore
          style={styles.menuItemStyle}
          primaryText={primaryText}
          onClick={() => this.openModal(item.modalProps)}
        />
      ),
      direct: (
        <MenuItem
          key={key}
          // @ts-ignore
          style={styles.menuItemStyle}
          primaryText={primaryText}
          onClick={() => item.onClick()}
        />
      ),
      element: (
        <MenuItem
          key={key}
          // @ts-ignore
          style={styles.menuItemStyle}
          primaryText={primaryText}
        >
          {item.element}
        </MenuItem>
      ),
      trigger: (
        <MenuItem
          key={key}
          // @ts-ignore
          style={styles.menuItemStyle}
          primaryText={primaryText}
          onClick={() => this.openModalById(item.id)}
        />
      ),
    };

    return menuItem[item.type];
  };

  render() {
    const {
      open,
      title,
      text,
      content,
      labelCancel,
      labelConfirm,
      openId,
    } = this.state;

    const { iconStyle, menuItems } = this.props;

    const isDeleteModal =
      !R.isNil(labelConfirm) && R.toLower(labelConfirm) === DELETE_LABEL;

    const dialogContent = content ? content : text;

    return (
      <div>
        <IconMenu
          onClick={event => event.stopPropagation()}
          iconButtonElement={
            <IconButton style={styles.iconButtonStyle} iconStyle={iconStyle}>
              <MoreVertIcon />
            </IconButton>
          }
        >
          {menuItems.map((item, key) => this.buildMenuItem(item, key))}
        </IconMenu>
        <Modal
          title={title}
          open={open}
          onCancel={this.closeModal}
          onExited={this.closeModal}
          onConfirm={this.confirm}
          cancelButtonText={labelCancel}
          confirmButtonText={labelConfirm}
          ConfirmButtonProps={{
            style: {
              background: isDeleteModal ? colors.brand.red : colors.brand.blue,
              color: isDeleteModal ? colors.neutral.white : colors.neutral.dark,
            },
          }}
        >
          <div>{dialogContent}</div>
        </Modal>
        {menuItems
          .filter(item => item.type === 'trigger')
          .map(item => {
            const { element, id } = item;
            return {
              ...element,
              props: {
                ...element.props,
                open: id === openId,
                closeModal: this.closeModalById,
              },
            };
          })}
      </div>
    );
  }
}

export default AdditionalOptions;
