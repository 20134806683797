import * as React from 'react';
import { Control } from 'react-redux-form';
import * as R from 'ramda';

import { RadioGroup } from '@atom/mui';
import colors from '@atom/styles/colors';

import './attributeControls.css';

const { RadioButton } = RadioGroup;

const radioStyle = {
  button: {
    marginTop: '0.35rem',
    width: '100px',
    color: colors.neutral.black,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    paddingRight: '1.2rem',
  },
};

interface Props {
  name: string;
  value: any;
  isEditable: boolean;
  onUpdate?: (hasUnsavedChanges: boolean) => void;
}

interface State {
  value: any;
}

class BooleanAttribute extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: R.isNil(props.value) ? null : !!props.value,
    };
  }

  onChange = event => {
    this.setState({ value: event.target.value === 'true' });
    if (this.props.onUpdate) {
      this.props.onUpdate(true);
    }
  };

  render() {
    const { name, isEditable } = this.props;
    const { value } = this.state;

    return (
      <div key={name} styleName="boolean-attribute">
        <Control.radio
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '0.75rem',
            flexWrap: 'wrap',
          }}
          component={RadioGroup}
          model={`.${name}`}
          name={`${name}-group`}
          valueSelected={value}
          onChange={event => this.onChange(event)}
          disabled={!isEditable}
        >
          <RadioButton
            key={`${name}-true`}
            label="true"
            value="true"
            style={radioStyle.button}
            checked={value === true}
          />
          <RadioButton
            key={`${name}-false`}
            label="false"
            value="false"
            style={radioStyle.button}
            checked={value === false}
          />
        </Control.radio>
      </div>
    );
  }
}

export default BooleanAttribute;
