import React, { useCallback, useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import {
  GET_BUDGET_CATEGORY_FILTERS,
  GET_BUDGET_ITEM_TEMPLATE_FILTERS,
} from '@atom/graph/budget';
import { ComboSelect } from '@atom/mui';
import {
  BudgetCategoriesFilterConnectionInput,
  BudgetCategory,
  BudgetCategoryFiltersConnection,
  BudgetItemTemplate,
  BudgetItemTemplatesFilterConnection,
  BudgetItemTemplatesFilterConnectionInput,
} from '@atom/types/budget';

import BudgetDetailContext from './BudgetDetailContext';
import { DEBOUNCE_TIME } from './budgetDetailUtils';

import './budgetDetail.css';

const BudgetDetailFilters = () => {
  const {
    categoryFilters,
    setCategoryFilters,
    budgetItemTemplateFilters,
    setBudgetItemTemplateFilters,
    budget,
  } = useContext(BudgetDetailContext);

  const [categoryOpen, setCategoryOpen] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<BudgetCategory[]>([]);
  const [categoryQuery, setCategoryQuery] = useState<string>('');

  const [budgetItemTemplateOpen, setBudgetItemTemplateOpen] = useState<boolean>(
    false,
  );
  const [budgetItemTemplateOptions, setBudgetItemTemplateOptions] = useState<
    BudgetItemTemplate[]
  >([]);
  const [budgetItemTemplateQuery, setBudgetItemTemplateQuery] = useState<
    string
  >('');

  const [
    fetchCategoryOptions,
    { loading: loadingCategoryOptions },
  ] = useLazyQuery<
    { budgetCategoriesFilter: BudgetCategoryFiltersConnection },
    { input: BudgetCategoriesFilterConnectionInput }
  >(GET_BUDGET_CATEGORY_FILTERS, {
    // TODO: Add pagination for infinite scroll
    variables: {
      input: {
        budgetId: budget.id,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const categoriesResponse = R.pathOr(
        [],
        ['budgetCategoriesFilter', 'budgetCategories'],
        data,
      );
      setCategoryOptions(categoriesResponse);
    },
  });

  const [
    fetchBudgetItemTemplateOptions,
    { loading: loadingBudgetItemTemplateOptions },
  ] = useLazyQuery<
    { budgetItemTemplatesFilter: BudgetItemTemplatesFilterConnection },
    { input: BudgetItemTemplatesFilterConnectionInput }
  >(GET_BUDGET_ITEM_TEMPLATE_FILTERS, {
    // TODO: Add pagination for infinite scroll
    variables: {
      input: {
        budgetId: budget.id,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const budgetItemTemplateFiltersResponse = R.pathOr(
        [],
        ['budgetItemTemplatesFilter', 'budgetItemTemplates'],
        data,
      );
      setBudgetItemTemplateOptions(budgetItemTemplateFiltersResponse);
    },
  });

  const handleSelectCategoryOption = (option: BudgetCategory) => {
    const selectedIds = categoryFilters.map(category => category.id);
    const newCategories = selectedIds.includes(option.id)
      ? categoryFilters.filter(category => category.id !== option.id)
      : [...categoryFilters, option];
    setCategoryFilters(newCategories);
  };

  const handleSelectBudgetItemTemplateOption = (option: BudgetItemTemplate) => {
    const selectedIds = budgetItemTemplateFilters.map(template => template.id);
    const newTemplates = selectedIds.includes(option.id)
      ? budgetItemTemplateFilters.filter(template => template.id !== option.id)
      : [...budgetItemTemplateFilters, option];
    setBudgetItemTemplateFilters(newTemplates);
  };

  const fetchCategoryOptionsDebounced = useCallback(
    debounce((value: string) => {
      fetchCategoryOptions({
        variables: {
          input: {
            budgetId: budget.id,
            name: value,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const fetchBudgetItemTemplateOptionsDebounced = useCallback(
    debounce((value: string) => {
      fetchBudgetItemTemplateOptions({
        variables: {
          input: {
            budgetId: budget.id,
            name: value,
          },
        },
      });
    }, DEBOUNCE_TIME),
    [],
  );

  // TODO: Add infinite scroll pagination
  const handleCategoryQueryChange = (value: string) => {
    setCategoryQuery(value || '');
    if (value.length > 1 || value.length === 0) {
      fetchCategoryOptionsDebounced(value);
    }
  };

  // TODO: Add infinite scroll pagination
  const handleBudgetItemTemplateChange = (value: string) => {
    setBudgetItemTemplateQuery(value || '');
    if (value.length > 1 || value.length === 0) {
      fetchBudgetItemTemplateOptionsDebounced(value);
    }
  };

  return (
    <>
      <div styleName="filter-container">
        <div styleName="filter-label">Filters:</div>
        <div>
          <ComboSelect
            open={categoryOpen}
            setOpen={setCategoryOpen}
            itemName="Category"
            loading={loadingCategoryOptions}
            options={categoryOptions}
            fetchOptions={fetchCategoryOptions}
            query={categoryQuery}
            onQueryChange={handleCategoryQueryChange}
            selectedOptions={categoryFilters}
            clearSelections={() => setCategoryFilters([])}
            onSelectOption={handleSelectCategoryOption}
          />
        </div>
        <div>
          <ComboSelect
            open={budgetItemTemplateOpen}
            setOpen={setBudgetItemTemplateOpen}
            itemName="Budget Item"
            loading={loadingBudgetItemTemplateOptions}
            options={budgetItemTemplateOptions}
            fetchOptions={fetchBudgetItemTemplateOptions}
            query={budgetItemTemplateQuery}
            onQueryChange={handleBudgetItemTemplateChange}
            selectedOptions={budgetItemTemplateFilters}
            clearSelections={() => setBudgetItemTemplateFilters([])}
            onSelectOption={handleSelectBudgetItemTemplateOption}
          />
        </div>
      </div>
    </>
  );
};

export default BudgetDetailFilters;
