import React from 'react';

import { Draggable, Droppable } from '@atom/components/common/dragAndDrop';
import {
  AttributesType,
  DetailAttributeGroupsItem,
  InventoryDetailRenamePayload,
} from '@atom/types/inventory';

import DataManagementElementAttributeEntityRow from './DataManagementElementAttributeEntityRow';
import DataManagementElementAttributeGroupRow from './DataManagementElementAttributeGroupRow';

import './dataManagementDetail.css';

interface Props {
  onClick: (attributeGroupId: string, attributeId: string) => void;
  schemaId: string;
  activeAttribute: string;
  attributeGroup: DetailAttributeGroupsItem;
  isOnlyGroup: boolean;
  attributeGroupInEditMode: string;
  attributeInEditMode: string;
  isDisabled: boolean;
  updateAttributeInEditMode: (id: string) => void;
  addAttribute: (schemaId: string, attributeGroupId: string) => void;
  onAttributeGroupRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onAttributeGroupRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  onAttributeGroupDelete: (
    schemaId: string,
    attributeGroupId: string,
  ) => boolean;
  updateAttributeGroupInEditMode: (id: string) => void;
  onAttributeRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onAttributeRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  onAttributeDelete: (
    schemaId: string,
    attributeGroupId: string,
    attributeId: string,
  ) => boolean;
}

const DataManagementElementAttributeGroup = ({
  attributeGroup,
  activeAttribute,
  onClick,
  isOnlyGroup,
  schemaId,
  addAttribute,
  isDisabled,
  attributeGroupInEditMode,
  onAttributeGroupRenameSave,
  onAttributeGroupRenameCancel,
  onAttributeGroupDelete,
  updateAttributeGroupInEditMode,
  updateAttributeInEditMode,
  attributeInEditMode,
  onAttributeRenameSave,
  onAttributeRenameCancel,
  onAttributeDelete,
}: Props) => {
  return (
    <>
      <DataManagementElementAttributeGroupRow
        attributeGroupId={attributeGroup.id}
        name={attributeGroup.name}
        schemaId={schemaId}
        isDisabled={isDisabled}
        isOnlyGroup={isOnlyGroup}
        addAttribute={addAttribute}
        attributeGroupInEditMode={attributeGroupInEditMode}
        onAttributeGroupRenameSave={onAttributeGroupRenameSave}
        onAttributeGroupRenameCancel={onAttributeGroupRenameCancel}
        onAttributeGroupDelete={onAttributeGroupDelete}
        updateAttributeGroupInEditMode={updateAttributeGroupInEditMode}
      />
      <Droppable
        droppableId={attributeGroup.id}
        type="attribute"
        isDropDisabled={isDisabled}
      >
        {attributeGroup.attributes.map(
          (attribute: AttributesType, index: number) => {
            return (
              <Draggable
                key={attribute.id}
                draggableId={attribute.id}
                index={index}
                type="attribute"
                isDragDisabled={isDisabled}
              >
                <DataManagementElementAttributeEntityRow
                  key={`${attribute.id}-${index}`}
                  schemaId={schemaId}
                  onAttributeDelete={onAttributeDelete}
                  attributeGroupId={attributeGroup.id}
                  isActive={activeAttribute === attribute.id}
                  attributeId={attribute.id}
                  attribute={attribute}
                  onClick={onClick}
                  isDisabled={isDisabled && !attribute.isPending}
                  onAttributeRenameSave={onAttributeRenameSave}
                  onAttributeRenameCancel={onAttributeRenameCancel}
                  updateAttributeInEditMode={updateAttributeInEditMode}
                  attributeInEditMode={attributeInEditMode}
                />
              </Draggable>
            );
          },
        )}
      </Droppable>
    </>
  );
};

export default DataManagementElementAttributeGroup;
