import React from 'react';
import {
  StepContent as MUIStepContent,
  StepContentProps as MUIStepContentProps,
} from '@mui/material';

export type StepContentProps = MUIStepContentProps;

export const Step = (props: StepContentProps) => {
  return <MUIStepContent {...props} />;
};

export default Step;
