import React, { useCallback, useContext } from 'react';
import debounce from 'lodash.debounce';

import TaskDateField from '@atom/components/common/workOrderDetail/taskFields/TaskDateField';
import TaskEnumMultipleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumMultipleField';
import TaskEnumSingleField from '@atom/components/common/workOrderDetail/taskFields/TaskEnumSingleField';
import TaskHyperlinkField from '@atom/components/common/workOrderDetail/taskFields/TaskHyperlinkField';
import TaskNumericField from '@atom/components/common/workOrderDetail/taskFields/TaskNumericField';
import TaskTextField from '@atom/components/common/workOrderDetail/taskFields/TaskTextField';
import {
  Task,
  TASK_FIELD_DATA_TYPE_OPTIONS,
  TaskField,
  TaskFieldDataType,
} from '@atom/types/task';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
import { updateFieldInTask } from '@atom/utilities/taskUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BulkContext from '../../../BulkContext';
import { useWorkOrderValidation } from '../../../hooks/bulkWorkOrderHook';
import Field from '../Field';

import './task.css';

const DEBOUNCE_TIME = 1000;

export interface Props {
  taskIndex: number;
}

const TaskFields = ({ taskIndex }: Props) => {
  const { invalidFields, workOrder, setWorkOrder } = useContext(BulkContext);
  const { validateTaskField } = useWorkOrderValidation();
  const { tasks } = workOrder;
  const task = tasks[taskIndex];
  const { fieldOrder, fields } = task;

  const handleChange = (fieldId: string, value: any) => {
    // Update field's value in task and set it on the work order:
    const updatedTask = updateFieldInTask(task, fieldId, value);
    const newWorkOrder = {
      ...workOrder,
      tasks: tasks.map((taskItem: Task, indexValue: number) =>
        indexValue === taskIndex ? { ...updatedTask } : taskItem,
      ),
    };
    setWorkOrder(newWorkOrder);

    // And run validation:
    validateTaskField(fieldId, updatedTask);
  };

  const handleChangeDebounced = useCallback(
    debounce(handleChange, DEBOUNCE_TIME),
    [handleChange],
  );

  const getTaskFieldIcon = (dataType: TaskFieldDataType) => {
    const option = TASK_FIELD_DATA_TYPE_OPTIONS.find(
      item => item.dataType === dataType,
    );

    return option?.icon || '';
  };

  const getContent = (field: TaskField) => {
    const isFieldEditDisabled = doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR);
    const isError = invalidFields[task.id]?.has(field.id);

    const props = {
      field,
      error: isError,
      helperText: isError ? 'This field is required' : null,
      onChange: handleChange,
      isDisabled: isFieldEditDisabled,
      previewView: true,
      onBlur: handleChange,
    };

    const hasSubFields = !isNilOrEmpty(field.subFields);

    const components = {
      [TaskFieldDataType.SHORT_TEXT]: (
        <TaskTextField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.LONG_TEXT]: (
        <TaskTextField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.DATE]: (
        <TaskDateField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.DATE_TIME]: (
        <TaskDateField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.ENUM_SINGLE]: hasSubFields ? (
        <div styleName="enum-single-nested-container">
          <TaskEnumSingleField {...props} onChange={handleChangeDebounced} />
        </div>
      ) : (
        <TaskEnumSingleField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.ENUM_MULTIPLE]: <TaskEnumMultipleField {...props} />,
      [TaskFieldDataType.NUMBER]: (
        <TaskNumericField {...props} onChange={handleChangeDebounced} />
      ),
      [TaskFieldDataType.HYPERLINK]: <TaskHyperlinkField {...props} />,
    };

    return (
      <Field
        icon={getTaskFieldIcon(field?.dataType)}
        input={components[field.dataType]}
      />
    );
  };

  return (
    <>
      {fieldOrder.map((fieldId: string) => {
        const field = fields.find(item => item.id === fieldId);

        if (!field) {
          return null;
        }

        return getContent(field);
      })}
    </>
  );
};

export default TaskFields;
