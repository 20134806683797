import React from 'react';
import * as R from 'ramda';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import colors from '@atom/styles/colors';
import { DashboardCard } from '@atom/types/dashboard';
import { SchemaType } from '@atom/types/schema';
import schemaUtilities from '@atom/utilities/schemaUtilities';

import CardEmpty from '../commonCardStates/CardEmpty';
import { getCardHeight } from '../dashboardUtilities';

import '../dashboardCard.css';

interface Props {
  card: DashboardCard;
  cardData: { schemas?: SchemaType[] };
}

const DISPLAY_COUNT_BEFORE_OTHER: number = 9;

const prepOtherType = (schemas: SchemaType[]): SchemaType[] => {
  const otherSchemaBase = {
    id: 'other',
    name: 'Other',
    assetCount: 0,
    assetType: '',
    attributes: null,
    attributeGroups: [],
    colorId: 42,
    markerId: 0,
    isMaterial: null,
    budget: null,
    elementCount: 0,
    elementGroups: [],
    locationType: null,
  };
  const otherSchemas = schemas.slice(DISPLAY_COUNT_BEFORE_OTHER);
  const assetCount = otherSchemas.reduce(
    (count, schema) => count + schema.assetCount,
    0,
  );
  return [
    ...schemas.slice(0, DISPLAY_COUNT_BEFORE_OTHER),
    {
      ...otherSchemaBase,
      assetCount,
    },
  ];
};

const CardAssetCount = ({ card, cardData }: Props) => {
  const schemas = R.sort(
    (schema1, schema2) => schema2.assetCount - schema1.assetCount,
    cardData?.schemas || [],
  );
  const displaySchemas =
    schemas?.length <= DISPLAY_COUNT_BEFORE_OTHER
      ? schemas
      : prepOtherType(schemas);

  const style = {
    height: getCardHeight(card),
  };

  const formatLegendText = (value: string) => {
    const thisSchema = displaySchemas.find(schema => schema.name === value);
    return `${value}: ${thisSchema.assetCount}`;
  };

  return R.isEmpty(schemas) ? (
    <CardEmpty card={card} />
  ) : (
    <div style={style} styleName="card-content">
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={displaySchemas}
            dataKey="assetCount"
            nameKey="name"
            startAngle={90}
            endAngle={-270}
            outerRadius="90%"
            fill={colors.neutral.white}
          >
            {displaySchemas.map((schema: SchemaType, index: number) => (
              <Cell
                style={{ outline: 'none' }}
                key={index}
                fill={schemaUtilities.getSchemaColorFromColorId(schema.colorId)}
              />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconType="circle"
            formatter={formatLegendText}
            iconSize={8}
          />
          <Tooltip animationDuration={0} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CardAssetCount;
