import React, { useContext } from 'react';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import WorkOrderAssetViewContext from '@atom/components/common/workOrderDetail/workOrderAssetView/WorkOrderAssetViewContext';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { ChangeType } from '@atom/types/inventory';
import { WorkOrderAssetTreeElement } from '@atom/types/work';
import api from '@atom/utilities/api';
import { getWorkOrderAssetsEndpoint } from '@atom/utilities/endpoints';

import './workOrderAssetTree.css';

interface Props {
  element: WorkOrderAssetTreeElement;
}

const ElementControls = ({ element }: Props) => {
  const {
    workOrderDetail,
    refetchWorkOrderAssetTree,
    workOrderAsset,
    activeElementId,
    setActiveElementId,
  } = useContext(WorkOrderAssetViewContext);

  const handleApprove = async () => {
    await api.post(
      `${getWorkOrderAssetsEndpoint(workOrderDetail.id)}/${element.id}/approve`,
      { includeChangesBefore: element.includeChangesBefore },
    );

    if (
      element.changeType === ChangeType.DELETED &&
      element.id === activeElementId
    ) {
      setActiveElementId(workOrderAsset.id);
    }

    refetchWorkOrderAssetTree();
  };

  const handleReject = async () => {
    await api.post(
      `${getWorkOrderAssetsEndpoint(workOrderDetail.id)}/${element.id}/reject`,
      { includeChangesBefore: element.includeChangesBefore },
    );

    if (
      element.changeType === ChangeType.ADDED &&
      element.id === activeElementId
    ) {
      setActiveElementId(workOrderAsset.id);
    }

    refetchWorkOrderAssetTree();
  };

  return (
    <div styleName="pending-approval-container">
      <div styleName="pending-element-label">{element.changeType}</div>
      <div styleName="pending-controls-container">
        <div styleName="pending-element-control">
          <DialogTrigger
            labelCancel="Cancel"
            labelConfirm="Reject"
            confirmAction={handleReject}
            title="Reject Changes"
            content={
              <span>
                Are you sure you want to reject the changes on this item?
              </span>
            }
            trigger={
              <IconButton tooltip="Reject">
                <Icon color={colors.brand.blue}>clear</Icon>
              </IconButton>
            }
          />
        </div>
        <div styleName="pending-element-control">
          <IconButton tooltip="Approve" onClick={handleApprove}>
            <Icon color={colors.brand.blue}>check</Icon>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ElementControls;
