import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { MuiThemeProvider } from 'material-ui';
import * as R from 'ramda';

import InventoryUpload from '@atom/components/inventoryUpload/InventoryUpload';
import {
  Environment,
  isCurrentEnvironment,
  isCurrentTenant,
  Tenant,
} from '@atom/utilities/featureToggleUtilities';
import { muiTheme, newMuiTheme } from '@atom/utilities/muiTheme';

import AnalyticsDashboard from './analyticsDashboard/AnalyticsDashboard';
import AnalyticsDetail from './analyticsDetail/AnalyticsDetail';
import BudgetDetail from './budgetDetail/BudgetDetail';
import BudgetingPortal from './budgeting/Budgeting';
import BudgetingDetail from './budgetingDetail/BudgetingDetail';
import BudgetsPortal from './budgets/BudgetsPortal';
import NoPermission from './common/errorPages/NoPermission';
import NotFound from './common/errorPages/NotFound';
import UnknownError from './common/errorPages/UnknownError';
import DashboardPortal from './dashboard/DashboardPortal';
import DataManagementPortal from './dataManagement/DataManagementPortal';
import DataManagementDetail from './dataManagementDetail/DataManagementDetail';
import FormBuilder from './formBuilder/FormBuilder';
import FormInstance from './formInstance/FormInstance';
import FormRepository from './formRepository/FormRepository';
import InventoryPortal from './inventory/InventoryPortal';
import InventoryDetail from './inventoryDetail/InventoryDetail';
import InventoryExportPortal from './inventoryExport/InventoryExportPortal';
import InventoryExportDetail from './inventoryExportDetail/InventoryExportDetail';
import HennepinSignCodePdfReport from './inventoryReports/HennepinSignCodePdfReport';
import Login from './login/Login';
import Registration from './login/Registration';
import TenantCreation from './login/TenantCreation';
import TenantEntry from './login/TenantEntry';
import TenantSelection from './login/TenantSelection';
import Unverified from './login/Unverified';
import VerifyEmail from './login/VerifyEmail';
import MapPortal from './mapPortal/MapPortal';
import RoleDetail from './roleDetail/RoleDetail';
import SchedulePortal from './schedule/SchedulePortal';
import SchemaDetail from './schemaDetail/SchemaDetail';
import SearchPortal from './search/SearchPortal';
import TeamPortal from './teamPortal/TeamPortal';
import UserDetail from './userDetail/UserDetail';
import UserGroupDetail from './userGroupDetail/UserGroupDetail';
import WorkExportDetail from './workExportDetail/WorkExportDetail';
import WorkExports from './workExports/WorkExports';
import WorkDetail from './workOrderDetail/WorkOrderDetail';
import AldotWorkOrderPdfReport from './workOrderDetailPdfReport/AldotWorkOrderPdfReport/AldotWorkOrderPdfReport';
import GwrrWorkOrderPdfReport from './workOrderDetailPdfReport/GwrrWorkOrderPdfReport/GwrrWorkOrderPdfReport';
import IdlWorkOrderPdfReport from './workOrderDetailPdfReport/IdlWorkOrderPdfReport/IdlWorkOrderPdfReport';
import OdotWorkOrderPdfReport from './workOrderDetailPdfReport/OdotWorkOrderPdfReport/OdotWorkOrderPdfReport';
import ScdotWorkOrderPdfReport from './workOrderDetailPdfReport/ScdotWorkOrderPdfReport/ScdotWorkOrderPdfReport';
import WorkOrderPdfReport from './workOrderDetailPdfReport/WorkOrderPdfReport/WorkOrderPdfReport';
import WorkOrders from './workOrders/WorkOrders';
import WorkTemplate from './workTemplate/WorkTemplate';
import WorkTemplates from './workTemplates/WorkTemplates';
import AdminRoute from './AdminRoute';
import AuthCallback from './AuthCallback';
import AuthorizedRoute from './AuthorizedRoute';

class Application extends React.Component {
  render() {
    return (
      <ErrorBoundary FallbackComponent={UnknownError}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={newMuiTheme}>
            <MuiThemeProvider muiTheme={muiTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div style={{ height: '100%' }}>
                  <Switch>
                    <AuthorizedRoute exact path="/" component={<div />} />
                    <AuthorizedRoute
                      path="/dashboard"
                      component={DashboardPortal}
                    />
                    <AuthorizedRoute path="/map" component={MapPortal} />
                    <AuthorizedRoute
                      exact
                      path="/team"
                      component={TeamPortal}
                    />
                    <AuthorizedRoute
                      exact
                      path="/team/:id"
                      component={UserDetail}
                      getKey={R.pathOr('workOrderId', [
                        'match',
                        'params',
                        'id',
                      ])}
                    />
                    <AuthorizedRoute
                      exact
                      path="/team/group/:id"
                      component={UserGroupDetail}
                    />
                    <AuthorizedRoute
                      exact
                      path="/team/role/:id"
                      component={RoleDetail}
                    />
                    <AuthorizedRoute
                      exact
                      path="/workOrders"
                      component={WorkOrders}
                    />
                    <AuthorizedRoute
                      path="/workOrders/:workOrderId/:taskId?"
                      component={WorkDetail}
                      getKey={R.pathOr('workOrderId', [
                        'match',
                        'params',
                        'workOrderId',
                      ])}
                    />
                    <AuthorizedRoute
                      path="/workOrder/:workOrderId/gwrrPdfReport/:reportType"
                      component={GwrrWorkOrderPdfReport}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/workOrder/:workOrderId/aldotPdfReport"
                      component={AldotWorkOrderPdfReport}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/workOrder/:workOrderId/odotPdfReport"
                      component={OdotWorkOrderPdfReport}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/workOrder/:workOrderId/workPdfReport"
                      component={WorkOrderPdfReport}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/workOrder/:workOrderId/scdotPdfReport"
                      component={ScdotWorkOrderPdfReport}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/workOrder/:workOrderId/idlPdfReport"
                      component={IdlWorkOrderPdfReport}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/inventory/MUTCD"
                      component={HennepinSignCodePdfReport}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      exact
                      path="/search"
                      component={SearchPortal}
                    />
                    <AuthorizedRoute
                      exact
                      path="/schedule"
                      component={SchedulePortal}
                    />
                    <AuthorizedRoute
                      exact
                      path="/inventory"
                      component={InventoryPortal}
                    />
                    <AuthorizedRoute
                      path="/inventory/export/:id"
                      component={InventoryExportDetail}
                    />
                    <AuthorizedRoute
                      path="/inventory/export"
                      component={InventoryExportPortal}
                    />
                    <AuthorizedRoute
                      path="/inventory/:id"
                      component={InventoryDetail}
                    />
                    <AuthorizedRoute
                      exact
                      path="/workExports"
                      component={WorkExports}
                    />
                    <AuthorizedRoute
                      exact
                      path="/workExports/:id"
                      component={WorkExportDetail}
                    />
                    <AuthorizedRoute
                      exact
                      path="/work-templates"
                      component={WorkTemplates}
                    />
                    <AuthorizedRoute
                      exact
                      path="/work-types"
                      component={() => <Redirect to={'/work-templates'} />}
                    />
                    <AuthorizedRoute
                      exact
                      path="/work-templates/:id"
                      component={WorkTemplate}
                    />
                    <AuthorizedRoute
                      exact
                      path="/work-types/:id"
                      component={props => (
                        <Redirect
                          to={`/work-templates/${props.match.params.id}`}
                        />
                      )}
                    />
                    <AuthorizedRoute
                      exact
                      path="/form-repository"
                      component={FormRepository}
                    />
                    <AuthorizedRoute
                      path="/form-builder/:id/preview"
                      component={FormInstance}
                    />
                    <AuthorizedRoute
                      path="/form-instance/:id"
                      component={FormInstance}
                    />
                    <AuthorizedRoute
                      path="/form-builder/:id"
                      component={FormBuilder}
                    />
                    <AuthorizedRoute
                      path="/analytics/dashboard"
                      component={AnalyticsDashboard}
                    />
                    <AuthorizedRoute
                      exact
                      path="/analytics/:id"
                      component={AnalyticsDetail}
                    />
                    {isCurrentTenant([Tenant.UAT_SDDOT]) && (
                      <AuthorizedRoute
                        exact
                        path="/budgeting"
                        component={BudgetingPortal}
                      />
                    )}
                    {isCurrentTenant([Tenant.UAT_SDDOT]) && (
                      <AuthorizedRoute
                        exact
                        path="/budgeting/:id"
                        component={BudgetingDetail}
                      />
                    )}
                    {/* // TODO: [AM-14917]: Remove feature toggle */}
                    {isCurrentEnvironment([
                      Environment.DEV,
                      Environment.QA,
                    ]) && (
                      <AuthorizedRoute
                        exact
                        path="/budgets"
                        component={BudgetsPortal}
                      />
                    )}
                    {/* // TODO: [AM-14917]: Remove feature toggle */}
                    {isCurrentEnvironment([
                      Environment.DEV,
                      Environment.QA,
                    ]) && (
                      <AuthorizedRoute
                        exact
                        path="/budget/:id"
                        component={BudgetDetail}
                      />
                    )}
                    <AuthorizedRoute
                      path="/no-permission"
                      component={NoPermission}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/not-found"
                      component={NotFound}
                      isTopNavigationDisabled
                    />
                    <AuthorizedRoute
                      path="/unknown-error"
                      component={UnknownError}
                      isTopNavigationDisabled
                    />
                    <AdminRoute
                      exact
                      path="/admin/inventoryTypes"
                      component={DataManagementPortal}
                    />
                    <AdminRoute
                      path="/admin/inventoryTypes/:id"
                      component={DataManagementDetail}
                    />
                    {/* // TODO: [AM-14839]: Remove feature toggle */}
                    {isCurrentEnvironment([Environment.DEV]) && (
                      <AdminRoute
                        path="/admin/inventoryTypes-new/:id"
                        component={SchemaDetail}
                      />
                    )}
                    <Route path="/login" component={Login} />
                    <Route path="/callback" component={AuthCallback} />
                    <Route path="/registration" component={Registration} />
                    <Route path="/verify-email" component={VerifyEmail} />
                    <Route path="/tenant-creation" component={TenantCreation} />
                    <Route
                      path="/tenant-selection"
                      component={TenantSelection}
                    />
                    <Route path="/tenant-entry" component={TenantEntry} />
                    <Route path="/unverified" component={Unverified} />
                    <AuthorizedRoute component={UnknownError} />
                  </Switch>
                </div>
                {/* "global" components */}
                <InventoryUpload.Provider />
              </LocalizationProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundary>
    );
  }
}

export default Application;
