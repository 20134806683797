import React, { useState } from 'react';

import BudgetingDetailHeader from './BudgetingDetailHeader';
import { BudgetingView } from './BudgetingView';

import './budgetingDetail.css';

const viewIframeSourcesMap: { [key in BudgetingView]: string } = {
  [BudgetingView.SUMMARY]:
    'https://datastudio.google.com/embed/reporting/9dcb1d59-383f-4868-881c-8b6abe1b86bd/page/wl9XC',
  [BudgetingView.INVENTORY]:
    'https://datastudio.google.com/embed/reporting/a9a677b8-29dd-4b3b-a374-4f612dcee1bd/page/wl9XC',
  [BudgetingView.WORK]:
    'https://datastudio.google.com/embed/reporting/ac7168bc-84ab-4633-971d-010a8076d5a1/page/wl9XC',
  [BudgetingView.CALCULATOR]:
    'https://datastudio.google.com/embed/reporting/a3a593e6-eeb6-4dca-ad15-73d8fc4275cd/page/wl9XC',
  [BudgetingView.PERFORMANCE]:
    'https://datastudio.google.com/embed/reporting/9dcb1d59-383f-4868-881c-8b6abe1b86bd/page/wl9XC',
};

const BudgetingDetail = () => {
  const [activeView, setActiveView] = useState<BudgetingView>(
    BudgetingView.SUMMARY,
  );

  return (
    <>
      <BudgetingDetailHeader
        activeView={activeView}
        setActiveView={setActiveView}
      />
      <div styleName="tab">
        <div styleName="container">
          <div styleName="content">
            <iframe
              width="100%"
              height="100%"
              src={viewIframeSourcesMap[activeView]}
              frameBorder="0"
              style={{ border: 0, overflow: 'hidden' }}
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetingDetail;
