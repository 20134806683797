import React, { useEffect, useState } from 'react';
import { Dialog } from 'material-ui';

import TextField from '@atom/components/common/TextField';
import { Button } from '@atom/mui';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';

const styles = {
  floatingLabelStyle: {
    color: colors.neutral.dim,
    textTransform: 'capitalize',
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
  iconLayoutDiv: { display: 'flex', alignItems: 'center', gap: '1rem' },
};

export interface Props {
  open: boolean;
  loading?: boolean;
  title?: string;
  fieldName?: string;
  initialValue?: string;
  cancelText?: string;
  confirmText?: string;
  icon?: string;
  onCancel: () => void;
  onConfirm: (value: string) => void;
}

const CreateModal = ({
  open,
  loading,
  title,
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  fieldName,
  initialValue,
  icon,
}: Props) => {
  const [value, setValue] = useState<string>(initialValue || '');

  const setErrorText = () => {
    return value.length === 0 ? `The ${fieldName} cannot be blank` : '';
  };

  const disabled = loading || value.length === 0;

  useEffect(() => {
    if (!open) {
      setValue(initialValue || '');
    }
  }, [open, initialValue]);

  const actionButtons = [
    <Button onClick={onCancel} disabled={loading}>
      {cancelText || 'Cancel'}
    </Button>,
    <Button
      onClick={() => onConfirm(value)}
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      {confirmText || 'Create'}
    </Button>,
  ];

  return (
    <Dialog
      title={title || 'Create'}
      contentStyle={{ width: layout.mediumModalWidth }}
      actions={actionButtons}
      modal
      open={open}
    >
      <div style={styles.iconLayoutDiv}>
        {icon && (
          <div>
            <img src={icon} />
          </div>
        )}
        <TextField
          fullWidth
          floatingLabelFixed
          floatingLabelText={fieldName || ''}
          style={styles.textFieldStyle}
          floatingLabelStyle={styles.floatingLabelStyle}
          value={value}
          name="value"
          errorText={setErrorText()}
          onChange={event => setValue(event.target.value)}
        />
      </div>
    </Dialog>
  );
};

export default CreateModal;
