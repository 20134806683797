import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';

import client from '@atom/graph/client';
import { GET_POLICIES_FOR_VALIDATION, UPDATE_POLICY } from '@atom/graph/policy';
import { Modal } from '@atom/mui';
import {
  PoliciesConnection,
  PoliciesConnectionInput,
  Policy,
  POLICY_GRANT_IDS_ALL,
  PolicyResource,
  PolicyUpdateInput,
} from '@atom/types/policy';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RoleContext from '../../RoleContext';

import AnalyticsFolderSelection from './AnalyticsFolderSelect/AnalyticsFolderSelection';
import PolicyAssetSelection from './PolicyAssetSelection';
import PolicyCategorySelection from './PolicyCategorySelection';
import PolicyModalContext, {
  basePolicyModalStyle,
  PolicyModalState,
  policyModalStyles,
} from './PolicyModalContext';
import PolicyModalMain from './PolicyModalMain';
import PolicyModalTitle from './PolicyModalTitle';
import PolicyWorkTemplateFolderSelection from './PolicyWorkTemplateFolderSelection';

interface Props {
  open: boolean;
  onClose: () => void;
  policy?: Policy;
}

const getInitialState = (policy: Policy): PolicyModalState => {
  const grants =
    policy?.grant?.ids === POLICY_GRANT_IDS_ALL
      ? [
          {
            id: POLICY_GRANT_IDS_ALL,
            name: 'All',
          },
        ]
      : policy?.grants || [];

  return {
    view: 'MAIN',
    resource: policy?.resource || null,
    grantType: policy?.grant?.type || null,
    grants,
    actions: policy?.action ? [policy.action] : [],
    existingPolicies: [],
    loadingExistingPolicies: false,
  };
};

const EditPolicyModal = ({ open, onClose, policy }: Props) => {
  const { role, refetch } = useContext(RoleContext);
  const [state, setState] = useState<PolicyModalState>(getInitialState(policy));

  const { view, resource, grants, grantType, actions } = state;

  const updateState = useCallback((update: Partial<PolicyModalState>) => {
    setState(current => ({ ...current, ...update }));
  }, []);

  const resetState = () => {
    setState(getInitialState(policy));
  };

  const getExistingPolicies = useCallback(async () => {
    updateState({ loadingExistingPolicies: true });

    const { data } = await client.query<
      { policies: PoliciesConnection },
      { input: PoliciesConnectionInput }
    >({
      query: GET_POLICIES_FOR_VALIDATION,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          subjectIds: [role.id],
          resources: [resource],
        },
      },
    });

    updateState({
      existingPolicies: data.policies.policies,
      loadingExistingPolicies: false,
    });
  }, [role.id, resource]);

  useEffect(() => {
    resetState();
  }, [policy]);

  useEffect(() => {
    if (resource) {
      getExistingPolicies();
    }
  }, [resource]);

  const [updatePolicy, { loading }] = useMutation<
    { policy: Policy },
    { input: PolicyUpdateInput }
  >(UPDATE_POLICY);

  const handleSubmit = async () => {
    await updatePolicy({
      variables: {
        input: {
          id: policy.id,
          action: actions[0],
          resource,
          grant: {
            type: grantType,
            ids: grants.map(({ id }) => id).join(','),
          },
        },
      },
    });

    onClose();
    refetch();
  };

  const isValid = useMemo(() => {
    return (
      !isNilOrEmpty(resource) && !isNilOrEmpty(grants) && !isNilOrEmpty(actions)
    );
  }, [resource, grants, actions]);

  const getContent = () => {
    switch (view) {
      case PolicyResource.INVENTORY_FOLDER: {
        return <PolicyCategorySelection />;
      }
      case PolicyResource.INVENTORY_ITEM: {
        return <PolicyAssetSelection />;
      }
      case PolicyResource.WORK_TYPE_FOLDER: {
        return <PolicyWorkTemplateFolderSelection />;
      }
      case PolicyResource.ANALYTICS_FOLDER: {
        return <AnalyticsFolderSelection />;
      }
      default: {
        return <PolicyModalMain isEdit />;
      }
    }
  };

  return (
    <PolicyModalContext.Provider value={{ state, updateState, policy }}>
      <Modal
        title={<PolicyModalTitle title="Edit Policy" />}
        open={open}
        onCancel={onClose}
        onConfirm={handleSubmit}
        confirmButtonText="Save"
        contentStyle={policyModalStyles[view] || basePolicyModalStyle}
        loading={loading}
        onExited={resetState}
        disableFooter={view !== 'MAIN'}
        disabled={!isValid}
      >
        {getContent()}
      </Modal>
    </PolicyModalContext.Provider>
  );
};

export default EditPolicyModal;
