import React, { useContext } from 'react';

import { Icon, ListTable } from '@atom/mui';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';

import BudgetDetailContext from './BudgetDetailContext';
import { BUDGET_COL_WIDTH } from './budgetDetailUtils';

import './budgetDetail.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const BudgetDetailChildUnits = () => {
  const { childBudgetUnits, setParentBudgetUnit } = useContext(
    BudgetDetailContext,
  );

  return (
    <div>
      <div styleName="overview-heading">
        Budget Units
        <span styleName="item-count"> {childBudgetUnits?.length || 0}</span>
      </div>
      <ListTable fullHeight={false}>
        <TableHead>
          <TableRow>
            <TableCell variant="head">NAME</TableCell>
            <TableCell width={BUDGET_COL_WIDTH} align="right" variant="head">
              BUDGET
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {childBudgetUnits.map(budgetUnit => (
            <TableRow
              key={budgetUnit.id}
              onClick={() => setParentBudgetUnit(budgetUnit)}
              style={{ cursor: 'pointer' }}
            >
              <TableCell>
                <span styleName="budget-table-cell">
                  <Icon>folder</Icon> {budgetUnit.name}
                </span>
              </TableCell>
              <TableCell align="right">
                <span styleName="budget-table-cell">
                  {numberToLocaleString(budgetUnit?.totalBudget)}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
    </div>
  );
};

export default BudgetDetailChildUnits;
