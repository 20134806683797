import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import * as R from 'ramda';

import { SEARCH_CATEGORIES } from '@atom/graph/category';
import {
  SearchCategoriesConnection,
  SearchCategory,
} from '@atom/types/inventory';

import client from '../graph/client';

export interface CategoriesSearchOptions {
  // search query
  query: string;
  // number of milliseconds to debounce search at
  // defaults to 500
  debounceTime?: number;
  // character min before querying the API for results
  characterMin?: number;
  // results limit
  limit?: number;
  // denotes that only material categories should be returned
  includeMaterialCategories?: boolean;
  // denotes that only location based categories should be returned
  includeLocationBasedCategories?: boolean;
}

export type Data = [
  SearchCategoriesConnection,
  {
    fetchMore: () => void;
    hasMore: boolean;
    loadingSearch: boolean;
    loadingPagination: boolean;
    error: boolean;
  },
];

type DebouncedSearch = (
  query: string,
  categories: SearchCategory[],
  limit?: number,
  page?: number,
) => void;

export const useCategoriesSearch = ({
  query,
  limit,
  characterMin = 1,
  debounceTime = 500,
  includeMaterialCategories,
  includeLocationBasedCategories,
}: CategoriesSearchOptions): Data => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingPagination, setLoadingPagination] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [connection, setConnection] = useState<SearchCategoriesConnection>({
    totalCount: 0,
    categories: [],
  });

  const nextPage = useMemo(() => {
    if (!limit || !connection.totalCount) {
      return 1;
    }

    return Math.floor(connection.categories.length / limit) + 1;
  }, [connection, limit]);

  const searchCategories = async (
    search: string,
    categories: SearchCategory[],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    limit?: number,
    page?: number,
  ) => {
    setError(false);

    try {
      const { data } = await client.query<{
        categoriesSearch: SearchCategoriesConnection;
      }>({
        query: SEARCH_CATEGORIES,
        variables: {
          input: {
            query: search,
            ...R.reject(R.isNil, {
              limit,
              page,
              includeMaterialCategories,
              includeLocationBasedCategories,
            }),
          },
        },
      });

      setConnection({
        totalCount: data.categoriesSearch.totalCount,
        categories: [...categories, ...data.categoriesSearch.categories],
      });
    } catch (err) {
      setError(true);
    }

    setLoadingSearch(false);
    setLoadingPagination(false);
  };

  const searchCategoriesDebounced = useCallback<DebouncedSearch>(
    debounce(searchCategories, debounceTime),
    [],
  );

  useEffect(() => {
    if (query.length >= characterMin) {
      setLoadingSearch(true);
      searchCategoriesDebounced(query, [], limit);
    } else {
      setConnection({ totalCount: 0, categories: [] });
    }
  }, [query]);

  const hasMore = connection.categories.length < connection.totalCount;

  const fetchMore = () => {
    if (hasMore) {
      setLoadingPagination(true);
      searchCategories(query, connection.categories, limit, nextPage);
    }
  };

  return [
    connection,
    {
      fetchMore,
      hasMore,
      loadingSearch,
      loadingPagination,
      error,
    },
  ];
};
