import * as React from 'react';
import { SelectChangeEvent } from '@mui/material';

import Checkbox from '@atom/components/common/Checkbox';
import TextField from '@atom/components/common/TextField';
import { Icon, Select } from '@atom/mui';
import {
  addFieldOption,
  DATA_TYPE_OPTIONS,
  FILTERABLE_DATA_TYPES,
  removeFieldOption,
  updateFieldOption,
} from '@atom/selectors/schemaSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType } from '@atom/types/event';
import { AttributesType } from '@atom/types/inventory';

import DataManagementEnumBuilder from './DataManagementEnumBuilder';

import './dataManagementDetail.css';

const { MenuItem } = Select;

const styles = {
  floatingLabelStyle: {
    fontSize: fonts.md,
    color: colors.neutral.gray,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
    fontSize: fonts.md,
  },
  underlineStyle: {
    borderColor: colors.neutral.silver,
  },
  selectFieldStyle: {
    input: {
      fontSize: fonts.md,
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      fontSize: fonts.md,
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
};

interface DataTypeRenderInfo {
  icon: string;
  title: string;
  dataType: string;
}

interface Props {
  attribute: AttributesType;
  isDisabled: boolean;
  updateAttribute: (data: any) => void;
}

interface State {
  dataType: string;
  attributeId: string;
  enumeration: any;
  unit: any;
  isVisibleAsSubtext: boolean;
  isFilterable: boolean;
  isPending?: boolean;
  isPendingUpdate?: boolean;
  publishedAttribute?: any;
}

class DataManagementAttributeDetailPane extends React.Component<Props, State> {
  state = {
    dataType: '',
    enumeration: null,
    unit: null,
    attributeId: '',
    isVisibleAsSubtext: false,
    isFilterable: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Object {
    if (
      prevState.attributeId !== nextProps.attribute.id ||
      nextProps.attribute.isPendingUpdate
    ) {
      return {
        dataType: nextProps.attribute.dataType || '',
        unit: nextProps.attribute.unit || null,
        enumeration: nextProps.attribute.enumeration || null,
        attributeId: nextProps.attribute.id || '',
        isVisibleAsSubtext: nextProps.attribute.isVisibleAsSubtext || false,
        isFilterable: nextProps.attribute.isFilterable || false,
        isPending: nextProps.attribute.isPending || false,
        isPendingUpdate: nextProps.attribute.isPendingUpdate || false,
        publishedAttribute: nextProps.attribute.publishedAttribute,
      };
    }
    return { attributeId: nextProps.attribute.id };
  }

  onDataTypeChange = (event: SelectChangeEvent): void => {
    const { updateAttribute } = this.props;
    const { value } = event.target;

    if (value === 'number') {
      updateAttribute({
        ...this.state,
        dataType: value,
        unit: '',
        enumeration: null,
      });
      return this.setState({ dataType: value, unit: '', enumeration: null });
    }

    if (value === 'enumsingle' || value === 'enummultiple') {
      const enumeration =
        value === 'enumsingle' ? ['', 'Option 1'] : ['Option 1', 'Option 2'];

      updateAttribute({
        ...this.state,
        dataType: value,
        enumeration,
        unit: null,
      });
      return this.setState({ dataType: value, enumeration, unit: null });
    }

    updateAttribute({
      ...this.state,
      dataType: value,
      unit: null,
      enumeration: null,
    });
    return this.setState({ dataType: value, unit: null, enumeration: null });
  };

  onChange = (event: EventType) => {
    const { updateAttribute } = this.props;

    updateAttribute({ ...this.state, unit: event.target.value });
    this.setState({ unit: event.target.value });
  };

  toggleCheck = (key: string) => {
    const { updateAttribute } = this.props;

    updateAttribute({ ...this.state, [key]: !this.state[key] });
    // @ts-ignore
    this.setState({ [key]: !this.state[key] });
  };

  getDataTypeOption = (type: DataTypeRenderInfo) => {
    return (
      <div styleName="data-type-option-container">
        <Icon color={colors.neutral.gray}>{type.icon}</Icon>
        <div styleName="data-type-option-title">{type.title}</div>
      </div>
    );
  };

  addOption = () => {
    const { updateAttribute } = this.props;
    const { enumeration, dataType } = this.state;

    const newEnumerationValue = addFieldOption(enumeration, dataType);

    updateAttribute({ ...this.state, enumeration: newEnumerationValue });
    this.setState({ enumeration: newEnumerationValue });
  };

  removeOption = (index: number) => {
    const { updateAttribute } = this.props;
    const { enumeration } = this.state;

    const newEnumerationValue = removeFieldOption(index, enumeration);

    updateAttribute({ ...this.state, enumeration: newEnumerationValue });
    this.setState({ enumeration: newEnumerationValue });
  };

  updateOption = (event: EventType) => {
    const { updateAttribute } = this.props;
    const { enumeration } = this.state;

    const newEnumerationValue = updateFieldOption(
      event.target.id,
      event.target.value,
      enumeration,
    );

    updateAttribute({ ...this.state, enumeration: newEnumerationValue });
    this.setState({ enumeration: newEnumerationValue });
  };

  render() {
    const { attribute, isDisabled } = this.props;
    const {
      dataType,
      unit,
      enumeration,
      isVisibleAsSubtext,
      isFilterable,
    } = this.state;

    const isNumericType = dataType === 'number';
    const isEnumType = dataType === 'enumsingle' || dataType === 'enummultiple';

    return (
      <div styleName="attribute-detail-section-pane">
        <div styleName="attribute-detail-container">
          <div styleName="attribute-detail-title">{attribute.name}</div>
          <Select
            fullWidth
            label="Field Type"
            style={styles.textFieldStyle}
            onChange={this.onDataTypeChange}
            value={dataType}
            disabled={isDisabled}
          >
            {DATA_TYPE_OPTIONS.map((type: DataTypeRenderInfo) => {
              const primaryText = this.getDataTypeOption(type);
              return (
                <MenuItem key={type.dataType} value={type.dataType}>
                  {primaryText}
                </MenuItem>
              );
            })}
          </Select>
          {isNumericType && (
            <TextField
              value={unit}
              floatingLabelFixed
              onChange={this.onChange}
              name="unit"
              fullWidth
              disabled={isDisabled}
              style={styles.textFieldStyle}
              floatingLabelText="Unit"
            />
          )}
          {isEnumType && (
            <DataManagementEnumBuilder
              dataType={dataType}
              enumValue={enumeration}
              updateOption={this.updateOption}
              removeOption={this.removeOption}
              addOption={this.addOption}
              isDisabled={isDisabled}
              attribute={attribute}
            />
          )}
        </div>
        <div styleName="attribute-detail-controls-container">
          <div styleName="attribute-detail-title">Controls</div>
          {FILTERABLE_DATA_TYPES.has(attribute.dataType) && (
            <div styleName="attribute-detail-checkbox-container">
              <Checkbox
                isChecked={isFilterable}
                toggleCheck={(): void => this.toggleCheck('isFilterable')}
              />
              <div styleName="attribute-detail-checkbox-label">Filterable</div>
            </div>
          )}
          <div styleName="attribute-detail-checkbox-container">
            <Checkbox
              isChecked={isVisibleAsSubtext}
              toggleCheck={(): void => this.toggleCheck('isVisibleAsSubtext')}
            />
            <div styleName="attribute-detail-checkbox-description-container">
              <div styleName="attribute-detail-checkbox-label">
                Display as Item Subtext
              </div>
              <div styleName="attribute-detail-checkbox-label-subtext">
                Selected subtext display items cannot exceed 15 characters per
                item.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataManagementAttributeDetailPane;
