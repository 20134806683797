import React from 'react';

import '../../styles/detail-header.css';

const BudgetingHeader = () => {
  return (
    <div styleName="header-container budgeting-header">
      <div styleName="name-container">
        <span>Budgeting</span>
      </div>
    </div>
  );
};

export default BudgetingHeader;
