import React, { useEffect, useState } from 'react';

import { DatePicker, DateTimePicker } from '@atom/mui';
import { TaskField, TaskFieldDataType } from '@atom/types/task';

import './taskFields.css';

interface Props {
  field: TaskField;
  onChange: (fieldId: string, value: any) => void;
  isDisabled: boolean;
  previewView?: boolean;
  error?: boolean;
  helperText?: string;
  onClick?: () => void;
  taskId?: string;
}

const TaskDateField = ({
  field,
  taskId,
  onChange,
  isDisabled,
  previewView = false,
  error = false,
  helperText,
  onClick,
}: Props) => {
  const [value, setValue] = useState<Date>(
    field.value ? new Date(field.value) : null,
  );

  useEffect(() => {
    setValue(field.value ? new Date(field.value) : null);
  }, [taskId, field?.id]);

  const handleChange = (date?: Date) => {
    setValue(date);

    // date was removed entirely
    if (!date) {
      onChange(field.id, '');
    } else if (!Number.isNaN(date.valueOf())) {
      onChange(field.id, date.valueOf());
    }
  };

  const styles = {
    date: {
      flex: previewView ? 1 : 0.27,
    },
    dateTime: {
      flex: previewView ? 1 : 0.47,
    },
  };

  const title = field.required ? `* ${field.title}` : field.title;

  return field.dataType === TaskFieldDataType.DATE ? (
    <DatePicker
      value={value}
      onChange={handleChange}
      disabled={isDisabled}
      label={title}
      style={styles.date}
      errorHelperText={helperText}
      TextFieldProps={{ error, onClick }}
    />
  ) : (
    <DateTimePicker
      value={value}
      onChange={handleChange}
      disabled={isDisabled}
      label={title}
      style={styles.dateTime}
      errorHelperText={helperText}
      TextFieldProps={{ error, onClick }}
    />
  );
};

export default TaskDateField;
