import React from 'react';
import { FontIcon } from 'material-ui';

import colors from '@atom/styles/colors';
import { getColorFromColorId } from '@atom/utilities/colorUtilities';

interface Props {
  colorId?: number;
  disabled?: boolean;
}

const styles = {
  icon: {
    fontSize: '22px',
    color: colors.neutral.white,
    fontWeight: '500',
  },
  iconContainer: {
    width: '34px',
    height: '34px',
    borderRadius: '50%',
    backgroundColor: colors.neutral.gray,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const UserGroupIcon = ({ colorId, disabled }: Props) => {
  const color = getColorFromColorId(colorId);

  return (
    <div
      style={{
        ...styles.iconContainer,
        backgroundColor: color,
        opacity: disabled ? '0.5' : '1',
      }}
    >
      <FontIcon className="material-icons" style={styles.icon}>
        group
      </FontIcon>
    </div>
  );
};

export default UserGroupIcon;
