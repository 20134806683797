import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SortDirection } from '@mui/material';

import { Icon, ListTable } from '@atom/components/common/mui';
import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetSortByOptions } from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { setDisplayDate } from '@atom/utilities/timeUtilities';
import userUtilities from '@atom/utilities/userUtilities';

import BudgetsContext from './BudgetsContext';

const { getUserFullName } = userUtilities;

const styles = {
  link: {
    fontWeight: 500,
  },
  row: {
    backgroundColor: colors.neutral.white,
    height: '3rem',
  },
  progress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} = ListTable;

import BudgetsNoneAvailable from './BudgetsNoneAvailable';
import BudgetsOptions from './BudgetsOptions';

import './budgets.css';

const BudgetsTable = () => {
  const {
    budgets,
    totalCount,
    page,
    setPage,
    limit,
    setLimit,
    sortDirection,
    setSortDirection,
    sortBy,
    setSortBy,
    loadingBudgets,
  } = useContext(BudgetsContext);
  const getSortDirection = (field: string) => {
    return field === sortBy ? sortDirection : 'desc';
  };

  const handleSortDirectionChange = (field: BudgetSortByOptions) => (
    newSortDirection: SortDirection,
  ) => {
    setSortBy(field);
    setSortDirection(newSortDirection);
  };

  return loadingBudgets ? (
    <div style={styles.progress}>
      <Progress />
    </div>
  ) : totalCount === 0 ? (
    <BudgetsNoneAvailable />
  ) : (
    <ListTable offsetTop="165px">
      <TableHead>
        <TableRow header>
          <TableCell variant="head" />
          <TableCell
            variant="head"
            sortDirection={getSortDirection('name')}
            onSortChange={handleSortDirectionChange('name')}
          >
            Budget Name
          </TableCell>
          <TableCell variant="head">Budget Template</TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('startDate')}
            onSortChange={handleSortDirectionChange('startDate')}
          >
            Start Date
          </TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('endDate')}
            onSortChange={handleSortDirectionChange('endDate')}
          >
            End Date
          </TableCell>
          <TableCell
            variant="head"
            sortDirection={getSortDirection('totalBudget')}
            onSortChange={handleSortDirectionChange('totalBudget')}
          >
            Total Budget
          </TableCell>
          <TableCell variant="head">Created By</TableCell>
          <TableCell variant="head">Created On</TableCell>
          <TableCell variant="head" />
        </TableRow>
      </TableHead>
      <TableBody>
        {budgets.map(budget => (
          <TableRow key={budget.id} style={styles.row}>
            <TableCell padding="checkbox">
              <Icon color={colors.brand.blue}>insert_chart_outlined</Icon>
            </TableCell>
            <TableCell style={styles.link}>
              <Link to={`/budget/${budget.id}`}>{budget.name}</Link>
            </TableCell>
            <TableCell>{budget.templateName}</TableCell>
            <TableCell>
              {setDisplayDate(budget?.startDate?.valueOf())}
            </TableCell>
            <TableCell>{setDisplayDate(budget?.endDate?.valueOf())}</TableCell>
            <TableCell>{numberToLocaleString(budget?.totalBudget)}</TableCell>
            <TableCell>{getUserFullName(budget?.createdBy)}</TableCell>
            <TableCell>{setDisplayDate(budget?.createdDate)}</TableCell>
            <TableCell width="3rem">
              <BudgetsOptions budget={budget} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter
        style={{ border: `1px solid ${colors.neutral.ash}`, width: '100%' }}
      >
        <TableRow hover={false}>
          <TablePagination
            style={{ border: 'none' }}
            rowsPerPageOptions={[25, 50, 100, 250]}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={pageData => setPage(pageData)}
            onRowsPerPageChange={event => {
              setLimit(+event.target.value);
              setPage(1);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default BudgetsTable;
