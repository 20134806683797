import * as React from 'react';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import FontIconButton from '@atom/components/common/FontIconButton';
import colors from '@atom/styles/colors';
import { InventoryDetailRenamePayload } from '@atom/types/inventory';

import EditField from './EditField';

import './dataManagementDetail.css';

const styles = {
  icon: {
    padding: 0,
    height: '35px',
    width: '35px',
  },
  deleteButton: {
    marginRight: '1em',
    marginBottom: '3px',
    width: '2em',
    minWidth: '2em',
    color: colors.neutral.gray,
  },
};

interface Props {
  name: string;
  attributeGroupId: string;
  schemaId: string;
  isOnlyGroup: boolean;
  isDisabled: boolean;
  attributeGroupInEditMode: string;
  addAttribute: (schemaId: string, attributeGroupId: string) => void;
  onAttributeGroupRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onAttributeGroupRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  onAttributeGroupDelete: (
    schemaId: string,
    attributeGroupId: string,
  ) => boolean;
  updateAttributeGroupInEditMode: (id: string) => void;
}

const DataManagementElementAttributeGroupRow = ({
  attributeGroupId,
  schemaId,
  name,
  isDisabled,
  isOnlyGroup,
  addAttribute,
  attributeGroupInEditMode,
  onAttributeGroupRenameSave,
  onAttributeGroupRenameCancel,
  onAttributeGroupDelete,
  updateAttributeGroupInEditMode,
}: Props) => {
  const isInEditMode =
    attributeGroupId === attributeGroupInEditMode || name === 'Untitled';

  const attributeGroupRow = (
    <React.Fragment>
      <div styleName="element-attribute-group-text">{name}</div>
      {isDisabled ? (
        <div styleName="group-icon-container">
          <FontIconButton
            color={colors.neutral.gray}
            style={styles.icon}
            icon="control_point"
            onClick={(): void => addAttribute(schemaId, attributeGroupId)}
            tooltip="Add Attribute"
            tooltipPosition="bottom-left"
          />
        </div>
      ) : (
        <div styleName="group-icon-container">
          <FontIconButton
            color={colors.neutral.gray}
            style={styles.icon}
            icon="control_point"
            onClick={(): void => addAttribute(schemaId, attributeGroupId)}
            tooltip="Add Attribute"
            tooltipPosition="bottom-left"
          />
          <FontIconButton
            color={colors.neutral.gray}
            style={styles.icon}
            icon="edit"
            onClick={(): void =>
              updateAttributeGroupInEditMode(attributeGroupId)
            }
            tooltip="Rename"
            tooltipPosition="bottom-left"
          />
          {!isOnlyGroup && (
            <DialogTrigger
              labelCancel="Cancel"
              labelConfirm="Delete"
              confirmAction={(): boolean =>
                onAttributeGroupDelete(schemaId, attributeGroupId)
              }
              title="Delete Group?"
              content={`Deleting ${name} will also delete all the items under that group.`}
              trigger={
                <FontIconButton
                  color={colors.neutral.gray}
                  style={styles.icon}
                  icon="delete"
                  tooltip="Delete"
                  tooltipPosition="bottom-left"
                />
              }
            />
          )}
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div styleName="element-attribute-group-row">
      {isInEditMode ? (
        <div styleName="attribute-group-edit-field-container">
          <EditField
            id={schemaId}
            attributeGroupId={attributeGroupId}
            name={name}
            leftIcon={<div />}
            onRenameSave={onAttributeGroupRenameSave}
            onRenameCancel={onAttributeGroupRenameCancel}
          />
        </div>
      ) : (
        attributeGroupRow
      )}
    </div>
  );
};

export default DataManagementElementAttributeGroupRow;
