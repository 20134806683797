import * as React from 'react';

import TextField from '@atom/components/common/TextField';
import { Button, Modal } from '@atom/mui';
import config from '@atom/selectors/config';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';

import './folders.css';

const styles = {
  dialog: {
    rot: {},
    bodyStyle: { margin: 0, padding: 0, border: 0 },
    contentStyle: {
      width: layout.mediumModalWidth,
      height: '200px',
      left: '50%',
      marginLeft: '-250px',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
  iconStyle: {
    color: colors.neutral.gray,
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
  actionsContainerStyle: {
    paddingRight: '1rem',
    paddingBottom: '1rem',
  },
  floatingLabelStyle: {
    color: colors.neutral.dim,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
};

const initialState = {
  open: false,
  name: 'Untitled',
};

interface Props {
  folderId?: string;
  folderPath?: any[];
  type: string;
  createAction: (folderId: string, folderPath: any[], name: string) => void;
  open?: boolean;
  closeModal?: () => void;
}

interface State {
  open: boolean;
  name: string;
}

class CreateMediaFolderModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      open: props.open,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({
        open: nextProps.open,
      });
    }
  }

  closeModal = () => {
    const { closeModal } = this.props;

    this.setState({ ...initialState });
    return closeModal();
  };

  onChange = event => {
    const { name: property, value } = event.target;
    // @ts-ignore
    this.setState({ [property]: value });
  };

  confirm = () => {
    const { name } = this.state;
    const { folderId, createAction, folderPath } = this.props;

    createAction(folderId, folderPath, name);
    this.closeModal();
  };

  setErrorText = () => {
    const { name } = this.state;

    if (name.length === 0) {
      return 'The folder name cannot be blank';
    }

    if (name.length >= config.MAX_CHARACTERS_FOLDER_NAME) {
      return 'The folder name cannot exceed 50 characters';
    }

    return '';
  };

  render() {
    const { open, name } = this.state;
    const disabled = !name || name.length >= config.MAX_CHARACTERS_FOLDER_NAME;

    const getFooter = () => {
      return (
        <div style={styles.dialog.actionsContainerStyle}>
          <Button style={styles.actionButtons.cancel} onClick={this.closeModal}>
            Cancel
          </Button>
          <Button
            disabled={disabled}
            onClick={this.confirm}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </div>
      );
    };

    return !open ? (
      <div />
    ) : (
      <Modal
        title="Create Folder"
        style={styles.dialog.contentStyle}
        footer={getFooter()}
        open={open}
      >
        <div styleName="modal-container">
          <TextField
            fullWidth
            floatingLabelFixed
            floatingLabelText="Folder Name"
            style={styles.textFieldStyle}
            floatingLabelStyle={styles.floatingLabelStyle}
            value={name}
            name="name"
            errorText={this.setErrorText()}
            onChange={this.onChange}
          />
        </div>
      </Modal>
    );
  }
}

export default CreateMediaFolderModal;
