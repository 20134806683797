import * as React from 'react';

import TextField from '@atom/components/common/TextField';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { EventType, KeyPressEventType } from '@atom/types/event';
import { InventoryDetailRenamePayload } from '@atom/types/inventory';

import './dataManagementDetail.css';

const styles = {
  icon: {
    color: colors.neutral.gray,
    padding: 0,
    fontSize: 14,
    transform: 'rotate(90deg)',
  },
  iconContainer: {
    padding: 0,
    height: '30px',
    width: '30px',
  },
  textFieldStyles: {
    fontSize: fonts.md,
  },
};

interface Props {
  id: string;
  attributeGroupId: string;
  attributeId: string;
  name: string;
  leftIcon: any;
  isOnlyGroup: boolean;
  onRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onRenameCancel: (payload: InventoryDetailRenamePayload) => void;
}

interface State {
  name: string;
}

class EditField extends React.Component<Props, State> {
  static defaultProps = {
    attributeGroupId: '',
    attributeId: '',
    isOnlyGroup: false,
  };

  state = {
    name: this.props.name,
  };

  onChange = (event: EventType) => {
    this.setState({ name: event.target.value });
  };

  onKeyPress = (event: KeyPressEventType) => {
    const {
      onRenameSave,
      id,
      attributeGroupId,
      attributeId,
      isOnlyGroup,
    } = this.props;
    const { name } = this.state;

    const payload = {
      id,
      name,
      attributeGroupId,
      attributeId,
      isOnlyGroup,
    };

    if (event.key === 'Enter') {
      onRenameSave(payload);
    }
  };

  render() {
    const {
      id,
      onRenameSave,
      onRenameCancel,
      leftIcon,
      attributeGroupId,
      attributeId,
      isOnlyGroup,
    } = this.props;
    const { name } = this.state;

    const payload = {
      id,
      name,
      attributeGroupId,
      attributeId,
      isOnlyGroup,
    };

    return (
      <div styleName="element-row-info-container edit">
        <div styleName="asset-row-icon">{leftIcon}</div>
        <div styleName="edit-input-container">
          <TextField
            value={name}
            onChange={this.onChange}
            key={id}
            name="groupName"
            onKeyPress={this.onKeyPress}
            style={styles.textFieldStyles}
            fullWidth
            autoFocus
          />
        </div>
        <div styleName="edit-buttons-container">
          <input
            styleName="edit-button cancel"
            type="button"
            value="CANCEL"
            onClick={(): void => onRenameCancel(payload)}
          />
          <input
            styleName="edit-button save"
            autoFocus
            type="button"
            value="SAVE"
            onClick={(): void => onRenameSave(payload)}
          />
        </div>
      </div>
    );
  }
}

export default EditField;
