import React from 'react';
import * as R from 'ramda';

import { WorkOrder, WorkOrderFieldDataType } from '@atom/types/work';
import { WorkOrderColumn } from '@atom/types/workColumns';
import {
  getDisplayDateTime,
  setDisplayDate,
} from '@atom/utilities/timeUtilities';

import './workOrders.css';

interface Props {
  workOrder: WorkOrder;
  column: WorkOrderColumn;
}

const CustomWorkFieldCell = ({ workOrder, column }: Props) => {
  const field = workOrder.fields.find(item => item.id === column.fieldId);

  const getFieldDisplay = () => {
    const content = {
      [WorkOrderFieldDataType.SHORT_TEXT]: field?.value,
      [WorkOrderFieldDataType.LONG_TEXT]: field?.value,
      [WorkOrderFieldDataType.DATE]: setDisplayDate(field?.value),
      [WorkOrderFieldDataType.DATE_TIME]: getDisplayDateTime(field?.value),
      [WorkOrderFieldDataType.ENUM_SINGLE]: field?.value,
      [WorkOrderFieldDataType.ENUM_MULTIPLE]:
        field?.dataType === WorkOrderFieldDataType.ENUM_MULTIPLE
          ? R.pathOr([], ['value'], field).join(', ')
          : '',
      [WorkOrderFieldDataType.NUMBER]: field?.value,
    };

    return content[field?.dataType] || '';
  };

  return <div>{getFieldDisplay()}</div>;
};

export default CustomWorkFieldCell;
