// @ts-ignore
import multipleLocationsIcon from '@atom/components/common/svgIcons/multipleLocationsIcon.svg';
// @ts-ignore
import singleLocationIcon from '@atom/components/common/svgIcons/singleLocationIcon.svg';
import {
  AttributeGroupsItem,
  AttributesCollection,
  ElementGroupsTreeItem,
} from '@atom/types/inventory';
import { PolicyAction, PolicyGrant } from '@atom/types/policy';
import { TaskUserStatus } from '@atom/types/task';

import { GeoJSONGeometry } from './geojson';
import { Task, TaskField } from './task';
import { WorkTemplateListItem } from './workTemplate';

export enum WorkOrderTab {
  INFO = 'INFO',
  TASKS = 'TASKS',
  TEAM = 'TEAM',
  DOCUMENTS = 'DOCUMENTS',
  PHOTOS = 'PHOTOS',
  VIDEOS = 'VIDEOS',
}

export type WorkPriorityType = 0 | 1 | 2 | 3;

export enum WorkOrderFieldDataType {
  SUMMARY = 'summary',
  SHORT_TEXT = 'shorttext',
  LONG_TEXT = 'longtext',
  DATE = 'date',
  DATE_TIME = 'datetime',
  ENUM_SINGLE = 'enumsingle',
  ENUM_MULTIPLE = 'enummultiple',
  NUMBER = 'number',
  HYPERLINK = 'hyperlink',
}

export enum WorkOrderType {
  DEFAULT = 'DEFAULT',
  TASK_ASSETS_AND_LOCATIONS = 'TASK_ASSETS_AND_LOCATIONS',
}

export interface WorkOrderTypeOption {
  value: string;
  title: string;
  subtext: string;
  icon: any;
}

export const TYPE_OPTIONS = {
  [WorkOrderType.DEFAULT]: {
    value: WorkOrderType.DEFAULT,
    title: 'Work with a Single Asset and Location',
    subtext: 'Track a specific asset and location for the entire work item.',
    icon: singleLocationIcon,
  },
  [WorkOrderType.TASK_ASSETS_AND_LOCATIONS]: {
    value: WorkOrderType.TASK_ASSETS_AND_LOCATIONS,
    title: 'Work with Multiple Assets and Locations',
    subtext: 'Track assets and locations per task on a work item.',
    icon: multipleLocationsIcon,
  },
};

// TODO: [AM-5853] To remove here when it is removed from backend
export interface Summary {
  title: string;
  contents: string;
}

// InvalidTaskFields is a dictionary of invalid tasks with a
// Set of the invalid fieldIds
export interface InvalidTaskFields {
  [taskId: string]: Set<string>;
}

// Used in bulk work creation modals
export interface TaskRequiredValidation {
  isValid: boolean;
  invalidTaskFields?: InvalidTaskFields;
}

export interface TaskValidation {
  // for work with type TASK_ASSETS_AND_LOCATIONS and requireAtLeastOneLocation: true, this value will be true when a task does not have at least one location
  missingRequiredLocation?: boolean;
  // for work with type TASK_ASSETS_AND_LOCATIONS and requireAtLeastOneAsset: true or list of requiredMultiAssetSchemaIds,
  // this value will be true when a task does not have the require asset(s)
  missingRequiredAsset?: boolean;
  // Set of required custom fields that have no value
  invalidTaskFields?: Set<string>;
}

// TaskValidations is a dictionary of task ids to corresponding task validation objects
export interface TaskValidations {
  [taskId: string]: TaskValidation;
}

export interface WorkValidations {
  taskValidations?: TaskValidations;
}

export interface ValueDictionary {
  [index: string]: string;
}

export interface WorkOrderDropdownField {
  title?: string;
  dataType?: WorkOrderFieldDataType;
  enumeration: string[];
  defaultValue?: string;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderField {
  id: string;
  dataType: WorkOrderFieldDataType;
  title: string;
  value?: any;
  enumeration?: string[];
  defaultValue?: string;
  subFieldsTruncated?: boolean;
  subFields?: SubField[];
  units?: string;
  hyperlink?: string;
}

export interface WorkOrderFieldCreatePayload {
  dataType: WorkOrderFieldDataType;
  title: string;
  enumeration?: string[];
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderFieldUpdatePayload {
  fieldId: string;
  value?: any;
  required?: boolean;
  title?: string;
  enumeration?: string[];
  subFields?: SubField[];
  units?: string;
}

export interface SubField {
  matchValue: any;
  nestedField: any;
}

export interface WorkOrderFieldCreateInput {
  workOrderId: string;
  dataType: WorkOrderFieldDataType;
  title: string;
  required?: boolean;
  value?: any;
  enumeration?: string[];
  defaultValue?: string;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderFieldUpdateInput {
  workOrderId: string;
  fieldId: string;
  title?: string;
  required?: boolean;
  value?: any;
  enumeration?: string[];
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderFieldDeleteInput {
  workOrderId: string;
  fieldId: string;
}

export interface WorkCollectionItem {
  id: string;
  name: string;
  inventoryAssetName: string;
  statusId: string;
  priorityId: WorkPriorityType;
  isClosed: boolean;
  location: GeoJSONGeometry;
  dueDate: number;
  tasks: Task[];
}

export interface HydratedUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  employeeId?: string;
}

export interface WorkOrderDetailType {
  id?: string;
  type?: WorkOrderType;
  createdDate: number;
  createdBy?: string;
  createdByName?: string;
  updatedDate: number;
  updatedBy?: string;
  name?: string;
  originalName: string;
  inventoryAssetId?: string;
  inventoryAssetName?: string;
  inventoryAssetSchemaId?: string;
  statusId: string;
  isLocationBased: boolean;
  address: string;
  inventoryAssetLocation?: GeoJSONGeometry;
  inventoryAssetMarkerId?: number;
  inventoryAssetAddress?: string;
  inventoryAssetActions?: PolicyAction[];
  inventoryAssetGrants?: PolicyGrant[];
  priorityId: WorkPriorityType;
  isClosed?: boolean;
  mainPhotoId?: string;
  mainPhotoFileId?: string;
  leadAssigneeId?: string;
  leadAssigneeName?: string;
  leadAssigneeUser?: HydratedUser;
  closedDate: number;
  closedBy?: string;
  closedByName?: string;
  closedByUser?: HydratedUser;
  appliedBy?: string;
  appliedByName?: string;
  appliedDate: number;
  completedBy?: string;
  completedByName?: string;
  completedByUser?: HydratedUser;
  location?: GeoJSONGeometry;
  dueDate?: number;
  completionDate: number;
  estimatedCost?: number;
  startTime?: number;
  duration?: number;
  workTime?: number;
  actualCost?: number;
  summary?: Summary[];
  assets?: Object;
  multiAssets?: Object;
  formInstances?: Object;
  tasks: Task[];
  fieldOrder: string[];
  fields: WorkOrderField[];
  workTemplateId?: string;
  workTemplate?: WorkTemplateListItem;
  workTemplateName: string;
  workTemplateFolderPath: WorkTemplateFolderPath[];
  workTemplateFolderActions?: PolicyAction[];
  workTemplateFolderGrants?: PolicyGrant[];
  reopened: boolean;
}

export interface WorkTemplateFolderPath {
  id: string;
  name: string;
}

export interface WorkOrderAssetTreeElement {
  id?: string;
  name?: string;
  schemaId?: string;
  assetType?: string;
  markerId?: number;
  isNew: boolean;
  attributes?: AttributesCollection;
  attributeGroups?: AttributeGroupsItem[];
  elementGroups?: ElementGroupsTreeItem[];
  elements?: WorkOrderAssetTreeElement[];
  changeType?: string;
  includeChangesBefore?: number;
}

export interface WorkOrderAssetTreeType {
  id: string;
  name: string;
  assetType: string;
  isNew?: boolean;
  markerId?: number;
  attributes?: AttributesCollection;
  attributeGroups?: AttributeGroupsItem[];
  elements: WorkOrderAssetTreeElement[];
  elementGroups: ElementGroupsTreeItem[];
  changeType?: string;
  includeChangesBefore?: number;
}

export interface WorkOrderBulkCounts {
  totalAssets: number;
  totalWorkOrders: number;
}

export enum WorkOrderCustomDueDateUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum WorkOrderCustomDueDateStatus {
  Closed = 'Closed',
  Completed = 'Completed',
}

export interface WorkOrderCustomDueDate {
  duration: number;
  unit: WorkOrderCustomDueDateUnit;
  status: WorkOrderCustomDueDateStatus;
}

export interface WorkOrderBulkCreateInput {
  categoryId: string;
  workTemplateId: string;
  dueDate?: number;
  customDueDate?: WorkOrderCustomDueDate;
  schemaIds?: string[];
}

export interface WorkOrderUpdateInput {
  id: string;
  name?: string;
  dueDate?: any;
  location?: any;
  isLocationBased?: boolean;
  statusId?: string;
  isClosed?: boolean;
  priorityId?: WorkPriorityType;
  completionDate?: any;
  leadAssigneeId?: string;
  mainPhotoId?: string;
  fieldOrder?: string[];
}

export interface WorkOrderUpdate {
  id: string;
  name: string;
  dueDate: number;
  location: any;
  isLocationBased: boolean;
  statusId: string;
  priorityId: WorkPriorityType;
  completionDate: number;
  leadAssigneeId: string;
  mainPhotoId: string;
}

export interface WorkOrderFieldDataTypeOption {
  dataType: WorkOrderFieldDataType;
  text: string;
  icon: string;
}

export const WORK_ORDER_FIELD_DATA_TYPE_OPTIONS: readonly WorkOrderFieldDataTypeOption[] = Object.freeze(
  [
    {
      dataType: WorkOrderFieldDataType.SUMMARY,
      text: 'Title and Description',
      icon: 'title',
    },
    {
      dataType: WorkOrderFieldDataType.SHORT_TEXT,
      text: 'Short Text',
      icon: 'short_text',
    },
    {
      dataType: WorkOrderFieldDataType.LONG_TEXT,
      text: 'Long Text',
      icon: 'format_align_left',
    },
    {
      dataType: WorkOrderFieldDataType.DATE,
      text: 'Date Picker',
      icon: 'today',
    },
    {
      dataType: WorkOrderFieldDataType.DATE_TIME,
      text: 'Date and Time',
      icon: 'schedule',
    },
    {
      dataType: WorkOrderFieldDataType.ENUM_SINGLE,
      text: 'Single Select Dropdown',
      icon: 'arrow_drop_down_circle',
    },
    {
      dataType: WorkOrderFieldDataType.ENUM_MULTIPLE,
      text: 'Multi-Select Dropdown',
      icon: 'done_all',
    },
    {
      dataType: WorkOrderFieldDataType.NUMBER,
      text: 'Numeric',
      icon: 'looks_one',
    },
    {
      dataType: WorkOrderFieldDataType.HYPERLINK,
      text: 'Hyperlink',
      icon: 'link',
    },
  ],
);

export interface WorkOrdersConnection {
  totalCount: number;
  workOrders: WorkOrder[];
}

export interface WorkOrderUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  photoUrl: string;
}

export interface WorkOrderUserGroup {
  id: string;
  name: string;
  colorId: number;
}

export interface WorkOrderTaskUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  photoUrl: string;
  status: TaskUserStatus;
}

export interface WorkOrderTask {
  id: string;
  name: string;
  users: WorkOrderTaskUser[];
  userGroups: WorkOrderUserGroup[];
  formInstanceIds: string[];
  isCompleted: boolean;
  dueDate?: number;
  completedDate?: number;
  startTime?: number;
  duration?: number;
  fields: TaskField[];
  locationIds?: string[];
}

export interface WorkOrder {
  id: string;
  type?: WorkOrderType;
  name: string;
  priorityId: number;
  statusId: string;
  inventoryAssetId?: string;
  inventoryAssetName?: string;
  workTemplateId?: string;
  workTemplate?: WorkTemplateListItem;
  dueDate: number;
  completionDate?: number;
  completedBy?: WorkOrderUser;
  tasks: WorkOrderTask[];
  reopened: boolean;
  startTime?: number;
  duration?: number;
  createdDate?: number;
  createdBy?: WorkOrderUser;
  fields: WorkOrderField[];
}

export interface TaskFilter {
  dueDateStart?: number;
  dueDateEnd?: number;
  startDateStart?: number;
  startDateEnd?: number;
  completionDateStart?: number;
  completionDateEnd?: number;
  durationStart?: number;
  durationEnd?: number;
  commonFields?: FieldFilter[];
  each?: TaskFieldsFilter[];
}

export interface TaskFieldsFilter {
  id: string;
  fields: FieldFilter[];
}

export interface FieldFilter {
  id?: string;
  valueStart?: number;
  valueEnd?: number;
  value?: string;
  values?: string[];
}

export interface WorkOrdersConnectionInput {
  dueDateStart?: number;
  dueDateEnd?: number;
  withoutDueDate?: boolean;
  createdDateStart?: number;
  createdDateEnd?: number;
  createdByIds?: string[];
  startDateStart?: number;
  startDateEnd?: number;
  completionDateStart?: number;
  completionDateEnd?: number;
  durationStart?: number;
  durationEnd?: number;
  priorityIds?: number[];
  statusIds?: number[];
  workTemplateIds?: string[];
  tasks?: TaskFilter;
  fields?: FieldFilter[];
  userIds?: string[];
  groupIds?: string[];
  categoryIds?: string[];
  assetId?: string;
  completedByIds?: string[];
  hasWorkTemplate?: boolean;
  name?: string;
  assetSchemaId?: string;
  hasForms?: boolean;
  workTemplateFolderIds?: string[];
  sortBy?: string;
  page?: number;
  limit?: number;
}

export interface WorkOrderBulkField {
  id?: string;
  dataType: WorkOrderFieldDataType;
  title: string;
  value?: any;
  enumeration?: string[];
  defaultValue?: string;
  subFieldsTruncated?: boolean;
  subFields?: SubField[];
  units?: string;
}

export interface WorkOrderBulkTask {
  taskId: string;
  dueDate?: number;
  startDate?: number;
  fields?: WorkOrderBulkField[];
  userIds?: string[];
}

export interface WorkOrderCreateInput {
  name?: string;
  type?: WorkOrderType;
  workTemplateId?: string;
  inventoryAssetId?: string;
  location?: GeoJSONGeometry;
  dueDate?: number;
  summary?: Summary[];
}

export interface WorkOrderBulkMapCreateInput {
  workTemplateId: string;
  assetIds: string[];
  statusId: string;
  dueDate?: number;
  completionDate?: number;
  completedBy?: string;
  closedDate?: number;
  closedBy?: string;
  summary?: Summary[];
  fields?: WorkOrderBulkField[];
  tasks?: WorkOrderBulkTask[];
}

export interface WorkReportsConnection {
  totalCount: number;
  workReports: WorkReport[];
}

export interface WorkReport {
  workOrderId: string;
  taskId: string;
  acctId: string;
  divId: string;
  distId: string;
  roadwayClassId: string;
  funcId: string;
}
