import React from 'react';
import { useDispatch } from 'react-redux';

import * as inventorySchemaActions from '@atom/actions/inventorySchemaActions';
import { RadioGroup, Switch } from '@atom/mui';
import { InventorySchemaDetail } from '@atom/types/inventory';
import { StartEndReadingOption, StockBasedOption } from '@atom/types/schema';

import './cost.css';

const { RadioButton } = RadioGroup;

interface Props {
  schema: InventorySchemaDetail;
  value: StockBasedOption;
  setValue: (value: StockBasedOption) => void;
  setReadingsValue: (value: StartEndReadingOption) => void;
}

const StockDetails = ({ schema, value, setValue, setReadingsValue }: Props) => {
  const dispatch = useDispatch();

  const toggled =
    value === StockBasedOption.STOCK_BASED ||
    value === StockBasedOption.STOCK_BASED_OPTIONAL;

  const updateSchema = budget => {
    dispatch(
      inventorySchemaActions.requestUpdateSchema({
        schemaId: schema.id,
        rootSchemaId: schema.id,
        budget: {
          ...schema?.budget,
          ...budget,
          // Always disable start/end reading when the stock based option changes.
          startEndReadingOption: StartEndReadingOption.NONE,
        },
      }),
    );
  };

  const updateStockBasedOption = (option: StockBasedOption) => {
    setReadingsValue(StartEndReadingOption.NONE);
    setValue(option);
    updateSchema({ stockBasedOption: option });
  };

  const handleToggle = () => {
    setReadingsValue(StartEndReadingOption.NONE);

    if (value === StockBasedOption.NONE) {
      setValue(StockBasedOption.STOCK_BASED);
      return updateSchema({
        stockBasedOption: StockBasedOption.STOCK_BASED,
      });
    }

    setValue(StockBasedOption.NONE);
    return updateSchema({
      stockBasedOption: StockBasedOption.NONE,
    });
  };

  const isDisabled = schema.isPublished;

  return (
    <div styleName="cost-container">
      <Switch
        checked={toggled}
        onChange={handleToggle}
        label="Stock-based Inventory"
        disabled={isDisabled}
      />
      {toggled && (
        <RadioGroup
          value={value}
          onChange={event => updateStockBasedOption(event.target.value)}
        >
          <RadioButton
            value={StockBasedOption.STOCK_BASED}
            label="Default"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set an available inventory level and track usage for all inventory
            of this type.
          </div>
          <RadioButton
            value={StockBasedOption.STOCK_BASED_OPTIONAL}
            label="Optional"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Set an available inventory level and track usage at the individual
            inventory item level.
          </div>
        </RadioGroup>
      )}
    </div>
  );
};

export default StockDetails;
