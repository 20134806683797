import { SubField } from '@atom/types/work';

export enum TaskUserStatus {
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface TaskBudget {
  budgetId: string;
  name: string;
  rate: number;
  groupPath?: string[];
  restored: boolean;
  reopenedWorkOrderIds: string[];
}

export interface TaskUser {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  title: string;
  email: string;
  employeeId?: string;
  status?: TaskUserStatus;
  workTime?: number;
  actualCost?: number;
  budget?: TaskBudget;
}

export interface TaskFormInstanceItem {
  formTemplateId: string;
  id: string;
  isValid: boolean;
  name: string;
}

export interface TaskMaterialItem {
  assetId: string;
  name: string;
  colorId: number;
  quantity: number;
  cost: number;
  isTimeBased: boolean;
  isStockBased: boolean;
  isStartEndReading?: boolean;
  isStartEndCalculated?: boolean;
  rate: number;
  unit: string;
}

export interface TaskUserGroupItem {
  userGroupId: string;
  name: string;
  quantity: number;
  workTime: number;
  colorId: number;
  budget: TaskBudget;
}

export enum TaskFieldDataType {
  SHORT_TEXT = 'shorttext',
  LONG_TEXT = 'longtext',
  DATE = 'date',
  DATE_TIME = 'datetime',
  ENUM_SINGLE = 'enumsingle',
  ENUM_MULTIPLE = 'enummultiple',
  NUMBER = 'number',
  HYPERLINK = 'hyperlink',
}

export interface ValueDictionary {
  [index: string]: string;
}

export interface TaskDropdownField {
  title?: string;
  dataType?: TaskFieldDataType;
  enumeration: string[];
  defaultValue?: string;
  subFields?: SubField[];
  units?: string;
}

export interface TaskField {
  id: string;
  dataType: TaskFieldDataType;
  title: string;
  required: boolean;
  value?: any;
  defaultValue?: string;
  enumeration: string[];
  subFieldsTruncated?: boolean;
  subFields?: SubField[];
  units?: string;
  hyperlink?: string;
}

export interface TaskCategory {
  id: string;
  name: string;
  categoryPath: string[];
  colorId: string;
}

export interface TaskLocationItem {
  id: string;
  name: string;
}

export interface Task {
  id: string;
  name: string;
  originalName?: string;
  dueDate: number;
  createdDate: number;
  updatedDate: number;
  completionDate: number;
  createdBy: string;
  updatedBy: string;
  completedBy: string;
  completedByName: string;
  description: string;
  estimatedCost: number;
  startTime: number;
  workTime: number;
  actualCost: number;
  isEstimatedCostOverridden: boolean;
  isCompleted: boolean;
  users: TaskUser[];
  assetIds: string[];
  formInstanceIds: string[];
  materials: TaskMaterialItem[];
  userGroups: TaskUserGroupItem[];
  fieldOrder: string[];
  fields: TaskField[];
  duration: number;
  categories: TaskCategory[];
  taskTemplateId?: string;
  locations?: TaskLocationItem[];
  locationsEnabled?: boolean;
  requireAtLeastOneLocation?: boolean;
  multiAssetsEnabled?: boolean;
  allowedMultiAssetSchemaIds?: string[];
  requireAtLeastOneMultiAsset?: boolean;
  requiredMultiAssetSchemaIds?: string[];
}

export interface TaskCreateInput {
  workOrderId: string;
  templateId?: string;
  name?: string;
  dueDate?: number;
  description?: string;
  estimatedCost?: number;
}

export interface TaskUpdateInput {
  id: string;
  workOrderId: string;
  name?: string;
  dueDate?: number;
  description?: string;
  estimatedCost?: number;
  isCompleted?: boolean;
  assigneeIds?: string[];
  assetIds?: string[];
  formInstanceIds?: string[];
  startTime?: number;
  completionDate?: number;
  isEstimatedCostOverridden?: boolean;
  categoryIds?: string[];
  fieldOrder?: string[];
  taskTemplateId?: string;
}

export interface TaskUserGroupInputItem {
  userGroupId: string;
  budgetId?: string;
  quantity: number;
}

export interface TaskUserGroupsAddInput {
  id: string;
  workOrderId: string;
  userGroups: TaskUserGroupInputItem[];
}

export interface TaskUserGroupUpdateInput {
  id: string;
  workOrderId: string;
  userGroupId: string;
  budgetId?: string;
  quantity?: number;
  workTime?: number;
}

export interface TaskUserGroupRemoveInput {
  id: string;
  workOrderId: string;
  userGroupId: string;
}

export interface TaskUserInputItem {
  userId: string;
  budgetId?: string;
}

export interface TaskUsersAddInput {
  id: string;
  workOrderId: string;
  users: TaskUserInputItem[];
}

export interface TaskUserUpdateInput {
  id: string;
  workOrderId: string;
  userId: string;
  budgetId: string;
}

export interface TaskUserRemoveInput {
  id: string;
  workOrderId: string;
  userId: string;
}

export interface TaskFieldCreateInput {
  workOrderId: string;
  taskId: string;
  dataType: TaskFieldDataType;
  title: string;
  required?: boolean;
  value?: any;
  enumeration?: string[];
  defaultValue?: string;
  subFields?: SubField[];
  units?: string;
}

export interface TaskFieldCreatePayload {
  dataType: TaskFieldDataType;
  title: string;
  required: boolean;
  enumeration?: string[];
  defaultValue?: any;
  subFields?: SubField[];
  units?: string;
}

export interface TaskFieldUpdateInput {
  workOrderId: string;
  taskId: string;
  fieldId: string;
  title?: string;
  required?: boolean;
  value?: string[];
  enumeration?: string[];
  defaultValue?: string;
  subFields?: SubField[];
  units?: string;
  hyperlink?: string;
}

export interface TaskFieldUpdatePayload {
  fieldId: string;
  title?: string;
  required?: boolean;
  enumeration?: string[];
  subFields?: SubField[];
  units?: string;
}

export interface TaskFieldDeleteInput {
  workOrderId: string;
  taskId: string;
  fieldId: string;
}

export interface TaskMaterialsAddInput {
  workOrderId: string;
  taskId: string;
  assetIds: string[];
}

export interface TaskUsersStatusUpdateInput {
  workOrderId: string;
  taskId: string;
  users: Array<{ userId: string; status: TaskUserStatus }>;
}

export interface TaskAssetRemoveInput {
  workOrderId: string;
  taskId: string;
  assetId: string;
}

export interface TaskAssetsAddInput {
  workOrderId: string;
  taskId: string;
  assetIds: string[];
}

interface TaskFieldDataTypeOption {
  icon: string;
  text: string;
  dataType: TaskFieldDataType;
}

export const TASK_FIELD_DATA_TYPE_OPTIONS: readonly TaskFieldDataTypeOption[] = Object.freeze(
  [
    {
      dataType: TaskFieldDataType.SHORT_TEXT,
      text: 'Short Text',
      icon: 'short_text',
    },
    {
      dataType: TaskFieldDataType.LONG_TEXT,
      text: 'Long Text',
      icon: 'format_align_left',
    },
    {
      dataType: TaskFieldDataType.DATE,
      text: 'Date Picker',
      icon: 'today',
    },
    {
      dataType: TaskFieldDataType.DATE_TIME,
      text: 'Date and Time',
      icon: 'schedule',
    },
    {
      dataType: TaskFieldDataType.ENUM_SINGLE,
      text: 'Single Select Dropdown',
      icon: 'arrow_drop_down_circle',
    },
    {
      dataType: TaskFieldDataType.ENUM_MULTIPLE,
      text: 'Multi-Select Dropdown',
      icon: 'done_all',
    },
    {
      dataType: TaskFieldDataType.NUMBER,
      text: 'Numeric',
      icon: 'looks_one',
    },
    {
      dataType: TaskFieldDataType.HYPERLINK,
      text: 'Hyperlink',
      icon: 'link',
    },
  ],
);
