import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';

import client from '@atom/graph/client';
import {
  BULK_CREATE_POLICIES,
  GET_POLICIES_FOR_VALIDATION,
} from '@atom/graph/policy';
import { Modal } from '@atom/mui';
import {
  PoliciesConnection,
  PoliciesConnectionInput,
  Policy,
  PolicyCreateInput,
  PolicyResource,
  PolicySubjectType,
} from '@atom/types/policy';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RoleContext from '../../RoleContext';

import AnalyticsFolderSelection from './AnalyticsFolderSelect/AnalyticsFolderSelection';
import PolicyAssetSelection from './PolicyAssetSelection';
import PolicyCategorySelection from './PolicyCategorySelection';
import PolicyModalContext, {
  basePolicyModalStyle,
  initialState,
  PolicyModalState,
  policyModalStyles,
} from './PolicyModalContext';
import PolicyModalMain from './PolicyModalMain';
import PolicyModalTitle from './PolicyModalTitle';
import PolicyWorkTemplateFolderSelection from './PolicyWorkTemplateFolderSelection';

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreatePolicyModal = ({ open, onClose }: Props) => {
  const { role, refetch } = useContext(RoleContext);
  const [state, setState] = useState<PolicyModalState>(initialState);

  const { view, resource, grantType, grants, actions } = state;

  const updateState = useCallback((update: Partial<PolicyModalState>) => {
    setState(current => ({ ...current, ...update }));
  }, []);

  const resetState = () => {
    setState(initialState);
  };

  const getExistingPolicies = useCallback(async () => {
    updateState({ loadingExistingPolicies: true });

    const { data } = await client.query<
      { policies: PoliciesConnection },
      { input: PoliciesConnectionInput }
    >({
      query: GET_POLICIES_FOR_VALIDATION,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          subjectIds: [role.id],
          resources: [resource],
        },
      },
    });

    updateState({
      existingPolicies: data.policies.policies,
      loadingExistingPolicies: false,
    });
  }, [role.id, resource]);

  useEffect(() => {
    if (resource) {
      getExistingPolicies();
    }
  }, [resource]);

  const [createPolicies, { loading }] = useMutation<
    { policy: Policy },
    { input: PolicyCreateInput[] }
  >(BULK_CREATE_POLICIES);

  const handleSubmit = async () => {
    const policies = actions.map(
      (action): PolicyCreateInput => ({
        subjectType: PolicySubjectType.ROLE,
        subjectId: role.id,
        action,
        resource,
        grant: {
          type: grantType,
          ids: grants.map(({ id }) => id).join(','),
        },
      }),
    );

    await createPolicies({
      variables: {
        input: policies,
      },
    });

    onClose();
    refetch();
  };

  const isValid = useMemo(() => {
    return (
      !isNilOrEmpty(resource) && !isNilOrEmpty(grants) && !isNilOrEmpty(actions)
    );
  }, [resource, grants, actions]);

  const getContent = () => {
    switch (view) {
      case PolicyResource.INVENTORY_FOLDER: {
        return <PolicyCategorySelection />;
      }
      case PolicyResource.INVENTORY_ITEM: {
        return <PolicyAssetSelection />;
      }
      case PolicyResource.WORK_TYPE_FOLDER: {
        return <PolicyWorkTemplateFolderSelection />;
      }
      case PolicyResource.ANALYTICS_FOLDER: {
        return <AnalyticsFolderSelection />;
      }
      default: {
        return <PolicyModalMain isEdit={false} />;
      }
    }
  };

  return (
    <PolicyModalContext.Provider value={{ state, updateState }}>
      <Modal
        title={<PolicyModalTitle title="Add Policy" />}
        open={open}
        onCancel={onClose}
        onConfirm={handleSubmit}
        confirmButtonText="Save"
        contentStyle={policyModalStyles[view] || basePolicyModalStyle}
        loading={loading}
        onExited={resetState}
        disableFooter={view !== 'MAIN'}
        disabled={!isValid}
      >
        {getContent()}
      </Modal>
    </PolicyModalContext.Provider>
  );
};

export default CreatePolicyModal;
