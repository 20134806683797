import * as React from 'react';

import { SchemaType } from '@atom/types/schema';

import HeaderNavigationIcon from './HeaderNavigationIcon';

import './header.css';

interface PassedProps {
  activeView: string;
  setActiveView: (view: string) => void;
  navigateBack: () => void;
  schema: SchemaType;
}

type Props = PassedProps;

const locationViews = [
  {
    value: 'assetInfo',
    label: 'info',
    icon: 'info',
  },
  {
    value: 'subItems',
    label: 'sub items',
    icon: 'list',
  },
];

const materialViews = [
  {
    value: 'assetInfo',
    label: 'info',
    icon: 'info',
  },
  {
    value: 'cost',
    label: 'cost',
    icon: 'monetization_on',
  },
];

const DataManagementPreviewHeader = ({
  activeView,
  setActiveView,
  schema,
}: Props) => {
  const views = schema.isMaterial ? materialViews : locationViews;
  return (
    <div styleName="header-container">
      <div styleName="info-boxes-wrapper">
        <div styleName="info-box label">
          <div>Asset Name:</div>
          <div>Last Updated:</div>
        </div>
        <div styleName="info-box value">
          <div>-</div>
          <div>-</div>
        </div>
        <div styleName="info-box label">
          <div>Last Work ID:</div>
          <div>Last Work ID Updated:</div>
        </div>
        <div styleName="info-box value">
          <div>-</div>
          <div>-</div>
        </div>
      </div>
      <div styleName="icon-row">
        {views.map(view => {
          return (
            <HeaderNavigationIcon
              key={view.value}
              value={view.value}
              label={view.label}
              icon={view.icon}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DataManagementPreviewHeader;
