import React from 'react';

import {
  Budget,
  BudgetCategory,
  BudgetItem,
  BudgetItemTemplate,
  BudgetUnit,
} from '@atom/types/budget';

import { EditField } from './budgetDetailUtils';

interface Context {
  budget: Budget;
  parentBudgetUnit: BudgetUnit;
  setParentBudgetUnit: (budgetUnit: BudgetUnit) => void;
  childBudgetUnits: BudgetUnit[];
  setChildBudgetUnits: (budgetUnits: BudgetUnit[]) => void;
  budgetCategories: BudgetCategory[];
  setBudgetCategories: (budgetCategories: BudgetCategory[]) => void;
  unitsVisited: { id?: BudgetUnit };
  setUnitsVisited: (unitsVisited: { id?: BudgetUnit }) => void;
  categoryFilters: BudgetCategory[];
  setCategoryFilters: (categories: BudgetCategory[]) => void;
  categoryIds: string[];
  budgetItemTemplateFilters: BudgetItemTemplate[];
  setBudgetItemTemplateFilters: (id: BudgetItemTemplate[]) => void;
  budgetItemTemplateNames: string[];
  editingItem: BudgetItem;
  setEditingItem: (item: BudgetItem) => void;
  editingField: EditField;
  setEditingField: (field: EditField) => void;
  expandedCategories: Set<string>;
  setExpandedCategories: (ids: Set<string>) => void;
  excludeZeroBudgetItems: boolean;
  setExcludeZeroBudgetItems: (hide: boolean) => void;
}

export const initialState: Context = {
  budget: null,
  parentBudgetUnit: null,
  setParentBudgetUnit: () => {},
  childBudgetUnits: null,
  setChildBudgetUnits: () => {},
  budgetCategories: null,
  setBudgetCategories: () => {},
  unitsVisited: {},
  setUnitsVisited: () => {},
  categoryFilters: [],
  setCategoryFilters: () => {},
  categoryIds: [],
  budgetItemTemplateFilters: [],
  setBudgetItemTemplateFilters: () => {},
  budgetItemTemplateNames: [],
  editingItem: null,
  setEditingItem: () => {},
  editingField: null,
  setEditingField: () => {},
  expandedCategories: new Set([]),
  setExpandedCategories: () => {},
  excludeZeroBudgetItems: false,
  setExcludeZeroBudgetItems: () => {},
};

const BudgetDetailContext = React.createContext<Context>(initialState);

export default BudgetDetailContext;
