import * as React from 'react';

import DialogTrigger from '@atom/components/common/DialogTrigger';
import FontIconButton from '@atom/components/common/FontIconButton';
// @ts-ignore
import addItemIcon from '@atom/components/common/svgIcons/add_subItem.svg';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import {
  DetailElementGroupsItem,
  InventoryDetailRenamePayload,
} from '@atom/types/inventory';

import EditField from './EditField';

import './dataManagementDetail.css';

interface Props {
  group: DetailElementGroupsItem;
  groupInEditMode: string;
  parentSchemaId: string;
  isDisabled: boolean;
  onGroupDelete: (groupId: string) => boolean;
  addSchemaOrGroup: (
    parentSchemaId: string,
    group?: string,
    groupId?: string,
  ) => void;
  onGroupRenameSave: (payload: InventoryDetailRenamePayload) => void;
  onGroupRenameCancel: (payload: InventoryDetailRenamePayload) => void;
  updateGroupInEditMode: (id: string) => void;
}

const styles = {
  icon: {
    padding: 0,
    fontSize: 14,
    transform: 'rotate(90deg)',
  },
  toolIcon: {
    padding: 0,
    height: '35px',
    width: '35px',
  },
  iconBox: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  iconContainer: {
    padding: 0,
    height: '30px',
    width: '30px',
  },
  dialog: {
    delete: {
      contentStyle: {
        width: layout.modalWidth,
        paddingRight: '2rem',
        paddingBottom: '1rem',
      },
      titleStyle: {
        fontSize: fonts.xl,
        padding: '1.125em 1.125em 1.125em 1.25em',
        fontWeight: '500',
      },
      actionsContainerStyle: {
        paddingRight: '1rem',
        paddingBottom: '1rem',
      },
    },
  },
};

const DataManagementElementGroupRow = ({
  group,
  isDisabled,
  addSchemaOrGroup,
  onGroupDelete,
  parentSchemaId,
  groupInEditMode,
  onGroupRenameSave,
  onGroupRenameCancel,
  updateGroupInEditMode,
}: Props) => {
  const isInEditMode =
    group.id === groupInEditMode || group.name === 'Untitled';

  const leftIcon = (
    <IconButton style={styles.iconContainer}>
      <Icon color={colors.neutral.gray} style={styles.icon}>
        play_arrow
      </Icon>
    </IconButton>
  );

  const groupDisplay = (
    <React.Fragment>
      <div styleName="element-group-info-container">
        <div styleName="asset-row-icon">{leftIcon}</div>
        <div styleName="element-group-row-name">{group.name}</div>
      </div>
      {isDisabled ? (
        <div />
      ) : (
        <div styleName="element-buttons-container">
          <IconButton
            tooltip="Add Sub Item"
            style={styles.toolIcon}
            onClick={(): void =>
              addSchemaOrGroup(parentSchemaId, group.name, group.id)
            }
          >
            <div style={styles.iconBox}>
              <img src={addItemIcon} />
            </div>
          </IconButton>
          <FontIconButton
            color={colors.neutral.gray}
            style={styles.toolIcon}
            icon="edit"
            onClick={(): void => updateGroupInEditMode(group.id)}
            tooltip="Rename"
            tooltipPosition="bottom-left"
          />
          <DialogTrigger
            labelCancel="Cancel"
            labelConfirm="Delete"
            confirmAction={(): boolean => onGroupDelete(group.id)}
            title="Delete Group?"
            content={`Deleting ${group.name} will remove the group, but not the sub items.`}
            trigger={
              <FontIconButton
                color={colors.neutral.gray}
                style={styles.toolIcon}
                icon="delete"
                tooltip="Delete"
                tooltipPosition="bottom-left"
              />
            }
          />
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div styleName="element-group-row">
      {isInEditMode ? (
        <EditField
          id={group.id}
          name={group.name}
          leftIcon={leftIcon}
          onRenameSave={onGroupRenameSave}
          onRenameCancel={onGroupRenameCancel}
        />
      ) : (
        groupDisplay
      )}
    </div>
  );
};

export default DataManagementElementGroupRow;
