import * as React from 'react';
import * as R from 'ramda';

import { Progress } from '@atom/mui';
import { InventorySchemaDetail } from '@atom/types/inventory';
import history from '@atom/utilities/history';

import CostTab from './cost/CostTab';
import PreviewDetailTab from './detail/PreviewDetailTab';
import DataManagementPreviewHeader from './header/DataManagementPreviewHeader';
import PreviewAssetInfo from './info/PreviewAssetInfo';

import './dataManagementPreview.css';

interface State {
  activeView: string;
}

interface Props {
  schema: InventorySchemaDetail;
  loading: boolean;
}

class DataManagementPreview extends React.Component<Props, State> {
  state = {
    activeView: 'assetInfo',
  };

  setActiveView = (view: string) => {
    this.setState({ activeView: view });
  };

  navigateBack = (): void => {
    return history.goBackWithState();
  };

  setDetailBodyPane = (activeView: string) => {
    const { schema, loading } = this.props;

    const content = {
      assetInfo: <PreviewAssetInfo schema={schema} />,
      subItems: <PreviewDetailTab schema={schema} loading={loading} />,
      cost: <CostTab schema={schema} />,
    };

    return content[activeView];
  };

  render() {
    const { schema, loading } = this.props;
    const { activeView } = this.state;

    if (loading && R.isEmpty(schema)) {
      return (
        <div styleName="spinner-container">
          <Progress />
        </div>
      );
    }

    return (
      <>
        <DataManagementPreviewHeader
          activeView={activeView}
          setActiveView={this.setActiveView}
          navigateBack={this.navigateBack}
          // @ts-ignore
          schema={schema}
        />
        <div styleName="body-container">
          {this.setDetailBodyPane(activeView)}
        </div>
      </>
    );
  }
}

export default DataManagementPreview;
