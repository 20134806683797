import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';

import * as inventoryAssetActionCreators from '@atom/actions/inventoryAssetActions';
import { Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetActions } from '@atom/types/actions';
import {
  InventoryAssetDetailState,
  ReduxStore,
  UserProfileState,
} from '@atom/types/store';

import DetailTree from './DetailTree';
import DetailViewPane from './DetailViewPane';

import '../../../../styles/body-pane.css';

interface ReduxStateProps {
  inventoryAssetDetail: InventoryAssetDetailState;
  userProfile: UserProfileState;
  loadingAsset: boolean;
  loadingAssetAttributes: any[];
  loadingElementAttributes: any[];
  elementDetail: any;
}

interface ReduxDispatchProps {
  inventoryAssetActions: InventoryAssetActions;
}

type Props = ReduxStateProps & ReduxDispatchProps;

interface State {
  selectedItem: Object;
}

const styles = {
  leftPaneStyles: {
    borderRight: `1px solid ${colors.neutral.typhoon}`,
    boxSizing: 'border-box',
  },
  progress: {
    height: '100%',
  },
};

const initialState = {
  selectedItem: {},
};

class DetailTab extends React.Component<Props, State> {
  state = initialState;

  navigateToElement = (element: any) => {
    const { inventoryAssetActions } = this.props;

    inventoryAssetActions.retrieveInventoryElementDetail({
      id: element.id,
    });
  };

  navigateToAsset = (asset: any) => {
    const { inventoryAssetActions } = this.props;
    inventoryAssetActions.retrieveInventoryAssetDetail({ id: asset.id });
  };

  onPendingApproval = (action: string, element: object) => {
    const { inventoryAssetActions } = this.props;
    inventoryAssetActions.requestAssetPendingApproval({ action, element });
  };

  onToggle = (
    id: string,
    elementPath: any[],
    expanded: boolean,
    hasChildren: any,
  ) => {
    if (!hasChildren && !R.isNil(hasChildren)) {
      return;
    }

    const { inventoryAssetActions } = this.props;
    const data = {
      elementPath,
      data: {
        expanded: !expanded,
      },
    };

    inventoryAssetActions.requestInventoryAssetTreeNodeExpandedUpdate(data);

    if (id && elementPath.length > 2) {
      inventoryAssetActions.retrieveInventoryAssetTreeNode({ id, elementPath });
    }
  };

  updateSelectedItem = (item: Object) => {
    this.setState({ selectedItem: item });
  };

  onRename = (payload: Object) => {
    const { inventoryAssetActions } = this.props;

    inventoryAssetActions.requestInventoryAssetTreeNodeUpdate(payload);
  };

  onDelete = (payload: Object) => {
    const { inventoryAssetActions, inventoryAssetDetail } = this.props;

    inventoryAssetActions.requestInventoryAssetDeletion({
      ...payload,
      rootAssetId: inventoryAssetDetail.id,
    });
  };

  onBatchCreate = (payload: Object) => {
    const { inventoryAssetActions } = this.props;
    inventoryAssetActions.requestRootAssetBatchCreate(payload);
  };

  render() {
    const {
      inventoryAssetDetail,
      userProfile,
      loadingAsset,
      loadingElementAttributes,
      loadingAssetAttributes,
      elementDetail,
    } = this.props;
    const { selectedItem } = this.state;

    const loading =
      !R.isEmpty(loadingAssetAttributes) ||
      !R.isEmpty(loadingElementAttributes) ||
      loadingAsset;

    return (
      <div styleName="body-container">
        <div styleName="left-body-pane-half" style={styles.leftPaneStyles}>
          <DetailTree
            updateSelectedItem={this.updateSelectedItem}
            selectedItem={selectedItem}
            inventoryAssetDetail={inventoryAssetDetail}
            navigateToElement={this.navigateToElement}
            onRenameAction={this.onRename}
            onDeleteAction={this.onDelete}
            onToggle={this.onToggle}
            onBatchCreate={this.onBatchCreate}
            loading={loadingAsset}
            onPendingApproval={this.onPendingApproval}
            navigateToAsset={this.navigateToAsset}
          />
        </div>
        <div styleName="right-body-pane-half">
          {loading ? (
            <Progress style={styles.progress} />
          ) : (
            <DetailViewPane
              loadingAsset={loadingAsset}
              selectedItem={selectedItem}
              loadingAssetAttributes={loadingAssetAttributes}
              loadingElementAttributes={loadingElementAttributes}
              inventoryAssetDetail={inventoryAssetDetail}
              elementDetail={elementDetail}
              user={userProfile}
            />
          )}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  inventoryAssetDetail: state.inventoryAssetDetail,
  userProfile: state.userProfile,
  loadingAsset: state.loading.loadingInventoryElementDetail,
  loadingAssetAttributes: state.loading.loadingInventoryAssetAttributes,
  loadingElementAttributes: state.loading.loadingInventoryElementAttributes,
  elementDetail: state.inventoryAssetDetail.elementDetail,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventoryAssetActions: bindActionCreators(
    inventoryAssetActionCreators,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailTab);
