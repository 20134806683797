import { gql } from '@apollo/client';

export const BASE_BUDGET = gql`
  fragment BaseBudget on Budget {
    id
    name
    startDate
    endDate
    totalBudget
    templateId
    templateName
    rootBudgetUnitId
    createdBy {
      id
      firstName
      lastName
    }
    createdDate
  }
`;

export const GET_BUDGETS = gql`
  query budgets($input: BudgetsConnectionInput) {
    budgets(input: $input) {
      totalCount
      budgets {
        ...BaseBudget
      }
    }
  }
  ${BASE_BUDGET}
`;

export const GET_BUDGET = gql`
  query budget($id: ID!) {
    budget(id: $id) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const CREATE_BUDGET = gql`
  mutation budgetCreate($input: BudgetCreateInput!) {
    budgetCreate(input: $input) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const UPDATE_BUDGET = gql`
  mutation budgetUpdate($input: BudgetUpdateInput!) {
    budgetUpdate(input: $input) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const DUPLICATE_BUDGET = gql`
  mutation budgetDuplicate($input: BudgetDuplicateInput!) {
    budgetDuplicate(input: $input) {
      ...BaseBudget
    }
  }
  ${BASE_BUDGET}
`;

export const DELETE_BUDGET = gql`
  mutation budgetDelete($id: ID!) {
    budgetDelete(id: $id)
  }
`;

export const GET_BUDGET_TEMPLATES = gql`
  query budgetTemplates {
    budgetTemplates {
      totalCount
      budgetTemplates {
        id
        name
      }
    }
  }
`;

export const GET_BUDGET_CATEGORIES = gql`
  query budgetCategories($input: BudgetCategoriesConnectionInput!) {
    budgetCategories(input: $input) {
      totalCount
      budgetCategories {
        id
        name
        totalBudget
        budgetItems {
          id
          name
          budgetValue
          comment
          costType
        }
      }
    }
  }
`;

export const SEARCH_BUDGET_UNITS = gql`
  query budgetUnitSearch($input: BudgetUnitSearchInput!) {
    budgetUnitSearch(input: $input) {
      totalCount
      budgetUnits {
        id
        name
        ancestors {
          name
          id
        }
      }
    }
  }
`;

export const GET_BUDGET_UNITS = gql`
  query budgetUnits($input: BudgetUnitsConnectionInput!) {
    budgetUnits(input: $input) {
      totalCount
      budgetUnits {
        id
        name
        ancestors
        templateId
        totalBudget
        ancestors
        hasBudgetItems
      }
    }
  }
`;

export const GET_BUDGET_UNIT = gql`
  query budgetUnit($input: BudgetUnitConnectionInput!) {
    budgetUnit(input: $input) {
      id
      name
      templateId
      totalBudget
      ancestors
      hasBudgetItems
    }
  }
`;

export const GET_BUDGET_ITEM = gql`
  query budgetItem($id: ID!) {
    budgetItem(id: $id) {
      id
      name
      categoryId
      budgetUnitId
      type
      budgetValue
      comment
      costType
    }
  }
`;

export const UPDATE_BUDGET_ITEM = gql`
  mutation budgetItemUpdate($input: BudgetItemUpdateInput!) {
    budgetItemUpdate(input: $input) {
      id
      name
      budgetId
      budgetCategoryId
      budgetItemTemplateId
      budgetValue
      comment
      costType
    }
  }
`;

export const GET_BUDGET_CATEGORY_FILTERS = gql`
  query budgetCategoriesFilter($input: BudgetCategoriesFilterConnectionInput!) {
    budgetCategoriesFilter(input: $input) {
      totalCount
      budgetCategories {
        id
        name
      }
    }
  }
`;

export const GET_BUDGET_ITEM_TEMPLATE_FILTERS = gql`
  query budgetItemTemplatesFilter(
    $input: BudgetItemTemplatesFilterConnectionInput!
  ) {
    budgetItemTemplatesFilter(input: $input) {
      totalCount
      budgetItemTemplates {
        id
        name
      }
    }
  }
`;

export const GET_BUDGET_UNIT_TREE = gql`
  query budgetUnitTree($id: ID!) {
    budgetUnitTree(id: $id) {
      id
      name
      unitPath
      children
    }
  }
`;
