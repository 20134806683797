import React, { useState } from 'react';

import { Button, Icon, IconButton, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';

import PermissionsTable from './PermissionsTable';

import './permissionsTab.css';

const styles = {
  icon: {
    padding: '0px',
    height: '35px',
    width: '35px',
  },
  input: {
    display: 'none',
  },
  dialog: {
    bodyStyle: {
      margin: 0,
      padding: 0,
      width: layout.permissionDetailModalWidth,
      height: layout.permissionDetailModalHeight,
      maxWidth: layout.permissionDetailModalWidth,
      maxHeight: layout.permissionDetailModalHeight,
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    uploadPhotoButton: {
      float: 'left',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.25rem',
      fontWeight: '500',
      lineHeight: '20px',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
};

const PermissionsModal = () => {
  const [open, setOpen] = useState(false);

  const getFooter = () => {
    return <Button onClick={() => setOpen(false)}>done</Button>;
  };

  return (
    <>
      <IconButton tooltip="Permission Details" onClick={() => setOpen(true)}>
        <Icon color={colors.neutral.gray}>help_outline</Icon>
      </IconButton>
      <Modal
        width="xl"
        title="Permission Details"
        footer={getFooter()}
        style={styles.dialog.bodyStyle}
        open={open}
      >
        <PermissionsTable />
      </Modal>
    </>
  );
};

export default PermissionsModal;
