import React from 'react';

import DatePicker from '@atom/components/common/DatePicker';
import TextField from '@atom/components/common/TextField';
import { Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  FormInstanceFieldItem,
  PciModuleDataUpdate,
  PciModuleInspectionOptions,
  PciType,
} from '@atom/types/form';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { SchemaPavementConditionInspection } from '@atom/types/schema';

import './pci.css';

const { MenuItem } = Select;

interface Props {
  fieldId: string;
  field: FormInstanceFieldItem;
  inventoryAsset: InventoryAssetDetailType;
  preview: boolean;
  schemas: SchemaPavementConditionInspection[];
  pciType: PciType;
  onPciTypeChange: (type: PciType, options: PciModuleInspectionOptions) => void;
  onChange: (update: PciModuleDataUpdate) => void;
  isSummary?: boolean;
  isEditable?: boolean;
}

const styles = {
  inputFloatingLabel: {
    color: colors.neutral.dim,
    fontSize: '15px',
  },
  nameInput: {
    width: '10rem',
    marginRight: '2rem',
  },
  dateInput: {
    width: '8rem',
    marginRight: '2rem',
  },
  notesInput: {
    width: '35rem',
  },
  select: {
    width: '10rem',
  },
  menuItem: {
    color: colors.neutral.black,
    fontWeight: 500,
    fontSize: fonts.md,
  },
  menuItemSelected: {
    color: colors.brand.blue,
  },
};

const getPciTypes = (
  schemas: SchemaPavementConditionInspection[],
): string[] => {
  const types = new Set(
    schemas.reduce(
      (acc: string[], schema) => [...acc, ...Object.keys(schema.options)],
      [],
    ),
  );

  return [...types];
};

const getAttributeValue = (
  asset: InventoryAssetDetailType,
  attributeName: string,
): string => {
  if (!asset) {
    return '-';
  }

  const attribute = Object.values(asset.attributes || {}).find(
    ({ name }) => name === attributeName,
  );

  return attribute?.value || '-';
};

const PciFieldDetails = ({
  field,
  fieldId,
  inventoryAsset,
  preview,
  schemas,
  pciType,
  onPciTypeChange,
  onChange,
  isSummary,
  isEditable,
}: Props) => {
  const handlePciTypeChange = (type: PciType) => {
    const options = schemas.find(schema => schema.options[type])?.options[type];

    if (options) {
      onPciTypeChange(type, options);
    }
  };

  const handleChange = (property: keyof PciModuleDataUpdate) => (
    value: any,
  ) => {
    onChange({ fieldId, [property]: value });
  };

  return (
    <div styleName="details-container">
      <div styleName="subtitle">Details</div>
      <div styleName="detail-content">
        <div styleName="detail">
          <div>Road</div>
          <div styleName="detail-value">
            {getAttributeValue(inventoryAsset, 'Road')}
          </div>
        </div>
        <div styleName="detail">
          <div>Location</div>
          <div styleName="detail-value">
            {getAttributeValue(inventoryAsset, 'PCI Location')}
          </div>
        </div>
        <div styleName="detail">
          <div>Sample No</div>
          <div styleName="detail-value">
            {getAttributeValue(inventoryAsset, 'Sample No')}
          </div>
        </div>
        <div styleName="detail">
          <div>Surface</div>
          {preview ? (
            <Select
              value={pciType}
              onChange={event => handlePciTypeChange(event.target.value)}
              style={styles.select}
              disabled={!preview}
            >
              {getPciTypes(schemas).map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <div styleName="detail-value">{pciType}</div>
          )}
        </div>
        <div styleName="detail">
          <div>Length</div>
          <div styleName="detail-value">
            {getAttributeValue(inventoryAsset, 'Sample Length (Feet)')}
          </div>
        </div>
        <div styleName="detail">
          <div>Width</div>
          <div styleName="detail-value">
            {getAttributeValue(inventoryAsset, 'Width (Feet)')}
          </div>
        </div>
        <div styleName="detail">
          <div>Sample Area</div>
          <div styleName="detail-value">
            {getAttributeValue(inventoryAsset, 'Sample Area (Sq Ft)')}
          </div>
        </div>
      </div>
      {!isSummary && (
        <div styleName="inputs-container">
          <TextField
            floatingLabelFixed
            floatingLabelText="Name"
            name="name"
            style={styles.nameInput}
            floatingLabelStyle={styles.inputFloatingLabel}
            value={field?.pci?.name || ''}
            onChange={event => handleChange('name')(event.target.value)}
            disabled={!isEditable}
          />
          <div style={styles.dateInput}>
            <DatePicker
              valueMillis={field?.pci?.date || null}
              // eslint-disable-next-line @typescript-eslint/no-shadow
              onDataChange={(name, updatedDate) =>
                handleChange('date')(updatedDate)
              }
              floatingLabelText="Date"
              name="date"
              floatingLabelStyle={styles.inputFloatingLabel}
              floatingLabelFixed
              fullWidth
              disabled={!isEditable}
            />
          </div>
          <TextField
            multiLine
            floatingLabelFixed
            floatingLabelText="Notes"
            name="notes"
            style={styles.notesInput}
            floatingLabelStyle={styles.inputFloatingLabel}
            value={field?.pci?.notes || ''}
            onChange={event => handleChange('notes')(event.target.value)}
            disabled={!isEditable}
          />
        </div>
      )}
    </div>
  );
};

export default PciFieldDetails;
