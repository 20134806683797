import React, { useContext } from 'react';
import * as R from 'ramda';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { Collapse, List, Progress, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import fontStyles from '@atom/styles/fonts';
import layouts from '@atom/styles/layout';
import {
  SchemaTree,
  SchemaTreeAttribute,
  SchemaTreeAttributeGroup,
} from '@atom/types/schema';
import iconUtilities from '@atom/utilities/iconUtilities';

import './previewSubItems.css';

const { ListItemButton, ListItemText } = List;

const styles = {
  selectedListItem: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    fontSize: fontStyles.md,
    height: '3.125rem',
    paddingTop: '0.6rem',
  },
  listItem: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    fontSize: fontStyles.md,
    height: '3.125rem',
    paddingTop: '0.6rem',
  },
  assetListItem: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    fontSize: fontStyles.md,
  },
  selectedAssetListItem: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    fontSize: fontStyles.md,
  },
  innerItem: {
    paddingLeft: '3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row nowrap',
    alignItems: 'center',
  },
  iconStyles: {
    fontSize: fontStyles.xxxl,
    color: colors.neutral.gray,
  },
  rootListItemStyle: {
    paddingRight: '1.0rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '1rem',
  },
  rootListItemTextStyle: {
    lineHeight: fontStyles.md,
    fontSize: fontStyles.md,
    fontWeight: 0,
    paddingLeft: '0.50rem',
    color: colors.neutral.dark,
  },
  rootGroupItemTextStyle: {
    lineHeight: fontStyles.md,
    fontSize: fontStyles.md,
    textTransform: 'uppercase',
    paddingLeft: '0.50rem',
    color: colors.neutral.gray,
  },
  selectedNode: {
    boxSizing: 'border-box',
    backgroundColor: colors.utility.highlight,
    borderLeft: `3px solid ${colors.brand.blue}`,
    height: '2.5rem',
    paddingTop: '0.25rem',
  },
  folderNode: {
    boxSizing: 'border-box',
    borderLeft: '3px solid transparent',
    height: '2.5rem',
    paddingTop: '0.25rem',
  },
  elementNameContainer: {
    padding: '0',
  },
  elementLabel: {
    padding: '0',
    margin: '0',
    marginBottom: '2px',
  },
  assetType: {
    padding: '0',
    margin: '0',
    marginTop: '2px',
    color: colors.neutral.gray,
    fontSize: fontStyles.sm,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    position: 'absolute',
    maxWidth: '80%',
  },
  delete: {
    bodyStyle: {
      borderTop: 'initial',
      borderBottom: 'initial',
    },
    contentStyle: {
      width: layouts.modalWidth,
      paddingRight: '2rem',
      paddingBottom: '1rem',
    },
    titleStyle: {
      fontSize: fontStyles.xl,
      padding: '1.125em 1.125em 1.125em 1.25em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
};

interface Props {
  updateSelectedItem: (item: SchemaTree) => void;
  selectedItem: SchemaTree;
}

const PreviewDetailTree = ({ updateSelectedItem, selectedItem }: Props) => {
  const { schemaTree } = useContext(SchemaDetailContext);

  const getAttributeSubText = (element: SchemaTree): string => {
    if (
      R.isEmpty(element.attributeGroups) ||
      R.isNil(element.attributeGroups)
    ) {
      return '';
    }

    const attributes = element.attributeGroups.reduce(
      (
        acc: SchemaTreeAttribute[],
        group: SchemaTreeAttributeGroup,
      ): SchemaTreeAttribute[] => {
        return [...acc, ...group.attributes];
      },
      [],
    );

    return attributes.reduce(
      (acc: string, attribute: SchemaTreeAttribute): string => {
        if (!attribute.isVisibleAsSubtext) {
          return acc;
        }

        const label = attribute.name;
        return acc ? `${acc}, ${label}` : label;
      },
      '',
    );
  };

  const renderItems = (items: any[], level: number = 0) => {
    return items.map((item: any, index: number) => {
      const isGroup = R.isNil(item.assetType);
      const elements = R.pathOr([], ['elements'], item);
      const elementGroups = R.pathOr([], ['elementGroups'], item);

      const children = [...elements, ...elementGroups];

      const rootStyles =
        selectedItem.id === item.id && !isGroup
          ? styles.selectedListItem
          : styles.listItem;

      const groupIcon = iconUtilities.leftIcon('down-arrow');
      const elementIcon = R.isEmpty(children)
        ? iconUtilities.getElementIcon(item.markerId, false)
        : iconUtilities.leftElementIcon(item.markerId, 'down-arrow', false);

      const icon = isGroup ? groupIcon : elementIcon;

      const subText = getAttributeSubText(item);

      const onClick = isGroup ? () => {} : (): void => updateSelectedItem(item);

      const primaryText = !isGroup ? (
        <div style={styles.elementNameContainer}>
          <p style={styles.elementLabel}>{item.name}</p>
          {!R.isEmpty(subText) && (
            <Tooltip
              lightTooltip
              title={subText.replace(/,/g, ';')}
              placement="right-start"
            >
              <p style={styles.assetType}>{subText}</p>
            </Tooltip>
          )}
        </div>
      ) : (
        item.name
      );

      return (
        <React.Fragment key={index}>
          <ListItemButton
            style={{ ...rootStyles, paddingLeft: `${level * 1.5}rem` }}
            onClick={onClick}
          >
            <div style={styles.rootListItemStyle}>
              {icon}
              {isGroup ? (
                <ListItemText
                  secondaryTextStyle={styles.rootGroupItemTextStyle}
                  secondary={primaryText}
                />
              ) : (
                <ListItemText
                  primaryTextStyle={styles.rootListItemTextStyle}
                  primary={primaryText}
                />
              )}
            </div>
          </ListItemButton>
          <Collapse in timeout="auto" unmountOnExit>
            {renderItems(children, level + 1)}
          </Collapse>
        </React.Fragment>
      );
    });
  };

  const allItems = [...schemaTree.elements, ...schemaTree.elementGroups];
  const topLevelListItems = renderItems(allItems);

  return R.isEmpty(schemaTree) ? (
    <Progress style={{ height: '100%' }} />
  ) : (
    <>
      <div styleName="tree-header">Inventory</div>
      <List disablePadding>{topLevelListItems}</List>
    </>
  );
};

export default PreviewDetailTree;
