import { TaskFieldDataType } from '@atom/types/task';
import { WorkOrderType } from '@atom/types/work';

import { MappedField } from './reportUtilities';

export enum AldotCustomCascadeTitle {
  MAINTENANCE_TYPE = 'Maintenance Type',
  MANAGEMENT_UNIT = 'Management Unit (where work is performed)',
  VENDOR_NAME = 'Vendor Name',
  EVENT_RELATED = 'Event Related?',
}

export const aldotCustomCascadeValues = {
  [AldotCustomCascadeTitle.MAINTENANCE_TYPE]: [
    {
      id: 'Maintenance Type',
      title: 'Maintenance Type',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Project Reference ID',
      title: 'Project Reference ID',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Event ID',
      title: 'Event ID',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Site ID',
      title: 'Site ID',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
  ],
  [AldotCustomCascadeTitle.MANAGEMENT_UNIT]: [
    {
      id: 'Management Unit (where work is performed)',
      title: 'Management Unit (where work is performed)',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'County',
      title: 'County',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Road Class',
      title: 'Road Class',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Route',
      title: 'Route',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Direction',
      title: 'Direction',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
  ],
  [AldotCustomCascadeTitle.VENDOR_NAME]: [
    {
      id: 'Vendor Name',
      title: 'Vendor Name',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'PO number',
      title: 'PO number',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'PO Line',
      title: 'PO Line',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Activity Code',
      title: 'Activity Code',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Unit Cost',
      title: 'Unit Cost',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Unit of Measure',
      title: 'Unit of Measure',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
  ],
  [AldotCustomCascadeTitle.EVENT_RELATED]: [
    {
      id: 'Event Related?',
      title: 'Event Related?',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Event ID',
      title: 'Event ID',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
    {
      id: 'Site ID',
      title: 'Site ID',
      dataType: TaskFieldDataType.SHORT_TEXT,
      value: '',
    },
  ],
};

export const EMPTY_TASK = {
  id: 'empty',
  name: 'Activity Code:',
  users: [],
  materials: [],
};

export const MULTI_EMPTY_TASK_FIELDS: MappedField[] = [
  {
    id: 'Asset Type',
    title: 'Asset Type',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Maintenance Type',
    title: 'Maintenance Type',
    required: true,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Project Reference ID',
    title: 'Project Reference ID',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Event ID',
    title: 'Event ID',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Site ID',
    title: 'Site ID',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Accident Related?',
    title: 'Accident Related?',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Damage to State Property?',
    title: 'Damage to State Property?',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Insurance Claim?',
    title: 'Insurance Claim?',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Accomplishment (unit)',
    title: 'Accomplishment (unit)',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
];

export const EMPTY_TASK_FIELDS: MappedField[] = [
  {
    id: 'Asset Type',
    title: 'Asset Type',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Maintenance Type',
    title: 'Maintenance Type',
    required: true,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Project Reference ID',
    title: 'Project Reference ID',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Event ID',
    title: 'Event ID',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Site ID',
    title: 'Site ID',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Accident Related?',
    title: 'Accident Related?',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Damage to State Property?',
    title: 'Damage to State Property?',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Insurance Claim?',
    title: 'Insurance Claim?',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Management Unit',
    title: 'Management Unit',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'County',
    title: 'County',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Road Class',
    title: 'Road Class',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Route',
    title: 'Route',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Direction',
    title: 'Direction',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Beginning Milepost',
    title: 'Beginning Milepost',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'End Milepost',
    title: 'End Milepost',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
  {
    id: 'Accomplishment (unit)',
    title: 'Accomplishment (unit)',
    required: false,
    dataType: TaskFieldDataType.SHORT_TEXT,
    value: '',
  },
];

export const getEmptyTaskFields = (type: WorkOrderType) => {
  return type === WorkOrderType.TASK_ASSETS_AND_LOCATIONS
    ? MULTI_EMPTY_TASK_FIELDS
    : EMPTY_TASK_FIELDS;
};
