import * as React from 'react';
import { Control } from 'react-redux-form';

import DatePicker from '../common/DatePicker';

import './attributeControls.css';

interface Props {
  name: string;
  value: any;
  onChange: (date: number) => void;
  isEditable: boolean;
  onUpdate?: (hasUnsavedChanges: boolean) => void;
}

interface State {
  value: any;
}

const style = {
  root: {
    marginTop: '-0.5rem',
  },
};

export class DatePickerWrapper extends React.Component<Props> {
  onChange = (event, date) => this.props.onChange(date);

  render() {
    return (
      // @ts-ignore
      <DatePicker
        {...this.props}
        onDataChange={this.onChange}
        valueMillis={this.props.value}
      />
    );
  }
}

class DateAttribute extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? this.convertToMillis(props.value) : {},
    };
  }

  convertToMillis = dateString => {
    return dateString.valueOf();
  };

  onChange = value => {
    if (this.props.onUpdate) {
      this.props.onUpdate(true);
    }
    this.setState({ value });
  };

  render() {
    const { name, isEditable } = this.props;
    const { value } = this.state;

    return (
      <div key={name}>
        {/* 
          // @ts-ignore */}
        <Control
          model={`.${name}`}
          component={DatePickerWrapper}
          value={value}
          onChange={date => this.onChange(date)}
          disabled={!isEditable}
          mapProps={{
            fullWidth: true,
            name,
            selected: value,
            style: style.root,
          }}
        />
      </div>
    );
  }
}

export default DateAttribute;
