import React from 'react';
import { makeStyles } from '@mui/styles';

import { DatePicker, DateTimePicker } from '@atom/mui';
import colors from '@atom/styles/colors';
import { TaskField } from '@atom/types/task';
import { WorkOrderField, WorkOrderFieldDataType } from '@atom/types/work';

import './workOrdersFilters.css';

const styles = {
  dateTime: {
    flex: 0.47,
  },
  dateSeparator: {
    margin: '0 1rem',
    paddingBottom: '0.5rem',
  },
  dateTimeSeparator: {
    paddingBottom: '0.5rem',
  },
  label: {
    color: colors.neutral.dim,
  },
};

const useClasses = makeStyles({
  label: {
    position: 'unset',
    color: `${colors.neutral.dim} !important`,
  },
});

interface Props {
  field: WorkOrderField | TaskField;
  startDateValue: any;
  endDateValue: any;
  onStartDateChange: (id: string, value: any) => void;
  onEndDateChange: (id: string, value: any) => void;
}

const WorkOrdersFiltersDatePicker = ({
  field,
  startDateValue,
  endDateValue,
  onStartDateChange,
  onEndDateChange,
}: Props) => {
  const classes = useClasses();

  return field.dataType === WorkOrderFieldDataType.DATE ? (
    <div styleName="filters-date-range">
      <DatePicker
        label={field.title}
        value={startDateValue}
        onChange={date => onStartDateChange(field.id, date)}
        placeholder="mm/dd/yyyy"
        TextFieldProps={{
          InputLabelProps: {
            classes: { root: classes.label },
          },
        }}
      />
      <span style={styles.dateSeparator}>to</span>
      <DatePicker
        value={endDateValue}
        onChange={date => onEndDateChange(field.id, date)}
        placeholder="mm/dd/yyyy"
        TextFieldProps={{
          InputLabelProps: { style: { visibility: 'hidden' } },
        }}
      />
    </div>
  ) : (
    <div>
      <DateTimePicker
        label={field.title}
        value={startDateValue}
        onChange={date => onStartDateChange(field.id, date)}
        style={styles.dateTime}
        placeholder="mm/dd/yyyy --:-- --"
        TextFieldProps={{
          InputLabelProps: {
            classes: { root: classes.label },
          },
        }}
        InputProps={{
          style: styles.label,
        }}
      />
      <span style={styles.dateTimeSeparator}>to</span>
      <DateTimePicker
        value={endDateValue}
        onChange={date => onEndDateChange(field.id, date)}
        style={styles.dateTime}
        placeholder="mm/dd/yyyy --:-- --"
        InputProps={{
          style: styles.label,
        }}
      />
    </div>
  );
};

export default WorkOrdersFiltersDatePicker;
