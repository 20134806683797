import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import TimeEntryStatusIcon from '@atom/components/common/timeEntryStatusIcon/TimeEntryStatusIcon';
import TeamContext from '@atom/components/teamPortal/TeamContext';
import { TASK_USERS_STATUS_UPDATE } from '@atom/graph/task';
import {
  GET_TIME_ENTRIES_STATUS,
  TIME_ENTRIES_BULK_CREATE,
} from '@atom/graph/timeEntry';
import { GET_USER } from '@atom/graph/user';
import { usePreferences } from '@atom/hooks/usePreferences';
import {
  Button,
  Icon,
  IconButton,
  ListTable,
  Progress,
  Select,
  TextField,
} from '@atom/mui';
import { canUserCheckInToTask } from '@atom/selectors/taskSelectors';
import { userBudgetsSelector } from '@atom/selectors/userSelectors';
import { TimeEntryType } from '@atom/types/preferences';
import { TaskUsersStatusUpdateInput, TaskUserStatus } from '@atom/types/task';
import {
  TimeEntriesConnectionInput,
  TimeEntriesStatusConnection,
  TimeEntryCreateInput,
  TimeEntryStatus,
} from '@atom/types/timeEntry';
import { UserDetail, UserInput } from '@atom/types/user';
import { WorkOrder, WorkOrderDetailType } from '@atom/types/work';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { timeSheetTableWidths } from '@atom/utilities/timeSheetUtilities';
import {
  convertHoursToMillis,
  roundTimeEntryDuration,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import WorkOrderSearch from './WorkOrderSearch';
import WorkTemplateModal from './WorkTemplateModal';

import './timeSheet.css';

const { TableRow, TableCell } = ListTable;
const { MenuItem } = Select;

const styles = {
  workInfo: {
    minWidth: timeSheetTableWidths.work,
  },
  smallCell: {
    padding: '8px 4px',
  },
  progress: {
    marginLeft: '12px',
  },
  start: {
    padding: '8px',
  },
};

interface DateDurationMap {
  [key: number]: any;
}

interface Props {
  userId: string;
  selectedPayPeriodWeek: number;
  setCreateTimeEntry: (createTimeEntry: boolean) => void;
  refetchTimeSheet: () => void;
  newWorkOrder: WorkOrderDetailType;
}

const TimeSheetTableCreateRow = ({
  userId,
  setCreateTimeEntry,
  selectedPayPeriodWeek,
  refetchTimeSheet,
  newWorkOrder,
}: Props) => {
  const preferences = usePreferences();
  const { payPeriodWeeks, selectedPayPeriod } = useContext(TeamContext);

  const initialDateDuration = R.flatten(payPeriodWeeks).reduce(
    (acc, day) => ({ ...acc, [day]: '' }),
    {},
  );

  const [selectedWorkTemplate, setSelectedWorkTemplate] = useState<any>();
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<
    WorkOrder | WorkOrderDetailType
  >();
  const [taskId, setTaskId] = useState<string>('');
  const [userGroupId, setUserGroupId] = useState<string>('');
  const [selectedBudgetId, setSelectedBudgetId] = useState<string>('');
  const [selectedType, setSelectedType] = useState<TimeEntryType>();
  const [dateDuration, setDateDuration] = useState<DateDurationMap>(
    initialDateDuration,
  );
  const [openWorkTemplateModal, setOpenWorkTemplateModal] = useState<boolean>(
    false,
  );

  const [
    getTimeEntriesStatus,
    { loading: timeEntriesStatusLoading, data: timeEntriesStatusData },
  ] = useLazyQuery<
    { timeEntries: TimeEntriesStatusConnection },
    { input: TimeEntriesConnectionInput }
  >(GET_TIME_ENTRIES_STATUS, {
    fetchPolicy: 'network-only',
  });

  const { loading: userLoading, data: userData } = useQuery<
    { user: UserDetail },
    { id: string; input: UserInput }
  >(GET_USER, {
    variables: {
      id: userId,
      input: {
        includeRestoredBudgets: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [timeEntriesBulkCreate] = useMutation<
    { timeEntriesBulkCreate: boolean },
    { input: TimeEntryCreateInput[] }
  >(TIME_ENTRIES_BULK_CREATE);

  const [updateUsersTaskStatus] = useMutation<
    { taskUsersStatusUpdate: boolean },
    { input: TaskUsersStatusUpdateInput }
  >(TASK_USERS_STATUS_UPDATE);

  useEffect(() => {
    getTimeEntriesStatus({
      variables: {
        input: {
          userId,
          userGroupId,
          dateStart: selectedPayPeriod?.startDate,
          dateEnd: selectedPayPeriod?.endDate,
        },
      },
    });
  }, [userGroupId]);

  useEffect(() => {
    if (!newWorkOrder) {
      setSelectedWorkOrder(null);
      setTaskId('');
    }
  }, [selectedWorkTemplate?.id]);

  useEffect(() => {
    setTaskId('');
  }, [selectedWorkOrder?.id]);

  useEffect(() => {
    setSelectedWorkTemplate({
      id: newWorkOrder?.workTemplateId,
      name: newWorkOrder?.workTemplateName,
    });
    setSelectedWorkOrder(newWorkOrder);
  }, [newWorkOrder]);

  useEffect(() => {
    if (userData?.user) {
      const newUserGroupId =
        R.pathOr([], ['user', 'userGroups'], userData).length === 1
          ? userData?.user?.userGroups[0]?.id
          : '';

      setUserGroupId(newUserGroupId);
    }
  }, [userData?.user]);

  useEffect(() => {
    if (selectedWorkOrder) {
      const newTaskId =
        R.pathOr([], ['tasks'], selectedWorkOrder).length === 1
          ? selectedWorkOrder?.tasks[0]?.id
          : '';

      setTaskId(newTaskId);
    }
  }, [selectedWorkOrder]);

  const resetState = () => {
    setCreateTimeEntry(false);
    setSelectedWorkTemplate(null);
    setSelectedWorkOrder(null);
    setTaskId('');
    setUserGroupId('');
    setDateDuration(initialDateDuration);
    setSelectedType(null);
    setOpenWorkTemplateModal(false);
  };

  const handleDuration = (duration: number, date: number) => {
    if (isNilOrEmpty(duration)) {
      setDateDuration({ ...dateDuration, [date]: '' });
    } else if (duration >= 0) {
      setDateDuration({ ...dateDuration, [date]: duration });
    }
  };

  const onDurationBlur = (date: number) => {
    if (!isNilOrEmpty(dateDuration[date])) {
      const roundedValue = roundTimeEntryDuration(dateDuration[date]);

      setDateDuration({ ...dateDuration, [date]: roundedValue });
    }
  };

  const onCancel = () => {
    resetState();
  };

  const buildTimeEntriesBulkCreateInput = (): TimeEntryCreateInput[] => {
    return R.keys(dateDuration).reduce((acc, date) => {
      const input = {
        userId,
        workOrderId: selectedWorkOrder?.id,
        taskId,
        userGroupId,
        date: Number(date),
        duration: convertHoursToMillis(dateDuration[date]),
        ...(!preferences?.timeTracking?.computedBudgets &&
          !R.isEmpty(selectedBudgetId) && {
            budgetId: selectedBudgetId,
          }),
        ...(!isNilOrEmpty(preferences?.timeTracking?.typeEnumeration) && {
          type: selectedType,
        }),
      };

      return isNilOrEmpty(dateDuration[date]) ? acc : [...acc, input];
    }, []);
  };

  const handleTimeEntryCreate = async () => {
    const timeEntriesBulkCreateInput = buildTimeEntriesBulkCreateInput();

    await timeEntriesBulkCreate({
      variables: {
        input: timeEntriesBulkCreateInput,
      },
    });

    const selectedTask = R.pathOr([], ['tasks'], selectedWorkOrder).find(
      task => task.id === taskId,
    );

    if (canUserCheckInToTask(selectedTask, userId)) {
      await updateUsersTaskStatus({
        variables: {
          input: {
            workOrderId: selectedWorkOrder.id,
            taskId,
            users: [
              {
                userId,
                status: TaskUserStatus.IN_PROGRESS,
              },
            ],
          },
        },
      });
    }

    refetchTimeSheet();
    resetState();
  };

  const currentWeekDates = payPeriodWeeks[selectedPayPeriodWeek];

  const typeOptions = preferences?.timeTracking?.typeEnumeration;
  const hasTypeOptions = !isNilOrEmpty(typeOptions);

  const hideBudgetSelection = preferences?.timeTracking?.computedBudgets;
  const budgets = userBudgetsSelector(userData?.user);

  const budgetOptions = useMemo(() => {
    const options = budgets.filter(
      budget =>
        !budget.restored ||
        (budget.restored &&
          budget.reopenedWorkOrderIds.includes(selectedWorkOrder?.id)),
    );

    return [{ id: '', name: '', rate: 0 }, ...options];
  }, [budgets, selectedWorkOrder]);

  const userGroupIsApproved =
    timeEntriesStatusData?.timeEntries?.status === TimeEntryStatus.approved;

  const getHasAtLeastOneDuration = () => {
    return R.keys(dateDuration).reduce((acc, date) => {
      return acc || !isNilOrEmpty(dateDuration[date]);
    }, false);
  };

  const hasAtLeastOneDuration = getHasAtLeastOneDuration();

  const isSaveDisabled =
    isNilOrEmpty(selectedWorkOrder) ||
    isNilOrEmpty(taskId) ||
    isNilOrEmpty(userGroupId) ||
    !hasAtLeastOneDuration ||
    timeEntriesStatusLoading ||
    userGroupIsApproved ||
    (hasTypeOptions && R.isNil(selectedType));

  const isWorkOrderSearchDisabled = isNilOrEmpty(selectedWorkTemplate?.id);
  const isTaskSearchDisabled =
    isNilOrEmpty(selectedWorkTemplate?.id) || isNilOrEmpty(selectedWorkOrder);

  const taskOptions = R.pathOr([], ['tasks'], selectedWorkOrder);

  return (
    <>
      <TableRow key="create" height="120px">
        <TableCell
          width={timeSheetTableWidths.status}
          style={styles.start}
          center
        >
          <TimeEntryStatusIcon status={TimeEntryStatus.draft} />
        </TableCell>
        <TableCell width={timeSheetTableWidths.workType}>
          {isNilOrEmpty(selectedWorkTemplate?.id) ? (
            <Button onClick={() => setOpenWorkTemplateModal(true)}>
              select
            </Button>
          ) : (
            <div>
              <div>{selectedWorkTemplate.name}</div>
              <div
                styleName="work-template-clear-button"
                onClick={() => setSelectedWorkTemplate(null)}
              >
                clear
              </div>
            </div>
          )}
        </TableCell>
        <TableCell style={styles.workInfo}>
          <WorkOrderSearch
            workTemplateId={selectedWorkTemplate?.id}
            selectedWorkOrder={selectedWorkOrder}
            setSelectedWorkOrder={setSelectedWorkOrder}
            disabled={isWorkOrderSearchDisabled}
          />
        </TableCell>
        <TableCell width={timeSheetTableWidths.task}>
          <Select
            fullWidth
            value={taskId}
            onChange={event => setTaskId(event.target.value)}
            disabled={isTaskSearchDisabled}
            defaultValue=""
          >
            {taskOptions.map(task => {
              return (
                <MenuItem key={task.id} value={task.id}>
                  {task.name}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
        <TableCell width={timeSheetTableWidths.userGroup}>
          {!userLoading && (
            <Select
              fullWidth
              value={userGroupId}
              onChange={event => setUserGroupId(event.target.value)}
              defaultValue=""
              error={userGroupIsApproved}
              helperText={
                userGroupIsApproved &&
                'Cannot add time to an approved timesheet.'
              }
              data-cy="timeEntryCreateGroupDropdown"
            >
              {userData?.user?.userGroups.map(userGroup => {
                const primaryText = userGroup.groupPath.join(' / ');

                return (
                  <MenuItem key={userGroup.id} value={userGroup.id}>
                    {primaryText}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </TableCell>
        <TableCell width={timeSheetTableWidths.budget}>
          {hideBudgetSelection
            ? hasTypeOptions && (
                <Select
                  fullWidth
                  value={selectedType}
                  onChange={event => setSelectedType(event.target.value)}
                  defaultValue=""
                  data-cy="timeEntryCreateBudgetDropdown"
                >
                  {typeOptions.map(type => {
                    const primaryText = type.name;

                    return (
                      <MenuItem key={type.value} value={type.value}>
                        <span styleName="type-option">{primaryText}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              )
            : !userLoading && (
                <Select
                  fullWidth
                  value={selectedBudgetId}
                  onChange={event => setSelectedBudgetId(event.target.value)}
                  defaultValue=""
                  disabled={isNilOrEmpty(selectedWorkOrder)}
                >
                  {budgetOptions.map(option => {
                    const primaryText = option.id ? (
                      <>
                        <span>
                          {`${option.name}: ${numberToLocaleString(
                            option.rate,
                          )}/Hours `}
                        </span>
                        {option.restored && (
                          <span styleName="snapshot-suffix">(snapshot)</span>
                        )}
                      </>
                    ) : (
                      'No Budget'
                    );

                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {primaryText}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
        </TableCell>
        <TableCell
          width={timeSheetTableWidths.small}
          center
          style={styles.smallCell}
        />
        {currentWeekDates.map(date => {
          return (
            <TableCell
              width={timeSheetTableWidths.small}
              center
              style={styles.smallCell}
              key={date}
            >
              <TextField
                type="text"
                inputMode="numeric"
                value={dateDuration[date]}
                onChange={event => handleDuration(event.target.value, date)}
                onBlur={() => onDurationBlur(date)}
              />
            </TableCell>
          );
        })}
        <TableCell
          width={timeSheetTableWidths.small}
          center
          style={styles.smallCell}
        />
        <TableCell
          width={timeSheetTableWidths.action}
          style={styles.smallCell}
          center
        >
          <IconButton
            onClick={onCancel}
            size="small"
            data-cy="timeEntryCreateCloseButton"
          >
            <Icon>close</Icon>
          </IconButton>
        </TableCell>
        <TableCell
          width={timeSheetTableWidths.action}
          style={styles.smallCell}
          center
        >
          {timeEntriesStatusLoading ? (
            <Progress size={20} style={styles.progress} />
          ) : (
            <IconButton
              onClick={handleTimeEntryCreate}
              disabled={isSaveDisabled}
              size="small"
              data-cy="timeEntryCreateDoneButton"
            >
              <Icon>done</Icon>
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <WorkTemplateModal
        open={openWorkTemplateModal}
        onClose={() => setOpenWorkTemplateModal(false)}
        setSelectedWorkTemplate={setSelectedWorkTemplate}
        selectedWorkTemplate={selectedWorkTemplate}
      />
    </>
  );
};

export default TimeSheetTableCreateRow;
