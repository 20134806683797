import * as React from 'react';

import colors from '@atom/styles/colors';
import { InventorySchemaDetail } from '@atom/types/inventory';

import PreviewDetailTree from './PreviewDetailTree';
import PreviewDetailViewPane from './PreviewDetailViewPane';

import './detail.css';

interface PassedProps {
  schema: InventorySchemaDetail;
  loading: boolean;
}

type Props = PassedProps;

interface State {
  selectedItem: any;
}

const styles = {
  leftPaneStyles: {
    borderRight: `1px solid ${colors.neutral.typhoon}`,
    boxSizing: 'border-box',
  },
};

class PreviewDetailTab extends React.Component<Props, State> {
  state = {
    selectedItem: {},
  };

  updateSelectedItem = (item: InventorySchemaDetail) => {
    this.setState({ selectedItem: item });
  };

  render() {
    const { schema, loading } = this.props;
    const { selectedItem } = this.state;

    return (
      <>
        <div styleName="left-body-pane-half" style={styles.leftPaneStyles}>
          <PreviewDetailTree
            updateSelectedItem={this.updateSelectedItem}
            selectedItem={selectedItem}
            schema={schema}
            loading={loading}
          />
        </div>
        <div styleName="right-body-pane-half">
          <PreviewDetailViewPane selectedItem={selectedItem} />
        </div>
      </>
    );
  }
}

export default PreviewDetailTab;
