import React from 'react';
import { useDispatch } from 'react-redux';

import * as inventorySchemaActions from '@atom/actions/inventorySchemaActions';
import { RadioGroup, Switch } from '@atom/mui';
import { InventorySchemaDetail } from '@atom/types/inventory';
import { StartEndReadingOption, StockBasedOption } from '@atom/types/schema';

import './cost.css';

const { RadioButton } = RadioGroup;

interface Props {
  schema: InventorySchemaDetail;
  value: StartEndReadingOption;
  stockValue: StockBasedOption;
  setValue: (value: StartEndReadingOption) => void;
}

const ReadingsDetails = ({ schema, value, stockValue, setValue }: Props) => {
  const dispatch = useDispatch();

  const updateSchema = budget => {
    dispatch(
      inventorySchemaActions.requestUpdateSchema({
        schemaId: schema.id,
        rootSchemaId: schema.id,
        budget: {
          ...schema?.budget,
          ...budget,
        },
      }),
    );
  };

  const updateStartEndReadingOption = (option: StartEndReadingOption) => {
    setValue(option);
    updateSchema({ startEndReadingOption: option });
  };

  const handleToggle = () => {
    if (value === StartEndReadingOption.NONE) {
      setValue(StartEndReadingOption.START_END_READING);
      return updateSchema({
        startEndReadingOption: StartEndReadingOption.START_END_READING,
      });
    }

    setValue(StartEndReadingOption.NONE);
    return updateSchema({
      startEndReadingOption: StartEndReadingOption.NONE,
    });
  };

  const isStockBased =
    stockValue === StockBasedOption.STOCK_BASED ||
    stockValue === StockBasedOption.STOCK_BASED_OPTIONAL;

  const toggled =
    !isStockBased &&
    (value === StartEndReadingOption.START_END_READING ||
      value === StartEndReadingOption.START_END_READING_OPTIONAL);

  const isDisabled = schema.isPublished || isStockBased;

  const label = isStockBased ? (
    <div styleName="readings-container">
      <div styleName="readings-bold">Enable Start and End Readings</div>
      <div styleName="readings-subtext">
        Not supported for&nbsp;
        <span styleName="readings-bold">Stock-based Inventory</span>
      </div>
    </div>
  ) : (
    'Enable Start and End Readings'
  );

  return (
    <div styleName="cost-container">
      <Switch
        checked={toggled}
        onChange={handleToggle}
        label={label}
        disabled={isDisabled}
      />
      {toggled && (
        <RadioGroup
          value={value}
          onChange={event => updateStartEndReadingOption(event.target.value)}
        >
          <RadioButton
            value={StartEndReadingOption.START_END_READING}
            label="Default"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Enable for all inventory items of this type.
          </div>
          <RadioButton
            value={StartEndReadingOption.START_END_READING_OPTIONAL}
            label="Optional"
            disabled={isDisabled}
          />
          <div styleName="option-subtext">
            Enable at the individual inventory item level.
          </div>
        </RadioGroup>
      )}
    </div>
  );
};

export default ReadingsDetails;
