import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { bindActionCreators, Dispatch } from 'redux';
import uuid from 'uuid/v4';

import * as inventorySchemaActionCreators from '@atom/actions/inventorySchemaActions';
import DataManagementPreview from '@atom/components/dataManagementPreview/DataManagementPreview';
import { Progress } from '@atom/mui';
import { getInventorySchemaDetailElementsFlattened } from '@atom/selectors/schemaSelectors';
import { InventorySchemaActions } from '@atom/types/actions';
import {
  AttributesType,
  DetailAttributeGroupsItem,
  InventoryDetailRenamePayload,
  InventorySchemaDetail,
} from '@atom/types/inventory';
import { ReduxStore } from '@atom/types/store';
import api from '@atom/utilities/api';
import { INVENTORY_SCHEMAS_ENDPOINT } from '@atom/utilities/endpoints';
import history from '@atom/utilities/history';

import DataManagementAttributeDetailPane from './DataManagementAttributeDetailPane';
import DataManagementAttributePane from './DataManagementAttributePane';
import DataManagementDetailHeader from './DataManagementDetailHeader';
import DataManagementElementPane from './DataManagementElementPane';

import './dataManagementDetail.css';

interface State {
  isEditView: boolean;
  activeElement: string;
  activeAttribute: string;
  activeAttributeGroup: string;
  groupInEditMode: string;
  elementInEditMode: string;
  attributeGroupInEditMode: string;
  attributeInEditMode: string;
  loadingPublishChanges: boolean;
  pendingAttributes: {
    [id: string]: AttributesType;
  };
  pendingAttributeUpdates: {
    [id: string]: AttributesType;
  };
}

interface ReduxStateProps {
  loading: boolean;
  loadingSchema: boolean;
  schema: InventorySchemaDetail;
  elements: InventorySchemaDetail[];
}

interface ReduxDispatchProps {
  inventorySchemaActions: InventorySchemaActions;
}

interface PassedProps {
  match: any;
}

type Props = ReduxStateProps & ReduxDispatchProps & PassedProps;

const initialState: State = {
  isEditView: false,
  activeElement: '',
  activeAttribute: '',
  activeAttributeGroup: '',
  groupInEditMode: '',
  elementInEditMode: '',
  attributeGroupInEditMode: '',
  attributeInEditMode: '',
  loadingPublishChanges: false,
  pendingAttributes: {},
  pendingAttributeUpdates: {},
};

class DataManagementDetail extends React.Component<Props, State> {
  state = initialState;

  componentDidMount = () => {
    const { inventorySchemaActions, match } = this.props;
    inventorySchemaActions.retrieveSchemaDetail({ id: match.params.id });
  };

  toggleView = () => {
    const { isEditView } = this.state;

    this.setState({ isEditView: !isEditView });
  };

  navigateBack = (): void => {
    return history.goBackWithState('/inventory');
  };

  onElementSelect = (activeElement: string) => {
    this.setState({ activeElement, activeAttribute: '' });
  };

  onAttributeSelect = (attributeGroupId: string, attributeId: string) => {
    this.setState({
      activeAttributeGroup: attributeGroupId,
      activeAttribute: attributeId,
    });
  };

  getSelectedSchema = (): any => {
    const { schema, elements } = this.props;
    const { activeElement } = this.state;

    if (R.isNil(schema) || R.isEmpty(schema)) {
      return {};
    }

    const selectedSchema: any =
      elements.find(
        (element: InventorySchemaDetail): boolean =>
          element.id === activeElement,
      ) || {};

    return R.isEmpty(selectedSchema)
      ? selectedSchema
      : this.hydrateSchema(selectedSchema);
  };

  getSelectedAttribute = (element: InventorySchemaDetail): any => {
    const { schema } = this.props;
    const { activeElement, activeAttribute } = this.state;

    if (R.isNil(schema) || R.isEmpty(schema)) {
      return {};
    }

    if (R.isNil(element) || R.isEmpty(element)) {
      return {};
    }

    if (!activeElement || !activeAttribute) {
      return {};
    }

    const attributes = element.attributeGroups.reduce(
      (acc: any, next: DetailAttributeGroupsItem): AttributesType[] => {
        return [...acc, ...next.attributes];
      },
      [],
    );

    return (
      attributes.find(
        (attribute: AttributesType): boolean =>
          attribute.id === activeAttribute,
      ) || {}
    );
  };

  addSchemaOrGroup = (
    parentSchemaId: string,
    group?: string,
    groupId?: string,
  ) => {
    const { schema, inventorySchemaActions } = this.props;

    const assetTitle = group === 'Untitled' ? 'Untitled Sub Item' : 'Untitled';

    inventorySchemaActions.requestCreateSchema({
      rootSchemaId: schema.id,
      name: assetTitle,
      assetType: assetTitle,
      parentSchemaId,
      ...(group ? { group } : {}),
      ...(groupId ? { groupId } : {}),
    });
  };

  addAttributeGroup = (schemaId: string) => {
    const { schema, inventorySchemaActions } = this.props;

    inventorySchemaActions.requestCreateAttributeGroup({
      rootSchemaId: schema.id,
      schemaId,
      name: 'Untitled',
    });
  };

  addAttribute = (schemaId: string, attributeGroupId: string) => {
    const { schema, inventorySchemaActions } = this.props;

    const attribute = {
      rootSchemaId: schema.id,
      schemaId,
      attributeGroupId,
      name: 'Untitled',
      dataType: 'shorttext',
      isVisibleAsSubtext: false,
      isFilterable: false,
    };

    if (!schema.isPublished) {
      inventorySchemaActions.requestCreateAttribute(attribute);
    } else {
      this.addPendingAttribute(attribute);
    }
  };

  addPendingAttribute = (attribute: any) => {
    const { pendingAttributes } = this.state;
    const id = uuid();

    this.setState({
      pendingAttributes: R.assoc(
        id,
        { ...attribute, id, isPending: true },
        pendingAttributes,
      ),
    });
  };

  onGroupRenameSave = (payload: InventoryDetailRenamePayload) => {
    const { schema, inventorySchemaActions } = this.props;
    const { id, name } = payload;

    if (name !== '') {
      inventorySchemaActions.requestUpdateGroup({
        rootSchemaId: schema.id,
        groupId: id,
        name,
      });

      this.setState({ groupInEditMode: '', activeElement: '' });
    }
  };

  onGroupRenameCancel = (payload: InventoryDetailRenamePayload) => {
    const { id, name } = payload;

    this.setState({ groupInEditMode: '' });

    if (name === 'Untitled') {
      const { schema, inventorySchemaActions } = this.props;

      inventorySchemaActions.requestDeleteGroup({
        groupId: id,
        rootSchemaId: schema.id,
      });
    }
  };

  updateGroupInEditMode = (id: string) => {
    this.setState({ groupInEditMode: id });
  };

  onElementRenameSave = (payload: InventoryDetailRenamePayload) => {
    const { schema, inventorySchemaActions } = this.props;
    const { id, name } = payload;

    if (name !== '') {
      inventorySchemaActions.requestUpdateSchema({
        rootSchemaId: schema.id,
        schemaId: id,
        assetType: name,
        name,
      });

      this.setState({ elementInEditMode: '' });
    }
  };

  onElementRenameCancel = (payload: InventoryDetailRenamePayload) => {
    const { id, name } = payload;

    this.setState({ elementInEditMode: '', activeElement: '' });

    if (name === 'Untitled') {
      const { schema, inventorySchemaActions } = this.props;

      inventorySchemaActions.requestDeleteSchema({
        schemaId: id,
        rootSchemaId: schema.id,
      });
    }
  };

  updateElementInEditMode = (id: string) => {
    this.setState({ elementInEditMode: id });
  };

  onSchemaDelete = (schemaId: string): boolean => {
    const { schema, inventorySchemaActions } = this.props;

    inventorySchemaActions.requestDeleteSchema({
      schemaId,
      rootSchemaId: schema.id,
    });

    this.setState({ activeElement: '' });

    return true;
  };

  onGroupDelete = (groupId: string): boolean => {
    const { schema, inventorySchemaActions } = this.props;

    inventorySchemaActions.requestDeleteGroup({
      groupId,
      rootSchemaId: schema.id,
    });

    this.setState({ activeElement: '' });

    return true;
  };

  onAttributeGroupRenameSave = (payload: InventoryDetailRenamePayload) => {
    const { schema, inventorySchemaActions } = this.props;
    const { id, name, attributeGroupId } = payload;

    if (name !== '') {
      inventorySchemaActions.requestUpdateAttributeGroup({
        rootSchemaId: schema.id,
        schemaId: id,
        attributeGroupId,
        name,
      });

      this.setState({
        attributeGroupInEditMode: '',
        activeAttribute: '',
        activeAttributeGroup: '',
      });
    }
  };

  onAttributeGroupRenameCancel = (payload: InventoryDetailRenamePayload) => {
    const { id, name, attributeGroupId, isOnlyGroup } = payload;

    this.setState({ attributeGroupInEditMode: '' });

    if (name === 'Untitled' && !isOnlyGroup) {
      const { schema, inventorySchemaActions } = this.props;

      inventorySchemaActions.requestDeleteAttributeGroup({
        rootSchemaId: schema.id,
        schemaId: id,
        attributeGroupId,
      });
    }
  };

  updateAttributeGroupInEditMode = (id: string) => {
    this.setState({ attributeGroupInEditMode: id });
  };

  onAttributeGroupDelete = (
    schemaId: string,
    attributeGroupId: string,
  ): boolean => {
    const { schema, inventorySchemaActions } = this.props;

    inventorySchemaActions.requestDeleteAttributeGroup({
      rootSchemaId: schema.id,
      schemaId,
      attributeGroupId,
    });

    this.setState({ activeAttributeGroup: '', activeAttribute: '' });

    return true;
  };

  onAttributeRenameSave = (payload: InventoryDetailRenamePayload) => {
    const { schema, inventorySchemaActions } = this.props;
    const { id, name, attributeGroupId, attributeId } = payload;

    if (name === '') {
      return;
    }

    if (!schema.isPublished) {
      inventorySchemaActions.requestUpdateAttribute({
        rootSchemaId: schema.id,
        schemaId: id,
        attributeGroupId,
        attributeId,
        name,
      });

      this.setState({ attributeInEditMode: '' });
    } else {
      this.onPendingAttributeRenameSave(attributeId, name);
    }
  };

  onPendingAttributeRenameSave = (attributeId: string, name: string) => {
    const { pendingAttributes } = this.state;

    const lens = R.lensPath([attributeId, 'name']);

    this.setState({
      pendingAttributes: R.set(lens, name, pendingAttributes),
      attributeInEditMode: '',
    });
  };

  onAttributeRenameCancel = (payload: InventoryDetailRenamePayload) => {
    const { schema, inventorySchemaActions } = this.props;
    const { id, name, attributeGroupId, attributeId } = payload;

    this.setState({
      attributeInEditMode: '',
      activeAttribute: '',
      activeAttributeGroup: '',
    });

    if (name !== 'Untitled') {
      return;
    }

    if (!schema.isPublished) {
      inventorySchemaActions.requestDeleteAttribute({
        rootSchemaId: schema.id,
        schemaId: id,
        attributeGroupId,
        attributeId,
      });
    } else {
      this.onPendingAttributeDelete(attributeId);
    }
  };

  updateAttributeInEditMode = (id: string) => {
    this.setState({ attributeInEditMode: id });
  };

  updateAttribute = (payload: any): any => {
    const { schema, inventorySchemaActions } = this.props;
    const { activeElement, activeAttributeGroup } = this.state;
    const { enumeration, unit, attributeId, ...data } = payload;

    if (!attributeId || !payload.dataType) {
      return;
    }

    if (!schema.isPublished) {
      inventorySchemaActions.requestUpdateAttribute({
        rootSchemaId: schema.id,
        schemaId: activeElement,
        attributeGroupId: activeAttributeGroup,
        attributeId,
        ...(!R.isNil(enumeration) ? { enumeration } : {}),
        ...(!R.isNil(unit) ? { unit } : {}),
        ...data,
      });
    } else if (data.isPending) {
      this.updatePendingAttribute(payload);
    } else {
      this.addPendingAttributeUpdate(payload);
    }
  };

  updatePendingAttribute = (payload: any) => {
    const { pendingAttributes } = this.state;
    const { attributeId, ...data } = payload;

    const lens = R.lensPath([attributeId]);

    this.setState({
      pendingAttributes: R.set(
        lens,
        {
          // @ts-ignore
          ...R.view(lens, pendingAttributes),
          ...data,
        },
        pendingAttributes,
      ),
    });
  };

  addPendingAttributeUpdate = (payload: any) => {
    const { pendingAttributeUpdates, activeElement } = this.state;

    const {
      attributeId,
      enumeration,
      isVisibleAsSubtext,
      isFilterable,
      publishedAttribute,
    } = payload;

    const pendingAttributeUpdate = pendingAttributeUpdates[attributeId];

    if (pendingAttributeUpdate) {
      const isVisibleAsSubtextChanged =
        publishedAttribute.isVisibleAsSubtext !== isVisibleAsSubtext;

      const isFilterableChanged =
        publishedAttribute.isFilterable !== isFilterable;

      const enumerationChanged =
        enumeration && !R.equals(publishedAttribute.enumeration, enumeration);

      // indicates that user has discarded all pending updates
      if (
        !isVisibleAsSubtextChanged &&
        !isFilterableChanged &&
        !enumerationChanged
      ) {
        this.setState({
          pendingAttributeUpdates: R.omit(
            [attributeId],
            pendingAttributeUpdates,
          ),
        });

        return;
      }
    }

    this.setState({
      pendingAttributeUpdates: R.assoc(
        attributeId,
        {
          id: attributeId,
          schemaId: activeElement,
          isPendingUpdate: true,
          isVisibleAsSubtext,
          isFilterable,
          ...(!R.isNil(enumeration) && { enumeration }),
        },
        pendingAttributeUpdates,
      ),
    });
  };

  onAttributeDelete = (
    schemaId: string,
    attributeGroupId: string,
    attributeId: string,
  ): boolean => {
    const { schema, inventorySchemaActions } = this.props;

    if (!schema.isPublished) {
      inventorySchemaActions.requestDeleteAttribute({
        rootSchemaId: schema.id,
        schemaId,
        attributeGroupId,
        attributeId,
      });

      this.setState({ activeAttributeGroup: '', activeAttribute: '' });

      return true;
    }

    return this.onPendingAttributeDelete(attributeId);
  };

  onPendingAttributeDelete = (attributeId: string) => {
    const { pendingAttributes } = this.state;

    this.setState({
      pendingAttributes: R.omit([attributeId], pendingAttributes),
    });

    return true;
  };

  editRootSchema = (
    name: string,
    colorId: number,
    markerId: number,
    isMaterial: boolean,
    locationType: string,
  ) => {
    const { schema, inventorySchemaActions } = this.props;

    inventorySchemaActions.requestUpdateSchema({
      rootSchemaId: schema.id,
      schemaId: schema.id,
      assetType: name,
      name,
      colorId: isMaterial ? colorId : markerId,
      markerId: isMaterial ? null : markerId,
      locationType: isMaterial ? null : locationType,
      isMaterial,
      ...(schema.budget ? { budget: schema.budget } : {}),
    });
  };

  publishRootSchema = () => {
    const { schema, inventorySchemaActions } = this.props;

    if (!schema.isPublished) {
      inventorySchemaActions.requestUpdateSchema({
        rootSchemaId: schema.id,
        schemaId: schema.id,
        isPublished: true,
      });
    } else {
      this.publishPendingChanges();
    }
  };

  publishPendingChanges = async () => {
    const { match, inventorySchemaActions } = this.props;
    const { pendingAttributes, pendingAttributeUpdates } = this.state;

    this.setState({ loadingPublishChanges: true });

    const groupedBySchemaId = R.groupBy<AttributesType>(
      // @ts-ignore
      R.prop('schemaId'),
      R.concat(R.values(pendingAttributes), R.values(pendingAttributeUpdates)),
    );

    const promises = Object.entries(groupedBySchemaId).reduce(
      (acc, [schemaId, attributes]) => [
        ...acc,
        // @ts-ignore
        this.publishSchemaChanges(schemaId, attributes),
      ],
      [],
    );

    // @ts-ignore
    await Promise.all(promises);

    inventorySchemaActions.retrieveSchemaDetail({ id: match.params.id });

    this.setState({
      loadingPublishChanges: false,
      pendingAttributes: {},
      pendingAttributeUpdates: {},
    });

    return true;
  };

  publishSchemaChanges = async (schemaId: string, data: AttributesType[]) => {
    const endpoint = `${INVENTORY_SCHEMAS_ENDPOINT}/${schemaId}/attributes/bulk`;
    const post = data.filter(attribute => attribute.isPending);
    const patch = data.filter(attribute => attribute.isPendingUpdate);

    // POST and PATCH need to be run consecutively to avoid race condition
    if (post.length) {
      await api.post(endpoint, { attributes: post });
    }

    if (patch.length) {
      await api.patch(endpoint, { attributes: patch });
    }
  };

  deleteRootSchema = () => {
    const { schema, inventorySchemaActions } = this.props;

    inventorySchemaActions.requestDeleteSchema({
      schemaId: schema.id,
      detail: true,
    });
  };

  updateSchema = (payload: any) => {
    const { schema, inventorySchemaActions } = this.props;

    inventorySchemaActions.requestUpdateSchema({
      rootSchemaId: schema.id,
      ...(schema.budget ? { budget: schema.budget } : {}),
      ...payload,
    });
  };

  // hydrates schema with pending attributes and attribute updates
  hydrateSchema = (schema: InventorySchemaDetail): InventorySchemaDetail => {
    const { pendingAttributes, pendingAttributeUpdates } = this.state;

    return {
      ...schema,
      attributeGroups: schema.attributeGroups.map(group => {
        const attributes = Object.values(pendingAttributes).filter(
          attribute => attribute.attributeGroupId === group.id,
        );

        return {
          ...group,
          attributes: group.attributes.concat(attributes).map(attribute => {
            const update = pendingAttributeUpdates[attribute.id];

            return update
              ? {
                  ...attribute,
                  ...update,
                  publishedAttribute: attribute,
                }
              : attribute;
          }),
        };
      }),
    };
  };

  render() {
    const { loading, schema, loadingSchema } = this.props;
    const {
      isEditView,
      activeElement,
      activeAttribute,
      groupInEditMode,
      elementInEditMode,
      attributeGroupInEditMode,
      attributeInEditMode,
      loadingPublishChanges,
      pendingAttributes,
      pendingAttributeUpdates,
    } = this.state;

    const selectedSchema = this.getSelectedSchema();
    const showActiveElement = activeElement && !R.isEmpty(selectedSchema);
    const showActiveAttribute =
      activeElement && !R.isEmpty(selectedSchema) && activeAttribute;

    const selectedAttribute = this.getSelectedAttribute(selectedSchema);

    if (loading || R.isEmpty(schema) || loadingPublishChanges) {
      return (
        <div styleName="spinner-container">
          <Progress />
        </div>
      );
    }

    const isDisabled = schema.isPublished;
    const hydratedSchema = this.hydrateSchema(schema);

    return (
      <React.Fragment>
        <DataManagementDetailHeader
          toggleView={this.toggleView}
          schema={hydratedSchema}
          navigateBack={this.navigateBack}
          editRootSchema={this.editRootSchema}
          publishRootSchema={this.publishRootSchema}
          deleteRootSchema={this.deleteRootSchema}
          isDisabled={isDisabled}
          isEditView={isEditView}
          pendingChanges={
            !R.isEmpty(pendingAttributes) || !R.isEmpty(pendingAttributeUpdates)
          }
        />
        <div styleName="content-container">
          {isEditView ? (
            <>
              <DataManagementElementPane
                schema={hydratedSchema}
                addSchemaOrGroup={this.addSchemaOrGroup}
                groupInEditMode={groupInEditMode}
                elementInEditMode={elementInEditMode}
                activeElement={activeElement}
                // @ts-ignore
                onClick={this.onElementSelect}
                onGroupDelete={this.onGroupDelete}
                onSchemaDelete={this.onSchemaDelete}
                onGroupRenameSave={this.onGroupRenameSave}
                onGroupRenameCancel={this.onGroupRenameCancel}
                onElementRenameSave={this.onElementRenameSave}
                onElementRenameCancel={this.onElementRenameCancel}
                updateElementInEditMode={this.updateElementInEditMode}
                updateGroupInEditMode={this.updateGroupInEditMode}
                isDisabled={isDisabled}
              />
              {showActiveElement && (
                <DataManagementAttributePane
                  schema={selectedSchema}
                  activeAttribute={activeAttribute}
                  onClick={this.onAttributeSelect}
                  attributeGroupInEditMode={attributeGroupInEditMode}
                  addAttributeGroup={this.addAttributeGroup}
                  onAttributeGroupRenameSave={this.onAttributeGroupRenameSave}
                  onAttributeGroupRenameCancel={
                    this.onAttributeGroupRenameCancel
                  }
                  onAttributeGroupDelete={this.onAttributeGroupDelete}
                  updateAttributeGroupInEditMode={
                    this.updateAttributeGroupInEditMode
                  }
                  addAttribute={this.addAttribute}
                  updateAttributeInEditMode={this.updateAttributeInEditMode}
                  attributeInEditMode={attributeInEditMode}
                  onAttributeRenameSave={this.onAttributeRenameSave}
                  onAttributeRenameCancel={this.onAttributeRenameCancel}
                  onAttributeDelete={this.onAttributeDelete}
                  updateSchema={this.updateSchema}
                  loading={loadingSchema}
                  isDisabled={isDisabled}
                />
              )}
              {showActiveAttribute && (
                <DataManagementAttributeDetailPane
                  attribute={selectedAttribute}
                  updateAttribute={this.updateAttribute}
                  isDisabled={isDisabled && !selectedAttribute.isPending}
                />
              )}
            </>
          ) : (
            <DataManagementPreview schema={schema} loading={loading} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxStore): ReduxStateProps => ({
  loading: state.loading.loadingSchemaDetail,
  schema: state.inventorySchemaDetail,
  loadingSchema: state.loading.loadingSchema,
  elements: getInventorySchemaDetailElementsFlattened(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
  inventorySchemaActions: bindActionCreators(
    inventorySchemaActionCreators,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataManagementDetail);
