import React from 'react';
import { Dialog } from 'material-ui';

import { Button } from '@atom/mui';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';

import './teamErrorModal.css';

const styles = {
  dialog: {
    bodyStyle: {
      margin: 0,
      padding: 0,
    },
    contentStyle: {
      width: layout.mediumModalWidth,
      paddingRight: '2rem',
      paddingBottom: '1rem',
      height: '100vh',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
  actionButtons: {
    cancel: { marginRight: '1rem' },
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  type: string;
}

const TeamErrorModal = ({ open, onClose, type }: Props) => {
  const actionButtons = [
    <Button style={styles.actionButtons.cancel} onClick={onClose}>
      OK
    </Button>,
  ];

  return (
    <Dialog
      title={`Cannot delete ${type}`}
      actions={actionButtons}
      modal
      contentStyle={styles.dialog.contentStyle}
      titleStyle={styles.dialog.title}
      actionsContainerStyle={styles.dialog.actionsContainerStyle}
      bodyStyle={styles.dialog.bodyStyle}
      open={open}
    >
      <div styleName="modal-container">
        {`The ${type} cannot be deleted because it is in use on one or more work. Please remove the ${type} from all work and try again.`}
      </div>
    </Dialog>
  );
};

export default TeamErrorModal;
