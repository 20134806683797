import React, { useState } from 'react';

import FontIconButton from '@atom/components/common/FontIconButton';
import { useFileUpload } from '@atom/hooks/useFileUpload';
import { Button, Icon, Modal } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';
import { MediaItem } from '@atom/types/media';

import MediaTile from '../../MediaTile';

import './pci.css';

interface Props {
  formInstanceId: string;
  assetId: string;
  preview: boolean;
  media: MediaItem[];
  onChange: (mediaIds: string[]) => void;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
  isSingleDistress?: boolean;
  isEditable: boolean;
}

const styles = {
  icon: {
    padding: '0px',
    height: '24px',
    width: '24px',
  },
  input: {
    display: 'none',
  },
  dialog: {
    body: {
      margin: 0,
      padding: 0,
      maxWidth: layout.formModuleMediaModalWidth,
      minHeight: layout.formModuleMediaModalHeight,
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    uploadPhotoButton: {
      float: 'left',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.25rem',
      fontWeight: '500',
      lineHeight: '20px',
    },
    actionsContainerStyle: {
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
  },
};

const PciMediaModal = ({
  formInstanceId,
  assetId,
  preview,
  media,
  onChange,
  renameMedia,
  removeFile,
  isSingleDistress,
  isEditable,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const [uploadFiles, { loading }] = useFileUpload();

  const handleUpload = async event => {
    const { files } = event.target;

    if (!files.length) {
      return;
    }

    const newMedia = await uploadFiles({
      files,
      subjectId: assetId,
      subjectType: 'formInstanceAsset',
      parentSubjectId: formInstanceId,
    });

    const mediaIds = media.map(medium => medium.id);
    const newMediaIds = newMedia.map(medium => medium.id);

    onChange([...mediaIds, ...newMediaIds]);
  };

  const handleRemove = (mediaId: string) => {
    const mediaIds = media
      .filter(({ id }) => id !== mediaId)
      .map(({ id }) => id);

    // removes association from form
    onChange(mediaIds);
    // removes actual media entity
    removeFile(mediaId, assetId);
  };

  const getFooter = () => {
    return (
      <div>
        <Button
          style={styles.dialog.uploadPhotoButton}
          color="primary"
          disabled={loading || !isEditable}
        >
          upload photo
          <input
            type="file"
            style={styles.input}
            multiple
            onChange={handleUpload}
            disabled={loading || !isEditable}
          />
        </Button>
        <Button
          onClick={() => setOpen(false)}
          color="primary"
          variant="contained"
        >
          done
        </Button>
      </div>
    );
  };

  return (
    <>
      {isSingleDistress ? (
        <Button
          color="primary"
          variant="contained"
          startIcon={<Icon>backup</Icon>}
          disabled={preview}
          onClick={() => setOpen(true)}
        >
          Upload Photos
        </Button>
      ) : (
        <>
          <FontIconButton
            style={styles.icon}
            onClick={() => setOpen(true)}
            color={media.length ? colors.brand.blue : colors.neutral.gray}
            disabled={preview}
            icon="photo"
          />
          <div styleName={`column-suffix-text ${media.length ? 'blue' : ''}`}>
            {media.length}
          </div>
        </>
      )}

      <Modal
        open={open}
        width="lg"
        footer={getFooter()}
        title="Photos"
        style={styles.dialog.body}
      >
        <div styleName="photo-modal-content">
          {media.map((medium: MediaItem) => (
            <MediaTile
              key={medium.id}
              media={medium}
              assetId={assetId}
              removeFile={handleRemove}
              renameMedia={renameMedia}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default PciMediaModal;
