import React from 'react';

import { SchemaTree } from '@atom/types/schema';

export enum SchemaDetailView {
  PREVIEW = 'PREVIEW',
  BUILDER = 'BUILDER',
}

export enum PreviewTab {
  INFO = 'INFO',
  SUB_ITEMS = 'SUB_ITEMS',
  COST = 'COST',
}

interface Context {
  schemaTree: SchemaTree;
  dispatch: React.Dispatch<SchemaTreeActions>;
  refetchSchemaTree: () => Promise<any>;
  view: SchemaDetailView;
  setView: (view: SchemaDetailView) => void;
  previewTab: PreviewTab;
  setPreviewTab: (previewTab: PreviewTab) => void;
}

export const initialState: Context = {
  // @ts-ignore
  schemaTree: {},
  dispatch: () => {},
  refetchSchemaTree: () => Promise.resolve({}),
  view: SchemaDetailView.PREVIEW,
  setView: () => {},
  previewTab: PreviewTab.INFO,
  setPreviewTab: () => {},
};

const SchemaDetailContext = React.createContext<Context>(initialState);

export enum SchemaTreeActionTypes {
  SET_SCHEMA_TREE = 'SET_SCHEMA_TREE',
}

interface SetSchemaTree {
  type: SchemaTreeActionTypes.SET_SCHEMA_TREE;
  data: SchemaTree;
}

type SchemaTreeActions = SetSchemaTree;

export const reducer = (
  state: SchemaTree,
  action: SchemaTreeActions,
): SchemaTree => {
  switch (action.type) {
    case SchemaTreeActionTypes.SET_SCHEMA_TREE:
      return action.data;
    default: {
      return state;
    }
  }
};

export default SchemaDetailContext;
