import naturalSort from 'natural-sort';
import * as R from 'ramda';

import { AttributesType } from '@atom/types/inventory';
import { MediaItem } from '@atom/types/media';
import { WorkOrderAssetTreeType } from '@atom/types/work';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export enum ScdotGroupNameEnum {
  LOCATION = 'Location Information',
  STRUCTURE = 'Structure Information',
  INSPECTION = 'Inspection Data',
}

// Gets list of attributes by given group name
export const getAttributesByGroupName = (
  tree: WorkOrderAssetTreeType,
  groupName: ScdotGroupNameEnum,
) => {
  if (isNilOrEmpty(tree)) {
    return [];
  }

  const attributeIds = R.find(R.propEq('name', groupName))(
    tree?.attributeGroups || [],
  )?.attributes;

  return attributeIds.map(attributeId => tree?.attributes[attributeId]);
};

// Returns array of rows. Each index is an array of 2 attributes which
// correspond to the left and right attribute in each row
export const buildDoubleRows = (
  attributes: AttributesType[],
): AttributesType[][] => {
  if (isNilOrEmpty(attributes)) {
    return [];
  }

  const splitList = R.splitEvery(Math.ceil(attributes.length / 2), attributes);

  return splitList[0].reduce((acc, item, index) => {
    const rightValue = splitList[1][index];
    const newValue = rightValue ? [item, rightValue] : [item];
    return [...acc, newValue];
  }, []);
};

// Sorts media with specific rules. The first photo is the cover photo, then followed
// by a list of media sorted by name. SCDOT will be editing each photo to have 1. ,2.,
// etc as a prefix to control the order.
export const sortReportMedia = (
  media: MediaItem[],
  mainPhotoId: string,
): MediaItem[] => {
  if (isNilOrEmpty(media)) {
    return [];
  }

  const coverPhoto = R.find(R.propEq('id', mainPhotoId))(media);
  const noCoverPhotoMedia = coverPhoto
    ? R.remove(R.findIndex(R.propEq('id', mainPhotoId))(media), 1, media)
    : media;

  const sortedMedia = [...noCoverPhotoMedia].sort((first, second) => {
    return naturalSort({ direction: 'asc' })(first?.name, second?.name);
  });

  return coverPhoto ? [coverPhoto, ...sortedMedia] : sortedMedia;
};
