import React from 'react';

// @ts-ignore
import calculateIcon from '@atom/components/common/svgIcons/calculate.svg';
import { Icon, IconButton } from '@atom/mui';
import colors from '@atom/styles/colors';
import history from '@atom/utilities/history';

import { BudgetingView } from './BudgetingView';

import './budgetingDetail.css';

interface Props {
  activeView: BudgetingView;
  setActiveView: (view: BudgetingView) => void;
}

const viewIconsMap: { [key in BudgetingView]: React.ReactNode } = {
  [BudgetingView.SUMMARY]: 'info',
  [BudgetingView.INVENTORY]: 'category',
  [BudgetingView.WORK]: 'work',
  [BudgetingView.CALCULATOR]: <img src={calculateIcon} />,
  [BudgetingView.PERFORMANCE]: 'trending_up',
};

const BudgetingDetailHeader = ({ activeView, setActiveView }: Props) => {
  return (
    <div styleName="header-container">
      <div styleName="title-bar">
        <div>
          <IconButton
            tooltip="Go back"
            onClick={() => history.push('/budgeting')}
          >
            <Icon color={colors.neutral.white}>arrow_back</Icon>
          </IconButton>
          Level of Service
        </div>
        <div styleName="published">
          <Icon color={colors.neutral.white} style={{ marginRight: '0.5rem' }}>
            lock
          </Icon>
          Published
        </div>
      </div>
      <div styleName="header-info-section">
        <div styleName="info-section-block">
          <div styleName="info-label-container">
            <div>Created By:</div>
            <div>Created On:</div>
          </div>
          <div>
            <div>Patrick Skoglund</div>
            <div>10/10/2022</div>
          </div>
        </div>
        <div styleName="info-section-block">
          <div styleName="info-label-container">
            <div>Inventory Type:</div>
            <div>Published On:</div>
          </div>
          <div>
            <div>Sign</div>
            <div>10/10/2022</div>
          </div>
        </div>
      </div>
      <div styleName="icon-row">
        {Object.keys(BudgetingView).map(view => {
          const styleName =
            view === activeView ? 'icon-container active' : 'icon-container';

          return (
            <div
              key={view}
              onClick={() => setActiveView(view as BudgetingView)}
              styleName={styleName}
            >
              <div styleName="icon">
                <Icon color={colors.neutral.white}>{viewIconsMap[view]}</Icon>
              </div>
              <div styleName="label">{view}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BudgetingDetailHeader;
