import * as R from 'ramda';
import { createSelector } from 'reselect';

export const inventorySchemas = R.pathOr([], ['inventorySchemas']);
export const inventorySchema = R.pathOr({}, ['inventorySchema']);
export const inventorySchemaDetail = R.pathOr({}, ['inventorySchemaDetail']);

export const DEFECT_MARKER_ID = 100;
export const PROTECTIVE_COATING_MARKER_ID = 200;

export const getFilteredSchemaByMarkerId = (state, ownProps) => {
  const markerId = ownProps.markerId;
  const isFhwaForm = !!ownProps.isFhwaForm;
  const schema = inventorySchema(state);

  if (R.isNil(schema) || R.isEmpty(schema)) {
    return schema;
  }

  if (markerId) {
    return {
      ...schema,
      // @ts-ignore
      elementGroups: schema.elementGroups.reduce((acc, elementGroup) => {
        if (elementGroup.isGroup) {
          return acc;
        }

        const elementMarkerId = elementGroup.elements[0].markerId;

        const newElementGroup = {
          ...elementGroup,
          elements: elementGroup.elements.map(element => ({
            ...element,
            hasChildren: false,
          })),
        };

        return elementMarkerId === markerId ? [...acc, newElementGroup] : acc;
      }, []),
    };
  }

  if (isFhwaForm) {
    return {
      ...schema,
      // @ts-ignore
      elementGroups: schema.elementGroups.map(elementGroup => ({
        ...elementGroup,
        elements: elementGroup.elements.map(element => ({
          ...element,
          hasChildren: false,
        })),
      })),
    };
  }

  return schema;
};

export const getSchemaAttributeFilterGroupsSelector = createSelector(
  // @ts-ignore
  ownProps => ownProps.rootSchema,
  schema => {
    if (R.isNil(schema)) {
      return [];
    }

    return schema.attributeGroups
      .map(group => {
        const { name, attributes } = group;

        const filterableAttributes = attributes
          .map(id => ({
            id,
            ...schema.attributes[id],
          }))
          .filter(attribute => attribute.isFilterable);

        return {
          name,
          attributes: filterableAttributes,
        };
      })
      .filter(group => !R.isEmpty(group.attributes));
  },
);

export const mapElementProperties = element => ({
  id: element.id,
  name: element.name,
  assetType: element.assetType,
  attributeGroups: element.attributeGroups,
});

export const getElements = element => {
  const rootElement = mapElementProperties(element);

  if (R.isEmpty(element.elements)) {
    return [rootElement];
  }

  const elements = element.elements.map(elem => R.flatten(getElements(elem)));

  const groupedElements = element.elementGroups.reduce((acc, group) => {
    const elems = group.elements.map(elem => getElements(elem));
    return [...acc, ...R.flatten(elems)];
  }, []);

  return [rootElement, ...elements, ...groupedElements];
};

export const getInventorySchemasSelector = createSelector(
  inventorySchemas,
  schemas => [
    { id: 'all', name: 'All', attributes: {}, attributeGroups: [] },
    ...schemas,
  ],
);

export const getInventorySchemaDetailElementsFlattened = createSelector(
  inventorySchemaDetail,
  schema => {
    if (R.isEmpty(schema)) {
      return [];
    }

    const rootSchema = mapElementProperties(schema);

    // @ts-ignore
    const allElements = schema.elements.reduce((acc, element) => {
      const elements = getElements(element);
      return [...acc, ...R.flatten(elements)];
    }, []);

    // @ts-ignore
    const allGroupedElements = schema.elementGroups.reduce((acc, group) => {
      const elements = group.elements.map(elem => getElements(elem));
      return [...acc, ...R.flatten(elements)];
    }, []);

    return [rootSchema, ...allElements, ...allGroupedElements];
  },
);

export const DATA_TYPE_OPTIONS = [
  {
    dataType: 'shorttext',
    title: 'Short Text',
    icon: 'short_text',
  },
  {
    dataType: 'longtext',
    title: 'Long Text',
    icon: 'format_align_left',
  },
  {
    dataType: 'enumsingle',
    title: 'Single Select',
    icon: 'arrow_drop_down_circle',
  },
  {
    dataType: 'enummultiple',
    title: 'Multi Select',
    icon: 'done_all',
  },
  {
    dataType: 'boolean',
    title: 'True / False',
    icon: 'radio_button_checked',
  },
  {
    dataType: 'number',
    title: 'Numeric',
    icon: 'looks_one',
  },
  {
    dataType: 'currency',
    title: 'Currency',
    icon: 'attach_money',
  },
  {
    dataType: 'date',
    title: 'Date Picker',
    icon: 'today',
  },
];

export const FILTERABLE_DATA_TYPES = new Set([
  'enumsingle',
  'enummultiple',
  'boolean',
  'number',
  'currency',
  'date',
]);

export const getAttributeIconName = dataType => {
  switch (dataType) {
    case 'date': {
      return 'today';
    }
    case 'enummultiple': {
      return 'done_all';
    }
    case 'boolean': {
      return 'radio_button_checked';
    }
    case 'number': {
      return 'looks_one';
    }
    case 'currency': {
      return 'attach_money';
    }
    case 'shorttext': {
      return 'short_text';
    }
    case 'longtext': {
      return 'format_align_left';
    }
    case 'enumsingle': {
      return 'arrow_drop_down_circle';
    }
    default: {
      return 'title';
    }
  }
};

export const addFieldOption = (enumValue, dataType) => {
  const optionNumber =
    dataType === 'enumsingle' ? enumValue.length : enumValue.length + 1;

  const optionName = `Option ${optionNumber}`;
  return [...enumValue, optionName];
};

export const removeFieldOption = (index, enumValue) => {
  return R.remove(index, 1, enumValue);
};

export const updateFieldOption = (optionIndex, value, enumValue) => {
  return enumValue.map((option, index) => {
    return Number(optionIndex) === index ? value : option;
  });
};

export const reorderArray = (data, toIndex, fromIndex, array) => {
  const removeIndexOrder = R.remove(fromIndex, 1, array);
  return R.insert(toIndex, data, removeIndexOrder);
};

export const getAttributeGroupsPayload = attributeGroups => {
  return attributeGroups.map(attributeGroup => ({
    ...attributeGroup,
    attributes: attributeGroup.attributes.map(attribute => attribute.id),
  }));
};

export const reorderAttributeGroups = (schema, source, destination) => {
  const { attributeGroups } = schema;
  const attributeGroup = attributeGroups[source.index];

  const updatedAttributeGroups = reorderArray(
    attributeGroup,
    destination.index,
    source.index,
    attributeGroups,
  );

  const updatedSchema = {
    ...schema,
    attributeGroups: updatedAttributeGroups,
  };

  const attributeGroupsPayload = getAttributeGroupsPayload(
    updatedAttributeGroups,
  );

  const payload = {
    schemaId: schema.id,
    attributeGroups: attributeGroupsPayload,
  };

  return { updatedSchema, payload };
};

export const reorderAttributes = (schema, result) => {
  const { source, destination, draggableId } = result;
  const attributeId = draggableId;
  const originalAttributeGroupId = source.droppableId;
  const newAttributeGroupId = destination.droppableId;

  const originalAttributeGroup = schema.attributeGroups.find(
    group => group.id === originalAttributeGroupId,
  );

  const newAttributeGroup = schema.attributeGroups.find(
    group => group.id === newAttributeGroupId,
  );

  const selectedAttribute = originalAttributeGroup.attributes.find(
    attribute => attribute.id === attributeId,
  );

  if (originalAttributeGroupId === newAttributeGroupId) {
    const updatedAttributes = reorderArray(
      selectedAttribute,
      destination.index,
      source.index,
      originalAttributeGroup.attributes,
    );

    const updatedSchema = {
      ...schema,
      attributeGroups: schema.attributeGroups.map(group => {
        return group.id === originalAttributeGroupId
          ? {
              ...group,
              attributes: updatedAttributes,
            }
          : group;
      }),
    };

    const attributeGroupsPayload = getAttributeGroupsPayload(
      updatedSchema.attributeGroups,
    );

    const payload = {
      schemaId: schema.id,
      attributeGroups: attributeGroupsPayload,
    };

    return { updatedSchema, payload };
  }

  const updatedOriginalAttributes = R.remove(
    source.index,
    1,
    originalAttributeGroup.attributes,
  );

  const updatedNewAttributes = R.insert(
    destination.index,
    selectedAttribute,
    newAttributeGroup.attributes,
  );

  const updatedSchema = {
    ...schema,
    attributeGroups: schema.attributeGroups.map(group => {
      switch (group.id) {
        case originalAttributeGroupId:
          return { ...group, attributes: updatedOriginalAttributes };
        case newAttributeGroupId:
          return { ...group, attributes: updatedNewAttributes };
        default:
          return group;
      }
    }),
  };

  const attributeGroupsPayload = getAttributeGroupsPayload(
    updatedSchema.attributeGroups,
  );

  const payload = {
    schemaId: schema.id,
    attributeGroups: attributeGroupsPayload,
  };

  return { updatedSchema, payload };
};
