import * as React from 'react';
import { Dialog } from 'material-ui';

import { Button } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import layout from '@atom/styles/layout';

import './elements.css';

const styles = {
  dialog: {
    bodyStyle: {
      borderTop: 'initial',
      borderBottom: 'initial',
    },
    contentStyle: {
      width: layout.modalWidth,
      paddingRight: '2rem',
      paddingBottom: '1rem',
    },
    title: {
      fontSize: fonts.xl,
      padding: '1.125em 1.125em 1.125em 1.25em',
      fontWeight: '500',
    },
    actionsContainerStyle: {
      margin: 0,
      padding: 0,
    },
  },
  actionButtons: {
    cancel: { margin: '0.25rem' },
  },
  floatingLabelStyle: {
    color: colors.neutral.dim,
  },
  textFieldStyle: {
    color: colors.neutral.dark,
  },
};

interface Props {
  title: string;
  assetName: string;
  open: boolean;
  closeModal: () => any;
}

const NoElementsDialog = ({ title, assetName, open, closeModal }: Props) => {
  const actionButtons = [<Button onClick={closeModal}>ok</Button>];

  const dialogContent = (
    <div styleName="element-modal-subheading">
      There are no elements under
      <span styleName="element-modal-asset-name"> {assetName}</span>
    </div>
  );

  return (
    <div>
      <Dialog
        title={title}
        // @ts-ignore
        titleStyle={styles.dialog.title}
        // @ts-ignore
        style={styles.dialog.root}
        actions={actionButtons}
        modal
        bodyStyle={styles.dialog.bodyStyle}
        actionsContainerStyle={styles.dialog.actionsContainerStyle}
        contentStyle={styles.dialog.contentStyle}
        open={open}
      >
        {dialogContent}
      </Dialog>
    </div>
  );
};

export default NoElementsDialog;
