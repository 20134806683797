import * as React from 'react';

import AdditionalOptions from '@atom/components/common/AdditionalOptions';
import RenameModal from '@atom/components/common/RenameModal';
import colors from '@atom/styles/colors';
import { MediaItem } from '@atom/types/media';
import { FILES_ENDPOINT } from '@atom/utilities/endpoints';

import '../formInstance.css';

interface PassedProps {
  assetId: string;
  media: MediaItem;
  removeFile: (mediaId: string, subjectId: string) => void;
  renameMedia: (mediaId: string, subjectId: string, name: string) => void;
}

type Props = PassedProps;

interface State {
  name: string;
}

class FhwaMediaTile extends React.Component<Props, State> {
  state = {
    name: this.props.media.name,
  };

  renameAction = (id: string, name: string) => {
    const { renameMedia, media } = this.props;
    this.setState({ name });

    renameMedia(id, media.subjectId, name);
  };

  render() {
    const { media, assetId, removeFile } = this.props;
    const { name } = this.state;
    const { fileExtension, fileId, id, type } = media;

    return (
      <div styleName="fhwa-media-tile-container">
        <div styleName="fhwa-media-image-container">
          <img
            styleName="fhwa-image"
            src={`${FILES_ENDPOINT}/${fileId}_180`}
            alt={name}
          />
        </div>
        <div styleName="fhwa-media-tile-info">
          <div styleName="fhwa-file-name">{`${name}.${fileExtension}`}</div>
          <AdditionalOptions
            iconStyle={{ color: colors.neutral.gray }}
            menuItems={[
              {
                type: 'trigger',
                primaryText: 'edit',
                id,
                element: (
                  <RenameModal
                    id={id}
                    name={name}
                    type={type}
                    renameAction={this.renameAction}
                  />
                ),
              },
              {
                type: 'dialog',
                primaryText: 'delete',
                modalProps: {
                  labelCancel: 'Cancel',
                  labelConfirm: 'Delete',
                  confirmAction: (): void => removeFile(id, assetId),
                  title: `Delete ${name}`,
                  text: 'Are you sure you want to delete this photo?',
                },
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default FhwaMediaTile;
