import React, { useEffect, useState } from 'react';

import { TextField } from '@atom/mui';
import { TaskField, TaskFieldDataType } from '@atom/types/task';

import './taskFields.css';

interface Props {
  field: TaskField;
  taskId?: string;
  onChange: (fieldId: string, value: any) => void;
  onBlur: (fieldId: string, value: any) => void;
  isDisabled: boolean;
  previewView?: boolean;
  error?: boolean;
  helperText?: string;
  onClick?: () => void;
}

const TaskTextField = ({
  field,
  taskId,
  onChange,
  onBlur,
  isDisabled,
  previewView = false,
  error = false,
  helperText,
  onClick,
}: Props) => {
  const [value, setValue] = useState<string>(field?.value || '');

  useEffect(() => {
    setValue(field?.value || '');
  }, [taskId, field?.id]);

  const handleChange = event => {
    setValue(event.target.value);
    onChange(field.id, event.target.value);
  };

  const handleOnBlur = event => {
    if (field?.value !== event.target.value) {
      setValue(event.target.value);
      onBlur(field.id, event.target.value);
    }
  };

  const style = {
    flex:
      field.dataType === TaskFieldDataType.LONG_TEXT || previewView ? 1 : 0.66,
  };

  const title = field.required ? `* ${field.title}` : field.title;

  return (
    <TextField
      id={field.id}
      label={title}
      value={value}
      style={style}
      disabled={isDisabled}
      onChange={handleChange}
      onBlur={handleOnBlur}
      multiline={field.dataType === TaskFieldDataType.LONG_TEXT}
      error={error}
      helperText={helperText}
      onClick={onClick}
    />
  );
};

export default TaskTextField;
