import React, { useContext, useEffect, useMemo, useState } from 'react';

// @ts-ignore
import collapseAll from '@atom/images/collapseAll.svg';
// @ts-ignore
import expandAll from '@atom/images/expandAll.svg';
import { Checkbox, Icon, ListTable } from '@atom/mui';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { toggleFromSet } from '@atom/utilities/setUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import BudgetDetailItemTable from './BudgetDetailItemTable';
import {
  BUDGET_COL_WIDTH,
  CATEGORY_EXPAND_LIMIT,
  COMMENT_COL_WIDTH,
  COST_TYPE_COL_WIDTH,
} from './budgetDetailUtils';

import './budgetDetail.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const styles = {
  collapse: { width: '100%' },
  expandableTableCell: {
    padding: 0,
    border: 'none',
  },
};

interface Props {
  refetchParentUnit: () => void;
  getBudgetCategories: (input: any) => void;
}

const BudgetDetailCategories = ({
  refetchParentUnit,
  getBudgetCategories,
}: Props) => {
  const {
    budgetCategories,
    setEditingItem,
    expandedCategories,
    setExpandedCategories,
    excludeZeroBudgetItems,
    setExcludeZeroBudgetItems,
  } = useContext(BudgetDetailContext);

  const [showCollapse, setShowCollapse] = useState<boolean>(
    !(budgetCategories?.length < CATEGORY_EXPAND_LIMIT),
  );

  const allCategoryIds = useMemo(
    () => budgetCategories?.map(category => category.id) || [],
    [budgetCategories],
  );

  useEffect(() => {
    if (
      expandedCategories.size === 0 &&
      budgetCategories?.length < CATEGORY_EXPAND_LIMIT
    ) {
      setShowCollapse(false);
    } else {
      setShowCollapse(true);
    }
  }, [expandedCategories, budgetCategories]);

  const toggleRowOpen = (categoryId: string) => {
    setExpandedCategories(toggleFromSet(expandedCategories, categoryId));
  };

  const handleExpandAll = () => {
    if (budgetCategories?.length < CATEGORY_EXPAND_LIMIT) {
      setExpandedCategories(new Set(allCategoryIds));
    }
  };

  const handleCollapseAll = () => {
    setExpandedCategories(new Set([]));
  };

  return (
    <>
      <div styleName="category-controls-container">
        {showCollapse ? (
          <span styleName="all-control" onClick={() => handleCollapseAll()}>
            <img src={collapseAll} /> Collapse All
          </span>
        ) : (
          <span styleName="all-control" onClick={() => handleExpandAll()}>
            <img src={expandAll} /> Expand All
          </span>
        )}
        <span styleName="category-controls-divider">&nbsp;</span>
        <span>
          <Checkbox
            id="hide_budget"
            onChange={() => setExcludeZeroBudgetItems(!excludeZeroBudgetItems)}
            checked={excludeZeroBudgetItems}
          />
        </span>
        <label styleName="clickable" htmlFor="hide_budget">
          Hide budget items without a budget
        </label>
      </div>
      {!isNilOrEmpty(budgetCategories) && (
        <ListTable fullHeight={false}>
          <TableHead onClick={() => setEditingItem(null)}>
            <TableRow>
              <TableCell variant="head">NAME</TableCell>
              <TableCell width={COST_TYPE_COL_WIDTH} variant="head">
                COST TYPE
              </TableCell>
              <TableCell width={BUDGET_COL_WIDTH} align="right" variant="head">
                BUDGET
              </TableCell>
              <TableCell width={COMMENT_COL_WIDTH} variant="head">
                COMMENT
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetCategories.map(budgetCategory => (
              <React.Fragment key={budgetCategory.id}>
                <TableRow onClick={() => setEditingItem(null)}>
                  <TableCell>
                    <span
                      onClick={() => toggleRowOpen(budgetCategory.id)}
                      styleName="budget-table-cell clickable"
                    >
                      <Icon>
                        {expandedCategories.has(budgetCategory.id)
                          ? 'arrow_drop_down'
                          : 'arrow_right'}
                      </Icon>
                      {budgetCategory.name}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span styleName="budget-table-cell" />
                  </TableCell>
                  <TableCell align="right">
                    <span styleName="budget-table-cell budget-total-cell">
                      {numberToLocaleString(budgetCategory?.totalBudget)}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span styleName="budget-table-cell" />
                  </TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell style={styles.expandableTableCell} colSpan={4}>
                    {expandedCategories.has(budgetCategory.id) && (
                      <BudgetDetailItemTable
                        budgetCategory={budgetCategory}
                        refetchParentUnit={refetchParentUnit}
                        getBudgetCategories={getBudgetCategories}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </ListTable>
      )}
    </>
  );
};

export default BudgetDetailCategories;
